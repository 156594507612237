import jsPDF from 'jspdf'
import 'jspdf-autotable'
// Date Fns is used to format the dates we receive
// from our API call
import { format } from 'date-fns'

// define a generatePDF function that accepts a tickets argument
const PdfGen = (value) => {
  // initialize jsPDF
  //   const doc = new jsPDF();
  var doc = new jsPDF('l', 'pt', 'a4')
  var d = format(new Date(), 'yyyy-MM-dd')

  // define the columns we want and their titles
  const pretripColumn = ['Pre-Trip ID', 'Trip Date', 'Pre-Trip Time']
  const incabColumn = ['Incab ID', 'Incab Date']
  const ManueverColumn = [
    'Maneuver',
    'Mirrors',
    'Steering',
    'Encroaches',
    'Maneuver Date',
    'Maneuver Time',
    'Score',
    'Comments',
  ]

  const driveColumn = [
    'Bears',
    'Brake',
    'Downshifting',
    'FollowDistance',
    'Comments',
    'GearPattern',
    'Brake',
    'HitCurb',
    'HoverH',
    'Comments',
    'InterCoasting',
    'IntersectionMirrors',
    'IntersectiontrafficCheck',
    'MergingSmooth',
    'MergingTrafficCheck',
    'TimeIn',
    'TimeOut',
    'Timing',
    'TotalTime',
    'Reaction',
  ]
  const dpsTestColumn = ['Test Date', 'Test Hours', 'Comments', 'Test Result']

  const tripType = Object.keys(value)
  const pretripRow = []
  const incabRow = []
  const maneuverRow = []
  const driveRow = []
  const dpsTestRow = []
  const sample = ['Maneuver', 'Pretrip', 'Drive', 'Incab']

  const a = (e, k) => {
    e.forEach()
  }
  const TripeData = {
    Pretrip: function (e) {
      e.forEach((report) => {
        if (!!report.preTripId) {
          const pretripData = [
            report.preTripId,
            report.tripDate,
            report.preTripTime,
          ]
          pretripRow.push(pretripData)
        }
      })
    },
    Maneuver: function (e) {
      e.forEach((report) => {
        if (!!report.maneuver) {
          const maneuverData = [
            report.maneuver,
            report.mirrors,
            report.steering,
            report.encroaches,
            report.maneuverDate,
            report.maneuverTime,
            report.score,
            report.comments,
          ]
          maneuverRow.push(maneuverData)
        }
      })
    },
    Drive: function (e) {
      e.forEach((report) => {
        if (!!report.bears) {
          const driveData = [
            report.bears,
            report.brake,
            report.downshifting,
            report.followDistance,
            report.comments,
            report.gearPattern,
            report.brake,
            report.hitCurb,
            report.hoverH,
            report.lane,
            report.interCoasting,
            report.intersectionMirrors,
            report.intersectiontrafficCheck,
            report.mergingSmooth,
            report.mergingTrafficCheck,
            report.timeIn,
            report.timeOut,
            report.timing,
            report.totalTime,
            report.lane,
          ]
          driveRow.push(driveData)
        }
      })
    },
    Incab: function (e) {
      e.forEach((report) => {
        if (!!report.incabId) {
          const incabData = [report.incabId, report.incabDate]
          incabRow.push(incabData)
        }
      })
    },
    Dps: function (e) {
      e.forEach((report) => {
        if (!!report.testDate) {
          const dpsTestData = [
            report.testDate,
            report.testHours,
            report.comments,
            report.testResult,
          ]
          dpsTestRow.push(dpsTestData)
        }
      })
    },
  }
  tripType.forEach((type) => TripeData[type](value[type]))
  // define an empty array of rows
  // for each report pass all its data into an array
  var data = [
    { type: pretripColumn, data: pretripRow },
    { type: incabColumn, data: incabRow },
    { type: ManueverColumn, data: maneuverRow },
    { type: driveColumn, data: driveRow },
    { type: dpsTestColumn, data: dpsTestRow },
  ]
  data.forEach((i) => {
    if (i.data.length !== 0) {
      doc.autoTable(i.type, i.data, {
        startY: doc.autoTableEndPosY() + 50,
        pageBreak: 'auto',
      })
    }
  })
  const date = Date().split(' ')
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4]
  // report title. and margin-top + margin-left
  doc.text(`Report` + d, 10, 15)
  //   // we define the name of our PDF file.
  doc.save(`Report_${d}.pdf`)
}

export default PdfGen
