import { Col, FormGroup, Label, Input } from 'reactstrap'

export const CheckBoxViewComponet = (props) => {
  const { label, value } = props

  return (
    <Col>
      <FormGroup check>
        <Label check>
          <Input type="checkbox" checked={value == 'Yes' ? true : false} />
          {label}
        </Label>
      </FormGroup>
    </Col>
  )
}
