import React, { useState } from 'react'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import API from '../constants/API'
import { CHANGE_PASSWORD } from '../constants/Apipath'
import { useSnackbar } from 'notistack'
import { CustomMessage, CustomPageLoading } from '../function/Helperfunction'

import { SIGNIN_ROUTE } from '../constants/RoutePaths'
import { useHistory } from 'react-router'
import Iconawesomeeye from '../images/Iconawesomeeye.svg'
import Iconionicmdeyeoff from '../images/Iconionicmdeyeoff.svg'
export default function UpdateFogetpassword(props) {
  const { email } = props
  const [oldpasswordShown, SetoldpasswordShown] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmpasswordShown, setconfirmPasswordShown] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const oldTogglePassword = () => {
    SetoldpasswordShown(!oldpasswordShown)
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const confirmTogglePassword = () => {
    setconfirmPasswordShown(!confirmpasswordShown)
  }
  const history = useHistory()
  const ChangePassword = async (values) => {
    const config = {
      method: 'PUT',
      url: CHANGE_PASSWORD,
      data: {
        email: email,
        newpassword: values.newpassword,
        oldpassword: values.oldpassword,
      },
    }
    setloading(true)

    try {
      const response = await API(config)

      if (response.status === 200) {
        history.push(SIGNIN_ROUTE)
        CustomMessage(response.data.message, 'success', enqueueSnackbar)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object({
    oldpassword: Yup.string()
      .trim()
      .min(6, 'OTP must be six characters')
      .required('OTP is required'),
    newpassword: Yup.string()
      .trim()
      .min(6, 'Password mst be six characters')
      .required('New Password Required'),
    confirmPassword: Yup.string()
      .min(6, 'Password must be six characters')
      .required('Confirm password is required')
      .test('passwords-match', 'Password mismatch', function (value) {
        return this.parent.newpassword === value
      }),
  })
  const { handleSubmit, handleChange, values, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        oldpassword: '',
        newpassword: '',
      },
      validationSchema,
      onSubmit: (values) => ChangePassword(values),
    })
  return (
    <>
      <div>
        {loading && <CustomPageLoading />}

        <div className="passwordform">
          <p>Enter your otp</p>
          <Form form="true" onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="oldpassword">OTP</Label>
              <InputGroup>
                <Input
                  type={oldpasswordShown ? 'text' : 'password'}
                  name="oldpassword"
                  id="oldpassword"
                  placeholder="Enter Your OTP"
                  value={values.oldpassword}
                  invalid={
                    errors.oldpassword && touched.oldpassword ? true : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={oldTogglePassword}
                    style={{ cursor: 'pointer' }}>
                    {!oldpasswordShown ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </InputGroupText>
                </InputGroupAddon>
                <FormFeedback>
                  {errors.oldpassword && touched.oldpassword
                    ? errors.oldpassword
                    : ''}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="newpassword">New Password</Label>
              <InputGroup>
                <Input
                  type={passwordShown ? 'text' : 'password'}
                  name="newpassword"
                  id="newpassword"
                  value={values.newpassword}
                  invalid={
                    errors.newpassword && touched.newpassword ? true : false
                  }
                  placeholder="Enter New Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter New Password"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={togglePassword}
                    style={{ cursor: 'pointer' }}>
                    {!passwordShown ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </InputGroupText>
                </InputGroupAddon>
                <FormFeedback>
                  {errors.newpassword && touched.newpassword
                    ? errors.newpassword
                    : ''}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="confirmPassword">Confirm Password</Label>
              <InputGroup>
                <Input
                  type={confirmpasswordShown ? 'text' : 'password'}
                  name="confirmPassword"
                  placeholder="Confirm Your Password"
                  id="confirmPassword"
                  value={values.confirmPassword}
                  placeholder="Enter Confirm Password"
                  invalid={
                    errors.confirmPassword && touched.confirmPassword
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={confirmTogglePassword}
                    style={{ cursor: 'pointer' }}>
                    {!confirmpasswordShown ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </InputGroupText>
                </InputGroupAddon>
                <FormFeedback>
                  {errors.confirmPassword && touched.confirmPassword
                    ? errors.confirmPassword
                    : ''}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            {/* button */}
            <div className="top-btn">
              <Button type="submit" color="primary">
                Save Changes
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}
