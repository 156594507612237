import React, { useState } from 'react'
import { Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import API from '../constants/API'
import { SEND_OPT } from '../constants/Apipath'
import { useSnackbar } from 'notistack'
import { CustomMessage, CustomPageLoading } from '../function/Helperfunction'
import Logo from '../images/logo.png'
import UpdateFogetpassword from './UpdateForgetpassword'
import { Link, useHistory } from 'react-router-dom'
import { SIGNIN_ROUTE } from '../constants/RoutePaths'
export default function Forgetpassword() {
  const [email, setemail] = useState('')
  const [Otp, setOtp] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const history = useHistory()
  const SendOpt = async (values) => {
    setemail(values.email)
    const config = {
      method: 'POST',
      url: SEND_OPT,
      data: values,
    }
    setloading(true)

    try {
      const response = await API(config)
      const { data } = response
      if (response.status === 200) {
        setOtp(false)
        CustomMessage(data.message, 'success', enqueueSnackbar) //call toster
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email Required'),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => SendOpt(values),
  })
  return (
    <>
      <div>
        {loading && <CustomPageLoading />}
        <section className="login-page">
          <div className="form">
            <img onClick={() => history.push(SIGNIN_ROUTE)} src={Logo} />
            {Otp ? (
              <div className="passwordform">
                <h4>Forgot Password</h4>
                <p>Enter your email address</p>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      value={values.email}
                      id="exampleEmail"
                      invalid={touched.email && errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter your email"
                    />
                    <FormFeedback>
                      {errors.email && touched.email
                        ? errors.email
                        : errors.email}
                    </FormFeedback>
                  </FormGroup>

                  <Button color="primary" type="submit" block>
                    Send OTP
                  </Button>
                  <div className='frgtsignin'>
                  <Link to={SIGNIN_ROUTE} >
                    Signin
                  </Link>
                  </div>
                 
                </Form>
              </div>
            ) : (
              <UpdateFogetpassword email={email} />
            )}
          </div>
        </section>
      </div>
    </>
  )
}
