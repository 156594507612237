import React, { useState, useEffect, Fragment } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { authHeader, getUserId } from '../../function/Mainfunction'
import API from '../../constants/API'
import { LIST_BATCH } from '../../constants/Apipath'
import { Table } from 'reactstrap'
import { CustomPageLoading } from '../../function/Helperfunction'
import { BATCH_DETAILS_ROUTE } from '../../constants/RoutePaths'

export default function CalendarFun() {
  const [value, setValue] = useState(new Date())
  const [batchData, setbatchData] = useState([])
  const [loading, setloading] = useState(false)
  const historyRout = useHistory()
  const [activeMonth, setactiveMonth] = useState(
    moment(new Date()).format('MM-YYYY')
  )
  const [month, setMonth] = useState([])

  const onChange = (value) => {
    setactiveMonth(moment(value.activeStartDate).format('MM-YYYY'))
    //  setValue(value)
  }
  const chkfun = async (e) => {
    const currentDate = moment(e).format('YYYY-MM-DD')

    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_BATCH,
      params: {
        batchStartDate: currentDate,
        instructorId: getUserId(),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      setbatchData(data)
    } catch (error) {
      if (error.response) {
        let { data } = error.response
      }
    } finally {
      setloading(false)
    }
  }
  useEffect(() => {
    chkfun()
  }, [])
  const GetMonth = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_BATCH,
      params: {
        monthSearch: activeMonth,
        instructorId: getUserId(),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      setMonth(data)
    } catch (error) {
      if (error.response) {
        let { data } = error.response
      }
    } finally {
      setloading(false)
    }
  }
  useEffect(() => {
    GetMonth()
  }, [activeMonth])

  var months = []
  month.forEach((m) => {
    var start = moment(m.batchStartDate).format('DD')
    var end = moment(m.batchEndDate).format('DD')
    var i
    for (i = start; i <= end; i++) {
      months.push(
        `${i.toString().padStart(2, 0) + '/' + activeMonth.substring(0, 2)}`
      )
    }
  })

  const goTobatch = (id) => {
    historyRout.push(`${BATCH_DETAILS_ROUTE}/${id}`)
  }
  return (
    <div>
      {loading && <CustomPageLoading />}
      <Calendar
        onActiveStartDateChange={onChange}
        value={value}
        onClickDay={chkfun}
        allowPartialRange={true}
        tileContent={({ date, view }) => {
          if (months.find((x) => x === moment(date).format('DD/MM'))) {
            return <p style={{ margin: '0px', padding: 0 }}>__</p>
          }
        }}
        defaultActiveStartDate={new Date()}
      />
      {batchData && batchData.length ? (
        <div>
          {batchData.map(
            (
              {
                batchStartDate,
                batchEndDate,
                batchEndTime,
                batchStartTime,
                batchName,
                batchId,
              },
              index
            ) => (
              <Fragment key={index}>
                <Table
                  style={{ cursor: 'pointer' }}
                  onClick={() => goTobatch(batchId)}
                  className="event-table">
                  <thead>
                    <tr>
                      <th colspan="3">{batchName}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{index + 1}</td>

                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.788"
                          height="15.758"
                          viewBox="0 0 13.788 15.758">
                          <path
                            id="calendar-alt"
                            d="M12.311,1.97H10.834V.369A.37.37,0,0,0,10.464,0h-.246a.37.37,0,0,0-.369.369v1.6H3.939V.369A.37.37,0,0,0,3.57,0H3.324a.37.37,0,0,0-.369.369v1.6H1.477A1.478,1.478,0,0,0,0,3.447V14.281a1.478,1.478,0,0,0,1.477,1.477H12.311a1.478,1.478,0,0,0,1.477-1.477V3.447A1.478,1.478,0,0,0,12.311,1.97ZM1.477,2.955H12.311a.494.494,0,0,1,.492.492V4.924H.985V3.447A.494.494,0,0,1,1.477,2.955ZM12.311,14.773H1.477a.494.494,0,0,1-.492-.492V5.909H12.8v8.371A.494.494,0,0,1,12.311,14.773ZM4.555,9.849H3.324a.37.37,0,0,1-.369-.369V8.248a.37.37,0,0,1,.369-.369H4.555a.37.37,0,0,1,.369.369V9.479A.37.37,0,0,1,4.555,9.849Zm2.955,0H6.279a.37.37,0,0,1-.369-.369V8.248a.37.37,0,0,1,.369-.369H7.51a.37.37,0,0,1,.369.369V9.479A.37.37,0,0,1,7.51,9.849Zm2.955,0H9.233a.37.37,0,0,1-.369-.369V8.248a.37.37,0,0,1,.369-.369h1.231a.37.37,0,0,1,.369.369V9.479A.37.37,0,0,1,10.464,9.849ZM7.51,12.8H6.279a.37.37,0,0,1-.369-.369V11.2a.37.37,0,0,1,.369-.369H7.51a.37.37,0,0,1,.369.369v1.231A.37.37,0,0,1,7.51,12.8Zm-2.955,0H3.324a.37.37,0,0,1-.369-.369V11.2a.37.37,0,0,1,.369-.369H4.555a.37.37,0,0,1,.369.369v1.231A.37.37,0,0,1,4.555,12.8Zm5.909,0H9.233a.37.37,0,0,1-.369-.369V11.2a.37.37,0,0,1,.369-.369h1.231a.37.37,0,0,1,.369.369v1.231A.37.37,0,0,1,10.464,12.8Z"
                            fill="#e44a4a"
                          />
                        </svg>
                        {batchStartDate} - {batchEndDate}
                      </td>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9.44"
                          height="12.587"
                          viewBox="0 0 9.44 12.587">
                          <path
                            id="watch"
                            d="M7.867,2.776V.59A.592.592,0,0,0,7.277,0H2.163a.592.592,0,0,0-.59.59V2.776a4.72,4.72,0,0,0,0,7.036V12a.592.592,0,0,0,.59.59H7.277a.592.592,0,0,0,.59-.59V9.812a4.72,4.72,0,0,0,0-7.036ZM2.36.787H7.08V2.205a4.724,4.724,0,0,0-4.72,0ZM7.08,11.8H2.36V10.382a4.724,4.724,0,0,0,4.72,0ZM4.72,10.227A3.933,3.933,0,1,1,8.654,6.294,3.932,3.932,0,0,1,4.72,10.227Zm1.2-2.267L4.447,6.886a.3.3,0,0,1-.12-.238V3.695a.3.3,0,0,1,.3-.3h.2a.3.3,0,0,1,.3.3V6.4l1.273.927a.3.3,0,0,1,.064.413l-.116.16a.294.294,0,0,1-.411.064Z"
                            transform="translate(0 0)"
                            fill="#e44a4a"
                          />
                        </svg>
                        {batchStartTime} - {batchEndTime}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Fragment>
            )
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
