import React, { Fragment, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  Container,
  Button,
  Card,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  CustomInput,
  FormFeedback,
  Form,
} from 'reactstrap'
import * as Yup from 'yup'
import {
  CustomMessage,
  CustomPageLoading,
} from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import API from '../../../constants/API'
import { useFormik } from 'formik'
import moment from 'moment'
import { NUMBER_REGEX, PHONE_NUMBER_REGEX } from '../../../constants/Regex'
import {
  CREATE_USER_URL,
  USER_DETAILS,
  UPDATE_USER_DETAILS,
} from '../../../constants/Apipath'
import {
  userName,
  getRoleId,
  authHeader,
  rootUser,
} from '../../../function/Mainfunction'
import { USER_ROUTE } from '../../../constants/RoutePaths'

export default function UpdateUser() {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { userId } = useParams()
  const user_name = userName()
  const currentDate = new Date()

  const Goback = () => {
    history.goBack()
  }
  const [loading, setloading] = useState(false)
  const [formData, setformData] = useState({})

  useEffect(() => {
    UserDeatils()
  }, [])

  const UserDeatils = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: USER_DETAILS,
      params: {
        userId: userId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { data } = response.data
      setformData(data)
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.warn(data)
      }
    } finally {
      setloading(false)
    }
  }

  const UpdateUser = async (values) => {
    const config = {
      method: 'PUT',
      url: UPDATE_USER_DETAILS,
      headers: authHeader(),
      data: values,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status } = response.data
      if (status === 'Success') {
        CustomMessage(
          'User Details Update Successful',
          'success',
          enqueueSnackbar
        ) //call toster
        resetForm({})
        history.push(USER_ROUTE)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name Required'),
    lastName: Yup.string().required('Last name Required'),
    email: Yup.string().email('Invalid Email').required('Email Required'),
    dateOfBirth: Yup.string()
      .max(new Date(), 'Invalid Date')
      .required('Date Required'),
    phoneNumber: Yup.string()
      .matches(PHONE_NUMBER_REGEX, 'invalid phone number')
      .max(10, 'invalid phone number')
      .required('Phone number Required'),
    city: Yup.string().required('City Required'),
    zip: Yup.string()
      .max(6, 'Invalid Zip code')
      .matches(NUMBER_REGEX, 'Invalid Zip code')
      .required('Zip Required'),
    state: Yup.string().required('State Required'),
    address: Yup.string().required('Address Required'),
    roleId: Yup.string().required('Role Name Required'),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => UpdateUser(values),
  })
  return (
    <Fragment>
      <div className="wrapper">
        {loading && <CustomPageLoading />}
        <div className="page-title">
          <h4>Update User</h4>
          <div className="btn-right">
            <Button onClick={Goback} color="primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.543"
                height="8.695"
                viewBox="0 0 5.543 9.695">
                <path
                  id="Icon_ionic-ios-arrow-back"
                  data-name="Icon ionic-ios-arrow-back"
                  d="M12.921,11.04,16.59,7.374a.693.693,0,1,0-.981-.978l-4.156,4.153a.691.691,0,0,0-.02.955l4.173,4.182a.693.693,0,1,0,.981-.978Z"
                  transform="translate(-11.251 -6.194)"
                  fill="#fff"
                />
              </svg>
              Back
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <Card body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">First name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="Enter first name"
                      value={values.firstName}
                      invalid={
                        errors.firstName && touched.firstName ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.firstName && touched.firstName
                        ? errors.firstName
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Last name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Enter last name"
                      value={values.lastName}
                      invalid={
                        errors.lastName && touched.lastName ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.lastName && touched.lastName
                        ? errors.lastName
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Email address</Label>
                    <Input
                      type="email"
                      name="email"
                      disabled={true}
                      id="email"
                      placeholder="Enter email"
                      value={values.email}
                      invalid={errors.email && touched.email ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.email && touched.email ? errors.email : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Gender</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio"
                        value={'male'}
                        name="gender"
                        label="Male"
                        onChange={handleChange}
                        checked={values.gender === 'male'}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio2"
                        value={'female'}
                        name="gender"
                        label="Female"
                        onChange={handleChange}
                        checked={values.gender === 'female'}
                      />
                    </Row>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">DOB</Label>
                    <Input
                      type="date"
                      name="dateOfBirth"
                      id="dateOfBirth"
                      placeholder=""
                      value={moment(values.dateOfBirth).format('YYYY-MM-DD')}
                      onBlur={handleBlur}
                      invalid={
                        touched.dateOfBirth && errors.dateOfBirth ? true : false
                      }
                      onChange={handleChange}
                      placeholder="Enter DOB"
                    />
                    <FormFeedback>
                      {touched.dateOfBirth && errors.dateOfBirth}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Phone number</Label>
                    <Input
                      type="tel"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="Enter phone number"
                      value={values.phoneNumber}
                      invalid={
                        errors.phoneNumber && touched.phoneNumber ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.phoneNumber && touched.phoneNumber
                        ? errors.phoneNumber
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Address</Label>
                    <Input
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Enter address"
                      value={values.address}
                      invalid={errors.address && touched.address ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.address && touched.address ? errors.address : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">City</Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="Enter City"
                      value={values.city}
                      invalid={errors.city && touched.city ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.city && touched.city ? errors.city : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">State</Label>
                    <Input
                      type="text"
                      name="state"
                      id="state"
                      placeholder="Enter State"
                      value={values.state}
                      invalid={errors.state && touched.state ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.state && touched.state ? errors.state : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Zip</Label>
                    <Input
                      type="text"
                      name="zip"
                      id="zip"
                      placeholder="Enter Zip"
                      value={values.zip}
                      invalid={errors.zip && touched.zip ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.zip && touched.zip ? errors.zip : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Role</Label>
                    <Input
                      type="select"
                      name="roleId"
                      id="roleId"
                      placeholder=""
                      value={values.roleId}
                      invalid={errors.roleId && touched.roleId ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}>
                      <option value="">select role</option>
                      {getRoleId() == 1 && (
                        <option value="1">Super Admin</option>
                      )}
                      <option value="2">Admin</option>
                      {/* <option value="3">Instructor</option> */}
                      <option value="5">Roll call user</option>
                    </Input>
                    <FormFeedback>
                      {errors.roleId && touched.roleId ? errors.roleId : ''}
                    </FormFeedback>
                    {/* <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <Input
                          addon
                          type="radio"
                          aria-label="Checkbox for following text input"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" value="Super Admin" />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="4" xl="3">
                <FormGroup>
                  <Label for=""></Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <Input
                          addon
                          type="radio"
                          aria-label="Checkbox for following text input"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" value="Admin" />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="4" xl="3">
                <FormGroup>
                  <Label for=""></Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <Input
                          addon
                          type="radio"
                          aria-label="Checkbox for following text input"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" value="Instructor" />
                  </InputGroup> */}
                  </FormGroup>
                </Col>
              </Row>
              <div className="btn-center">
                <Button color="primary" className="pull-left">
                  Update
                </Button>
              </div>
            </Form>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}
