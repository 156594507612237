import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  Container,
  Nav,
  Button,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Card,
  Table,
  Row,
  Col,
  Input,
  Collapse,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  CardTitle,
  DropdownItem,
  FormText,
  FormGroup,
} from 'reactstrap'
import classnames from 'classnames'
import {
  CREATE_USER_ROUTE,
  UPDATE_USER_ROUTE,
} from '../../../constants/RoutePaths'
import { useHistory, useLocation } from 'react-router'
import Instructor from './Instructor'
import { useSnackbar } from 'notistack'
import API from '../../../constants/API'
import { CustomMessage, locationParams } from '../../../function/Helperfunction'
import { DELETE_USER, LIST_USER } from '../../../constants/Apipath'
import { Add, Delete, Filter, Pencil } from '../../../function/Icons'
import Avatar from 'react-avatar'
import Pagination from 'react-js-pagination'
import { MyLoader } from '../../../function/Loader'
import { StateValue, UrlReplace } from '../../../constants/Helper'
import { debounce } from 'underscore'
import TimePicker from 'react-time-picker'
import { authHeader, rootUser } from '../../../function/Mainfunction'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import generatePDF from './UserPDFGenerator'
import { CSVLink } from 'react-csv'
import NoRecordFound from '../../../function/EmptyRecord'
import { AssDess } from '../../../constants/Helper'

const InitialFilter = {
  firstName: '',
  phoneNumber: '',
  email: '',
  activeTab: '1',
  offset: '0',
}
const IstructorInitialFilter = {
  firstName: '',
  phoneNumber: '',
  email: '',
  activeTab: '2',
  offset: '0',
  dayInTime: '',
  dayOutTime: '',
}
export default function User() {
  const dayin = useRef()
  const dayout = useRef()
  const history = useHistory()
  const location = useLocation()
  const [totalcount, setTotalcount] = useState(0)
  const [adminlist, setAdminlist] = useState([])
  const [filterOpen, setfilterOpen] = useState(false)
  const [orderList, setorderList] = useState('LA')
  const [instructororderValue, setinstructororderValue] = useState('LA')

  const filtertoggle = () => setfilterOpen(!filterOpen)
  const [Filterstate, setFilterstate] = useState(
    StateValue(location.search, InitialFilter)
  )
  const [instructorstateValue, setInstructorstateValue] = useState(
    StateValue(location.search, IstructorInitialFilter)
  )
  const [activeTab, setActiveTab] = useState(Filterstate.activeTab)
  const toggle = (tab) => {
    setFilterstate({ ...Filterstate, activeTab: tab })
    setActiveTab(Filterstate.activeTab)
    if (activeTab !== tab) setActiveTab(tab)
  }

  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const exportToggle = () => {
    setdropdownOpen(!dropdownOpen)
  }
  const headers = [
    { label: 'First name', key: 'firstName' },
    { label: 'Last name', key: 'lastName' },

    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phoneNumber' },
    { label: 'Gender', key: 'gender' },
    { label: 'DOB', key: 'dateOfBirth' },
    { label: 'Address', key: 'address' },
  ]

  const Export = () => {
    generatePDF(adminlist)
  }
  const GetUser = async (Filterstate, value) => {
    const config = {
      method: 'GET',
      url: LIST_USER,
      headers: authHeader(),
      params: {
        count: 10,
        roleId: '1,2,5',
        ...Filterstate,
        ...AssDess[value],
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        setAdminlist(data)
        setTotalcount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  const filterHandle = (e) => {
    const { name, value } = e.target
    setFilterstate({ ...Filterstate, [name]: value, offset: '0' })
  }
  const AdminClean = () => {
    setFilterstate(InitialFilter)
  }
  const InstructorClean = () => {
    setInstructorstateValue(IstructorInitialFilter)
  }
  const CreateNewuser = () => {
    history.push(CREATE_USER_ROUTE)
  }
  const handlePageChange = (page) => {
    setFilterstate({ ...Filterstate, offset: page - 1 })
  }

  const InstructorHandler = (e) => {
    const { name, value } = e.target
    setInstructorstateValue({
      ...instructorstateValue,
      [name]: value,
      offset: '0',
    })
  }

  const TimeHandler = (time, name) => {
    setInstructorstateValue({
      ...instructorstateValue,
      [name]: time,
      offset: '0',
    })
  }

  const DeleteAlert = (e) => {
    const Id = e.currentTarget.dataset.userid
    Swal.fire({
      title: 'Are you want to Delete this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAdmin(Id)
      }
    })
  }

  const DeleteAdmin = async (Id) => {
    const config = {
      method: 'DELETE',
      url: DELETE_USER,
      headers: authHeader(),
      params: { userId: Id },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
        GetUser()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const delaySearch = useRef(
    debounce((Filterstate, orderList) => GetUser(Filterstate, orderList))
  ).current
  useEffect(() => {
    delaySearch(Filterstate, orderList)
  }, [Filterstate, orderList])

  useEffect(() => {
    let pagePath = location.pathname
    let locParams = locationParams(location.search)
    UrlReplace(pagePath, locParams, Filterstate, setfilterOpen, history)
  }, [Filterstate])

  const AdminorderHandle = (e) => {
    setorderList(e.target.value)
  }

  const InstructorprderHandle = (e) => {
    setinstructororderValue(e.target.value)
  }
  return (
    <Fragment>
      <div className="wrapper">
        <div className="page-title">
          <h4>Users</h4>
          <div className="new-input ">
            <Input
              value={activeTab == '1' ? instructororderValue : orderList}
              onChange={
                activeTab == '1' ? InstructorprderHandle : AdminorderHandle
              }
              type="select">
              <option value={'FA'}>First Name (Ascending) </option>
              <option value={'LA'}>Last Name (Ascending) </option>
              <option value={'FD'}>First Name (Descending) </option>
              <option value={'LD'}>Last Name (Descending) </option>
            </Input>
          </div>
          <div className="btn-right">
            <Button onClick={filtertoggle} color="danger">
              <Filter />
              Filter
            </Button>
            <Button onClick={CreateNewuser} color="primary">
              <Add />
              New User
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <Collapse isOpen={filterOpen}>
            <Card body>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="firstName"
                      value={
                        activeTab == '1'
                          ? instructorstateValue.firstName
                          : Filterstate.firstName
                      }
                      placeholder="Enter Name"
                      onChange={
                        activeTab == '1' ? InstructorHandler : filterHandle
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="phoneNumber"
                      value={
                        activeTab == '1'
                          ? instructorstateValue.phoneNumber
                          : Filterstate.phoneNumber
                      }
                      placeholder="Enter Phonenumber"
                      onChange={
                        activeTab == '1' ? InstructorHandler : filterHandle
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      placeholder="Enter Email"
                      type="text"
                      value={
                        activeTab == '1'
                          ? instructorstateValue.email
                          : Filterstate.email
                      }
                      name="email"
                      onChange={
                        activeTab == '1' ? InstructorHandler : filterHandle
                      }
                    />
                  </FormGroup>
                </Col>
                {activeTab == '1' && (
                  <>
                    <Col md="8" xl="4">
                      <Row>
                        <Col xs="6">
                          <FormGroup>
                            <Input
                              name="dayInTime"
                              // ref={dayin}
                              value={instructorstateValue.dayInTime}
                              // disableClock
                              type="time"
                              onChange={InstructorHandler}
                            />
                            <FormText color="muted">Day In Time</FormText>
                          </FormGroup>
                        </Col>

                        <Col xs="6">
                          <FormGroup>
                            <Input
                              ref={dayout}
                              name="dayOutTime"
                              type="time"
                              value={instructorstateValue.dayOutTime}
                              //disableClock
                              onChange={InstructorHandler}
                            />
                            <FormText color="muted">Day Out Time</FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
                <Col md="4" xl="3">
                  <Button
                    color="danger"
                    onClick={activeTab == '1' ? InstructorClean : AdminClean}>
                    Clean
                  </Button>
                </Col>
              </Row>
            </Card>
          </Collapse>

          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1')
                  }}>
                  Instructor
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2')
                  }}>
                  Admin
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="2">
                <Card body>
                  <CardTitle tag="h5">
                    Admin
                    <div className="btn-sec">
                      <Dropdown isOpen={dropdownOpen} toggle={exportToggle}>
                        <DropdownToggle color="danger" caret>
                          Export
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={Export}>PDF</DropdownItem>

                          <DropdownItem>
                            <CSVLink
                              filename={'User_Details.csv'}
                              data={adminlist}
                              headers={headers}>
                              CSV
                            </CSVLink>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </CardTitle>

                  {loading && (
                    <tr>
                      <td colSpan={9}>
                        <MyLoader rowCount={5} columncount={8} />
                      </td>
                    </tr>
                  )}
                  {!loading && adminlist && adminlist.length > 0 && (
                    <Table hover responsive>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Gender</th>
                          <th>DOB</th>
                          <th>Address</th>
                          <th>Role</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          adminlist.map((admin) => (
                            <tr>
                              <th>
                                <Avatar
                                  name={`${admin.firstName} ${admin.lastName}`}
                                  size={40}
                                  round="50px"
                                />
                              </th>
                              <td>
                                {admin.firstName} {``} {admin.lastName}{' '}
                              </td>
                              <td>{admin.email}</td>
                              <td>{admin.phoneNumber}</td>
                              <td>{admin.gender}</td>
                              <td>{admin.dateOfBirth}</td>
                              <td>{admin.address}</td>
                              <td>{admin.roleName}</td>
                              <td className="btn-column">
                                <Link
                                  to={`${UPDATE_USER_ROUTE}/${admin.userId}`}>
                                  <Button color="link">
                                    <Pencil />
                                  </Button>
                                </Link>
                                <Button
                                  onClick={DeleteAlert}
                                  disabled={admin.isRootUser}
                                  data-userid={admin.userId}
                                  color="link">
                                  <Delete />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  )}
                  {!loading && !adminlist.length && <NoRecordFound />}
                  <div className="d-flex flex-row justify-content-end">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={parseInt(Filterstate.offset) + 1}
                      itemsCountPerPage={10}
                      totalItemsCount={totalcount}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </div>
                </Card>
              </TabPane>
              <TabPane tabId="1">
                {/* <Card body> */}
                <Instructor
                  ordervalue={instructororderValue}
                  Filterstate={instructorstateValue}
                  setfilterOpen={setfilterOpen}
                  setFilterstate={setInstructorstateValue}
                />
                {/* </Card> */}
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
