import React, { useEffect, useState } from 'react'
import { BATCH_HISTORY, LIST_BATCH } from '../../../constants/Apipath'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Add } from '../../../function/Icons'
import classnames from 'classnames'

import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  CustomInput,
  Row,
  Card,
  Col,
} from 'reactstrap'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { authHeader } from '../../../function/Mainfunction'
import API from '../../../constants/API'
import { useHistory } from 'react-router'
import { CREATE_BATCH, ADMIN_BATCHDETAILS } from '../../../constants/RoutePaths'
import { TableLoader } from '../../../function/Loader'
import BatchModel from '../../../function/BatchModel'
import Pagination from 'react-js-pagination'
import { CustomMessage } from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import noData from '../../../images/no-data.svg'

const localizer = momentLocalizer(moment)
export default function BigCalendar() {
  const [calendarEvents, setCalendarEvents] = useState([])
  const [batchView, setbatchView] = useState(false)
  const History = useHistory()
  const [batchDetails, setbatchDetails] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const [todayCurrentpage, settodayCurrentpage] = useState(1)
  const [historyCurrentpage, sethistoryCurrentpage] = useState(1)
  const [tomarrowCurrentpage, settomarrowCurrentpage] = useState(1)

  const [TodayBatch, setTodayBatch] = useState([])
  const [TodayCount, setTodayCount] = useState('')
  const [historyCount, sethistoryCount] = useState('')
  const [historyBatch, sethistoryBatch] = useState([])
  const [tomarrowCount, settomarrowCount] = useState(0)
  const [tomarrowBatch, settomarrowBatch] = useState([])
  const formatCalendarEvents = (data) => {
    return data.map((e) => {
      const [s_month, s_date, s_year] = e.batchStartDate.split('/')
      const [e_month, e_date, e_year] = e.batchEndDate.split('/')

      return {
        start: new Date(s_year, s_month - 1, s_date),
        end: new Date(e_year, e_month - 1, parseInt(e_date) + parseInt(1)),
        title: e?.batchName || '',
        description: e?.notes || '',
        batchId: e?.batchId,
      }
    })
  }

  const getCalendarEvents = async (date) => {
    const req = {
      method: 'GET',
      url: LIST_BATCH,
      headers: authHeader(),
    }
    try {
      const res = await API(req)
      const { data = [] } = res.data
      setCalendarEvents(formatCalendarEvents(data))
    } catch (err) {
      console.error(err)
    }
  }

  const GotoCreateBatch = () => {
    History.push(CREATE_BATCH)
  }

  const handleTodayPageChange = (page) => {
    settodayCurrentpage(page)
  }
  const handletomarrowPageChange = (page) => {
    settomarrowCurrentpage(page)
  }

  const handleHistoryPageChange = (page) => {
    sethistoryCurrentpage(page)
  }

  const GetHistoryBatch = async () => {
    const config = {
      method: 'GET',
      url: BATCH_HISTORY,
      headers: authHeader(),
      params: {
        searchDate: 'history',
        offset: historyCurrentpage - 1,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        sethistoryBatch(data)
        sethistoryCount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  const GetBatch = async () => {
    const config = {
      method: 'GET',
      url: BATCH_HISTORY,
      headers: authHeader(),
      params: {
        searchDate: 'today',
        offset: todayCurrentpage - 1,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        setTodayBatch(data)
        setTodayCount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  const GettomarrorBatch = async () => {
    const config = {
      method: 'GET',
      url: BATCH_HISTORY,
      headers: authHeader(),
      params: {
        searchDate: 'tomorrow',
        offset: tomarrowCurrentpage - 1,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        settomarrowCount(totalCount)
        settomarrowBatch(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handleEventClick = (data, e) => {
    e.preventDefault()
    History.push(ADMIN_BATCHDETAILS + '/' + data.batchId)
  }

  useEffect(() => {
    GetHistoryBatch()
  }, [historyCurrentpage])

  useEffect(() => {
    GetBatch()
  }, [todayCurrentpage])
  useEffect(() => {
    GettomarrorBatch()
  }, [tomarrowCurrentpage])
  const BatchHandle = (e) => {
    setbatchView(!batchView)
  }
  useEffect(() => {
    getCalendarEvents()
  }, [])

  return (
    <>
      <div className="wrapper">
        <div className="page-title" style={{ margin: 0 }}>
          <div className="top-title">
            <h4>Batches</h4>
            <CustomInput
              className="ml-3"
              type="switch"
              id="exampleCustomSwitch2"
              name="customSwitch"
              value={batchView}
              onChange={BatchHandle}
              label={!batchView ? 'Calendar view ' : 'List view'}
            />
          </div>

          <div className="btn-right">
            <Button onClick={GotoCreateBatch} color="primary">
              <Add />
              New Batch
            </Button>
          </div>
        </div>
        {batchView ? (
          <Calendar
            popup
            onSelectEvent={handleEventClick}
            localizer={localizer}
            className="white"
            events={calendarEvents}
            views={['month']}
            startAccessor="start"
            endAccessor="end"
            components={{
              toolbar: CalendarToolbar,
            }}
          />
        ) : (
          <Container fluid={true}>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      toggle('1')
                    }}>
                    Today ({TodayCount})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      toggle('2')
                    }}>
                    Tomorrow ({tomarrowCount})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      toggle('3')
                    }}>
                    History ({historyCount})
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {loading && <TableLoader />}
                  {!loading && !TodayBatch.length && (
                    <div className="nodata-sec">
                      <img src={noData} />
                    </div>
                  )}
                  {!loading && (
                    <BatchModel
                      Admin={true}
                      batchDetails={TodayBatch}
                      hideActionButtons={false}
                    />
                  )}

                  <div className="d-flex flex-row py-4 justify-content-end">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={todayCurrentpage}
                      itemsCountPerPage={10}
                      totalItemsCount={TodayCount}
                      pageRangeDisplayed={5}
                      onChange={handleTodayPageChange}
                    />
                  </div>
                </TabPane>

                <TabPane tabId="3">
                  <Row>
                    <Col md="12">
                      {loading && <TableLoader />}
                      {!loading && !historyBatch.length && (
                        <div className="nodata-sec">
                          <img src={noData} />
                        </div>
                      )}

                      {!loading && (
                        <BatchModel
                          Admin={true}
                          history={false}
                          batchDetails={historyBatch}
                          hideActionButtons={false}
                        />
                      )}
                    </Col>
                  </Row>
                  <div className="d-flex flex-row py-4 justify-content-end">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={historyCurrentpage}
                      itemsCountPerPage={10}
                      totalItemsCount={historyCount}
                      pageRangeDisplayed={5}
                      onChange={handleHistoryPageChange}
                    />
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col md="12">
                      {loading && <TableLoader />}
                      {!loading && !tomarrowBatch.length && (
                        <div className="nodata-sec">
                          <img src={noData} />
                        </div>
                      )}
                      {!loading && (
                        <BatchModel
                          Admin={true}
                          history={false}
                          batchDetails={tomarrowBatch}
                          hideActionButtons={false}
                        />
                      )}
                    </Col>
                  </Row>
                  <div className="d-flex flex-row py-4 justify-content-end">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={tomarrowCurrentpage}
                      itemsCountPerPage={10}
                      totalItemsCount={tomarrowCount}
                      pageRangeDisplayed={5}
                      onChange={handletomarrowPageChange}
                    />
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </Container>
        )}
      </div>
    </>
  )
}

export const CalendarToolbar = (toolbar) => {
  // this.toolbarDate = toolbar.date

  const [currentMonth, setCurrentMonth] = useState('')

  const updateCalendarEvents = (date) => {
    const currentMonth = moment(date).endOf('month').format('MMMM YYYY')
    setCurrentMonth(currentMonth)
  }

  const goToBack = () => {
    let mDate = toolbar.date
    let newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1)
    toolbar.onNavigate('prev', newDate)
    updateCalendarEvents(newDate)
  }
  const goToNext = () => {
    let mDate = toolbar.date
    let newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1)
    toolbar.onNavigate('next', newDate)
    updateCalendarEvents(newDate)
  }
  useEffect(() => {
    let mDate = toolbar.date
    updateCalendarEvents(mDate)
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}>
      <div>
        <button className="btn btn-back" onClick={goToBack}>
          &lt;
        </button>
        <label className="label-date">{currentMonth}</label>
        <button className="btn btn-next" onClick={goToNext}>
          &gt;
        </button>
      </div>
    </div>
  )
}
