import React from 'react'
import { Badge, Card, CardTitle, Table } from 'reactstrap'
import noData from '../../../images/no-data.svg'

export default function Drive(props) {
  const { DriveDetails } = props

  return (
    <>
      {DriveDetails.map((dr) => (
        <Card body className="drive-data-card">
          <CardTitle tag="h5">
            Drive Details
            <div className="top-right">
              <Badge color="info">{dr.driveDate}</Badge>
            </div>
          </CardTitle>

          <Table responsive hover>
            <thead>
              <tr>
                <th>Bears</th>
                <th>Tractor</th>
                <th>Downshifting</th>
                <th>FollowDistance</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{dr.bears}</td>
                <td>{dr.tractor}</td>
                <td>{dr.downshifting}</td>
                <td>{dr.followDistance}</td>
                <td>{dr.comments}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>GearPattern</th>
                <th>Brake</th>
                <th>HitCurb</th>
                <th>HoverH</th>
                <th>Lane</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{dr.gearPattern}</td>
                <td>{dr.brake}</td>
                <td>{dr.hitCurb}</td>
                <td>{dr.hoverH}</td>
                <td>{dr.lane}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>InterCoasting</th>
                <th>IntersectionMirrors</th>
                <th>IntersectiontrafficCheck</th>
                <th>MergingSmooth</th>
                <th>MergingTrafficCheck</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{dr.interCoasting}</td>
                <td>{dr.intersectionMirrors}</td>
                <td>{dr.intersectiontrafficCheck}</td>
                <td>{dr.mergingSmooth}</td>
                <td>{dr.mergingTrafficCheck}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>TimeIn</th>
                <th>TimeOut</th>
                <th>Timing</th>
                <th>TotalTime</th>
                <th>Reaction</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{dr.timeIn}</td>
                <td>{dr.timeOut}</td>
                <td>{dr.timing}</td>
                <td>{dr.totalTime}</td>
                <td>{dr.reaction}</td>
              </tr>
            </tbody>
          </Table>
        </Card>
      ))}
      {!DriveDetails.length && (
        <Card body className="drive-data-card">
          <CardTitle tag="h5">Drive Details</CardTitle>
          <div className="nodata-sec">
            <img src={noData} />
          </div>
        </Card>
      )}
    </>
  )
}
