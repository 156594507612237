import React from 'react'
import { Badge, Card, CardTitle, Table } from 'reactstrap'
import noData from '../../../images/no-data.svg'

export default function Maneuver(props) {
  const { ManeuverDetails } = props
  return (
    <>
      <Card body className="drive-data-card">
        <CardTitle tag="h5">Maneuver</CardTitle>
        {!!ManeuverDetails.length && (
          <Table responsive hover>
            <thead>
              <tr>
                <th>Maneuver</th>
                <th>Instructor Name</th>
                <th>Mirrors</th>
                <th>Steering</th>
                <th>Encroaches</th>
                <th>Maneuver Date</th>
                <th>Maneuver Time</th>
                <th>Score</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {ManeuverDetails.map((ma) => (
                <tr>
                  <td>{ma.maneuver}</td>
                  <td>{ma.firstName + '  ' + ma.lastName}</td>
                  <td>{ma.mirrors}</td>
                  <td>{ma.steering}</td>
                  <td>{ma.encroaches}</td>
                  <td>{ma.maneuverDate}</td>
                  <td>{ma.maneuverTime}</td>
                  <td>
                    <Badge color="success">{ma.score}</Badge>
                  </td>
                  <td>{ma.comments || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {!ManeuverDetails.length && (
          <div className="nodata-sec">
            <img src={noData} />
          </div>
        )}
      </Card>
    </>
  )
}
