import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  Container,
  Button,
  Card,
  Table,
  Collapse,
  Row,
  Input,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'reactstrap'
import {
  CREATE_STATION,
  UPDATE_STATION_ROUTE,
} from '../../../constants/RoutePaths'
import { Add, Delete, Filter, Pencil } from '../../../function/Icons'
import API from '../../../constants/API'
import { authHeader } from '../../../function/Mainfunction'
import { CustomMessage, locationParams } from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import { DELETE_STATION, LIST_STATION } from '../../../constants/Apipath'
import Pagination from 'react-js-pagination'
import { Truckloader } from '../../../function/Loader'
import { debounce } from 'underscore'
import { useRef } from 'react'
import { StateValue, UrlReplace } from '../../../constants/Healper'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import generatePDF from './StationPDFGenerator'
import NoRecordFound from '../../../function/EmptyRecord'
const filterInitial = {
  stationId: '',
  stationName: '',
  offset: '0',
}
export default function Station() {
  const history = useHistory()
  const location = useLocation()
  const [station, setStation] = useState([])
  const [totalcount, setTotalcount] = useState(0)
  const [loading, setloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [filterOpen, setfilterOpen] = useState(false)
  const filtertoggle = () => setfilterOpen(!filterOpen)
  const [filterValue, setfilterValue] = useState(
    StateValue(location.search, filterInitial)
  )
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const toggle = () => {
    setdropdownOpen(!dropdownOpen)
  }

  const headers = [{ label: 'Station Name', key: 'stationName' }]

  const Export = () => {
    generatePDF(station)
  }
  const GetStation = async (filterValue) => {
    const config = {
      method: 'GET',
      url: LIST_STATION,
      headers: authHeader(),

      params: {
        count: 10,
        ...filterValue,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        setStation(data)
        setTotalcount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const DeleteAlert = (e) => {
    const Id = e.currentTarget.dataset.stationid
    Swal.fire({
      title: 'Are you want to Delete this Station?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteStation(Id)
      }
    })
  }

  const DeleteStation = async (Id) => {
    const config = {
      method: 'DELETE',
      url: DELETE_STATION,
      headers: authHeader(),
      params: { stationId: Id },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
        GetStation()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const filterHandle = (e) => {
    const { name, value } = e.target
    setfilterValue({ ...filterValue, [name]: value, offset: '0' })
  }

  const handlePageChange = (page) => {
    setfilterValue({ ...filterValue, offset: page - 1 })
  }

  const Clean = () => {
    setfilterValue(filterInitial)
  }

  const delaySearch = useRef(
    debounce((filterValue) => GetStation(filterValue))
  ).current
  useEffect(() => {
    let pagePath = location.pathname
    let locParams = locationParams(location.search)
    UrlReplace(pagePath, locParams, filterValue, setfilterOpen, history)
    delaySearch(filterValue)
  }, [filterValue])
  const CreatenewStaction = () => {
    history.push(CREATE_STATION)
  }
  return (
    <Fragment>
      <div className="wrapper">
        <div className="page-title">
          <h4>Station</h4>
          <div className="btn-right">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle color="danger" caret>
                Export
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={Export}>PDF</DropdownItem>

                <DropdownItem>
                  <CSVLink
                    filename={'Station_Details.csv'}
                    data={station}
                    headers={headers}>
                    CSV
                  </CSVLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Button onClick={filtertoggle} color="danger">
              <Filter />
              Filter
            </Button>
            <Button onClick={CreatenewStaction} color="primary">
              <Add />
              New Station
            </Button>
          </div>
        </div>
        <Container fluid={true} className="mb-4">
          <Collapse isOpen={filterOpen}>
            <Card body>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="number"
                      name="stationId"
                      value={filterValue.stationId}
                      onChange={filterHandle}
                      placeholder="Enter  Station Id"
                    />
                  </FormGroup>
                </Col>{' '}
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="stationName"
                      value={filterValue.stationName}
                      onChange={filterHandle}
                      placeholder="Enter Station Name"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <Button className="mt-1" color="danger" onClick={Clean}>
                    Clean
                  </Button>
                </Col>
              </Row>
            </Card>
          </Collapse>
        </Container>

        <Container fluid={true}>
          <div>
            <Card body>
              {loading && (
                <tr>
                  <td colSpan={3}>
                    <Truckloader />
                  </td>
                </tr>
              )}
              {!loading && station && station.length > 0 && (
                <Table hover responsive>
                  <thead>
                    <tr>
                      {/* <th>Station ID</th> */}
                      <th>Station Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      station.length &&
                      station.map((st) => (
                        <tr>
                          {/* <td>{st.stationId}</td> */}
                          <td>{st.stationName}</td>

                          <td className="btn-column">
                            <Link
                              to={`${UPDATE_STATION_ROUTE}/${st.stationId}`}>
                              <Button color="link">
                                <Pencil />
                              </Button>
                            </Link>
                            <Button
                              onClick={DeleteAlert}
                              data-stationid={st.stationId}
                              color="link">
                              <Delete />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
              {!loading && !station.length && <NoRecordFound />}

              <div className="d-flex flex-row justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={parseInt(filterValue.offset) + 1}
                  itemsCountPerPage={10}
                  totalItemsCount={totalcount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              </div>
            </Card>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
