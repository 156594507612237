import React, { Fragment, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  Container,
  Button,
  Card,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  FormFeedback,
  Form,
} from 'reactstrap'
import {
  CustomMessage,
  CustomPageLoading,
} from '../../../function/Helperfunction'
import moment from 'moment'
import * as Yup from 'yup'
import API from '../../../constants/API'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import { TRUCK_DETAILS, UPDATE_TRUCK_DETAILS } from '../../../constants/Apipath'
import { TRUCK_ROUTE } from '../../../constants/RoutePaths'
import { useEffect } from 'react'
import { authHeader } from '../../../function/Mainfunction'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
export default function UpdateTruck() {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const [formData, setformData] = useState({})
  const { truckId } = useParams()
  const Goback = () => {
    history.goBack()
  }

  useEffect(() => {
    TruckDetails()
  }, [])

  const TruckDetails = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: TRUCK_DETAILS,
      params: {
        truckId: truckId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      setformData(data)
    } catch (error) {
      if (error.response) {
        let { data } = error.response
      }
    } finally {
      setloading(false)
    }
  }

  const TruckUpdate = async (values) => {
    const config = {
      method: 'PUT',
      url: UPDATE_TRUCK_DETAILS,
      headers: authHeader(),
      data: values,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      if (status === 'Success') {
        CustomMessage('Update Truck Successful', 'success', enqueueSnackbar) //call toster
        history.push(TRUCK_ROUTE)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
        resetForm({})
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    truckName: Yup.string().required('Truck Name Required'),
    trailerUnit: Yup.string().required('Trailer Unit Required'),
    vin: Yup.string().required('Vin Required'),
    plateNo: Yup.string().required('Plate Number Required'),
    tagExpDate: Yup.string().required('Tag Expiry Date Required'),
    attachedtotractorUnit: Yup.string().required(
      'Attachedtotractor Unit Required'
    ),
    insurancePolicy: Yup.string().required('Insurance Policy Required'),
    insuranceExpDate: Yup.string().required('Insurance Expiry Date Required'),
    lastService: Yup.string().required('Last Service Required'),
    nextServiceDate: Yup.string().required('Next Service Date Required'),
    annualInspectionDate: Yup.string().required(
      'Annual Inspection Date Required'
    ),
    tireSize: Yup.string().required('Tire Size Required'),
    manufacturer: Yup.string().required('Manufacturer Required'),
    modelYear: Yup.string()
      .required('Model Year Required')
      .test('passwords-match', 'Invalid year', function (value) {
        if (value) {
          return value.length === 4
        }
      }),
    colorDOT: Yup.string().required('Color Required'),
    trailerSize: Yup.string().required('Trailer Size Required'),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => TruckUpdate(values),
  })

  const yeatHandler = (year) => {
    try {
      setValues({ ...values, modelYear: year.year().toString() })
    } catch (e) {
      setValues({ ...values, modelYear: year })
    }
  }
  return (
    <Fragment>
      <div className="wrapper">
        {loading && <CustomPageLoading />}
        <div className="page-title">
          <h4>Update Truck</h4>
          <div className="btn-right">
            <Button onClick={Goback} color="primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.543"
                height="8.695"
                viewBox="0 0 5.543 9.695">
                <path
                  id="Icon_ionic-ios-arrow-back"
                  data-name="Icon ionic-ios-arrow-back"
                  d="M12.921,11.04,16.59,7.374a.693.693,0,1,0-.981-.978l-4.156,4.153a.691.691,0,0,0-.02.955l4.173,4.182a.693.693,0,1,0,.981-.978Z"
                  transform="translate(-11.251 -6.194)"
                  fill="#fff"
                />
              </svg>
              Back
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <Card body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Truck name</Label>
                    <Input
                      type="text"
                      name="truckName"
                      id="truckName"
                      value={values.truckName}
                      invalid={touched.truckName && errors.truckName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter truck name"
                    />
                    <FormFeedback>
                      {errors.truckName && touched.truckName
                        ? errors.truckName
                        : errors.truckName}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Trailer unit</Label>
                    <Input
                      type="text"
                      name="trailerUnit"
                      id="trailerUnit"
                      value={values.trailerUnit}
                      invalid={touched.trailerUnit && errors.trailerUnit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter trailer unit"
                    />
                    <FormFeedback>
                      {errors.trailerUnit && touched.trailerUnit
                        ? errors.trailerUnit
                        : errors.trailerUnit}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Vin</Label>
                    <Input
                      type="text"
                      name="vin"
                      id="vin"
                      value={values.vin}
                      invalid={touched.vin && errors.vin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter vin"
                    />
                    <FormFeedback>
                      {errors.vin && touched.vin ? errors.vin : errors.vin}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Plate No</Label>
                    <Input
                      type="text"
                      name="plateNo"
                      id="plateNo"
                      value={values.plateNo}
                      invalid={touched.plateNo && errors.plateNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter plate number"
                    />
                    <FormFeedback>
                      {errors.plateNo && touched.plateNo
                        ? errors.plateNo
                        : errors.plateNo}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Tag exp date</Label>
                    <Input
                      type="date"
                      name="tagExpDate"
                      id="tagExpDate"
                      value={moment(values.tagExpDate).format('YYYY-MM-DD')}
                      invalid={touched.tagExpDate && errors.tagExpDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder=""
                    />
                    <FormFeedback>
                      {errors.tagExpDate && touched.tagExpDate
                        ? errors.tagExpDate
                        : errors.tagExpDate}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Attached tractor unit</Label>
                    <Input
                      type="text"
                      name="attachedtotractorUnit"
                      id="attachedtotractorUnit"
                      value={values.attachedtotractorUnit}
                      invalid={
                        touched.attachedtotractorUnit &&
                        errors.attachedtotractorUnit
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter attached tractoe unit"
                    />
                    <FormFeedback>
                      {errors.attachedtotractorUnit &&
                      touched.attachedtotractorUnit
                        ? errors.attachedtotractorUnit
                        : errors.attachedtotractorUnit}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Insurance policy</Label>
                    <Input
                      type="text"
                      name="insurancePolicy"
                      id="insurancePolicy"
                      value={values.insurancePolicy}
                      invalid={
                        touched.insurancePolicy && errors.insurancePolicy
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter insurance policy"
                    />
                    <FormFeedback>
                      {errors.insurancePolicy && touched.insurancePolicy
                        ? errors.insurancePolicy
                        : errors.insurancePolicy}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Insurance exp date</Label>
                    <Input
                      type="date"
                      name="insuranceExpDate"
                      id="insuranceExpDate"
                      value={moment(values.insuranceExpDate).format(
                        'YYYY-MM-DD'
                      )}
                      invalid={
                        touched.insuranceExpDate && errors.insuranceExpDate
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder=""
                    />
                    <FormFeedback>
                      {errors.insuranceExpDate && touched.insuranceExpDate
                        ? errors.insuranceExpDate
                        : errors.insuranceExpDate}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Last service</Label>
                    <Input
                      type="date"
                      name="lastService"
                      id="lastService"
                      value={moment(values.lastService).format('YYYY-MM-DD')}
                      invalid={touched.lastService && errors.lastService}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder=""
                    />
                    <FormFeedback>
                      {errors.lastService && touched.lastService
                        ? errors.lastService
                        : errors.lastService}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Next service date</Label>
                    <Input
                      type="date"
                      name="nextServiceDate"
                      id="nextServiceDate"
                      value={moment(values.nextServiceDate).format(
                        'YYYY-MM-DD'
                      )}
                      invalid={
                        touched.nextServiceDate && errors.nextServiceDate
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder=""
                    />
                    <FormFeedback>
                      {errors.nextServiceDate && touched.nextServiceDate
                        ? errors.nextServiceDate
                        : errors.nextServiceDate}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Annual inspection date</Label>
                    <Input
                      type="date"
                      name="annualInspectionDate"
                      id="annualInspectionDate"
                      value={moment(values.annualInspectionDate).format(
                        'YYYY-MM-DD'
                      )}
                      invalid={
                        touched.annualInspectionDate &&
                        errors.annualInspectionDate
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder=""
                    />
                    <FormFeedback>
                      {errors.annualInspectionDate &&
                      touched.annualInspectionDate
                        ? errors.annualInspectionDate
                        : errors.annualInspectionDate}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Tire size</Label>
                    <Input
                      type="text"
                      name="tireSize"
                      id="tireSize"
                      value={values.tireSize}
                      invalid={touched.tireSize && errors.tireSize}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter tire size"
                    />
                    <FormFeedback>
                      {errors.tireSize && touched.tireSize
                        ? errors.tireSize
                        : errors.tireSize}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Trailer Size</Label>
                    <Input
                      type="text"
                      name="trailerSize"
                      id="trailerSize"
                      value={values.trailerSize}
                      invalid={touched.trailerSize && errors.trailerSize}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter trailer size "
                    />
                    <FormFeedback>
                      {errors.trailerSize && touched.trailerSize
                        ? errors.trailerSize
                        : errors.trailerSize}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Manufacturer</Label>
                    <Input
                      type="text"
                      name="manufacturer"
                      id="manufacturer"
                      value={values.manufacturer}
                      invalid={touched.manufacturer && errors.manufacturer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter manufacturer"
                    />
                    <FormFeedback>
                      {errors.manufacturer && touched.manufacturer
                        ? errors.manufacturer
                        : errors.manufacturer}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Model year</Label>
                    <Datetime
                      dateFormat="YYYY"
                      initialViewMode="years"
                      timeFormat={false}
                      input={true}
                      inputProps={{ placeholder: 'Model year' }}
                      placeholder="Model Year"
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      value={values.modelYear}
                      onChange={yeatHandler}
                    />

                    <FormFeedback
                      style={{
                        display:
                          errors.modelYear && touched.modelYear
                            ? 'block'
                            : 'none',
                      }}>
                      {errors.modelYear && touched.modelYear
                        ? errors.modelYear
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Color</Label>
                    <Input
                      type="text"
                      name="colorDOT"
                      id="colorDOT"
                      value={values.colorDOT}
                      invalid={touched.colorDOT && errors.colorDOT}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter color name"
                    />
                    <FormFeedback>
                      {errors.colorDOT && touched.colorDOT
                        ? errors.colorDOT
                        : errors.colorDOT}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <div className="btn-center">
                <Button
                  color="primary"
                  className="pull-left"
                  type="submit"
                  block>
                  Update
                </Button>
              </div>
            </Form>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}
