import React from 'react'

import { Badge, Card, CardTitle, Table } from 'reactstrap'
import { ProfileImage } from './Helperfunction'
import Avatar from 'react-avatar'
import { useHistory } from 'react-router-dom'
import {
  ADMIN_BATCHDETAILS,
  BATCH_DETAILS_ROUTE,
} from '../constants/RoutePaths'

export default function BatchModel(props) {
  const {
    batchDetails = [],
    history = true,
    Admin = false,
    hideActionButtons = false,
  } = props || {}
  const historyRout = useHistory()

  const openBatch = (id) => {
    historyRout.push(
      `${Admin ? ADMIN_BATCHDETAILS : BATCH_DETAILS_ROUTE}/${id}`
    )
  }
  console.log(batchDetails)

  return (
    <>
      {batchDetails.map((ba) => (
        <Card
          onClick={() => openBatch(ba.batchId)}
          body
          style={{ cursor: 'pointer' }}
          className="batch-list-card">
          <CardTitle tag="h5">
            {ba.batchName}
            <Badge style={{ marginLeft: '10px' }} color="info">
              {ba.type}
            </Badge>
            {/* <div className="top-right">
              {!hideActionButtons && (
                <>
                  {history &&
                    (ba.hoursLeft ? (
                      <>
                        Time Left: <Badge color="danger">{ba.hoursLeft}</Badge>
                        {` `}Hours
                      </>
                    ) : (
                      <Badge color="success" className="ml-auto">
                        Active
                      </Badge>
                    ))}
                </>
              )}
            </div> */}
          </CardTitle>

          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Calender />
                  {ba.batchStartDate}-{ba.batchEndDate}
                </th>
                <th>
                  <Watch />
                  {ba.batchStartTime}-{ba.batchEndTime}
                </th>
                <th>
                  <Truck />
                  Truck Name- {ba.truckName}
                </th>
              </tr>
            </thead>

            <thead>
              <tr>
                {!hideActionButtons && (
                  <>
                    <th>
                      <div className="marginSmall">
                        {ba.batchCategory == 1 ? (
                          <>
                            {' '}
                            <Avatar
                              size={25}
                              round="50px"
                              name={ba.firstName}
                            />
                            <span className="marginSmall">{ba.firstName}</span>
                          </>
                        ) : (
                          'Floter batch'
                        )}
                      </div>
                    </th>
                  </>
                )}
                <th>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.813"
                      height="13.501"
                      viewBox="0 0 11.813 13.501">
                      <path
                        id="user-graduate"
                        d="M8.422,8.454,5.906,10.547,3.391,8.454A3.551,3.551,0,0,0,0,12.235,1.266,1.266,0,0,0,1.266,13.5h9.282a1.266,1.266,0,0,0,1.266-1.266A3.549,3.549,0,0,0,8.422,8.454Zm-2.937,4.2H1.266a.423.423,0,0,1-.422-.422A2.7,2.7,0,0,1,3.122,9.326l2.363,1.967Zm5.485-.422a.423.423,0,0,1-.422.422H6.328V11.294L8.691,9.326A2.7,2.7,0,0,1,10.969,12.235ZM.348,2.637l.179.053v.991A.609.609,0,0,0,.5,4.736L.092,6.381c-.045.182.055.369.2.369h1.1c.145,0,.245-.187.2-.369L1.184,4.738A.609.609,0,0,0,1.16,3.684v-.8l1.231.364a3.683,3.683,0,0,0-.282,1.4,3.8,3.8,0,0,0,7.594,0,3.683,3.683,0,0,0-.282-1.4l2.044-.606a.574.574,0,0,0,0-1.055L6.352.066a1.539,1.539,0,0,0-.891,0L.348,1.582A.574.574,0,0,0,.348,2.637ZM5.906,7.594A2.955,2.955,0,0,1,2.953,4.641a2.865,2.865,0,0,1,.253-1.155l2.254.67a1.551,1.551,0,0,0,.891,0l2.254-.67A2.875,2.875,0,0,1,8.86,4.641,2.955,2.955,0,0,1,5.906,7.594ZM5.7.873a.732.732,0,0,1,.411,0l4.166,1.237L6.112,3.346a.732.732,0,0,1-.411,0L1.535,2.109,5.7.873Z"
                        transform="translate(0)"
                        fill="#e44a4a"
                      />
                    </svg>

                    <ProfileImage
                      BatchStudents={ba.studentDetails || ba.batchStudents}
                    />
                  </div>
                </th>
                <th>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.496"
                    height="15.051"
                    viewBox="0 0 12.496 15.051">
                    <g
                      id="Icon_feather-map-pin"
                      data-name="Icon feather-map-pin"
                      transform="translate(0.5 0.5)">
                      <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M16,7.248c0,4.471-5.748,8.3-5.748,8.3S4.5,11.719,4.5,7.248a5.748,5.748,0,0,1,11.5,0Z"
                        transform="translate(-4.5 -1.5)"
                        fill="none"
                        stroke="#e44a4a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M17.332,12.416A1.916,1.916,0,1,1,15.416,10.5,1.916,1.916,0,0,1,17.332,12.416Z"
                        transform="translate(-9.668 -6.668)"
                        fill="none"
                        stroke="#e44a4a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </g>
                  </svg>

                  {ba.stationName}
                </th>
              </tr>
            </thead>
          </Table>
        </Card>
      ))}
    </>
  )
}

const Calender = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.788"
      height="15.758"
      viewBox="0 0 13.788 15.758">
      <path
        id="calendar-alt"
        d="M12.311,1.97H10.834V.369A.37.37,0,0,0,10.464,0h-.246a.37.37,0,0,0-.369.369v1.6H3.939V.369A.37.37,0,0,0,3.57,0H3.324a.37.37,0,0,0-.369.369v1.6H1.477A1.478,1.478,0,0,0,0,3.447V14.281a1.478,1.478,0,0,0,1.477,1.477H12.311a1.478,1.478,0,0,0,1.477-1.477V3.447A1.478,1.478,0,0,0,12.311,1.97ZM1.477,2.955H12.311a.494.494,0,0,1,.492.492V4.924H.985V3.447A.494.494,0,0,1,1.477,2.955ZM12.311,14.773H1.477a.494.494,0,0,1-.492-.492V5.909H12.8v8.371A.494.494,0,0,1,12.311,14.773ZM4.555,9.849H3.324a.37.37,0,0,1-.369-.369V8.248a.37.37,0,0,1,.369-.369H4.555a.37.37,0,0,1,.369.369V9.479A.37.37,0,0,1,4.555,9.849Zm2.955,0H6.279a.37.37,0,0,1-.369-.369V8.248a.37.37,0,0,1,.369-.369H7.51a.37.37,0,0,1,.369.369V9.479A.37.37,0,0,1,7.51,9.849Zm2.955,0H9.233a.37.37,0,0,1-.369-.369V8.248a.37.37,0,0,1,.369-.369h1.231a.37.37,0,0,1,.369.369V9.479A.37.37,0,0,1,10.464,9.849ZM7.51,12.8H6.279a.37.37,0,0,1-.369-.369V11.2a.37.37,0,0,1,.369-.369H7.51a.37.37,0,0,1,.369.369v1.231A.37.37,0,0,1,7.51,12.8Zm-2.955,0H3.324a.37.37,0,0,1-.369-.369V11.2a.37.37,0,0,1,.369-.369H4.555a.37.37,0,0,1,.369.369v1.231A.37.37,0,0,1,4.555,12.8Zm5.909,0H9.233a.37.37,0,0,1-.369-.369V11.2a.37.37,0,0,1,.369-.369h1.231a.37.37,0,0,1,.369.369v1.231A.37.37,0,0,1,10.464,12.8Z"
        fill="#e44a4a"
      />
    </svg>
  )
}

const Truck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.32"
      height="10.024"
      viewBox="0 0 14.32 10.024">
      <path
        id="truck-moving"
        d="M13.9,35.878l-1.309-1.309a1.433,1.433,0,0,0-1.014-.418h-1.2V32.716A.715.715,0,0,0,9.666,32H.716A.715.715,0,0,0,0,32.716v7.518A1.786,1.786,0,0,0,3.222,41.3a1.786,1.786,0,0,0,3.222-1.065,1.886,1.886,0,0,0-.036-.358H10.06a1.72,1.72,0,0,0-.036.358,1.79,1.79,0,0,0,3.58,0,1.886,1.886,0,0,0-.036-.358h.394a.359.359,0,0,0,.358-.358V36.889A1.427,1.427,0,0,0,13.9,35.878ZM1.79,41.308a1.074,1.074,0,1,1,1.074-1.074A1.074,1.074,0,0,1,1.79,41.308Zm2.864,0a1.074,1.074,0,1,1,1.074-1.074A1.074,1.074,0,0,1,4.654,41.308ZM9.666,39.16H6.077a1.772,1.772,0,0,0-2.846,0H3.215a1.791,1.791,0,0,0-1.425-.716,1.764,1.764,0,0,0-1.074.367v-6.1h8.95Zm.716-4.3h1.2a.705.705,0,0,1,.506.21L13.307,36.3H10.382Zm1.432,6.444a1.074,1.074,0,1,1,1.074-1.074A1.074,1.074,0,0,1,11.814,41.308ZM13.6,39.16h-.367a1.772,1.772,0,0,0-2.846,0h-.009V37.012H13.6Z"
        transform="translate(0 -32)"
        fill="#e44a4a"
      />
    </svg>
  )
}

const Watch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.44"
      height="12.587"
      viewBox="0 0 9.44 12.587">
      <path
        id="watch"
        d="M7.867,2.776V.59A.592.592,0,0,0,7.277,0H2.163a.592.592,0,0,0-.59.59V2.776a4.72,4.72,0,0,0,0,7.036V12a.592.592,0,0,0,.59.59H7.277a.592.592,0,0,0,.59-.59V9.812a4.72,4.72,0,0,0,0-7.036ZM2.36.787H7.08V2.205a4.724,4.724,0,0,0-4.72,0ZM7.08,11.8H2.36V10.382a4.724,4.724,0,0,0,4.72,0ZM4.72,10.227A3.933,3.933,0,1,1,8.654,6.294,3.932,3.932,0,0,1,4.72,10.227Zm1.2-2.267L4.447,6.886a.3.3,0,0,1-.12-.238V3.695a.3.3,0,0,1,.3-.3h.2a.3.3,0,0,1,.3.3V6.4l1.273.927a.3.3,0,0,1,.064.413l-.116.16a.294.294,0,0,1-.411.064Z"
        transform="translate(0 0)"
        fill="#e44a4a"
      />
    </svg>
  )
}
