import React from 'react'
import { Table, Card } from 'reactstrap'
import { sorting } from '../../../function/Helperfunction'
import Dpstestattendence from './Dpstestattendence'

function DpstestAttendance(props) {
  const { dpsTestAttendence, attendecedata, value } = props
  return (
    <div>
      <Card body>
        <Table responsive>
          <thead>
            <tr>
              <th>Student Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(dpsTestAttendence).length == 0 && (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={2}>No record found</td>
              </tr>
            )}
            {Object.values(dpsTestAttendence)
              .sort((a, b) => sorting(a, b, value))
              .map((rec, index) => {
                return (
                  <tr>
                    <td>{rec.firstName + ' ' + rec.lastName}</td>
                    <td>
                      <Dpstestattendence
                        userid={rec.userId}
                        dpsstatus={rec.status || ''}
                        value={attendecedata}
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </Card>
    </div>
  )
}

export default DpstestAttendance
