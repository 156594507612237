import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import ReactApexChart from 'react-apexcharts'
import { authHeader } from '../../../function/Mainfunction'
import { CustomMessage } from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import API from '../../../constants/API'
import { ADMIN_DASHBOARD } from '../../../constants/Apipath'
import { SmallLoader, TableLoader } from '../../../function/Loader'
import { Link } from 'react-router-dom'
import { Truck, Groupuser, User, Userclass } from '../../../function/Icons'
import BatchModel from '../../../function/BatchModel'
import NoRecordFound from '../../../function/EmptyRecord'

export default function Dashboard() {
  const [loading, setloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [adminCount, setadminCount] = useState('')
  const [instructorCount, setinstructorCount] = useState('')
  const [batchCount, setbatchCount] = useState('')
  const [studentCount, setstudentCount] = useState('')
  const [studenList, setstudenList] = useState([])
  const [batchDetails, setBatchDetails] = useState([])
  const [graphData, setgraphData] = useState([])

  const GetDash = async () => {
    const config = {
      method: 'GET',
      url: ADMIN_DASHBOARD,
      headers: authHeader(),
    }
    setloading(true)
    try {
      const response = await API(config)
      const {
        status,
        batchCount,
        adminCount,
        instructorCount,
        studentCount,
        recentJoinStudents,
        batchDetails,
        joiningReportGraph,
      } = response.data
      if (status === 'Success') {
        setadminCount(adminCount)
        setinstructorCount(instructorCount)
        setbatchCount(batchCount)
        setstudentCount(studentCount)
        setstudenList(recentJoinStudents)
        setBatchDetails(batchDetails)
        setgraphData(joiningReportGraph)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const series = [
    {
      name: 'Count',
      data: graphData.map((i) => i.studentCount),
    },
  ]
  const options = {
    markers: {
      colors: ['#F44336', '#E91E63', '#9C27B0'],
    },
    chart: {
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: ['rgb(249, 212, 92)'],
    },
    xaxis: {
      title: {
        text: 'Months',
      },
      labels: {
        rotate: 0,
      },
      categories: graphData.map((i) => i.monthName),
      // tickPlacement: 'on'
    },
    yaxis: {
      title: {
        text: 'Student Count',
      },
    },
  }
  useEffect(() => {
    GetDash()
  }, [])

  return (
    <div className="wrapper">
      <div className="page-title">
        <h4>Overview</h4>
      </div>

      <Container fluid={true}>
        <Row>
          <Col md="12">
            <Row className="dash-count">
              <Col md="6" lg="6" xl="3">
                <Link to="/admin/student">
                  <div className="box">
                    <User />
                    <div className="left">
                      <p>Students</p>
                      <h4>{loading ? <SmallLoader /> : studentCount}</h4>
                    </div>
                    <div className="right">
                      <User />
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md="6" lg="6" xl="3">
                <Link
                  to={{
                    pathname: '/admin/users',
                    search: 'activeTab=2',
                  }}>
                  <div className="box">
                    <Groupuser />

                    <div className="left">
                      <p>Admin</p>
                      <h4>{loading ? <SmallLoader /> : adminCount}</h4>
                    </div>
                    <div className="right">
                      <Groupuser />
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md="6" lg="6" xl="3">
                <Link
                  to={{
                    pathname: '/admin/users',
                    search: 'activeTab=1',
                  }}>
                  <div className="box">
                    <Truck />
                    <div className="left">
                      <p>Instructor</p>
                      <h4>{loading ? <SmallLoader /> : instructorCount}</h4>
                    </div>
                    <div className="right">
                      <Truck />
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md="6" lg="6" xl="3">
                <Link to="/admin/batch">
                  <div className="box">
                    <Userclass />
                    <div className="left">
                      <p>Batch</p>
                      <h4>{loading ? <SmallLoader /> : batchCount}</h4>
                    </div>
                    <div className="right">
                      <Userclass />
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </Col>

          <Col md="6">
            <Card body>
              <CardTitle tag="h5">Chart</CardTitle>
              <CardBody style={{ padding: 0 }}>
                <div id="chart">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md="6" style={{ marginTop: '10px' }}>
            <Card body>
              <CardTitle tag="h5">Upcoming batches</CardTitle>
              <BatchModel
                Admin={true}
                hideActionButtons={true}
                batchDetails={batchDetails}
              />
              {!loading && !batchDetails.length && <NoRecordFound />}
            </Card>
          </Col>

          <Col md="6" style={{ marginTop: '10px' }}>
            <Card body>
              <CardTitle tag="h5">Recent join students</CardTitle>
              <Table hover responsive>
                <tbody>
                  {loading ? (
                    <TableLoader />
                  ) : (
                    studenList.map((st, index) => (
                      <tr key={index}>
                        <td>{st.StudentName}</td>
                        <td>{st.batchName}</td>
                        <td>{st.email}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              {!loading && !studenList.length && <NoRecordFound />}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
