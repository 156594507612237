import React from 'react'
import { Badge, Card, CardTitle, Table } from 'reactstrap'
import noData from '../../../images/no-data.svg'

import IncabPreview from './IncabPreview'

export default function Incab(props) {
  const { IncabDetails } = props

  return (
    <Card body className="drive-data-card">
      <CardTitle tag="h5">In Cab</CardTitle>
      {!!IncabDetails.length && (
        <Table responsive hover>
          <thead>
            <tr>
              <th>Incab ID</th>
              <th>Incab Date</th>
              <th>Incab Time</th>
              <th>Comments</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {IncabDetails.map((inc) => (
              <tr>
                <td>{inc.incabId}</td>
                <td>{inc.incabDate}</td>
                <td>{inc.incabTime}</td>
                <td>{inc.comments}</td>

                <td>
                  <IncabPreview IncabDetails={inc} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!IncabDetails.length && (
        <div className="nodata-sec">
          <img src={noData} />
        </div>
      )}
    </Card>
  )
}
