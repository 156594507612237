import { locationParams } from '../function/Helperfunction'

export const StateValue = (search, initial) => {
  let URLparams = locationParams(search)
  let filterInitialKeys = Object.keys(initial) // get the key from the initial values for compare the search parameter in  url
  let filtersFromURL = {}
  for (let param in URLparams) {
    if (filterInitialKeys.includes(param)) {
      filtersFromURL[param] = URLparams[param]
    }
  }
  return { ...initial, ...filtersFromURL }
}

export const UrlReplace = (
  pagePath,
  locParams,
  filterValue,
  setfilterOpen,
  history
) => {
  const Newpar = Object.keys(locParams).filter(
    (i) => !['isWeekendStudent', 'activeTab', 'offset'].includes(i)
  )
  const locParamValues = Object.values(Newpar) //get the value
  const flag = locParamValues.filter((i) => i !== '').length
  if (flag != 0) {
    setfilterOpen(true)
  }
  let filterStateValuePairs = Object.entries(filterValue)
    .filter((e) => !!e[1]) // remove the empty value object for the url
    .map((e) => e[0] + '=' + e[1])
  if (filterStateValuePairs.length) {
    return history.replace(`${pagePath}?${filterStateValuePairs.join('&')}`) // when filterStateValuePairs have value, it replace url with values
  } else {
    history.replace(`${pagePath}`) // when url not have value, it replace url with default
    return
  }
}
