import React, { useState } from 'react'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

function AttendenceButtonDropdown(props) {
  const { value, userid, time, data = {} } = props

  const { status, onclick } = data[value] || {}
  const [isOpen, setisOpen] = useState(false)
  const toggle = () => setisOpen((val) => !val)

  let statuscolor = {
    Present: 'success',
    Excused: 'warning',
    Unmarked: 'primary',
    Absent: 'danger',
  }
  return (
    <>
      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
        <Button
          onClick={() => onclick(userid, time)}
          color={statuscolor[status]}>
          {status}
        </Button>
        <DropdownToggle split color={statuscolor[status]} />
        <DropdownMenu>
          <DropdownItem>
            {' '}
            <div onClick={() => data['unmarked'].onclick(userid, time)}>
              Present
            </div>
          </DropdownItem>
          <DropdownItem>
            {' '}
            <div onClick={() => data['present'].onclick(userid, time)}>
              Absent
            </div>
          </DropdownItem>
          <DropdownItem>
            {' '}
            <div onClick={() => data['absent'].onclick(userid, time)}>
              Excused
            </div>
          </DropdownItem>
          <DropdownItem>
            {' '}
            <div onClick={() => data['excused'].onclick(userid, time)}>
              Unmarked
            </div>
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  )
}

export default AttendenceButtonDropdown
