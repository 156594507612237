import React, { useState } from 'react'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import API from '../constants/API'
import { LOGIN } from '../constants/Apipath'
import { useSnackbar } from 'notistack'
import {
  CustomMessage,
  CustomPageLoading,
  locationParams,
} from '../function/Helperfunction'
import {
  AMERICAN_TRUCK_TOKEN,
  ROAL_ID,
  USER_NAME,
  USER_ID,
  LAST_NAME,
  USER_EMAIL,
  ROOT_USER,
} from '../constants/Localvaribale'
import {
  ADMIN_DASHBOARD,
  INSTRUCTOR_HOME_ROUTE,
  FORGET_PASSWORD,
  INSTRUCTOR_ROLLCALL,
  ADMIN_ROLLOVER,
} from '../constants/RoutePaths'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import Logo from '../images/logo.png'

export default function Signin() {
  const [passwordshow, setpasswordshow] = useState(true)
  const passwordToogle = () => setpasswordshow(!passwordshow)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const history = useHistory()
  const LogIn = async (values) => {
    const config = {
      method: 'POST',
      url: LOGIN,
      data: values,
    }
    setloading(true)
    const filter = locationParams(window.location.search) //loationParams refer=https://gist.github.com/SaT-hisH/5fe09ba70d36ed74e8f2621bb2a6421d
    let rootPath = window.location.origin
    try {
      const response = await API(config)
      const { data } = response.data
      const { accesstoken } = response.headers
      if (response.status === 200) {
        localStorage.setItem(AMERICAN_TRUCK_TOKEN, accesstoken)
        localStorage.setItem(ROAL_ID, data.roleId)
        localStorage.setItem(USER_NAME, data.firstName)
        localStorage.setItem(LAST_NAME, data.lastName)
        localStorage.setItem(USER_ID, data.userId)
        localStorage.setItem(USER_EMAIL, data.email)
        localStorage.setItem(ROOT_USER, data.isRootUser)
        CustomMessage('Login Successful', 'success', enqueueSnackbar) //call toster
        if (filter.ref) {
          history.replace(filter.ref.replace(rootPath, ''))
        } else {
          if ([1, 2].includes(data.roleId)) {
            history.push(ADMIN_DASHBOARD)
          } else if (data.roleId == 5) {
            history.push(ADMIN_ROLLOVER)
          } else {
            history.push(INSTRUCTOR_HOME_ROUTE)
          }
        }
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email Required'),
    password: Yup.string()
      .min(6, 'Password must be Six characters')
      .required('Password Required'),
  })
  const { handleSubmit, handleChange, values, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema,
      onSubmit: (values) => LogIn(values),
    })
  return (
    <>
      <div>
        {loading && <CustomPageLoading />}
        <section className="login-page">
          <div className="form">
            <img src={Logo} />

            <h4>Sign In</h4>
            <p>
              Enter your email address and password <br /> to access Dashboard
              panel.
            </p>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="email"
                  name="email"
                  value={values.email}
                  id="exampleEmail"
                  invalid={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter your email"
                />
                <FormFeedback>
                  {errors.email && touched.email ? errors.email : errors.email}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword">Password</Label>
                <InputGroup>
                  <Input
                    type={passwordshow ? 'password' : 'text'}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.password && errors.password}
                    value={values.password}
                    id="examplePassword"
                    placeholder="Enter your password"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText onClick={passwordToogle}>
                      {passwordshow ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback>
                    {touched.password && errors.password ? errors.password : ''}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <Link to={FORGET_PASSWORD}>
                <div className="forgot">Forgot your password?</div>
              </Link>

              <Button color="primary" type="submit" block>
                Sign in
              </Button>
            </Form>
          </div>
        </section>
      </div>
    </>
  )
}
