import React, { useState, useEffect } from 'react'
import API from '../../constants/API'
import {
  LIST_BATCH,
  ATTEENDANCE_DETAILS,
  ATTENDANCE_CHECKIN,
  ATTENDANCE_CHECKOUT,
  DELETE_ATTENDENCE,
} from '../../constants/Apipath'
import { authHeader, getUserId, userName } from '../../function/Mainfunction'
import { CSVLink } from 'react-csv'

import {
  Card,
  Table,
  Row,
  Col,
  CardBody,
  Button,
  Container,
  Collapse,
  Input,
  Tooltip,
} from 'reactstrap'
import {
  CustomMessage,
  CustomPageLoading,
  JSONParser,
} from '../../function/Helperfunction'
import { Pencil, Delete } from '../../function/Icons'
import AttedenceManual from './AttedenceManual'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { BsFillPersonCheckFill, BsFillPersonDashFill } from 'react-icons/bs'
import { IoCaretUpSharp, IoCaretDownSharp } from 'react-icons/io5'
import NoRecordFound from '../../function/EmptyRecord'
import Swal from 'sweetalert2'
import { INSTRUCTOR_ROLLCALL } from '../../constants/RoutePaths'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

export default function Attendance() {
  const [loading, setloading] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [attendencePeriod, setattendencePeriod] = useState('today')
  const [batchList, setbatchList] = useState([])
  const [id, setId] = useState('')
  const [attedenceModal, setattedenceModal] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [flag, setflag] = useState(true)
  const [AttedanceDetails, setAttedanceDetails] = useState([])
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { attendance_Date } = useParams()
  const rollCallDate = moment(attendance_Date).format('MM-DD-YYYY')

  const Totoggle = () => setTooltipOpen(!tooltipOpen)
  let BatDetails = {}
  AttedanceDetails.forEach(
    (A) =>
      (BatDetails[`Ba_${A.batchId}`] = {
        ...BatDetails[`Ba_${A.batchId}`],
        [`Stu_${A.studentId}`]: {
          checkIn:
            moment(A.checkIn).format('HH:mm') == 'Invalid date'
              ? ''
              : moment(A.checkIn).format('HH:mm'),
          checkOut:
            moment(A.checkOut).format('HH:mm') == 'Invalid date'
              ? ''
              : moment(A.checkOut || '').format('HH:mm'),
          attendanceId: A.attendanceId || 0,
        },
      })
  )

  const [batchStuId, setbatchStuId] = useState({ batchId: 0, userId: 0 })

  const [attedenceDate, setattedenceDate] = useState(new Date())
  var csvData = [['', 'Check In ', 'Check Out']]

  batchList.map((i) => {
    var studentValue = []
    csvData.push([`Batch-${i.batchName}`])

    JSONParser(i.studentDetails, []).map((s) => {
      const Studentdetails =
        (BatDetails[`Ba_${i.batchId}`] &&
          BatDetails[`Ba_${i.batchId}`][`Stu_${s.userId}`]) ||
        {}

      csvData.push([
        s.studentName,
        Studentdetails?.checkIn,
        Studentdetails?.checkOut,
      ])
    })
  })

  // const csvData = [
  //   ['firstname', 'lastname', 'email'],
  //   ['Ahmed', 'Tomi', 'ah@smthing.co.com'],
  //   ['Raed', 'Labes', 'rl@smthing.co.com'],
  //   ['Yezzi', 'Min l3b', 'ymin@cocococo.com'],
  // ]
  const attedencetoggle = (e) => {
    const stuID = e.currentTarget.dataset.stuid
    const attId = e.currentTarget.dataset.attendanceid
    const batId = e.currentTarget.dataset.batchid
    if (attId) {
      setflag(false)
    }
    if (!attId) {
      setflag(true)
    }
    setbatchStuId({ userId: stuID, batchId: batId })
    setattedenceModal(!attedenceModal)
  }
  const closeattedencetoggle = () => {
    setattedenceModal(!attedenceModal)
  }

  const toggle = (e) => {
    const ID = e.currentTarget.dataset.batchid
    if (id == ID) {
      setCollapse(!collapse)
    } else {
      setCollapse(true)
    }
    setId(ID)
  }

  const getStudentList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_BATCH,
      params: {
        batchStartDate: moment(attedenceDate || new Date()).format(
          'MM/DD/YYYY'
        ),
        instructorId: getUserId(),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setbatchList(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }
  //yy/mm/dd HH:mm
  const getAttendanceDetials = async (event) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: ATTEENDANCE_DETAILS,
      params: {
        attendanceDate: moment(attedenceDate || new Date()).format(
          'YYYY/MM/DD'
        ),
        instructorId: getUserId(),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setAttedanceDetails(data)
        // console.log(data, 'data')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const AttendanceCheckin = async (e) => {
    const stuID = e.currentTarget.dataset.stuid

    const batId = e.currentTarget.dataset.batchid
    const config = {
      method: 'POST',
      headers: authHeader(),
      url: ATTENDANCE_CHECKIN,
      data: {
        userId: stuID,
        createdBy: getUserId(),
        checkIn:
          moment(attedenceDate).format('YYYY/MM/DD') +
          ' ' +
          moment(new Date()).format('HH:mm'),
        batchId: batId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status } = response.data
      if (status === 'Success') {
        CustomMessage('Check In', 'success', enqueueSnackbar) //call toster
        successFull()
      }
    } catch (error) {
      let { data } = error?.response
      CustomMessage(data?.message, 'error', enqueueSnackbar) //call toster
    } finally {
      setloading(false)
    }
  }

  const AttendanceCheckOut = async (e) => {
    const attId = e.currentTarget.dataset.attendanceid

    const config = {
      method: 'PUT',
      headers: authHeader(),
      url: ATTENDANCE_CHECKOUT,
      data: {
        attendanceId: attId,
        checkOut:
          moment(attedenceDate).format('YYYY/MM/DD') +
          ' ' +
          moment(new Date()).format('HH:mm'),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status } = response.data
      if (status === 'Success') {
        CustomMessage('Check Out', 'success', enqueueSnackbar) //call toster
        successFull()
      }
    } catch (error) {
      let { data } = error.response
      CustomMessage(data?.message, 'error', enqueueSnackbar) //call toster
    } finally {
      setloading(false)
    }
  }

  const DeleteAlert = (e) => {
    const Id = e.currentTarget.dataset.attendanceid
    Swal.fire({
      title: 'Are you want to Delete this Attedance?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        AbsentStu(Id)
      }
    })
  }
  const AbsentStu = async (Id) => {
    const config = {
      method: 'DELETE',
      url: DELETE_ATTENDENCE,
      headers: authHeader(),
      params: { attendanceId: Id },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
        successFull()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  const AttedenceDateHandler = (e) => {
    setattedenceDate(e.target.value)
    // console.log(
    //   moment(e.target.value).format('YYYY/MM/DD') +
    //     ' ' +
    //     moment(new Date()).format('HH:mm')
    // )
  }
  const successFull = () => {
    getStudentList()
    getAttendanceDetials()
  }
  const Alert = (message) => {
    CustomMessage(`Double Click to ${message}`, 'info', enqueueSnackbar) //call toster
  }
  useEffect(() => {
    getStudentList()
    getAttendanceDetials()
  }, [attedenceDate])
  return (
    <div>
      <div className="page-title">
        <h4>Attendance</h4>
      </div>
      {loading && <CustomPageLoading />}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card body>
              <CardBody>
                <div className="Attendance-export">
                  <Col md="3" className="p-0">
                    <Input
                      id={attedenceDate}
                      type="date"
                      onChange={AttedenceDateHandler}
                      data-da={attedenceDate}
                      max={moment(new Date()).format('YYYY-MM-DD')}
                      value={moment(attedenceDate).format('YYYY-MM-DD')}
                    />
                  </Col>

                  <Button color="danger" className="ml-auto">
                    <CSVLink
                      filename={` ${userName()}_${moment(attedenceDate).format(
                        'YYYY-MM-DD'
                      )}_attendance.csv`}
                      data={csvData}>
                      Export CSV
                    </CSVLink>
                  </Button>
                </div>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>Batch Name</th>
                      <th>Batch Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!batchList.length && <NoRecordFound />}
                    {batchList.map((i, index) => (
                      <>
                        <tr
                          data-batchid={i.batchId}
                          onClick={toggle}
                          key={index}>
                          <td>{i.batchName}</td>
                          <td> {i.type}</td>
                          <td>
                            <Button
                              className="table-arrowBtn"
                              color="link"
                              data-batchid={i.batchId}
                              onClick={toggle}>
                              {collapse && i.batchId == id ? (
                                <IoCaretUpSharp />
                              ) : (
                                <IoCaretDownSharp />
                              )}
                            </Button>
                          </td>
                        </tr>

                        <tr className="collapse-td">
                          <td colSpan={5} className="p-0 border-none">
                            <Collapse isOpen={i.batchId == id && collapse}>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Student Name</th>
                                    <th style={{ textAlign: 'center' }}>
                                      Check In
                                    </th>
                                    <th style={{ textAlign: 'center' }}>
                                      Check out
                                    </th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {JSONParser(i.studentDetails, []).map(
                                    (st) => {
                                      const Studentdetails =
                                        (BatDetails[`Ba_${i.batchId}`] &&
                                          BatDetails[`Ba_${i.batchId}`][
                                            `Stu_${st.userId}`
                                          ]) ||
                                        {}

                                      return (
                                        <tr>
                                          <td>{st.studentName}</td>
                                          <td style={{ textAlign: 'center' }}>
                                            {!Studentdetails.checkIn && (
                                              <Button
                                                color="link"
                                                disabled={loading}
                                                data-stuid={st.userId}
                                                data-batchid={i.batchId}
                                                onClick={() =>
                                                  Alert('Check In')
                                                }
                                                onDoubleClick={
                                                  AttendanceCheckin
                                                }>
                                                <BsFillPersonCheckFill />
                                              </Button>
                                            )}
                                            {Studentdetails.checkIn &&
                                              Studentdetails.checkIn}
                                          </td>
                                          <td style={{ textAlign: 'center' }}>
                                            {!Studentdetails.checkOut &&
                                              Studentdetails.checkIn && (
                                                <Button
                                                  color="link"
                                                  disabled={loading}
                                                  onClick={() =>
                                                    Alert('Check Out')
                                                  }
                                                  data-attendanceid={
                                                    Studentdetails.attendanceId
                                                  }
                                                  onDoubleClick={
                                                    AttendanceCheckOut
                                                  }>
                                                  <BsFillPersonDashFill />
                                                </Button>
                                              )}
                                            {Studentdetails.checkOut &&
                                              Studentdetails.checkOut}
                                          </td>
                                          <td>
                                            <Button
                                              data-attendanceid={
                                                Studentdetails.attendanceId
                                              }
                                              data-stuid={st.userId}
                                              data-batchid={i.batchId}
                                              onClick={attedencetoggle}
                                              color="link">
                                              {' '}
                                              <Pencil />{' '}
                                            </Button>
                                            {Studentdetails.attendanceId && (
                                              <>
                                                <Button
                                                  id="TooltipExample"
                                                  data-attendanceid={
                                                    Studentdetails.attendanceId
                                                  }
                                                  onClick={DeleteAlert}
                                                  color="link">
                                                  <Delete />
                                                </Button>
                                                <Tooltip
                                                  placement="bottom"
                                                  isOpen={tooltipOpen}
                                                  target="TooltipExample"
                                                  toggle={Totoggle}>
                                                  Mark absent
                                                </Tooltip>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </Collapse>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <AttedenceManual
        batchStuId={batchStuId}
        BatDetails={BatDetails}
        flag={flag}
        attedenceDate={attedenceDate}
        successFull={successFull}
        attedenceModal={attedenceModal}
        close={closeattedencetoggle}
        attedencetoggle={attedencetoggle}
      />
    </div>
  )
}
