import { Redirect, Route } from 'react-router'
import {
  ADMIN_DASHBOARD,
  INSTRUCTOR_HOME_ROUTE,
  PAGE_NOT_FOUND_ROUTE,
  ROLLCALL,
  SIGNIN_ROUTE,
} from '../constants/RoutePaths'
import React from 'react'
import {
  AMERICAN_TRUCK_TOKEN,
  ROAL_ID,
  USER_NAME,
  USER_ID,
  REFERED_BY_ID,
  DPS_TEST_ID,
  DPS_TEST_TYPE_ID,
  USER_EMAIL,
  ROOT_USER,
} from '../constants/Localvaribale'
import PageNotFound from '../components/PageNotFount'

export function PublicRoute({ component, ...rest }) {
  const token = localStorage.getItem(AMERICAN_TRUCK_TOKEN)
  const location = window.location.href
  const search = window.location.search
  const Role = localStorage.getItem(ROAL_ID)
  if (!token) {
    return <Route {...rest} component={component} />
  } else if (search) {
    return (
      <Redirect
        to={{ pathname: `${SIGNIN_ROUTE}`, search: `${'?ref=' + location}` }}
      />
    )
  } else {
    if ([1, 2].includes(Role)) {
      return <Redirect to={{ pathname: ADMIN_DASHBOARD }} />
    } else if (Role == 5) {
      return <Redirect to={{ pathname: ROLLCALL }} />
    } else {
      return <Redirect to={{ pathname: INSTRUCTOR_HOME_ROUTE }} />
    }
  }
}

// export function PrivateRoute({ component, ...rest }) {
//   const token = localStorage.getItem(AMERICAN_TRUCK_TOKEN);

//   if (token) {
//     return <Route {...rest} component={component} />;
//   } else return <Redirect to={{ pathname: `${SIGNIN_ROUTE}` }} />;
// }
export function PrivateRoute({ component, rest }) {
  const token = localStorage.getItem(AMERICAN_TRUCK_TOKEN)
  const location = window.location.href
  const search = window.location.search

  if (token) {
    if (['1', '2'].includes(authRole())) {
      return <Admin rest={rest} component={component} />
    } else if (authRole() == 5) {
      return <Rollcall rest={rest} component={component} />
    } else {
      return <Instructor rest={rest} component={component} />
    }
  } else {
    if (search) {
      return (
        <Redirect
          to={{ pathname: `${SIGNIN_ROUTE}`, search: `${'?ref=' + location}` }}
        />
      )
    } else {
      return <Redirect to={{ pathname: `${SIGNIN_ROUTE}` }} />
    }
  }
}

export function Admin({ component, ...rest }) {
  if (window.location.pathname.split('/')[1] === 'admin') {
    return <Route {...rest} component={component} />
  } else {
    return <Redirect path={PAGE_NOT_FOUND_ROUTE} component={PageNotFound} />
  }
}

export function Rollcall({ component, ...rest }) {
  if (window.location.pathname.split('/')[1] === 'rollcalladmin') {
    return <Route {...rest} component={component} />
  } else {
    return <Redirect path={PAGE_NOT_FOUND_ROUTE} component={PageNotFound} />
  }
}

export function Instructor({ component, ...rest }) {
  if (window.location.pathname.split('/')[1] === 'instructor') {
    return <Route {...rest} component={component} />
  } else {
    return <Redirect path={PAGE_NOT_FOUND_ROUTE} component={PageNotFound} />
  }
}

export function authHeader() {
  const token = localStorage.getItem(AMERICAN_TRUCK_TOKEN)
  return { Authorization: token }
}

export function authRole() {
  const Role = localStorage.getItem(ROAL_ID)
  return Role
}

export function userName() {
  const user_name = localStorage.getItem(USER_NAME)
  return user_name
}

export function rootUser() {
  const root = localStorage.getItem(ROOT_USER)
  return root
}

export function getUserId() {
  const user_id = localStorage.getItem(USER_ID)
  return user_id
}

export function getEmail() {
  const user_email = localStorage.getItem(USER_EMAIL)
  return user_email
}

export function getRoleId() {
  const roleId = localStorage.getItem(ROAL_ID)
  return roleId
}

export function referedById() {
  const refered_by_id = localStorage.getItem(REFERED_BY_ID)
  return refered_by_id
}

export function dpsTestId() {
  const dps_test_id = localStorage.getItem(DPS_TEST_ID)
  return dps_test_id
}

export function dpsTestTypeId() {
  const dps_test_type_id = localStorage.getItem(DPS_TEST_TYPE_ID)
  return dps_test_type_id
}
