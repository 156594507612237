import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Container,
  Button,
  Form,
} from 'reactstrap'
import API from '../../../constants/API'
import { CustomMessage } from '../../../function/Helperfunction'
import { authHeader } from '../../../function/Mainfunction'
import { useSnackbar } from 'notistack'
import {
  LIST_INSTRUCTOR,
  LIST_STATION,
  LIST_TRUCK,
  REPORT,
  STUDENT_LIST,
} from '../../../constants/Apipath'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { debounce } from 'underscore'
import PdfGen from './PdfGen'
import { JSONParser } from '../../../function/Helperfunction'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import CustomPageLoading from '../../../function/CustomPageLoading'
const filterInitial = {
  startDate: new Date(),
  endDate: new Date(),
  studentId: '',
  type: '',
  truckId: '',
  stationId: '',
  instructorId: '',
}

export default function Report() {
  const [loading, setloading] = useState(false)
  const [studentOptions, setStudentOptions] = useState([])
  const [filterValue, setfilterValue] = useState(filterInitial)
  const [studentName, setstudentName] = useState('')
  const [instructorname, setinstructorname] = useState('')

  const [totalCount, settotalCount] = useState(0)
  const [truckList, setTruckList] = useState([])
  const [stationList, setStationList] = useState([])
  const [instructorList, setInstructorList] = useState([])

  const tripType = ['Maneuver', 'Pretrip', 'Drive', 'Incab', 'Dps']
  const [reportvalue, setreportvalue] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const GetReport = async (filterValue) => {
    const config = {
      method: 'GET',
      url: REPORT,
      headers: authHeader(),
      params: {
        count: 1000,
        ...filterValue,
        startDate: filterValue.endDate
          ? moment(filterValue.endDate).format('YYYY-MM-DD')
          : '',
        endDate: filterValue.startDate
          ? moment(filterValue.startDate).format('YYYY-MM-DD')
          : '',
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        setreportvalue(data)
        settotalCount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  const getTruckList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_TRUCK,
      params: {
        count: 1000,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setTruckList(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }
  const getStudentList = async (studentName) => {
    const config = {
      headers: authHeader(),
      method: 'GET',
      url: STUDENT_LIST,
      params: {
        firstName: studentName,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        let isWeekendStudent = []
        let isWeeDaysStudent = []
        let newData = [
          {
            label: 'Weekend student',
            options: isWeekendStudent,
          },
          {
            label: 'weekdays student',
            options: isWeeDaysStudent,
          },
        ]

        data.forEach((element) => {
          if (element.isWeekendStudent) {
            isWeekendStudent.push({
              value: element.userId,
              label: element.firstName + ' ' + element.lastName,
              isWeekendStudent: element.isWeekendStudent,
            })
          } else {
            isWeeDaysStudent.push({
              value: element.userId,
              label: element.firstName + ' ' + element.lastName,
              isWeekendStudent: element.isWeekendStudent,
            })
          }
        })
        setStudentOptions(newData)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.warn(data)
      }
    } finally {
      setloading(false)
    }
  }

  const OptionHandleStudent = (e, name) => {
    setfilterValue((pre) => ({ ...pre, [name]: e?.value || '' }))
  }

  const OptionInputHandleStudent = (e) => {
    setstudentName(e)
  }

  const OptionInputHandlinstructor = (e) => {
    setinstructorname(e)
  }

  const studentSearch = useRef(
    debounce((studentName) => getStudentList(studentName), 500)
  ).current
  useEffect(() => {
    studentSearch(studentName)
  }, [studentName])

  const delaySearch = useRef(
    debounce((filterValue) => GetReport(filterValue))
  ).current

  useEffect(() => {
    delaySearch(filterValue)
  }, [filterValue])

  const formHandler = (e, name) => {
    setfilterValue((value) => ({ ...value, [name]: e }))
  }

  const Report = () => {
    let temp = {}
    reportvalue.forEach((r) => {
      temp[r.type] = [...(temp[r.type] || ''), ...JSONParser(r.tripDetails, [])]
    })
    PdfGen(temp)
  }

  const pretripHeaders = ['Pre-Trip ID', 'Trip Date', 'Pre-Trip Time']

  const incabHaeders = ['Incab ID', 'Incab Date']

  const maneuverHeaders = [
    'Maneuver',
    'Mirrors',
    'Steering',
    'Encroaches',
    'Maneuver Date',
    'Maneuver Time',

    'Score',
    'Comments',
  ]

  const driveHaeders = [
    'Bears',
    'Brake',
    'Downshifting',
    'FollowDistance',
    'Comments',
    'GearPattern',
    'Brake',
    'HitCurb',
    'HoverH',
    'Comments',
    'InterCoasting',
    'IntersectionMirrors',
    'IntersectiontrafficCheck',
    'MergingSmooth',
    'MergingTrafficCheck',
    'TimeIn',
    'TimeOut',
    'Timing',
    'TotalTime',
    'Reaction',
  ]
  const dpsTestHeaders = ['Test Date', 'Test Hours', 'Comments', 'Test Result']

  let reportObf = {}
  reportvalue.forEach((r) => {
    reportObf[r.type] = [
      ...(reportObf[r.type] || ''),
      ...JSONParser(r.tripDetails, []),
    ]
  })

  let keys = Object.keys(reportObf)
  const csvReport = []
  let csVobj = {
    Maneuver: function (e) {
      csvReport.push([''], ['Maneuver'], maneuverHeaders)
      e.forEach((m) => {
        if (!!m.maneuver)
          csvReport.push([
            m.maneuver,
            m.mirrors,
            m.steering,
            m.encroaches,
            m.maneuverDate,
            m.maneuverTime,
            m.score,
            m.comments,
          ])
      })
    },
    Pretrip: function (e) {
      csvReport.push([''], ['Pretrip'], pretripHeaders)
      e.forEach((p) => {
        if (!!p.preTripId) {
          csvReport.push([p.preTripId, p.tripDate, p.preTripTime])
        }
      })
    },
    Drive: function (e) {
      csvReport.push([''], ['Drive'], driveHaeders)
      e.forEach((d) => {
        if (!!d.bears) {
          csvReport.push([
            d.bears,
            d.brake,
            d.downshifting,
            d.followDistance,
            d.comments,
            d.gearPattern,
            d.hitCurb,
            d.hoverH,
            d.lane,
            d.interCoasting,
            d.intersectionMirrors,
            d.intersectiontrafficCheck,
            d.mergingSmooth,
            d.mergingTrafficCheck,
            d.timeIn,
            d.timeOut,
            d.timing,
            d.totalTime,
            d.lane,
          ])
        }
      })
    },
    Incab: function (e) {
      csvReport.push([''], ['Incab'], incabHaeders)

      e.forEach((i) => {
        if (!!i.incabId) {
          csvReport.push([i.incabId, i.incabDate])
        }
      })
    },
    Dps: function (e) {
      csvReport.push([''], ['Dpstest'], dpsTestHeaders)
      e.forEach((s) => {
        if (s.testDate) {
          csvReport.push([s.testDate, s.testHours, s.comments, s.testResult])
        }
      })
    },
  }
  keys.forEach((k) => csVobj[k](reportObf[k]))

  const getInstructorList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_INSTRUCTOR,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setInstructorList(
          data.map((d) => ({ value: d.userId, label: d.firstName }))
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }
  const instrctorSearch = useRef(
    debounce((instructorname) => getInstructorList(instructorname), 500)
  ).current
  useEffect(() => {
    instrctorSearch(instructorname)
  }, [instructorname])

  const getStationList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_STATION,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setStationList(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }
  useEffect(() => {
    getTruckList()
    getStationList()
  }, [])

  return (
    <Fragment>
      <div className="wrapper">
        <div className="page-title" style={{ margin: 0 }}>
          <h3>Report</h3>
        </div>
        {loading && <CustomPageLoading />}
        <Container fluid>
          <Form>
            <Row xs="2">
              <Col>
                <FormGroup>
                  <Label>Select Student</Label>
                  <Select
                    name="studentId"
                    options={studentOptions}
                    isClearable={true}
                    placeholder="Select Student"
                    onChange={(e) => OptionHandleStudent(e || '', 'studentId')}
                    onInputChange={OptionInputHandleStudent}
                  />
                </FormGroup>
              </Col>
              <Col>
                {' '}
                <FormGroup>
                  <Label>Select Trip type</Label>

                  <Input
                    onChange={(e) => formHandler(e.target.value, 'type')}
                    name="type"
                    value={filterValue.type}
                    type="select">
                    <option value="">Select Type</option>
                    {tripType.map((t, index) => (
                      <option key={index} value={t}>
                        {t}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Select Truck</Label>

                  <Input
                    type="select"
                    name="truckId"
                    id="truckId"
                    placeholder="Select Truck"
                    value={filterValue.truckId}
                    onChange={(e) => formHandler(e.target.value, 'truckId')}>
                    <option value="">Select Truck</option>
                    {truckList.map((e) => (
                      <option value={e.truckId}>{e.truckName}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Select Station</Label>{' '}
                  <Input
                    type="select"
                    name="stationId"
                    id="stationId"
                    placeholder="Select Station"
                    value={filterValue.stationId}
                    onChange={(e) => formHandler(e.target.value, 'stationId')}>
                    <option value="">Select station</option>
                    {stationList.map((e) => (
                      <option value={e.stationId}>{e.stationName}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Select Instructor</Label>

                  <Select
                    onChange={(e) => OptionHandleStudent(e, 'instructorId')}
                    options={instructorList}
                    isClearable={true}
                    placeholder="Select Instructor"
                    name="instructorId"
                    onInputChange={OptionInputHandlinstructor}
                    // onInputChange={() => {}}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <div className="submit-class">
            <div className="submit">
              <Row xs="2">
                <Col>
                  <FormGroup>
                    <Label>Select From date</Label>

                    <DatePicker
                      onChange={(date) => formHandler(date, 'endDate')}
                      selected={
                        !filterValue.endDate
                          ? null
                          : new Date(filterValue.endDate)
                      }
                      name="endDate"
                      closeOnScroll={true}
                      placeholderText="Enter End Date"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Select Till date</Label>

                    <DatePicker
                      onChange={(date) => formHandler(date, 'startDate')}
                      name="startDate"
                      selected={
                        !filterValue.startDate
                          ? null
                          : new Date(filterValue.startDate)
                      }
                      closeOnScroll={true}
                      placeholderText="Enter Start date"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Button color="primary" onClick={Report}>
                    Pdf
                  </Button>
                </Col>
                <Col>
                  <CSVLink filename={`Details.csv`} data={csvReport}>
                    <Button color="danger">Csv</Button>
                  </CSVLink>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
