export const AMERICAN_TRUCK_TOKEN = 'AMERICAN_TRUCK_TOKEN'
export const ROAL_ID = 'ROAL_ID'
export const USER_ID = 'USER_ID'
export const USER_NAME = 'USER_NAME'
export const USER_EMAIL = 'USER_EMAIL'
export const REFERED_BY_ID = 'REFERED_BY_ID'
export const DPS_TEST_ID = 'DPS_TEST_ID'
export const LAST_NAME = 'LAST_NAME'
export const DPS_TEST_TYPE_ID = 'DPS_TEST_TYPE_ID'
export const ROOT_USER = 'ROOT_USER'
