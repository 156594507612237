import { useSnackbar } from 'notistack'
import React, { Fragment, useEffect, useState } from 'react'
import { Container, Col, Row, CardTitle, Card } from 'reactstrap'
import API from '../../constants/API'
import { INSTRUCTOR_DASHBOARD } from '../../constants/Apipath'
import { CustomMessage } from '../../function/Helperfunction'
import { getUserId } from '../../function/Mainfunction'
import { authHeader } from '../../function/Mainfunction'
import BatchModel from '../../function/BatchModel'
import { TableLoader } from '../../function/Loader'
import CalendarFun from './Calendar'
import empty from '../../images/empty.svg'
export default function Home() {
  const [loading, setloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [ActiveBatch, setActiveBatch] = useState([])
  const [UpcommingBatch, setUpcommingBatch] = useState([])
  const GetInstructorDash = async () => {
    const config = {
      method: 'GET',
      url: INSTRUCTOR_DASHBOARD,
      headers: authHeader(),
      params: {
        userId: getUserId(),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, activeBatch, upComingBatch } = response.data
      if (status === 'Success') {
        setActiveBatch(activeBatch)
        setUpcommingBatch(upComingBatch)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  useEffect(() => {
    GetInstructorDash()
  }, [])
  return (
    <Fragment>
      <div className="page-title">
        <h4>Overview</h4>
      </div>

      <Container fluid={true}>
        <Row>
          <Col md="8">
            <Card body>
              <CardTitle tag="h5">Active batch</CardTitle>
              {loading && <TableLoader />}
              {!loading && !ActiveBatch.length && UpcommingBatch.length ? (
                <div
                  className="data-empty-sec"
                  style={{ backgroundColor: '#1B3E6F29' }}>
                  <p>No Record Found!</p>
                </div>
              ) : (
                ''
              )}

              {!loading && (
                <BatchModel
                  hideActionButtons={true}
                  batchDetails={ActiveBatch}
                />
              )}
            </Card>

            <Card body className="mt-3">
              <CardTitle tag="h5">Upcoming batch</CardTitle>
              {loading && <TableLoader />}
              {!loading && ActiveBatch.length && !UpcommingBatch.length ? (
                <div
                  className="data-empty-sec"
                  style={{ backgroundColor: '#1B3E6F29' }}>
                  <p>No Record Found!</p>
                </div>
              ) : (
                ''
              )}

              {!loading && (
                <BatchModel
                  hideActionButtons={true}
                  batchDetails={UpcommingBatch}
                />
              )}
            </Card>
            <br />
            {!loading && !ActiveBatch.length && !UpcommingBatch.length && (
              <div className="data-empty-sec">
                <img src={empty} />
                <p>No Active and Upcoming Batches</p>
              </div>
            )}
          </Col>
          <Col md="4">
            <Card body>
              <CalendarFun />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
