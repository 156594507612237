import React, { Fragment } from 'react'
import { Route } from 'react-router'

import {
  INSTRUCTOR_HOME_ROUTE,
  BATCH_DETAILS_ROUTE,
  BATCH_ROUTE,
  CREATE_BTW_ROUTE,
  INSTRUCTOR_STUDENT_DETAILS,
  INSTRUCTOR_ATTENDANCE,
  INSTRUCTOR_SETTINGS,
  INSTRUCTOR_ROLLCALL,
  CLASSROOM_ROLLCALL_VIEW,
  INSTRUCTOR_ROLLOVER_VIEW,
} from '../../constants/RoutePaths'
import Batch from './Batch'
import BatchDetails from './BatchDetails'
import CommonInstructonDriveForm from './CommonInstructonDriveForm'
import Header from './Header'
import Home from './Home'
import Settings from './Settings'
import InstructorStudentDetails from './InstructorStudentDetails'
import Attendance from './Attendance'
import RollCall from './RollCall'
import RolloverView from '../admin/rollover/RolloverView'
import InstructorRolloverView from './InstructorRolloverView'
export default function Layout() {
  return (
    <Fragment>
      <Header />
      <Route exact path={INSTRUCTOR_HOME_ROUTE} component={Home} />
      <Route exact path={BATCH_ROUTE} component={Batch} />
      <Route
        path={`${BATCH_DETAILS_ROUTE}/:${'batchId'}`}
        component={BatchDetails}
      />
      <Route exact path={INSTRUCTOR_SETTINGS} component={Settings} />
      <Route
        exact
        path={`${CREATE_BTW_ROUTE}/:${'batchtype'}/:${'batchId'}/:${'userId'}`}
        component={CommonInstructonDriveForm}
      />
      <Route
        exact
        path={`${INSTRUCTOR_ATTENDANCE}/:${'attendance_Date'}`}
        component={Attendance}
      />
      <Route
        exact
        path={`${INSTRUCTOR_ROLLCALL}/:${'attendance_Date'}`}
        component={RollCall}
      />
      <Route
        exact
        path={`${INSTRUCTOR_STUDENT_DETAILS}/:${'studentId'}`}
        component={InstructorStudentDetails}
      />
      <Route
        exact
        path={`${INSTRUCTOR_ROLLOVER_VIEW}/:${'date'}`}
        component={InstructorRolloverView}
      />
    </Fragment>
  )
}
