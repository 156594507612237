import React from 'react'

import { Label, Row, Col, Input } from 'reactstrap'
import { getUserId } from '../../function/Mainfunction'
import { useDispatch, useSelector } from 'react-redux'
import {
  ATTENDANCE_ONCHANGE_HANDLE,
  ATTENDANCE_DPM_SELECT,
  HANDLE_ONCHANGED,
} from '../../redux/action/ActionType'
import { batid_, stuid_ } from '../../redux/helper'
let array = []
export default function RepsModal(props) {
  const { studentid, batchid, attendencid } = props
  const dispatch = useDispatch()

  const formvalues = useSelector((state) => state.AttendanceRepsReducer)

  const { medicalCard, permit, driverLicense } =
    formvalues[batid_ + batchid + stuid_ + studentid] || {}
  const handleChange = (e) => {
    const batchid = e.currentTarget.dataset.batid
    const studentid = e.currentTarget.dataset.stuid
    const { name, checked } = e.target
    dispatch({
      type: ATTENDANCE_ONCHANGE_HANDLE,
      batchId: batchid,
      studentid: studentid,
      value: checked,
      attid: attendencid,
      name: name,
    })
    dispatch({ type: HANDLE_ONCHANGED, payload: true })
  }

  return (
    <>
      <Row style={{ marginRight: '0px' }}>
        <Col>
          <Label>
            <Input
              data-stuid={studentid}
              data-batid={batchid}
              type="checkbox"
              name="driverLicense"
              onChange={handleChange}
              checked={driverLicense}
            />
            Driver License
          </Label>
        </Col>
        <Col>
          <Label>
            <Input
              data-stuid={studentid}
              data-batid={batchid}
              type="checkbox"
              name="permit"
              onChange={handleChange}
              checked={permit}
            />
            Permit
          </Label>
        </Col>
        <Col>
          <Label>
            <Input
              data-stuid={studentid}
              data-batid={batchid}
              type="checkbox"
              name="medicalCard"
              onChange={handleChange}
              checked={medicalCard}
            />
            Medical Card
          </Label>
        </Col>
      </Row>
    </>
  )
}
