import jsPDF from 'jspdf'
import 'jspdf-autotable'
// Date Fns is used to format the dates we receive
// from our API call
import { format } from 'date-fns'

// define a generatePDF function that accepts a tickets argument
const generatePDF = (name, IncabReport) => {
  // initialize jsPDF
  //   const doc = new jsPDF();
  var doc = new jsPDF('l', 'mm', 'a4')
  var d = format(new Date(), 'yyyy-MM-dd')
  // define the columns we want and their titles
  const tableColumn = [
    'Incab ID',
    'Incab Date',
    'Comment',
    // 'Seat belts',
    // 'Emergency Triangles3',
    // 'Fire Extinguisher',
    // 'Mirrors DrivePassHood',
    // 'Windshield Wipers',
    // 'Circuit Breaker',
    // 'Horns',
    // 'SteeringWheel',
    // 'Clutch',
    // 'StartTruck',
    // 'ABS',
    // 'LightsLTRT4wayH',
    // 'CoolantTemperatureGauge',
    // 'OilPressureGauge',
    // 'Def',
    // 'PrimarySecondaryAirGuages',
    // 'Voltage',
    // 'Heater Defroster',
    // 'Washerfluid',
    // 'Shut TruckOff',
    // 'Leave TruckinGear',
    // 'TurnKey BackOn',
    // 'ElectricOn',
    // 'PushInYellowValveFromA',
    // 'Verify RedValve',
    // 'Pushon BrakePedalHold',
    // 'Check for AirLoss',
    // 'Start PumpingBrakes',
    // 'Watch for LowAirPressure',
    // 'Light BuzzerPSI',
    // 'Continueto PumpBrakes',
    // 'Watch Air Gaugean Both Valves',

    // 'Valves Should Popout',
    // 'Pump Brakes Until',
    // 'GovTest',
    // 'Push InRedValve',
    // 'Leave YellowValveOut',
    // 'PutIn LowGearandPull',
    // 'PushIn YellowvalveFormB',
    // 'Leave RedValveOut',
    // 'PutIn LowGearandPullAgainstBrakes',

    // 'Place VehicleinLowGear',
    // 'Release AllBrakes',
    // 'Apply BrakesFirmly',
  ]
  // define an empty array of rows
  const tableRows = []

  // for each report pass all its data into an array
  IncabReport.forEach((i) => {
    const incabData = [
      i.incabId,
      i.incabDate,
      i.comments,
      // i.seatbelts,
      // i.emergencyTriangles3,
      // i.fireExtinguisher,
      // i.mirrorsDrivePassHood,
      // i.windshieldWipers,
      // i.circuitBreaker,
      // i.horns,
      // i.steeringWheel,
      // i.clutch,
      // i.startTruck,
      // i.ABS,
      // i.lightsLTRT4wayH,
      // i.coolantTemperatureGauge,
      // i.oilPressureGauge,
      // i.def,
      // i.primarySecondaryAirGuages,
      // i.voltage,
      // i.heaterDefroster,
      // i.washerfluid,
      // i.shutTruckOff,
      // i.leaveTruckinGear,
      // i.turnKeyBackOn,
      // i.electricOn,
      // i.pushInYellowValveFromA,
      // i.verifyRedValve,
      // i.pushonBrakePedalHold,
      // i.checkforAirLoss,
      // i.startPumpingBrakes,
      // i.watchforLowAirPressure,
      // i.lightBuzzerPSI,
      // i.continuetoPumpBrakes,
      // i.watchAirGaugeanBothValves,
      // i.valvesShouldPopout,
      // i.pumpBrakesUntil,
      // i.govTest,
      // i.pushInRedValve,
      // i.leaveYellowValveOut,
      // i.putInLowGearandPull,
      // i.pushInYellowvalveFormB,
      // i.leaveRedValveOut,
      // i.putInLowGearandPullAgainstBrakes,
      // i.placeVehicleinLowGear,
      // i.releaseAllBrakes,
      // i.applyBrakesFirmly,
      // called date-fns to format the date on the report
    ]

    tableRows.push(incabData)
  })

  // startY is basically margin-top
  doc.autoTable(tableColumn, tableRows, { startY: 20 })
  const date = Date().split(' ')
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4]
  // report title. and margin-top + margin-left
  doc.text(`${name}_Incab Details` + d, 14, 15)
  // we define the name of our PDF file.
  doc.save(`${name}_Incab_Details_${d}.pdf`)
}

export default generatePDF
