import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  Container,
  Button,
  Card,
  CardTitle,
  Table,
  Collapse,
  Col,
  Row,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'reactstrap'
import {
  CREATE_TRUCK_ROUTE,
  UPDATE_TRUCK_ROUTE,
} from '../../../constants/RoutePaths'
import { authHeader } from '../../../function/Mainfunction'
import { CustomMessage, locationParams } from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import Pagination from 'react-js-pagination'
import { DELETE_TRUCK, LIST_TRUCK } from '../../../constants/Apipath'
import API from '../../../constants/API'
import { Add, Delete, Filter, Pencil } from '../../../function/Icons'
import { Truckloader } from '../../../function/Loader'
import DatePicker from 'react-datepicker'
import { debounce } from 'underscore'
import moment from 'moment'
import { StateValue, UrlReplace } from '../../../constants/Healper'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import generatePDF from './TruckPDFGenerator'
import NoRecordFound from '../../../function/EmptyRecord'

const filterInitial = {
  truckName: '',
  plateNo: '',
  tagExpDate: '',
  insuranceExpDate: '',
  nextServiceDate: '',
  offset: '0',
}
export default function Truck() {
  const history = useHistory()
  const location = useLocation()
  const [totalcount, setTotalcount] = useState(0)
  const [truck, setTruck] = useState([])
  const [loading, setloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [filterValue, setfilterValue] = useState(
    StateValue(location.search, filterInitial)
  )
  const [filterOpen, setfilterOpen] = useState(false)
  const filtertoggle = () => setfilterOpen(!filterOpen)
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const toggle = () => {
    setdropdownOpen(!dropdownOpen)
  }
  const [orderList, setorderList] = useState('asc')

  const headers = [
    { label: 'Trailer unit', key: 'trailerUnit' },
    { label: 'Vin', key: 'vin' },
    { label: 'Plate No', key: 'plateNo' },
    { label: 'Exp date', key: 'tagExpDate' },
    { label: 'Insurance policy', key: 'insurancePolicy' },
    { label: 'Insurance exp date', key: 'insuranceExpDate' },
    { label: 'color', key: 'colorDOT' },
    { label: 'Annual inspection date', key: 'annualInspectionDate' },
    { label: 'Tire size', key: 'tireSize' },
    { label: 'Manufacturer', key: 'manufacturer' },
    { label: 'Trailer size<', key: 'trailerSize' },
    { label: 'Model Year', key: 'modelYear' },
    { label: 'Last service', key: 'lastService' },
    { label: 'Next service', key: 'nextServiceDate' },
  ]

  const Export = () => {
    generatePDF(truck)
  }
  const GetTruck = async (filterValue, value = 'asc') => {
    const config = {
      method: 'GET',
      url: LIST_TRUCK,
      headers: authHeader(),
      params: {
        count: 10,
        orderBy: value,
        columnName: 'truckName',
        ...filterValue,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        setTruck(data)
        setTotalcount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const DeleteAlert = (e) => {
    const Id = e.currentTarget.dataset.truckid
    Swal.fire({
      title: 'Are you want to Delete this Truck?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteTruck(Id)
      }
    })
  }

  const DeleteTruck = async (Id) => {
    const config = {
      method: 'DELETE',
      url: DELETE_TRUCK,
      headers: authHeader(),
      params: { truckId: Id },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
        GetTruck()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const handlePageChange = (page) => {
    setfilterValue({ ...filterValue, offset: page - 1 })
  }

  const filterHandle = (e) => {
    const { name, value } = e.target
    setfilterValue({ ...filterValue, [name]: value, offset: '0' })
  }

  const DateHandle = (date, name) => {
    if (date) {
      const newDate = moment(date).format('L')

      setfilterValue({ ...filterValue, [name]: newDate, offset: '0' })
    } else {
      setfilterValue({ ...filterValue, [name]: '', offset: '0' })
    }
  }

  const Clean = () => {
    setfilterValue(filterInitial)
  }

  const delaySearch = useRef(
    debounce((filterValue, orderList) => GetTruck(filterValue, orderList))
  ).current
  useEffect(() => {
    delaySearch(filterValue, orderList)
    let pagePath = location.pathname
    let locParams = locationParams(location.search)
    UrlReplace(pagePath, locParams, filterValue, setfilterOpen, history)
  }, [filterValue, orderList])

  const Createnewtruck = () => {
    history.push(CREATE_TRUCK_ROUTE)
  }
  const OrderList = (e) => {
    setorderList(e.target.value)
  }

  return (
    <Fragment>
      <div className="wrapper">
        <div className="page-title">
          <h4>Truck</h4>
          <div className="new-input">
            <Input value={orderList} onChange={OrderList} type="select">
              <option value={'asc'}> Ascending </option>
              <option value={'desc'}> Descending </option>
            </Input>
          </div>
          <div className="btn-right">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle color="danger" caret>
                Export
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={Export}>PDF</DropdownItem>

                <DropdownItem>
                  <CSVLink
                    filename={'Trucks_Details.csv'}
                    data={truck}
                    headers={headers}>
                    CSV
                  </CSVLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Button onClick={filtertoggle} color="danger">
              <Filter />
              Filter
            </Button>
            <Button onClick={Createnewtruck} color="primary">
              <Add />
              New Truck
            </Button>
          </div>
        </div>
        <Container fluid={true} className="mb-4">
          <Collapse isOpen={filterOpen}>
            <Card body>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="truckName"
                      value={filterValue.truckName}
                      onChange={filterHandle}
                      placeholder="Enter Truck Name"
                    />
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="plateNo"
                      value={filterValue.plateNo}
                      placeholder="Enter Plate number"
                      onChange={filterHandle}
                    />
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <DatePicker
                      name="tagExpDate"
                      onChange={(date) => DateHandle(date, 'tagExpDate')}
                      closeOnScroll={true}
                      selected={
                        !filterValue.tagExpDate
                          ? null
                          : new Date(filterValue.tagExpDate)
                      }
                      placeholderText="Enter Tag ExpDate"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <DatePicker
                      name="insuranceExpDate"
                      closeOnScroll={true}
                      placeholderText="Insurance ExpDate"
                      onChange={(date) => DateHandle(date, 'insuranceExpDate')}
                      selected={
                        !filterValue.insuranceExpDate
                          ? null
                          : new Date(filterValue.insuranceExpDate)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <DatePicker
                      closeOnScroll={true}
                      placeholderText="Next ServiceDate"
                      onChange={(date) => DateHandle(date, 'nextServiceDate')}
                      selected={
                        !filterValue.nextServiceDate
                          ? null
                          : new Date(filterValue.nextServiceDate)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Button color="danger" onClick={Clean}>
                      Clean
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Card>
          </Collapse>
        </Container>
        <Container fluid={true}>
          {loading && (
            <Card body className="truck-list-card">
              <div style={{ margin: '30px' }}>
                <Truckloader />
              </div>
            </Card>
          )}
          {truck && truck.length > 0 && (
            <>
              {!loading &&
                truck.map((tr, index) => (
                  <Card key={index} body className="truck-list-card">
                    <CardTitle tag="h5">
                      {tr.truckName}
                      <div className="top-right">
                        <Link to={`${UPDATE_TRUCK_ROUTE}/${tr.truckId}`}>
                          <Button color="link">
                            <Pencil />
                          </Button>
                        </Link>
                        <Button
                          onClick={DeleteAlert}
                          data-truckid={tr.truckId}
                          color="link">
                          <Delete />
                        </Button>
                      </div>
                    </CardTitle>

                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Trailer unit</th>
                          <th>Vin</th>
                          <th>Plate No</th>
                          <th>Exp date</th>
                          <th>Insurance policy</th>
                          <th>Insurance exp date</th>
                          <th>color</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{tr.trailerUnit}</td>
                          <td>{tr.vin}</td>
                          <td>{tr.plateNo}</td>
                          <td>{tr.tagExpDate}</td>
                          <td>{tr.insurancePolicy}</td>
                          <td>{tr.insuranceExpDate}</td>
                          <td>{tr.colorDOT}</td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th>Annual inspection date</th>
                          <th>Tire size</th>
                          <th>Manufacturer</th>
                          <th>Trailer size</th>
                          <th>Model Year</th>
                          <th>Last service</th>
                          <th>Next service</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{tr.annualInspectionDate}</td>
                          <td>{tr.tireSize}</td>
                          <td>{tr.manufacturer}</td>
                          <td>{tr.trailerSize}</td>
                          <td>{tr.modelYear}</td>
                          <td>{tr.lastService}</td>
                          <td>{tr.nextServiceDate}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                ))}
            </>
          )}
          {!loading && !truck.length && <NoRecordFound />}

          <div className="d-flex flex-row justify-content-end">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={parseInt(filterValue.offset) + 1}
              itemsCountPerPage={10}
              totalItemsCount={totalcount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
