import jsPDF from 'jspdf'
import 'jspdf-autotable'
// Date Fns is used to format the dates we receive
// from our API call
import { format } from 'date-fns'
import {
  capitalizeFirstLetter,
  sorting,
} from '../../../function/Helperfunction'

// define a generatePDF function that accepts a tickets argument
const Dpstestpdf = (StudentReport) => {
  // initialize jsPDF
  //   const doc = new jsPDF();
  var doc = new jsPDF('l', 'mm', 'a4')
  var d = format(new Date(), 'yyyy-MM-dd')
  // define the columns we want and their titles
  const tableColumn = ['Name', 'Status']
  // define an empty array of rows
  const tableRows = []

  // for each report pass all its data into an array
  Object.values(StudentReport)
    .sort((a, b) => sorting(a, b))
    .forEach((report) => {
      const studentData = [
        capitalizeFirstLetter(report.firstName) +
          ' ' +
          capitalizeFirstLetter(report.lastName),
        capitalizeFirstLetter(report.status),

        // called date-fns to format the date on the report
      ]

      tableRows.push(studentData)
    })

  // startY is basically margin-top
  doc.autoTable(tableColumn, tableRows, {
    startY: 20,
    // headerStyles: {
    //   lineWidth: 10,
    //   lineColor: [255, 0, 0],
    // },

    didParseCell: function (data) {
      let value = data.cell.raw

      if (value == 'Absent') data.cell.styles.fillColor = [239, 154, 154]

      if (value == 'Present') data.cell.styles.fillColor = [0, 255, 0]

      if (value == 'Excused') data.cell.styles.fillColor = [255, 255, 0]
      if (value == 'Unmarked') data.cell.styles.fillColor = [255, 250, 250]
    },
    // styles: { fillColor: '#43a047' },
  })
  const date = Date().split(' ')
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4]
  // report title. and margin-top + margin-left
  //   doc.setTextColor(100)
  doc.text('Student DPS Details' + d, 14, 15)
  // doc.setTextColor(255, 215, 0)

  // we define the name of our PDF file.
  doc.save(`Student_DPS_Details_${d}.pdf`)
}

export default Dpstestpdf
