import React, { useState, useEffect } from 'react'
import API from '../../constants/API'
import {
  LIST_BATCH,
  ATTEENDANCE_DETAILS,
  ATTENDANCE_CHECKIN,
  ATTENDANCE_CHECKOUT,
  DELETE_ATTENDENCE,
  REPS_ROLE_URL,
  GET_DPM_PREVIEW_URL,
  GET_ATTENDENCEREP,
  STUDENT_LIST,
} from '../../constants/Apipath'
import { authHeader, getUserId, userName } from '../../function/Mainfunction'
import { CSVLink } from 'react-csv'
import classnames from 'classnames'
import {
  Card,
  Table,
  Row,
  Col,
  CardBody,
  Button,
  Container,
  Collapse,
  Input,
  Tooltip,
  Form,
  FormGroup,
  Label,
  CustomInput,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from 'reactstrap'
import {
  CustomMessage,
  CustomPageLoading,
  JSONParser,
} from '../../function/Helperfunction'
import { Pencil, Delete } from '../../function/Icons'
import AttedenceManual from './AttedenceManual'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { BsFillPersonCheckFill, BsFillPersonDashFill } from 'react-icons/bs'
import { IoCaretUpSharp, IoCaretDownSharp } from 'react-icons/io5'
import NoRecordFound from '../../function/EmptyRecord'
import Swal from 'sweetalert2'
import { useFormik } from 'formik'
import RepsModal from './RepsModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  REFRESH_DATA,
  GET_ATTENANCE_PREVIEW,
  HANDLE_ONCHANGED,
} from '../../redux/action/ActionType'
import Calendar from 'react-calendar'
import { useHistory, useParams, Prompt } from 'react-router'
import {
  INSTRUCTOR_ATTENDANCE,
  INSTRUCTOR_ROLLCALL,
  ADMIN_ROLLOVER_VIEW,
  INSTRUCTOR_ROLLOVER_VIEW,
  ROLLCALL_VIEW,
  ROLLCALL_CLASS_ROOM,
  CLASSROOM_ROLLCALL_VIEW,
  ROLLCALL,
  BATCH_ROUTE,
} from '../../constants/RoutePaths'
import { batid_, stuid_ } from '../../redux/helper'
import { Link } from 'react-router-dom'
import RolloverView from '../admin/rollover/RolloverView'
import Listmodal from '../admin/rollover/Listmodal'

export default function RollCall() {
  // const batchList = useSelector((state) => state.AttendanceRepsReducer)
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setloading] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const hangletrigger = useSelector((state) => state.Onchangereducer)
  const [attendencePeriod, setattendencePeriod] = useState('today')
  const [batchList, setbatchList] = useState([])
  const [id, setId] = useState('')
  const [attedenceModal, setattedenceModal] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [flag, setflag] = useState(true)
  const [AttedanceDetails, setAttedanceDetails] = useState([])
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [modal, setModal] = useState(false)
  const [calendarModal, setcalendarModal] = useState(false)
  const [repsSubmit, setrepsSubmit] = useState(false)
  const [DPMPreviewList, setDPMPreviewList] = useState([])
  const [DPMCheckBox, setDPMCheckBox] = useState({})
  const [value, setValue] = useState(new Date())
  const formvalues = useSelector((state) => state.AttendanceRepsReducer)
  const [activeMonth, setactiveMonth] = useState(
    moment(new Date()).format('MM-YYYY')
  )
  const [addStudentmodal, setaddStudentmodal] = useState(false)
  const [studentList, setstudentList] = useState([])
  const [month, setMonth] = useState([])
  const [rolloverconflag, setrolloverconflag] = useState(false)
  const [record, setrecord] = useState(0)
  const { attendance_Date } = useParams()
  const [date, setdate] = useState('')
  const [absentPresentCount, setabsentPresentCount] = useState([])
  let currentActive = window.location.pathname.split('/')[2]
  console.log(record)
  const [rollcall, setRollCall] = useState('1')
  const onChange = (value) => {
    setactiveMonth(moment(value.activeStartDate).format('MM-YYYY'))
  }
  const [activeTab, setActiveTab] = useState('1')
  var months = []
  month.forEach((m) => {
    var start = moment(m.batchStartDate).format('DD')
    var end = moment(m.batchEndDate).format('DD')
    var i
    for (i = start; i <= end; i++) {
      months.push(
        `${i.toString().padStart(2, 0) + '/' + activeMonth.substring(0, 2)}`
      )
    }
  })

  let BatDetails = {}
  AttedanceDetails.forEach(
    (A) =>
      (BatDetails[`Ba_${A.batchId}`] = {
        ...BatDetails[`Ba_${A.batchId}`],
        [`Stu_${A.studentId}`]: {
          checkIn:
            moment(A.checkIn).format('HH:mm') == 'Invalid date'
              ? ''
              : moment(A.checkIn).format('HH:mm'),
          checkOut:
            moment(A.checkOut).format('HH:mm') == 'Invalid date'
              ? ''
              : moment(A.checkOut || '').format('HH:mm'),
          attendanceId: A.attendanceId || 0,
        },
      })
  )

  const [batchStuId, setbatchStuId] = useState({ batchId: 0, userId: 0 })
  let csvdpmreport = [[userName(), attendance_Date], ['']]

  batchList.forEach((bat) => {
    csvdpmreport.push(
      ['Batch name:', bat.batchName],
      ['', 'Driver License', 'Permit', 'Medical Card']
    )

    JSONParser(bat.studentDetails, []).forEach((stu) => {
      const { driverLicense, medicalCard, permit } =
        formvalues[batid_ + bat.batchId + stuid_ + stu.userId] || {}
      csvdpmreport.push([
        stu.studentName,
        driverLicense ? 'Yes' : 'No',
        permit ? 'Yes' : 'No',
        medicalCard ? 'Yes' : 'No',
      ])
    })
  })

  // formvalues[batid_ + bat.batchId + stuid_ + st.userId] || {}
  // console.log(newdata)

  // Object.values(DPMPreviewList).map((dp) => {
  //   csvdpmreport.push(['Batch name', dp.type])
  //   dp.value.forEach((val) =>
  //     csvdpmreport.push([
  //       val.studentName,
  //       val.email,
  //       val.driverLicense
  //         ? 'Driver License'
  //         : val.medicalCard
  //         ? 'Medical Card'
  //         : 'Permit',
  //     ])
  //   )
  //   csvdpmreport.push([''])
  // })

  const attedencetoggle = (e) => {
    const stuID = e.currentTarget.dataset.stuid
    const attId = e.currentTarget.dataset.attendanceid
    const batId = e.currentTarget.dataset.batchid
    if (attId) {
      setflag(false)
    }
    if (!attId) {
      setflag(true)
    }
    setbatchStuId({ userId: stuID, batchId: batId })
    setattedenceModal(!attedenceModal)
  }
  const closeattedencetoggle = () => {
    setattedenceModal(!attedenceModal)
  }

  const toggle = (e) => {
    const ID = e.currentTarget.dataset.batchid
    if (id == ID) {
      setCollapse(!collapse)
    } else {
      setCollapse(true)
    }
    setId(ID)
  }

  const getStudentList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_BATCH,
      params: {
        batchStartDate: moment(attendance_Date || new Date()).format(
          'MM/DD/YYYY'
        ),
        instructorId: getUserId(),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      console.log(data)
      if (status === 'Success') {
        setbatchList(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  //yy/mm/dd HH:mm
  const getAttendanceDetials = async (event) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: ATTEENDANCE_DETAILS,
      params: {
        attendanceDate: moment(attendance_Date || new Date()).format(
          'YYYY/MM/DD'
        ),
        instructorId: getUserId(),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setAttedanceDetails(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const AttedenceDateHandler = (e) => {
    history.push(INSTRUCTOR_ROLLCALL + '/' + moment(e).format('MM-DD-YYYY'))
    setcalendarModal(!calendarModal)
  }
  const successFull = () => {
    getStudentList()
    getAttendanceDetials()
  }
  // const Alert = (message) => {
  //   CustomMessage(`Double Click to ${message}`, 'info', enqueueSnackbar) //call toster
  // }

  const caledarToggle = () => {
    setcalendarModal(!calendarModal)
  }

  const getUpdateDPMDetails = async () => {
    dispatch({ type: REFRESH_DATA })
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: GET_DPM_PREVIEW_URL,
      params: {
        attendanceDate: moment(attendance_Date || new Date()).format(
          'MM/DD/YYYY'
        ),
      },
    }
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        data.forEach((val) =>
          dispatch({
            type: GET_ATTENANCE_PREVIEW,
            studentid: val.userId,
            batchId: val.batchId,
            payload: {
              medicalCard: val.medicalCard,
              permit: val.permit,
              driverLicense: val.driverLicense,
              attendanceId: val.attendanceId,
            },
          })
        )

        setDPMPreviewList(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const modalToggle = () => {
    setModal(!modal)
  }

  const handleDPM = async () => {
    const config = {
      method: 'POST',
      url: REPS_ROLE_URL,
      headers: authHeader(),
      data: {
        attendanceDate: moment(attendance_Date || new Date()).format(
          'MM/DD/YYYY'
        ),
        instructorId: getUserId(),
        repsRoleBatchAttendanceDetails: Object.values(formvalues),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { message } = response.data
      if (response.status === 200) {
        setrepsSubmit(true)
        getUpdateDPMDetails()
        dispatch({ type: HANDLE_ONCHANGED, payload: false })
        CustomMessage(message, 'success', enqueueSnackbar)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar)
      }
    } finally {
      setloading(false)
    }
  }
  useEffect(() => {
    dispatch({ type: HANDLE_ONCHANGED, payload: false })
  }, [])
  useEffect(() => {
    getStudentList()
    getAttendanceDetials()
    getUpdateDPMDetails()
    // return dispatch({ type: REFRESH_DATA })
  }, [attendance_Date])

  const toggleOne = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const getRollcall = async (e) => {
    console.log(e)
    let date = moment(e).format('YYYY-MM-DD')
    console.log(date)
    setdate(date)
    const config = {
      method: 'GET',
      url: GET_ATTENDENCEREP,
      headers: authHeader(),
      params: {
        attendanceDate: date,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, absentPresentCount, data = [] } = response.data
      console.log(response.data)
      if (status === 'Success') {
        console.log('2222', data?.length, absentPresentCount)
        if (data) {
          setrecord(data?.length)
          setrolloverconflag(true)
        }
        setabsentPresentCount(absentPresentCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }
  useEffect(() => {
    // getRollcall()
  }, [])

  const onRollCallChange = (item) => {
    setRollCall(item)
  }

  let statuscolor = {
    PRESENT: 'green',
    ABSENT: 'red',
    EXCUSED: 'orange',
    UNMARKED: 'blue',
  }
  const getCurrentData = (e) => {
    getRollcall(e)
  }
  const successfullcall = () => {
    history.push(`${INSTRUCTOR_ROLLOVER_VIEW}/${date}`)
  }
  const addStudenttoggle = () => {
    setaddStudentmodal((val) => !val)
  }
  const getStudent = async () => {
    const config = {
      method: 'GET',
      url: STUDENT_LIST,
      headers: authHeader(),
      params: {
        studentStatus: 'Active',
        count: 1000,
      },
    }
    // setloading(true)
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status === 'Success') {
        setstudentList(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    getStudent()
  }, [])
  return (
    <>
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggleOne('1')
              }}>
              RollCall
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggleOne('2')
              }}>
              General Roll Call
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div>
              <Prompt
                when={hangletrigger}
                message="You have unsaved changes, are you sure you want to leave?"
              />
              {/* <div className="page-title">
                <h4>Roll Call</h4>
              </div> */}
              {loading && <CustomPageLoading />}
              <Container fluid={true}>
                <Row>
                  <Col sm="12">
                    <Card body>
                      <CardBody>
                        <div className="Attendance-export">
                          <Button
                            color="danger"
                            className="ml-auto"
                            type="none">
                            <CSVLink
                              filename={` ${userName()}_${moment(
                                attendance_Date
                              ).format('YYYY-MM-DD')}_attendance.csv`}
                              data={csvdpmreport}>
                              Export CSV
                            </CSVLink>
                          </Button>
                          <Button
                            style={{ marginLeft: '10px' }}
                            color="primary"
                            disabled={!hangletrigger}
                            onClick={handleDPM}>
                            Submit
                          </Button>
                          <Button
                            style={{ marginLeft: '10px' }}
                            color="primary"
                            onClick={caledarToggle}>
                            Calendar
                          </Button>
                          <Button
                            style={{ marginLeft: '10px' }}
                            color="primary"
                            onClick={modalToggle}>
                            Preview
                          </Button>
                        </div>

                        <Modal isOpen={calendarModal} toggle={caledarToggle}>
                          <ModalHeader toggle={caledarToggle}>
                            Calendar
                          </ModalHeader>
                          <ModalBody>
                            {/* <Col md="4" className="p-0">
                      <Card body> */}
                            <Calendar
                              onActiveStartDateChange={onChange}
                              value={value}
                              onClickDay={AttedenceDateHandler}
                              allowPartialRange={true}
                              tileContent={({ date, view }) => {
                                if (
                                  months.find(
                                    (x) => x === moment(date).format('DD/MM')
                                  )
                                ) {
                                  return (
                                    <p style={{ margin: '0px', padding: 0 }}>
                                      __
                                    </p>
                                  )
                                }
                              }}
                              defaultActiveStartDate={new Date()}
                            />
                            {/* </Card>
                    </Col> */}
                          </ModalBody>
                        </Modal>
                        <Modal size="lg" isOpen={modal} toggle={modalToggle}>
                          <ModalHeader toggle={modalToggle}>
                            Roll call: &nbsp;
                            {attendance_Date}
                          </ModalHeader>
                          <ModalBody>
                            <Table>
                              {batchList.map((dp) => {
                                return (
                                  <Table>
                                    {' '}
                                    <thead>
                                      <tr>
                                        Batch Name:
                                        <Badge color="primary">
                                          {dp.batchName}
                                        </Badge>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {JSONParser(dp.studentDetails, []).map(
                                        (val) => {
                                          const {
                                            driverLicense = false,
                                            medicalCard = false,
                                            permit = false,
                                          } = formvalues[
                                            batid_ +
                                              dp.batchId +
                                              stuid_ +
                                              val.userId
                                          ] || {}
                                          return (
                                            <tr>
                                              <td>{val.studentName}</td>
                                              <td>
                                                <Row>
                                                  <Col>
                                                    <Label>
                                                      <Input
                                                        type="checkbox"
                                                        name="driverLicense"
                                                        checked={driverLicense}
                                                      />
                                                      Driver License
                                                    </Label>
                                                  </Col>
                                                  <Col>
                                                    <Label>
                                                      <Input
                                                        type="checkbox"
                                                        name="permit"
                                                        checked={permit}
                                                      />
                                                      Permit
                                                    </Label>
                                                  </Col>
                                                  <Col>
                                                    <Label>
                                                      <Input
                                                        type="checkbox"
                                                        name="medicalCard"
                                                        checked={medicalCard}
                                                      />
                                                      Medical Card
                                                    </Label>
                                                  </Col>
                                                </Row>
                                              </td>
                                            </tr>
                                          )
                                        }
                                      )}
                                    </tbody>{' '}
                                  </Table>
                                )
                              })}
                            </Table>
                          </ModalBody>
                        </Modal>
                        <Table responsive hover>
                          <thead>
                            <tr>
                              <th>Batch Name</th>
                              <th>Batch Type</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!batchList.length && <NoRecordFound />}
                            {batchList.map((i, index) => (
                              <>
                                <tr
                                  data-batchid={i.batchId}
                                  onClick={toggle}
                                  key={index}>
                                  <td>{i.batchName}</td>
                                  <td> {i.type}</td>
                                  <td>
                                    <Button
                                      className="table-arrowBtn"
                                      color="link"
                                      data-batchid={i.batchId}
                                      onClick={toggle}>
                                      {collapse && i.batchId == id ? (
                                        <IoCaretUpSharp />
                                      ) : (
                                        <IoCaretDownSharp />
                                      )}
                                    </Button>
                                  </td>
                                </tr>

                                <tr className="collapse-td">
                                  <td colSpan={5} className="p-0 border-none">
                                    <Collapse
                                      isOpen={i.batchId == id && collapse}>
                                      <Table>
                                        <thead>
                                          <tr>
                                            <th>Student Name</th>
                                            <th style={{ textAlign: 'center' }}>
                                              D/P/M
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {JSONParser(i.studentDetails, []).map(
                                            (st, index) => {
                                              const { attendanceId } =
                                                formvalues[
                                                  batid_ +
                                                    i.batchId +
                                                    stuid_ +
                                                    st.userId
                                                ] || {}

                                              return (
                                                <tr>
                                                  <td>{st.studentName}</td>
                                                  <td>
                                                    <RepsModal
                                                      studentid={st.userId}
                                                      batchid={i.batchId}
                                                      attendencid={
                                                        attendanceId || 0
                                                      }
                                                    />
                                                  </td>
                                                </tr>
                                              )
                                            }
                                          )}
                                        </tbody>
                                      </Table>
                                    </Collapse>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="2">
            <Row>
              {loading && <CustomPageLoading />}
              <Col sm="12" md={{ size: 6, offset: 3 }}>
                <div className="mb-3">
                  <Card body>
                    <Calendar onClickDay={getCurrentData} />
                  </Card>
                </div>
              </Col>
            </Row>
            {console.log('111111', currentActive)}
            {rolloverconflag && (
              <>
                {(record == 0 && currentActive == 'rollcall') ||
                absentPresentCount.length == 0 ? (
                  <>
                    <Alert className="text-center w-fit m-auto" color="primary">
                      No Roll call is created
                    </Alert>
                    <br />{' '}
                  </>
                ) : (
                  <>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: rollcall == '1' ? true : false,
                          })}
                          onClick={() => onRollCallChange('1')}>
                          Road side Roll call
                        </NavLink>
                      </NavItem>{' '}
                    </Nav>
                    <TabContent activeTab={rollcall}>
                      <TabPane tabId="1">
                        <Card body>
                          {record !== 0 && (
                            <Link
                              className="d-block ml-auto mb-3"
                              to={`${
                                // currentActive == 'rollcall'
                                //   ? ADMIN_ROLLOVER_VIEW
                                //   : ROLLCALL
                                INSTRUCTOR_ROLLOVER_VIEW
                              }/${date}`}>
                              <Button
                                color="primary"
                                // onClick={() =>
                                //   history.push(
                                //     `${INSTRUCTOR_ROLLOVER_VIEW}/${date}`
                                //   )
                                // }
                              >
                                View Summary
                              </Button>
                            </Link>
                          )}

                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th>AM</th>
                                <th>Noon</th>
                                <th>PM</th>
                              </tr>
                            </thead>
                            <tbody>
                              {absentPresentCount.map((pre) => {
                                return (
                                  <tr>
                                    <td
                                      style={{
                                        color: statuscolor[pre.status],
                                      }}>
                                      {pre.status}
                                    </td>
                                    <td>{pre.am}</td>
                                    <td>{pre.noon}</td>
                                    <td>{pre.pm}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </Card>
                      </TabPane>
                    </TabContent>
                  </>
                )}
              </>
            )}
          </TabPane>
        </TabContent>
        <Listmodal
          data={studentList}
          date={date}
          successfullcall={successfullcall}
          student={{}}
          modal={addStudentmodal}
          toggle={addStudenttoggle}
        />
      </div>
    </>
  )
}
