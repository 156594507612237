import jsPDF from 'jspdf'
import 'jspdf-autotable'
// Date Fns is used to format the dates we receive
// from our API call
import { format } from 'date-fns'
import {
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  Document,
  HeightRule,
  WidthType,
  Header,
  AlignmentType,
  PageNumber,
  TextRun,
  BorderStyle,
  HeadingLevel,
} from 'docx'
import { saveAs } from 'file-saver'

// define a generatePDF function that accepts a tickets argument
const generatePDF = (name, AllStudentReport) => {
  // initialize jsPDF
  //   const doc = new jsPDF();
  // var doc = new jsPDF('l', 'pt', 'a4')
  var d = format(new Date(), 'yyyy-MM-dd')
  // // define the columns we want and their titles
  const pretripColumn = ['Pre-Trip ID', 'Trip Date', 'Pre-Trip Time']
  const incabColumn = ['Incab ID', 'Incab Date', 'Incab Time']
  const ManueverColumn = [
    'Maneuver',
    'Mirrors',
    'Steering',
    'Encroaches',
    'Maneuver Date',
    'Maneuver Time',
    'Score',
    'Comments',
  ]

  const driveColumn = [
    'Bears',
    'Brake',
    'Downshifting',
    'FollowDistance',
    'Comments',
    'GearPattern',
    'Brake',
    'HitCurb',
    'HoverH',
    'Comments',
    'InterCoasting',
    'IntersectionMirrors',
    'IntersectiontrafficCheck',
    'MergingSmooth',
    'MergingTrafficCheck',
    'TimeIn',
    'TimeOut',
    'Timing',
    'TotalTime',
    'Reaction',
  ]
  const dpsTestColumn = ['Test Date', 'Test Hours', 'Comments', 'Test Result']
  // // define an empty array of rows
  const pretripRow = []
  const incabRow = []
  const maneuverRow = []
  const driveRow = []
  const dpsTestRow = []
  const borderNone = {
    top: {
      style: BorderStyle.NIL,
      space: 2,
    },
    bottom: {
      style: BorderStyle.NIL,
      space: 2,
    },
    left: {
      style: BorderStyle.NIL,
      space: 2,
    },
    right: {
      style: BorderStyle.NIL,
      space: 2,
    },
  }
  // // for each report pass all its data into an array
  AllStudentReport.forEach((report) => {
    if (!!report.preTripId) {
      const pretripData = [
        report.preTripId,
        report.tripDate,
        report.preTripTime,
        // called date-fns to format the date on the report
      ]
      pretripRow.push(pretripData)
    }

    if (!!report.incabId) {
      const incabData = [report.incabId, report.incabDate, report.incabTime]
      incabRow.push(incabData)
    }

    if (!!report.maneuver) {
      const maneuverData = [
        report.maneuver,
        report.mirrors,
        report.steering,
        report.encroaches,
        report.maneuverDate,
        report.maneuverTime,
        report.score,
        report.comments,
      ]
      maneuverRow.push(maneuverData)
    }

    if (!!report.bears) {
      const driveData = [
        report.bears,
        report.brake,
        report.downshifting,
        report.followDistance,
        report.comments,
        report.gearPattern,
        report.brake,
        report.hitCurb,
        report.hoverH,
        report.lane,
        report.interCoasting,
        report.intersectionMirrors,
        report.intersectiontrafficCheck,
        report.mergingSmooth,
        report.mergingTrafficCheck,
        report.timeIn,
        report.timeOut,
        report.timing,
        report.totalTime,
        report.lane,
      ]
      driveRow.push(driveData)
    }

    if (!!report.testDate) {
      const dpsTestData = [
        report.testDate,
        report.testHours,
        report.comments,
        report.testResult,
      ]
      dpsTestRow.push(dpsTestData)
    }
  })

  // // startY is basically margin-top
  // doc.autoTable(pretripColumn, pretripRow, {
  //   startY: doc.autoTableEndPosY() + 50,
  //   pageBreak: 'auto',
  // })
  // doc.autoTable(incabColumn, incabRow, {
  //   startY: doc.autoTableEndPosY() + 50,
  //   pageBreak: 'auto',
  // })
  // doc.autoTable(ManueverColumn, maneuverRow, {
  //   startY: doc.autoTableEndPosY() + 50,
  //   pageBreak: 'auto',
  // })
  // doc.autoTable(driveColumn, driveRow, {
  //   startY: doc.autoTableEndPosY() + 50,
  //   pageBreak: 'auto',
  // })
  // doc.autoTable(dpsTestColumn, dpsTestRow, {
  //   startY: doc.autoTableEndPosY() + 50,
  //   pageBreak: 'auto',
  // })
  // const date = Date().split(' ')
  // // we use a date string to generate our filename.
  // const dateStr = date[0] + date[1] + date[2] + date[3] + date[4]
  // // report title. and margin-top + margin-left
  // doc.text(`${name}All_Details` + d, 10, 15)
  // // we define the name of our PDF file.
  // doc.save(`${name}_All_Student_Details_${d}.pdf`)

  const pageHeader = () => {
    return {
      default: new Header({
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: 'organizationName' || '',
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            children: ['Page ', PageNumber.CURRENT],
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: '',
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
            ],
            width: { size: 100, type: WidthType.PERCENTAGE },
          }),
        ],
      }),
    }
  }
  const individualReport = (title, header, data) => {
    return {
      children: [
        new Paragraph({ text: title, heading: HeadingLevel.TITLE }),
        new Paragraph(''),
        new Table({
          rows: [
            new TableRow({
              children: [
                ...header.map((val) => {
                  return new TableCell({
                    children: [new Paragraph(val)],
                  })
                }),
              ],
            }),
            ...data.map((report) => {
              return new TableRow({
                children: [
                  ...report.map((va) => {
                    return new TableCell({
                      children: [new Paragraph('' + va)],
                      height: {
                        value: 500,
                        rule: HeightRule.ATLEAST,
                      },
                    })
                  }),
                ],
              })
            }),
          ],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
        }),
      ],
    }
  }

  const leaveoneline = () => {
    return new Paragraph('')
  }
  const docs = new Document({
    sections: [
      individualReport('Pretrip', pretripColumn, pretripRow),
      // leaveoneline(),
      individualReport('Incab', incabColumn, incabRow),
      // leaveoneline(),
      individualReport('Manuever', ManueverColumn, maneuverRow),
      // leaveoneline(),
      individualReport('DpsTest', dpsTestColumn, dpsTestRow),
      // leaveoneline(),
      // individualReport(pretripColumn, pretripRow),
      // leaveoneline(),
      // individualReport(pretripColumn, pretripRow),
      // leaveoneline(),
    ],
  })

  Packer.toBlob(docs).then((buffer) => {
    saveAs(buffer, `${name}_All_Student_Details_${d}.doc`)
  })
}

export default generatePDF
