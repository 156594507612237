import React, { Fragment, useState, useEffect } from 'react'
import {
  CustomPageLoading,
  CustomMessage,
} from '../../../function/Helperfunction'
import {
  Button,
  Container,
  Card,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'
import { useHistory, useParams } from 'react-router'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import API from '../../../constants/API'
import {
  UPDATE_STATION_DETAILS,
  STATION_DETAILS,
} from '../../../constants/Apipath'
import { STATION } from '../../../constants/RoutePaths'
import { authHeader } from '../../../function/Mainfunction'

export default function Createstaction() {
  const { stationId } = useParams()
  const History = useHistory()
  const [loading, setloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [formData, setformData] = useState({})

  const Goback = () => {
    History.goBack()
  }

  useEffect(() => {
    getStationList()
  }, [])

  const getStationList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: STATION_DETAILS,
      params: {
        stationId: stationId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setformData(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
      }
    } finally {
      setloading(false)
    }
  }
  const UpdateStation = async (values) => {
    const config = {
      method: 'PUT',
      headers: authHeader(),
      url: UPDATE_STATION_DETAILS,
      data: values,
    }
    setloading(true)
    try {
      const response = await API(config)
      console.info(response, 'response')
      const { status } = response.data
      if (status === 'Success') {
        CustomMessage('Update Station Successfull', 'success', enqueueSnackbar)
        History.push(STATION)
        resetForm()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    stationName: Yup.string().required('Station Name Required'),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => UpdateStation(values),
  })

  return (
    <Fragment>
      <div className="wrapper">
        {loading && <CustomPageLoading />}
        <div className="page-title">
          <h4>Update Station</h4>
          <div className="btn-right">
            <Button onClick={Goback} color="primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.543"
                height="8.695"
                viewBox="0 0 5.543 9.695">
                <path
                  id="Icon_ionic-ios-arrow-back"
                  data-name="Icon ionic-ios-arrow-back"
                  d="M12.921,11.04,16.59,7.374a.693.693,0,1,0-.981-.978l-4.156,4.153a.691.691,0,0,0-.02.955l4.173,4.182a.693.693,0,1,0,.981-.978Z"
                  transform="translate(-11.251 -6.194)"
                  fill="#fff"
                />
              </svg>
              Back
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <Card body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Station name</Label>
                    <Input
                      type="text"
                      name="stationName"
                      id="stationName"
                      placeholder="Enter station name"
                      value={values.stationName}
                      invalid={
                        errors.stationName && touched.stationName ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.stationName && touched.stationName
                        ? errors.stationName
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <div className="btn-center">
                <Button color="primary" className="pull-left">
                  Update
                </Button>
              </div>
            </Form>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}
