export const BASE_URL = 'https://devamericantrcuklearning.azurewebsites.net/'

//export const BASE_URL = 'https://testamericantrucklearning.azurewebsites.net/'
export const LOGIN = BASE_URL + 'user/Login'
export const LIST_USER = BASE_URL + 'user/listUser'
export const LIST_INSTRUCTOR = BASE_URL + 'instructor/getInstructorDetails'
export const DELETE_USER = BASE_URL + '/user/deleteUser'
export const DELETE_INSTRUCTOR = BASE_URL + '/instructor/deleteInstructorDetail'
//STUDENT
export const STUDENT_LIST = BASE_URL + 'Student/listStudent'
export const CREATE_STUDENT_URL = BASE_URL + 'Student/createStudent'
export const STUDENT_DETAILS = BASE_URL + '/Student/getStudent'
export const UPDATE_STUDENT_DETAILS = BASE_URL + 'Student/updateStudent'
export const DELETE_STUDENT = BASE_URL + 'student/deleteStudent'

//USER
export const CREATE_USER_URL = BASE_URL + 'user/createUser'
export const REFEREDBY_FILTER_URL = BASE_URL + 'user/listUser'
export const USER_DETAILS = BASE_URL + 'user/getUser'
export const UPDATE_USER_DETAILS = BASE_URL + 'user/updateUser'
export const CREATE_INSTRUCTOR = BASE_URL + 'instructor/createInstructor'
export const CHANGE_PASSWORD = BASE_URL + 'user/changePassword'

// TEST TYPE
export const CREATE_DPS_TEST = BASE_URL + 'testType/saveTest'
export const TEST_TYPE_LIST = BASE_URL + 'testType/listTestType'
export const TEST_TYPE_DETAILS = BASE_URL + 'testType/getTestDetailsById'
export const UPDATE_TEST_TYPE_DETAILS = BASE_URL + 'testType/updateTestDetails'
export const DELETE_DPS_TEST = BASE_URL + 'testType/deleteTestDetails'

//TRUCK DETAILS
export const CREATE_TRUCK_URL = BASE_URL + 'truckDetail/saveTruckDetails'
export const LIST_TRUCK = BASE_URL + 'truckDetail/getTruckDetails'
export const TRUCK_DETAILS = BASE_URL + 'truckDetail/getTruckDetailById'
export const UPDATE_TRUCK_DETAILS = BASE_URL + 'truckDetail/updateTruckDetails'
export const LIST_STATION = BASE_URL + 'station/listStation'
export const LIST_TEST = BASE_URL + 'test/getTestDetails'
export const LIST_FOUL = BASE_URL + 'Student/listStudentCard'
export const DELETE_FOUL = BASE_URL + 'student/deleteStudentCard'
export const DELETE_STATION = BASE_URL + 'station/deleteStation'
export const DELETE_TRUCK = BASE_URL + 'truckDetail/deleteTruckDetails'
export const CREATE_STATION_URL = BASE_URL + 'station/createStation'
export const STATION_DETAILS = BASE_URL + 'station/getStationById'
export const UPDATE_STATION_DETAILS = BASE_URL + 'station/updateStation'
export const INSTRUCTOR_DETAILS =
  BASE_URL + 'instructor/getInstructorDetailById'
export const UPDATE_INSTRUCTOR_DETAILS =
  BASE_URL + 'instructor/updateInstructorDetails'

//Foul
export const CREATE_FOUL_URL = BASE_URL + 'Student/createStudentCard'
export const UPDATE_FOUL_URL = BASE_URL + 'Student/updateStudentCard'
export const FOUL_DETAILS = BASE_URL + 'Student/getStudentCard'
export const UPDATE_FOUL_DETAILS = BASE_URL + 'Student/getStudentCard'

//dashboard
export const ADMIN_DASHBOARD = BASE_URL + 'dashBoard/getDashBoardReport'

export const INSTRUCTOR_DASHBOARD = BASE_URL + 'dashBoard/instructorDashBoard'

// batch
export const LIST_BATCH = BASE_URL + 'Batch/listBatch'
export const UPDATE_BATCH = BASE_URL + 'Batch/updateBatch'
export const DELETE_BATCH = BASE_URL + 'Batch/DeleteBatch'
export const GET_BATCH = BASE_URL + 'Batch/getBatch'
export const CREATE_BATCH_URL = BASE_URL + 'Batch/createBatch'
export const CREATE_BATCH_STUDENTS_URL = BASE_URL + 'Batch/saveBatchStudent'
export const CREATE_PRE_TRIP = BASE_URL + 'Batch/createPreTrip'
export const CREATE_INCAB = BASE_URL + 'Batch/createIncab'
export const CREATE_MANEUVER = BASE_URL + 'Batch/createManeuver'
export const CREATE_SAVE_DRIVE = BASE_URL + 'Batch/saveDriveDetails'
export const BATCH_HISTORY = BASE_URL + 'Batch/batchHistory'
export const GET_DPM_PREVIEW_URL = BASE_URL + 'instructor/getRepsRoleBatch'

//forgetpassword
export const SEND_OPT = BASE_URL + 'user/sendForgetPassword'
export const ATTENDANCE_CHECKOUT = BASE_URL + 'attendance/checkOut'
export const ATTENDANCE_CHECKIN = BASE_URL + 'attendance/checkIn'
export const SAVE_ATTEDANCCE = BASE_URL + 'attendance/saveAttendance'
export const REPS_ROLE_URL = BASE_URL + 'instructor/saveRepsRoleBatch'

export const ATTEENDANCE_DETAILS = BASE_URL + 'attendance/getAttendanceDetails'

export const BATCH_STUDENT = BASE_URL + 'Batch/listBatchStudent'

export const DELETE_ATTENDENCE = BASE_URL + 'attendance/deleteAttendanceById'

export const REPORT = BASE_URL + 'Batch/listBatchStudent'

export const STUDENT_REPS = BASE_URL + 'instructor/saveRepsRoleAttendance'
export const GET_ATTENDENCEREP = BASE_URL + 'instructor/getRepsRoleAttendance'
export const DPS_TEST_SCHEDULE = BASE_URL + 'instructor/saveDPSTestSchedule'
export const GET_CLASS_ROOM_ATTENDENCEREP =
  BASE_URL + 'instructor/getClassRoomAttendance'
export const CLASSROOM_ROLLCALL_REPS =
  BASE_URL + '/instructor/saveClassRoomAttendance'
