import * as types from '../action/ActionType'
export default function Onchangereducer(state = false, action) {
  const { type, payload } = action
  switch (type) {
    case types.HANDLE_ONCHANGED:
      return (state = payload)
    default:
      return state
  }
}
