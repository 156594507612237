import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Form,
  Button,
  FormGroup,
  CustomInput,
} from 'reactstrap'
import { TiPlus, TiMinus } from 'react-icons/ti'

const maneuverType = ['SB', 'Left Offset', 'Right Offset', 'DP', 'BP', 'AD']

export default function Maneuver(props) {
  const { GetValues, time } = props
  const validationSchema = Yup.object().shape({
    maneuver: Yup.string().required('Maneuver Required'),
    mirrors: Yup.string().required('Mirrors Required'),
    steering: Yup.string().required('Steering Required'),
    encroaches: Yup.string().required('Encroaches Required'),
    pullUp: Yup.string().required('PullUp Required'),
    GetOut: Yup.string().required('GetOut Required'),
    maneuverDate: Yup.date().required('Maneuver Date Required'),
    score: Yup.number().min(1, 'invalid score').required('Score Required'),
    // comments: Yup.string().required('Comments  Required'),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      maneuver: '',
      mirrors: '',
      steering: '',
      encroaches: '0',
      pullUp: '0',
      GetOut: '0',
      comments: '',
      maneuverDate: '',
      maneuverTime: '',
      score: '',
    },
    validationSchema,
    onSubmit: (values) => GetValues(values),
  })

  const numberInput = (e) => {
    const { name, value } = e.target
    const re = /^[0-9\b]+$/

    if (value == '' || re.test(value)) {
      setValues((val) => ({ ...val, [name]: parseInt(value || 0) }))
    }
  }
  const keyPress = (e) => {
    let key = e.key
    let name = e.target.name

    if (key === 'ArrowUp') {
      setValues((val) => ({
        ...val,
        [name]: (parseInt(val[name]) + 1).toString(),
      }))
    } else if (key === 'ArrowDown') {
      setValues((val) => ({
        ...val,
        [name]:
          parseInt(val[name]) <= 0 ? '0' : (parseInt(val[name]) - 1).toString(),
      }))
    }
  }

  const radioHandlechange = (e) => {
    const { name, value } = e.target
    setValues((val) => ({ ...val, [name]: value }))
  }

  const minusValue = (name) => {
    setValues((val) => ({
      ...val,
      [name]:
        parseInt(val[name]) <= 0 ? '0' : (parseInt(val[name]) - 1).toString(),
    }))
  }
  const plusValue = (name) => {
    setValues((val) => ({
      ...val,
      [name]: (parseInt(val[name]) + 1).toString(),
    }))
  }
  const rowStuyle = {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',
  }
  const pointer = { cursor: 'pointer' }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Maneuver Date</Label>
              <Input
                type="date"
                name="maneuverDate"
                placeholder=""
                onBlur={handleBlur}
                value={values.maneuverDate}
                invalid={
                  errors.maneuverDate && touched.maneuverDate ? true : false
                }
                onChange={handleChange}
              />

              <FormFeedback>
                {errors.maneuverDate && touched.maneuverDate
                  ? errors.maneuverDate
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Maneuver</Label>
              <Input
                type="select"
                name="maneuver"
                id="tripDate"
                placeholder=""
                onBlur={handleBlur}
                value={values.maneuver}
                invalid={errors.maneuver && touched.maneuver ? true : false}
                onChange={handleChange}>
                <option value=""> Select Maneuver </option>
                {maneuverType.map((m, i) => (
                  <option value={i + 1}>{m}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.maneuver && touched.maneuver ? errors.maneuver : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Mirrors</Label>
              <Row className="mt-2 ml-1">
                <CustomInput
                  className="col-auto"
                  type="radio"
                  id="exampleCustomRadio3"
                  label="Yes"
                  value="Yes"
                  name="mirrors"
                  onChange={radioHandlechange}
                  checked={values.mirrors == 'Yes'}
                />
                <CustomInput
                  className="col-auto"
                  type="radio"
                  id="exampleCustomRadio4"
                  label="No"
                  name="mirrors"
                  value="No"
                  onChange={radioHandlechange}
                  checked={values.mirrors == 'No'}
                />
              </Row>
              {/* <Input
                type="select"
                name="mirrors"
                placeholder=""
                onBlur={handleBlur}
                value={values.mirrors}
                invalid={errors.mirrors && touched.mirrors ? true : false}
                onChange={handleChange}>
                <option value=""> Select Mirrors </option>
                <option value="Yes"> Yes</option>
                <option value="No">No </option>
              </Input> */}
              <FormFeedback style={{ display: errors.mirrors ? 'block' : '' }}>
                {errors.mirrors && touched.mirrors ? errors.mirrors : ''}
              </FormFeedback>
            </FormGroup>
          </Col>

          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Steering</Label>
              <Row className="mt-2 ml-1">
                <CustomInput
                  className="col-auto"
                  type="radio"
                  id="exampleCustomRadio6"
                  label="Yes"
                  value="Yes"
                  name="steering"
                  onChange={radioHandlechange}
                  checked={values.steering == 'Yes'}
                />
                <CustomInput
                  className="col-auto"
                  type="radio"
                  id="exampleCustomRadio7"
                  label="No"
                  name="steering"
                  value="No"
                  onChange={radioHandlechange}
                  checked={values.steering == 'No'}
                />
              </Row>
              {/* <Input
                type="select"
                name="steering"
                placeholder=""
                onBlur={handleBlur}
                value={values.steering}
                invalid={errors.steering && touched.steering ? true : false}
                onChange={handleChange}>
                <option value=""> Select Steering </option>
                <option value="Yes"> Yes</option>
                <option value="No">No </option>
              </Input> */}
              <FormFeedback style={{ display: errors.steering ? 'block' : '' }}>
                {errors.steering && touched.steering ? errors.steering : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Encroaches</Label>
              <Row style={rowStuyle} xs="3">
                <TiMinus onClick={() => minusValue('encroaches')} />
                <Input
                  style={{ textAlign: 'center' }}
                  type="text"
                  name="encroaches"
                  onChange={numberInput}
                  value={values.encroaches}
                  autoComplete="off"
                  onKeyUp={keyPress}
                  placeholder="Enter Encroaches"
                />
                <TiPlus onClick={() => plusValue('encroaches')} />
              </Row>
              {/* <Input
                type="text"
                name="encroaches"
                placeholder="Enter Encroaches"
                onBlur={handleBlur}
                value={values.encroaches}
                invalid={errors.encroaches && touched.encroaches ? true : false}
                onChange={handleChange}
              /> */}

              <FormFeedback>
                {errors.encroaches && touched.encroaches
                  ? errors.encroaches
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Pull Up</Label>
              <Row xs="3" style={rowStuyle}>
                <TiMinus style={pointer} onClick={() => minusValue('pullUp')} />

                <Input
                  style={{ textAlign: 'center' }}
                  type="text"
                  name="pullUp"
                  onChange={numberInput}
                  value={values.pullUp}
                  autoComplete="off"
                  onKeyUp={keyPress}
                  placeholder="Enter PullUp"
                />
                <TiPlus style={pointer} onClick={() => plusValue('pullUp')} />
              </Row>
              {/* <Input
                type="text"
                name="pullUp"
                placeholder="Enter PullUp"
                onBlur={handleBlur}
                value={values.pullUp}
                invalid={errors.pullUp && touched.pullUp ? true : false}
                onChange={handleChange}
              /> */}

              <FormFeedback>
                {errors.pullUp && touched.pullUp ? errors.pullUp : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Get Out</Label>
              <Row style={rowStuyle} xs="3">
                <TiMinus onClick={() => minusValue('GetOut')} />
                <Input
                  style={{ textAlign: 'center' }}
                  type="text"
                  name="GetOut"
                  onChange={numberInput}
                  value={values.GetOut}
                  autoComplete="off"
                  onKeyUp={keyPress}
                  placeholder="Enter Get Out"
                />
                <TiPlus onClick={() => plusValue('GetOut')} />
              </Row>
              {/* <Input
                type="text"
                name="GetOut"
                placeholder="Enter GetOut"
                onBlur={handleBlur}
                value={values.GetOut}
                invalid={errors.GetOut && touched.GetOut ? true : false}
                onChange={handleChange}
              /> */}
              <FormFeedback>
                {errors.GetOut && touched.GetOut ? errors.GetOut : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Score</Label>
              <Input
                type="number"
                name="score"
                placeholder="Enter Score"
                onBlur={handleBlur}
                value={values.score}
                invalid={errors.score && touched.score ? true : false}
                onChange={handleChange}
              />

              <FormFeedback>
                {errors.score && touched.score ? errors.score : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Comments</Label>
              <Input
                type="text"
                name="comments"
                placeholder="Enter Comments"
                onBlur={handleBlur}
                value={values.comments}
                invalid={errors.comments && touched.comments ? true : false}
                onChange={handleChange}
              />

              <FormFeedback>
                {errors.comments && touched.comments ? errors.comments : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <div className="btn-center">
          <Button
            disabled={!time.endTime}
            color="primary"
            type="submit"
            className="pull-left">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  )
}
