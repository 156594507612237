import jsPDF from 'jspdf'
import 'jspdf-autotable'
// Date Fns is used to format the dates we receive
// from our API call
import { format } from 'date-fns'

// define a generatePDF function that accepts a tickets argument
const generatePDF = (name, DriverReport) => {
  // initialize jsPDF
  //   const doc = new jsPDF();
  var doc = new jsPDF('l', 'mm', 'a4')
  var d = format(new Date(), 'yyyy-MM-dd')
  // define the columns we want and their titles
  const tableColumn = [
    'Bears',
    'Brake',
    'Downshifting',
    'FollowDistance',
    'Comments',
    'GearPattern',
    'Brake',
    'HitCurb',
    'HoverH',
    'Comments',
    'InterCoasting',
    'IntersectionMirrors',
    'IntersectiontrafficCheck',
    'MergingSmooth',
    'MergingTrafficCheck',
    'TimeIn',
    'TimeOut',
    'Timing',
    'TotalTime',
    'Reaction',
  ]
  // define an empty array of rows
  const tableRows = []

  // for each report pass all its data into an array
  DriverReport.forEach((report) => {
    const driverData = [
      report.bears,
      report.brake,
      report.downshifting,
      report.followDistance,
      report.comments,
      report.gearPattern,
      report.brake,
      report.hitCurb,
      report.hoverH,
      report.lane,
      report.interCoasting,
      report.intersectionMirrors,
      report.intersectiontrafficCheck,
      report.mergingSmooth,
      report.mergingTrafficCheck,
      report.timeIn,
      report.timeOut,
      report.timing,
      report.totalTime,
      report.lane,
      // called date-fns to format the date on the report
    ]

    tableRows.push(driverData)
  })

  // startY is basically margin-top
  doc.autoTable(tableColumn, tableRows, { startY: 20 })
  const date = Date().split(' ')
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4]
  // report title. and margin-top + margin-left
  doc.text(`${name}_Drive Details` + d, 14, 15)
  // we define the name of our PDF file.
  doc.save(`${name}Drive_Details_${d}.pdf`)
}

export default generatePDF
