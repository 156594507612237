import React, { Fragment, useState, useRef, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  Container,
  Button,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Card,
  Row,
  Col,
  FormFeedback,
  Form,
} from 'reactstrap'
import {
  CustomMessage,
  CustomPageLoading,
} from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import API from '../../../constants/API'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  STUDENT_LIST,
  LIST_BATCH,
  CREATE_FOUL_URL,
  UPDATE_FOUL_DETAILS,
  UPDATE_FOUL_URL,
} from '../../../constants/Apipath'
import { FOUL_ROUTE } from '../../../constants/RoutePaths'
import Select from 'react-select'
import { debounce } from 'underscore'
import { userName, getUserId, authHeader } from '../../../function/Mainfunction'

export default function UpdateFoul() {
  const user_name = userName()
  const history = useHistory()
  const { studentCardDetailId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const [studentOptions, setStudentOptions] = useState([])
  const selectInputRefStudent = useRef()
  const [selectStudent, setSelectStudent] = useState('')
  const [userId, setUserId] = useState('')
  const [batchOption, setbatchOption] = useState([])
  const selectInputBatch = useRef()
  const [selectBatch, setselectBatch] = useState('')
  const [batchId, setbatchId] = useState('')
  const [formData, setFormData] = useState({})
  const Goback = () => {
    history.goBack()
  }

  useEffect(() => {
    getFoulDetails()
  }, [])

  const getFoulDetails = async (event) => {
    const config = {
      method: 'GET',
      url: UPDATE_FOUL_DETAILS,
      headers: authHeader(),
      params: {
        studentCardDetailId: studentCardDetailId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      if (status === 'Success') {
        setFormData(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.warn(data)
      }
    } finally {
      setloading(false)
    }
  }

  const getStudentList = async (selectStudent) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: STUDENT_LIST,
      params: {
        firstName: selectStudent,
        studentStatus: 'Active',

        count: 100000,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      if (status === 'Success') {
        setStudentOptions(
          data.map((d) => ({ value: d.userId, label: d.firstName }))
        )
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.warn(data)
      }
    } finally {
      setloading(false)
    }
  }

  const OptionHandleStudent = (e) => {
    if (e !== null) {
      const { value } = e
      setUserId(value)
      setFieldValue('studentId', value)
    } else {
      setSelectStudent('')
      setUserId('')
    }
  }
  const OptionInputHandleStudent = (text) => {
    setSelectStudent(text)
  }

  const StudentSearch = useRef(
    debounce((selectStudent, userId) => getStudentList(selectStudent, userId))
  ).current

  useEffect(() => {
    //callList();
    StudentSearch(selectStudent, userId)
  }, [selectStudent, userId])

  //Get Type List
  const getBatchList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_BATCH,
      params: {
        count: 100000,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      if (status === 'Success') {
        setbatchOption(
          data.map((d) => ({ value: d.batchId, label: d.batchName }))
        )
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.warn(data)
      }
    } finally {
      setloading(false)
    }
  }

  const optionHandleBatch = (e) => {
    if (e !== null) {
      const { value } = e
      setbatchId(value)
      setFieldValue('batchId', value)
    } else {
      setselectBatch('')
      setbatchId('')
    }
  }
  const OptionInputHandleBatch = (text) => {
    setselectBatch(text)
  }

  const BatchSearch = useRef(
    debounce((selectBatch, batchId) => getBatchList(selectBatch, batchId), 700)
  ).current

  useEffect(() => {
    //callList();
    BatchSearch(selectBatch, batchId)
  }, [selectBatch, batchId])

  const UpdateFoul = async (values) => {
    const config = {
      method: 'PUT',
      url: UPDATE_FOUL_URL,
      headers: authHeader(),
      data: values,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status } = response.data
      if (status === 'Success') {
        CustomMessage('Update Foul Successful', 'success', enqueueSnackbar) //call toster
        resetForm({})
        history.push(FOUL_ROUTE)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    comments: Yup.string().required('Comments Required'),
    studentId: Yup.number().required('Student is Required'),
    batchId: Yup.number().required('Batch is Required'),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => UpdateFoul(values),
  })

  return (
    <Fragment>
      <div className="wrapper">
        {loading && <CustomPageLoading />}
        <div className="page-title">
          <h4>Update Foul</h4>
          <div className="btn-right">
            <Button onClick={Goback} color="primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.543"
                height="8.695"
                viewBox="0 0 5.543 9.695">
                <path
                  id="Icon_ionic-ios-arrow-back"
                  data-name="Icon ionic-ios-arrow-back"
                  d="M12.921,11.04,16.59,7.374a.693.693,0,1,0-.981-.978l-4.156,4.153a.691.691,0,0,0-.02.955l4.173,4.182a.693.693,0,1,0,.981-.978Z"
                  transform="translate(-11.251 -6.194)"
                  fill="#fff"
                />
              </svg>
              Back
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <Card body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <span for="">Student</span>
                    <Select
                      options={studentOptions}
                      value={studentOptions.filter(
                        (i) => i.value == values.studentId
                      )}
                      isDisabled={true}
                      ref={selectInputRefStudent}
                      placeholder="Select Student"
                      onChange={OptionHandleStudent}
                      onInputChange={OptionInputHandleStudent}
                    />
                    <FormFeedback
                      style={{ display: errors.studentId && 'block' }}>
                      {errors.studentId && touched.studentId
                        ? errors.studentId
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <span>Batch</span>
                    <Select
                      options={batchOption}
                      value={batchOption.filter(
                        (i) => i.value == values.batchId
                      )}
                      ref={selectInputBatch}
                      placeholder="Select Batch"
                      onChange={optionHandleBatch}
                      onInputChange={OptionInputHandleBatch}
                    />
                  </FormGroup>
                  <FormFeedback style={{ display: errors.batchId && 'block' }}>
                    {errors.batchId && touched.batchId ? errors.batchId : ''}
                  </FormFeedback>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Card Type</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio"
                        name="customRadio"
                        value={'red'}
                        name="cardType"
                        label="Red"
                        onChange={handleChange}
                        checked={values.cardType === 'red'}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio2"
                        name="customRadio"
                        value={'yellow'}
                        name="cardType"
                        label="Yellow"
                        onChange={handleChange}
                        checked={values.cardType === 'yellow'}
                      />
                    </Row>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Comments</Label>
                    <Input
                      type="text"
                      name="comments"
                      id="comments"
                      placeholder="Enter comments"
                      value={values.comments}
                      invalid={
                        errors.comments && touched.comments ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.comments && touched.comments
                        ? errors.comments
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <div className="btn-center">
                <Button color="primary" className="pull-left">
                  Update
                </Button>
              </div>
            </Form>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}
