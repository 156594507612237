import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Autocomplete from 'react-autocomplete'
import classnames from 'classnames'

import {
  Button,
  ButtonGroup,
  Container,
  Input,
  Table,
  Card,
  Row,
  Col,
  Alert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import API from '../../../constants/API'
import { CustomPageLoading } from '../../../function/Helperfunction'
import { authHeader } from '../../../function/Mainfunction'
import Listmodal from './Listmodal'
import { Link } from 'react-router-dom'
import {
  ADMIN_ROLLOVER_VIEW,
  ROLLCALL_VIEW,
  CLASSROOM_ROLLCALL_VIEW,
  ROLLCALL_CLASS_ROOM,
} from '../../../constants/RoutePaths'
import {
  GET_ATTENDENCEREP,
  STUDENT_LIST,
  GET_CLASS_ROOM_ATTENDENCEREP,
} from '../../../constants/Apipath'
import { useHistory } from 'react-router'
import ClassRollOverListModal from './ClassRollOverListModal'

function Rollover() {
  const [loading, setloading] = useState(false)
  let currentActive = window.location.pathname.split('/')[1]
  const history = useHistory()
  const [rolloverconflag, setrolloverconflag] = useState(false)
  const [absentPresentCount, setabsentPresentCount] = useState([])
  const [record, setrecord] = useState(0)
  const [date, setdate] = useState('')
  const [studentList, setstudentList] = useState([])
  const [addStudentmodal, setaddStudentmodal] = useState(false)
  const [rollcall, setRollCall] = useState('1')
  const [classRoomRollCall, SetClassRoomRollCall] = useState([])
  const [classRollCallRecord, setClassRollCallRecord] = useState(0)
  const [addClassRoomStudentmodal, setAddClassRoomStudentmodal] =
    useState(false)
  const addStudenttoggle = () => {
    setaddStudentmodal((val) => !val)
  }
  const addClassRoomStudentToggle = () => {
    setAddClassRoomStudentmodal((val) => !val)
  }
  const getRollcall = async (e) => {
    let date = moment(e).format('YYYY-MM-DD')
    setdate(date)
    const config = {
      method: 'GET',
      url: GET_ATTENDENCEREP,
      headers: authHeader(),
      params: {
        attendanceDate: date,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, absentPresentCount, data = [] } = response.data

      if (status === 'Success') {
        setrecord(data.length)
        setabsentPresentCount(absentPresentCount)
        setrolloverconflag(true)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  const getClassRoomRollCall = async (e) => {
    let date = moment(e).format('YYYY-MM-DD')
    setdate(date)
    const config = {
      method: 'GET',
      url: GET_CLASS_ROOM_ATTENDENCEREP,
      headers: authHeader(),
      params: {
        attendanceDate: date,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, absentPresentCount, data = [] } = response.data
      if (status === 'Success') {
        SetClassRoomRollCall(absentPresentCount)
        setClassRollCallRecord(data.length)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    getRollcall()
    getClassRoomRollCall()
  }, [])

  const getStudent = async () => {
    const config = {
      method: 'GET',
      url: STUDENT_LIST,
      headers: authHeader(),
      params: {
        studentStatus: 'Active',
        count: 1000,
      },
    }
    // setloading(true)
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status === 'Success') {
        setstudentList(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    getStudent()
  }, [])

  const successfullcall = () => {
    history.push(`${ADMIN_ROLLOVER_VIEW}/${date}`)
  }
  const classrommRollsuccess = () => {
    history.push(`${CLASSROOM_ROLLCALL_VIEW}/${date}`)
  }
  const onRollCallChange = (item) => {
    setRollCall(item)
  }

  let statuscolor = {
    PRESENT: 'green',
    ABSENT: 'red',
    EXCUSED: 'orange',
    UNMARKED: 'blue',
  }
  const getCurrentData = (e) => {
    getRollcall(e)
    getClassRoomRollCall(e)
  }
  return (
    <Fragment>
      {loading && <CustomPageLoading />}
      <div className="wrapper">
        <div className="page-title">
          <h4>Roll Call</h4>
        </div>
        <div>
          <Container fluid={true}>
            <>
              {' '}
              <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <div className="mb-3">
                    <Card body>
                      <Calendar onClickDay={getCurrentData} />
                    </Card>
                  </div>
                </Col>
              </Row>
              {rolloverconflag && (
                <>
                  {record == 0 &&
                  classRollCallRecord == 0 &&
                  // classRollCallRecord == 0 &&
                  currentActive !== 'rollcalladmin' ? (
                    <>
                      <Alert
                        className="text-center w-fit m-auto"
                        color="primary">
                        No Roll call is created
                      </Alert>
                      <br />{' '}
                      <div className="d-flex w-fit m-auto">
                        <Button
                          className="d-block w-fit m-auto"
                          color="primary"
                          onClick={addStudenttoggle}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.782"
                            height="9.782"
                            viewBox="0 0 9.782 9.782">
                            <path
                              id="Icon_ionic-ios-add"
                              data-name="Icon ionic-ios-add"
                              d="M18.138,13.247H14.465V9.574a.609.609,0,0,0-1.218,0v3.673H9.574a.609.609,0,0,0,0,1.218h3.673v3.673a.609.609,0,1,0,1.218,0V14.465h3.673a.609.609,0,1,0,0-1.218Z"
                              transform="translate(-8.965 -8.965)"
                              fill="#fff"
                            />
                          </svg>{' '}
                          Road Side Roll call
                        </Button>{' '}
                        <Button
                          className="d-block w-fit ml-5"
                          color="primary"
                          onClick={addClassRoomStudentToggle}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.782"
                            height="9.782"
                            viewBox="0 0 9.782 9.782">
                            <path
                              id="Icon_ionic-ios-add"
                              data-name="Icon ionic-ios-add"
                              d="M18.138,13.247H14.465V9.574a.609.609,0,0,0-1.218,0v3.673H9.574a.609.609,0,0,0,0,1.218h3.673v3.673a.609.609,0,1,0,1.218,0V14.465h3.673a.609.609,0,1,0,0-1.218Z"
                              transform="translate(-8.965 -8.965)"
                              fill="#fff"
                            />
                          </svg>{' '}
                          Class room Roll call
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: rollcall == '1' ? true : false,
                            })}
                            onClick={() => onRollCallChange('1')}>
                            Road side Roll call
                          </NavLink>
                        </NavItem>{' '}
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: rollcall == '2' ? true : false,
                            })}
                            onClick={() => onRollCallChange('2')}>
                            Class room Roll call
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={rollcall}>
                        <TabPane tabId="1">
                          <Card body>
                            {record !== 0 && (
                              <Link
                                className="d-block ml-auto mb-3"
                                to={`${
                                  currentActive == 'rollcalladmin'
                                    ? ROLLCALL_VIEW
                                    : ADMIN_ROLLOVER_VIEW
                                }/${date}`}>
                                <Button color="primary">View Summary</Button>
                              </Link>
                            )}
                            {record == 0 && (
                              <Button
                                className="d-block ml-auto mb-3"
                                color="primary"
                                onClick={addStudenttoggle}>
                                Add Road Side Roll call
                              </Button>
                            )}
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>AM</th>
                                  <th>Noon</th>
                                  <th>PM</th>
                                </tr>
                              </thead>
                              <tbody>
                                {absentPresentCount.map((pre) => {
                                  return (
                                    <tr>
                                      <td
                                        style={{
                                          color: statuscolor[pre.status],
                                        }}>
                                        {pre.status}
                                      </td>
                                      <td>{pre.am}</td>
                                      <td>{pre.noon}</td>
                                      <td>{pre.pm}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </Card>
                        </TabPane>

                        <TabPane tabId="2">
                          <Card body>
                            {classRollCallRecord !== 0 && (
                              <Link
                                className="d-block ml-auto mb-3"
                                to={`${
                                  currentActive == 'rollcalladmin'
                                    ? ROLLCALL_CLASS_ROOM
                                    : CLASSROOM_ROLLCALL_VIEW
                                }/${date}`}>
                                <Button color="primary">View Summary</Button>
                              </Link>
                            )}
                            {classRollCallRecord == 0 && (
                              <Button
                                className="d-block ml-auto mb-3"
                                color="primary"
                                onClick={addClassRoomStudentToggle}>
                                Add Classroom rollcall
                              </Button>
                            )}
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>AM</th>
                                  <th>Noon</th>
                                  <th>PM</th>
                                </tr>
                              </thead>
                              <tbody>
                                {classRoomRollCall.map((pre) => {
                                  return (
                                    <tr>
                                      <td
                                        style={{
                                          color: statuscolor[pre.status],
                                        }}>
                                        {pre.status}
                                      </td>
                                      <td>{pre.am}</td>
                                      <td>{pre.noon}</td>
                                      <td>{pre.pm}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </Card>
                        </TabPane>
                      </TabContent>
                    </>
                  )}
                </>
              )}
            </>
          </Container>
        </div>
        <Listmodal
          data={studentList}
          date={date}
          successfullcall={successfullcall}
          student={{}}
          modal={addStudentmodal}
          toggle={addStudenttoggle}
        />
        <ClassRollOverListModal
          data={studentList}
          date={date}
          successfullcall={classrommRollsuccess}
          student={{}}
          modal={addClassRoomStudentmodal}
          toggle={addClassRoomStudentToggle}
        />
      </div>
    </Fragment>
  )
}

export default Rollover
