import React, { Fragment, useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Button,
  Badge,
  UncontrolledCollapse,
  NavItem,
  NavLink,
  Card,
  Row,
  DropdownItem,
  Dropdown,
  DropdownMenu,
  Col,
  TabContent,
  TabPane,
  DropdownToggle,
} from 'reactstrap'
import classnames from 'classnames'
import { authHeader } from '../../function/Mainfunction'
import API from '../../constants/API'
import { useSnackbar } from 'notistack'
import { STUDENT_DETAILS } from '../../constants/Apipath'
import { CustomMessage, JSONParser } from '../../function/Helperfunction'
import { useHistory, useParams } from 'react-router'
import moment from 'moment'
import { BackArrow } from '../../function/Icons'
import Pretrip from '../admin/students/Pretrip'
import Incab from '../admin/students/Incab'
import Maneuver from '../admin/students/Maneuver'
import Drive from '../admin/students/Drive'
import Dpstest from '../admin/students/ Dpstest'
import { TableLoader } from '../../function/Loader'
import AllStudentPDFGenerator from '../admin/students/StudentDetailsPDFGenerators/AllStudentPDFGenerator'
import PretripPDFGenerator from '../admin/students/StudentDetailsPDFGenerators/PretripPDFGenerator'
import IncabPDFGenerator from '../admin/students/StudentDetailsPDFGenerators/IncabPDFGenerator'
import ManeuverPDFGenrator from '../admin/students/StudentDetailsPDFGenerators/ManeuverPDFGenrator'
import DrivePDFGenerator from '../admin/students/StudentDetailsPDFGenerators/DrivePDFGenerator'
import DPSTesrPDFGenerator from '../admin/students/StudentDetailsPDFGenerators/DPSTesrPDFGenerator'
import { CSVLink } from 'react-csv'
export default function InstructorStudentDetails() {
  const history = useHistory()
  const { studentId } = useParams()
  const [activeTab, setActiveTab] = useState('1')
  const [studentDetails, setStudentDetails] = useState({})
  const [loading, setloading] = useState(false)
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const [pdfDropdownOpen, setpdfDropdownOpen] = useState(false)
  const [csvDropdownOpen, setcsvDropdownOpen] = useState(false)
  const pdfToggle = () => {
    setpdfDropdownOpen(!pdfDropdownOpen)
  }

  const csvToggle = () => {
    setcsvDropdownOpen(!csvDropdownOpen)
  }
  const { enqueueSnackbar } = useSnackbar()
  const {
    dpsTest,
    driveDetails,
    incabDetails,
    maneuverDetails,
    preTripDetails,
  } = studentDetails
  const DpsTest = JSONParser(dpsTest, [])
  const DriveDetails = JSONParser(driveDetails, [])
  const IncabDetails = JSONParser(incabDetails, [])
  const ManeuverDetails = JSONParser(maneuverDetails, [])
  const PreTripDetails = JSONParser(preTripDetails, [])

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const Getstudent = async () => {
    const config = {
      method: 'GET',
      url: STUDENT_DETAILS,
      headers: authHeader(),
      params: {
        studentId: studentId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setStudentDetails(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const Back = () => {
    history.goBack()
  }

  const pretripHeaders = [
    { label: 'Pre-Trip ID', key: 'preTripId' },
    { label: 'Trip Date', key: 'tripDate' },
    { label: 'Pre-Trip Time', key: 'preTripTime' },
  ]

  const incabHaeders = [
    { label: 'Incab ID', key: 'incabId' },
    { label: 'Incab Date', key: 'incabDate' },
    { label: 'Incab Time', key: 'incabTime' },
  ]

  const maneuverHeaders = [
    { label: 'Maneuver', key: 'maneuver' },
    { label: 'Mirrors', key: 'mirrors' },
    { label: 'Steering', key: 'steering' },
    { label: 'Encroaches', key: 'encroaches' },
    { label: 'Maneuver Date', key: 'maneuverDate' },
    { label: 'Maneuver Time', key: 'maneuverTime' },
    { label: 'Score', key: 'score' },
    { label: 'Comments', key: 'comments' },
  ]

  const driveHaeders = [
    { label: 'Bears', key: 'bears' },
    { label: 'Brake', key: 'brake' },
    { label: 'Downshifting', key: 'downshifting' },
    { label: 'FollowDistance', key: 'followDistance' },
    { label: 'Comments', key: 'comments' },
    { label: 'GearPattern', key: 'gearPattern' },
    { label: 'Brake', key: 'brake' },
    { label: 'HitCurb', key: 'hitCurb' },
    { label: 'HoverH', key: 'hoverH' },
    { label: 'Comments', key: 'lane' },
    { label: 'InterCoasting', key: 'interCoasting' },
    { label: 'IntersectionMirrors', key: 'intersectionMirrors' },
    { label: 'IntersectiontrafficCheck', key: 'intersectiontrafficCheck' },
    { label: 'MergingSmooth', key: 'mergingSmooth' },
    { label: 'MergingTrafficCheck', key: 'mergingTrafficCheck' },
    { label: 'TimeIn', key: 'timeIn' },
    { label: 'TimeOut', key: 'timeOut' },
    { label: 'Timing', key: 'timing' },
    { label: 'TotalTime', key: 'totalTime' },
    { label: 'Reaction', key: 'lane' },
  ]

  const dpsTestHeaders = [
    { label: 'Test Date', key: 'testDate' },
    { label: 'Test Hours', key: 'testHours' },
    { label: 'Comments', key: 'comments' },
    { label: 'Test Result', key: 'testResult' },
  ]

  const AllStudentDetails = [
    ...DpsTest,
    ...DriveDetails,
    ...IncabDetails,
    ...ManeuverDetails,
    ...PreTripDetails,
  ]

  //  const DpsTest = JSONParser(dpsTest, [])
  //  const DriveDetails = JSONParser(driveDetails, [])
  //  const IncabDetails = JSONParser(incabDetails, [])
  //  const ManeuverDetails = JSONParser(maneuverDetails, [])
  //  const PreTripDetails = JSONParser(preTripDetails, [])

  var csvDps = [[''], ['Dps Test'], dpsTestHeaders.map((i) => i.label)]
  DpsTest.forEach((s) =>
    csvDps.push([s.testDate, s.testHours, s.comments, s.testResult])
  )
  var csvDrive = [[''], ['Drive'], driveHaeders.map((i) => i.label)]

  DriveDetails.forEach((d) =>
    csvDrive.push([
      d.bears,
      d.brake,
      d.downshifting,
      d.followDistance,
      d.comments,
      d.gearPattern,
      d.hitCurb,
      d.hoverH,
      d.lane,
      d.interCoasting,
      d.intersectionMirrors,
      d.intersectiontrafficCheck,
      d.mergingSmooth,
      d.mergingTrafficCheck,
      d.timeIn,
      d.timeOut,
      d.timing,
      d.totalTime,
      d.lane,
    ])
  )

  var csvIncab = [[''], ['In cab'], incabHaeders.map((i) => i.label)]
  IncabDetails.forEach((i) =>
    csvIncab.push([i.incabId, i.incabDate, i.incabTime])
  )

  var csvMan = [[''], ['Maneuver'], maneuverHeaders.map((i) => i.label)]
  ManeuverDetails.forEach((m) =>
    csvMan.push([
      m.maneuver,
      m.mirrors,
      m.steering,
      m.encroaches,
      m.maneuverDate,
      m.maneuverTime,
      m.score,
      m.comments,
    ])
  )

  var csvPre = [[''], ['PreTrip'], pretripHeaders.map((i) => i.label)]

  PreTripDetails.forEach((p) =>
    csvPre.push([p.preTripId, p.tripDate, p.preTripTime])
  )

  const csvAll = [...csvDps, ...csvDrive, ...csvIncab, ...csvMan, ...csvPre]

  const AllExportPDF = () => {
    AllStudentPDFGenerator(studentDetails.firstName, AllStudentDetails)
  }
  const PretripExportPDF = () => {
    PretripPDFGenerator(studentDetails.firstName, PreTripDetails)
  }
  const IncabExportPDF = () => {
    IncabPDFGenerator(studentDetails.firstName, IncabDetails)
  }
  const ManeuverExportPDF = () => {
    ManeuverPDFGenrator(studentDetails.firstName, ManeuverDetails)
  }
  const DriveExportPDF = () => {
    DrivePDFGenerator(studentDetails.firstName, DriveDetails)
  }
  const DPSTestExportPDF = () => {
    DPSTesrPDFGenerator(studentDetails.firstName, DpsTest)
  }

  useEffect(() => {
    Getstudent()
  }, [])
  const handleOpen = () => {
    setdropdownOpen(!dropdownOpen)
  }
  return (
    <Fragment>
      <div className="wrapper">
        <div className="page-title">
          <h4>Student Details</h4>
          <div className="btn-right">
            <Button onClick={handleOpen} color="danger">
              Export
            </Button>
            {dropdownOpen && (
              <>
                <Dropdown isOpen={pdfDropdownOpen} toggle={pdfToggle}>
                  <DropdownToggle color="danger" caret>
                    PDF
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={AllExportPDF}>ALL</DropdownItem>
                    <DropdownItem onClick={PretripExportPDF}>
                      Pretrip
                    </DropdownItem>
                    <DropdownItem onClick={IncabExportPDF}>Incab</DropdownItem>
                    <DropdownItem onClick={ManeuverExportPDF}>
                      Maneuver
                    </DropdownItem>
                    <DropdownItem onClick={DriveExportPDF}>Driver</DropdownItem>
                    <DropdownItem onClick={DPSTestExportPDF}>
                      DPS Test
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown isOpen={csvDropdownOpen} toggle={csvToggle}>
                  <DropdownToggle color="danger" caret>
                    CSV
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_All_Details.csv`}
                        data={csvAll}>
                        <span className="csvLink">ALL</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_Pretrip_Details.csv`}
                        data={PreTripDetails}
                        headers={pretripHeaders}>
                        <span className="csvLink">Pretrip</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_Incab_Details.csv`}
                        data={IncabDetails}
                        headers={incabHaeders}>
                        <span className="csvLink">Incab</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_Maneuver_Details.csv`}
                        data={ManeuverDetails}
                        headers={maneuverHeaders}>
                        <span className="csvLink">Maneuver</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_Drive_Details.csv`}
                        data={DriveDetails}
                        headers={driveHaeders}>
                        <span className="csvLink">Drive</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_DPSTest_Details.csv`}
                        data={DpsTest}
                        headers={dpsTestHeaders}>
                        <span className="csvLink">DPS Test</span>
                      </CSVLink>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </>
            )}
            <Button onClick={Back} color="primary">
              <BackArrow />
              Back
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <div>
            <Card body>
              <div className="detail-card">
                <div className="top">
                  <div className="name">
                    <h4>
                      {loading ? (
                        <TableLoader />
                      ) : (
                        studentDetails.firstName + ' ' + studentDetails.lastName
                      )}
                      <Badge
                        pill
                        color={
                          studentDetails.isWeekendStudent ? 'danger' : 'primary'
                        }>
                        {studentDetails.isWeekendStudent ? 'We' : 'wd'}
                      </Badge>
                    </h4>
                  </div>
                  <div className="right">
                    <Badge color="danger">
                      Learning Hours :{' '}
                      {studentDetails.totalTrainingHrs || '00:00'}
                    </Badge>
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.347"
                        height="14.83"
                        viewBox="0 0 13.347 14.83">
                        <path
                          id="Icon_material-date-range"
                          data-name="Icon material-date-range"
                          d="M8.949,9.673H7.466v1.483H8.949Zm2.966,0H10.432v1.483h1.483Zm2.966,0H13.4v1.483h1.483Zm1.483-5.19h-.741V3H14.139V4.483H8.207V3H6.724V4.483H5.983A1.476,1.476,0,0,0,4.507,5.966L4.5,16.347A1.483,1.483,0,0,0,5.983,17.83H16.364a1.487,1.487,0,0,0,1.483-1.483V5.966A1.487,1.487,0,0,0,16.364,4.483Zm0,11.864H5.983V8.19H16.364Z"
                          transform="translate(-4.5 -3)"
                          fill="#c2c2c2"
                        />
                      </svg>
                      Joined {moment(studentDetails.createdDate).format('LL')}
                    </p>
                  </div>
                </div>

                <Row className="bottom pl-3">
                  <Col sm="2">
                    <div className="info">
                      <span>First name</span>
                      <p>
                        {' '}
                        {loading ? <TableLoader /> : studentDetails.firstName}
                      </p>
                    </div>
                  </Col>

                  <Col sm="2">
                    <div className="info">
                      <span>Last name</span>
                      <p>{studentDetails.lastName}</p>
                    </div>
                  </Col>
                  {/* 
                  <Col sm="2">
                    <div className="info">
                      <span>Email</span>
                      <p>{studentDetails.email}</p>
                    </div>
                  </Col> */}

                  <Col sm="2">
                    <div className="info">
                      <span>Gender</span>
                      <p>{loading ? <TableLoader /> : studentDetails.gender}</p>
                    </div>
                  </Col>
                  {/* 
                  <Col sm="2">
                    <div className="info">
                      <span>DOB</span>
                      <p>{studentDetails.dateOfBirth}</p>
                    </div>
                  </Col> */}

                  {/* <Col sm="2">
                    <Button
                      color="primary"
                      size="sm"
                      className="mt-3"
                      id="toggler">
                      View more details
                    </Button>
                  </Col> */}
                </Row>
                {/* 
                <UncontrolledCollapse className="p-0" toggler="#toggler">
                  <Row className="bottom m-0 border-none">
                    <Col sm="2">
                      <div className="info">
                        <span>Phone</span>
                        <p>{studentDetails.phoneNumber}</p>
                      </div>
                    </Col>

                    <Col sm="2">
                      <div className="info">
                        <span>Address</span>
                        <p>{studentDetails.address}</p>
                      </div>
                    </Col>
                  </Row>
                </UncontrolledCollapse> */}
              </div>
            </Card>
          </div>

          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1')
                  }}>
                  Pretrip
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2')
                  }}>
                  Incab
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    toggle('3')
                  }}>
                  Maneuver
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => {
                    toggle('4')
                  }}>
                  Drive
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => {
                    toggle('5')
                  }}>
                  DPS Test
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Pretrip PreTripDetails={PreTripDetails} />
              </TabPane>
              <TabPane tabId="2">
                <Incab IncabDetails={IncabDetails} />
              </TabPane>
              <TabPane tabId="3">
                <Maneuver ManeuverDetails={ManeuverDetails} />
              </TabPane>
              <TabPane tabId="4">
                <Drive DriveDetails={DriveDetails} />
              </TabPane>
              <TabPane tabId="5">
                <Dpstest DpsTest={DpsTest} />
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
