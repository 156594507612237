import React from 'react'
import {
  Row,
  Col,
  Button,
  Card,
  Badge,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'
import Avatar from 'react-avatar'
import { Link, useHistory } from 'react-router-dom'
import {
  CREATE_BTW_ROUTE,
  INSTRUCTOR_STUDENT_DETAILS,
  STUDENT_DETAILS_ROUTE,
} from '../../constants/RoutePaths'
import { JSONParser } from '../../function/Helperfunction'
export default function StudentBox(props) {
  const { StudentList = [], Admin = true, batchId } = props
  const history = useHistory()

  const Color = {
    completed: 'success',
    pending: 'warning',
  }

  const StudentView = (Id) => {
    // if (Admin) {
    //   return false
    // }
    history.push(
      `${!Admin ? STUDENT_DETAILS_ROUTE : INSTRUCTOR_STUDENT_DETAILS}/${Id}`
    )
  }

  const StartBatch = (e, type, batchId, userId) => {
    e.stopPropagation()
    history.push(`${CREATE_BTW_ROUTE}/${type}/${batchId}/${userId}`)
  }

  return (
    <>
      {StudentList.map((st, index) => (
        <Col key={index} md="4" xl="3" className="mb-3">
          <Card
            onClick={() => StudentView(st.userId)}
            body
            className="student-card">
            <div className="initials-avatar">
              <Avatar name={st.studentName} size={40} round="50px" />
            </div>
            <h4>
              {st.studentName}{' '}
              {!st.isWeekendStudent ? (
                <Badge color="primary" pill>
                  Wd
                </Badge>
              ) : (
                <Badge color="danger" pill>
                  We
                </Badge>
              )}
            </h4>
            <Badge color={Color[st.status]} className="m-auto">
              {st.status}
            </Badge>
            {JSONParser(st.tripDetails, []).map((tr) => (
              <>
                <ListGroup>
                  <ListGroupItem>
                    Total drive <span>{tr.count}</span>
                  </ListGroupItem>
                  <ListGroupItem>
                    Total drive time <span>{tr.time}</span>
                  </ListGroupItem>
                </ListGroup>
              </>
            ))}
            {Admin && (
              <>
                {' '}
                <div className="btn-section">
                  {st.status == 'completed' && (
                    <Button
                      onClick={(e) =>
                        StartBatch(e, st.type, batchId, st.userId)
                      }
                      color="danger"
                      size="sm">
                      Re-drive
                    </Button>
                  )}
                </div>
                {st.status == 'pending' && (
                  <Row className="justify-content-center">
                    <Button
                      onClick={(e) =>
                        StartBatch(e, st.type, batchId, st.userId)
                      }
                      color="danger"
                      size="sm">
                      Start
                    </Button>
                  </Row>
                )}
              </>
            )}
          </Card>
        </Col>
      ))}
    </>
  )
}
