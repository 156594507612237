import React from 'react'

export const Pencil = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.662"
        height="9.662"
        viewBox="0 0 9.662 9.662">
        <path
          id="pencil"
          d="M9.309,1.062,8.6.355a1.208,1.208,0,0,0-1.708,0L.243,7,0,9.16a.453.453,0,0,0,.5.5L2.658,9.42l6.65-6.65a1.208,1.208,0,0,0,0-1.708Zm-1.8,2.65L3.284,7.941V7.285H2.378V6.379H1.723L5.951,2.151,7.512,3.712ZM2.381,8.844l-1.113.124L.7,8.4.82,7.282l.375-.375H1.85v.906h.906v.656Zm6.5-6.5-.942.942L6.378,1.724,7.32.782a.6.6,0,0,1,.854,0l.707.707a.6.6,0,0,1,0,.854Z"
          transform="translate(-0.001 -0.001)"
          fill="#e44a4a"
        />
      </svg>
    </>
  )
}

export const Groupuser = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.837"
      height="26.486"
      viewBox="0 0 37.837 26.486">
      <path
        id="user-friends"
        d="M28.378,45.243A5.676,5.676,0,1,0,22.7,39.567,5.677,5.677,0,0,0,28.378,45.243Zm0-9.459a3.784,3.784,0,1,1-3.784,3.784A3.787,3.787,0,0,1,28.378,35.784ZM11.351,45.243A6.621,6.621,0,1,0,4.73,38.621,6.618,6.618,0,0,0,11.351,45.243Zm0-11.351a4.73,4.73,0,1,1-4.73,4.73A4.736,4.736,0,0,1,11.351,33.892Zm4.736,12.534c-1.975,0-2.465.709-4.736.709s-2.761-.709-4.736-.709A6.516,6.516,0,0,0,1.159,49.2,6.775,6.775,0,0,0,0,53v2.649a2.839,2.839,0,0,0,2.838,2.838H19.864A2.839,2.839,0,0,0,22.7,55.648V53a6.775,6.775,0,0,0-1.159-3.8,6.516,6.516,0,0,0-5.457-2.773Zm4.724,9.223a.949.949,0,0,1-.946.946H2.838a.949.949,0,0,1-.946-.946V53a4.912,4.912,0,0,1,.834-2.743A4.627,4.627,0,0,1,6.61,48.317c1.62,0,2.2.709,4.741.709s3.122-.709,4.736-.709a4.627,4.627,0,0,1,3.884,1.939A4.912,4.912,0,0,1,20.8,53v2.649Zm16.063-6.793a5.434,5.434,0,0,0-4.546-2.312c-1.644,0-2.057.591-3.949.591s-2.306-.591-3.949-.591a5.766,5.766,0,0,0-2.252.479A5.7,5.7,0,0,1,23.37,48.6a3.879,3.879,0,0,1,1.058-.154c1.289,0,1.774.591,3.949.591s2.66-.591,3.949-.591a3.542,3.542,0,0,1,2.98,1.478,3.762,3.762,0,0,1,.644,2.111v2.2a.474.474,0,0,1-.473.473H24.594a8.482,8.482,0,0,1-.095,1.892H35.472a2.364,2.364,0,0,0,2.365-2.365V52.024A5.648,5.648,0,0,0,36.873,48.855Z"
        transform="translate(0 -32)"
      />
    </svg>
  )
}

export const Userclass = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.352"
      height="24.282"
      viewBox="0 0 30.352 24.282">
      <path
        id="users-class"
        d="M25.8,10.623a3.794,3.794,0,1,0,3.794,3.794A3.793,3.793,0,0,0,25.8,10.623Zm0,6.07a2.276,2.276,0,1,1,2.276-2.276A2.277,2.277,0,0,1,25.8,16.694ZM11.382,14.417a3.794,3.794,0,1,0,3.794-3.794A3.793,3.793,0,0,0,11.382,14.417Zm6.07,0a2.276,2.276,0,1,1-2.276-2.276A2.277,2.277,0,0,1,17.452,14.417Zm-12.9,3.794A3.794,3.794,0,1,0,.759,14.417,3.793,3.793,0,0,0,4.553,18.211Zm0-6.07a2.276,2.276,0,1,1-2.276,2.276A2.277,2.277,0,0,1,4.553,12.141Zm22.195,7.588h-1.9a3.708,3.708,0,0,0-3.6,3.794.759.759,0,1,0,1.518,0,2.191,2.191,0,0,1,2.087-2.276h1.9a2.191,2.191,0,0,1,2.087,2.276.759.759,0,1,0,1.518,0A3.708,3.708,0,0,0,26.748,19.729Zm-21.246,0H3.6A3.708,3.708,0,0,0,0,23.523a.759.759,0,1,0,1.518,0A2.191,2.191,0,0,1,3.6,21.246H5.5a2.191,2.191,0,0,1,2.087,2.276.759.759,0,1,0,1.518,0A3.708,3.708,0,0,0,5.5,19.729Zm10.623,0h-1.9a3.708,3.708,0,0,0-3.6,3.794.759.759,0,1,0,1.518,0,2.191,2.191,0,0,1,2.087-2.276h1.9a2.191,2.191,0,0,1,2.087,2.276.759.759,0,1,0,1.518,0A3.708,3.708,0,0,0,16.124,19.729ZM3.035,2.276a.76.76,0,0,1,.759-.759H26.558a.76.76,0,0,1,.759.759V9.353a5.274,5.274,0,0,1,1.518.717V2.276A2.279,2.279,0,0,0,26.558,0H3.794A2.279,2.279,0,0,0,1.518,2.276V10.07a5.286,5.286,0,0,1,1.518-.717Z"
      />
    </svg>
  )
}

export const Truck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.904"
      height="20.232"
      viewBox="0 0 28.904 20.232">
      <path
        id="truck-moving"
        d="M28.059,39.827l-2.642-2.642a2.891,2.891,0,0,0-2.046-.845H20.955V33.445A1.444,1.444,0,0,0,19.51,32H1.445A1.444,1.444,0,0,0,0,33.445V48.62a3.6,3.6,0,0,0,6.5,2.15,3.6,3.6,0,0,0,6.5-2.15,3.806,3.806,0,0,0-.072-.723H20.3a3.471,3.471,0,0,0-.072.723,3.613,3.613,0,0,0,7.226,0,3.806,3.806,0,0,0-.072-.723h.795a.725.725,0,0,0,.723-.723V41.868A2.881,2.881,0,0,0,28.059,39.827ZM3.613,50.787A2.168,2.168,0,1,1,5.781,48.62,2.168,2.168,0,0,1,3.613,50.787Zm5.781,0a2.168,2.168,0,1,1,2.168-2.168A2.168,2.168,0,0,1,9.394,50.787ZM19.51,46.452H12.266a3.577,3.577,0,0,0-5.745,0H6.49a3.616,3.616,0,0,0-2.877-1.445,3.561,3.561,0,0,0-2.168.741v-12.3H19.51Zm1.445-8.671h2.416a1.424,1.424,0,0,1,1.021.425l2.466,2.466h-5.9Zm2.89,13.007a2.168,2.168,0,1,1,2.168-2.168A2.168,2.168,0,0,1,23.845,50.787Zm3.613-4.336h-.741a3.577,3.577,0,0,0-5.745,0h-.018V42.116h6.5Z"
        transform="translate(0 -32)"
        
      />
    </svg>
  )
}

export const User = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.981"
      height="30.836"
      viewBox="0 0 26.981 30.836">
      <path
        id="user-graduate"
        d="M19.236,19.308,13.491,24.09,7.745,19.308A8.11,8.11,0,0,0,0,27.945a2.892,2.892,0,0,0,2.891,2.891h21.2a2.892,2.892,0,0,0,2.891-2.891A8.106,8.106,0,0,0,19.236,19.308Zm-6.709,9.6H2.891a.966.966,0,0,1-.964-.964c0-6,5.065-6.619,5.2-6.643l5.4,4.493Zm12.527-.964a.966.966,0,0,1-.964.964H14.454V25.795l5.4-4.493C19.989,21.326,25.054,21.946,25.054,27.945ZM.795,6.023l.41.12V8.408a1.391,1.391,0,0,0-.054,2.409l-.94,3.758c-.1.416.126.843.458.843H3.186c.331,0,.56-.428.458-.843L2.7,10.823A1.391,1.391,0,0,0,2.65,8.414V6.577l2.813.831A8.412,8.412,0,0,0,4.818,10.6a8.673,8.673,0,0,0,17.345,0,8.412,8.412,0,0,0-.644-3.192l4.667-1.385a1.31,1.31,0,0,0,0-2.409L14.508.151a3.515,3.515,0,0,0-2.036,0L.795,3.614A1.31,1.31,0,0,0,.795,6.023Zm12.7,11.322A6.75,6.75,0,0,1,6.745,10.6a6.544,6.544,0,0,1,.578-2.638l5.149,1.53a3.542,3.542,0,0,0,2.036,0l5.149-1.53a6.566,6.566,0,0,1,.578,2.638,6.75,6.75,0,0,1-6.745,6.745Zm-.47-15.352a1.673,1.673,0,0,1,.94,0l9.516,2.825L13.96,7.643a1.673,1.673,0,0,1-.94,0L3.505,4.818l9.516-2.825Z"
      />
    </svg>
  )
}

export const Delete = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.271"
      height="10.595"
      viewBox="0 0 9.271 10.595">
      <path
        id="trash"
        d="M9.105,1.324H6.953L6.258.4A.993.993,0,0,0,5.463,0H3.808a.993.993,0,0,0-.795.4l-.7.927H.166A.166.166,0,0,0,0,1.49v.331a.166.166,0,0,0,.166.166H.557l.687,7.7a.993.993,0,0,0,.989.9H7.038a.993.993,0,0,0,.989-.9l.687-7.7h.391a.166.166,0,0,0,.166-.166V1.49A.166.166,0,0,0,9.105,1.324ZM3.543.795A.333.333,0,0,1,3.808.662H5.463a.333.333,0,0,1,.265.132l.4.53H3.145ZM7.367,9.631a.329.329,0,0,1-.329.3H2.233a.329.329,0,0,1-.329-.3L1.221,1.987H8.05Z"
        fill="#e44a4a"
      />
    </svg>
  )
}

export const Add = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.782"
      height="9.782"
      viewBox="0 0 9.782 9.782">
      <path
        id="Icon_ionic-ios-add"
        data-name="Icon ionic-ios-add"
        d="M18.138,13.247H14.465V9.574a.609.609,0,0,0-1.218,0v3.673H9.574a.609.609,0,0,0,0,1.218h3.673v3.673a.609.609,0,1,0,1.218,0V14.465h3.673a.609.609,0,1,0,0-1.218Z"
        transform="translate(-8.965 -8.965)"
        fill="#fff"
      />
    </svg>
  )
}

export const Filter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.696"
      height="10.696"
      viewBox="0 0 10.696 10.696">
      <path
        id="filter"
        d="M10.027,0H.669A.669.669,0,0,0,.2,1.141L4.011,4.957v3.9a.668.668,0,0,0,.228.5l1.337,1.17a.669.669,0,0,0,1.109-.5V4.957L10.5,1.141A.669.669,0,0,0,10.027,0ZM6.017,4.68v5.348L4.68,8.858V4.68L.669.669h9.359Z"
        fill="#fff"
      />
    </svg>
  )
}

export const BackArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.543"
      height="9.695"
      viewBox="0 0 5.543 9.695">
      <path
        id="Icon_ionic-ios-arrow-back"
        data-name="Icon ionic-ios-arrow-back"
        d="M12.921,11.04,16.59,7.374a.693.693,0,1,0-.981-.978l-4.156,4.153a.691.691,0,0,0-.02.955l4.173,4.182a.693.693,0,1,0,.981-.978Z"
        transform="translate(-11.251 -6.194)"
        fill="#fff"
      />
    </svg>
  )
}

export const SmallLocation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.496"
      height="15.051"
      viewBox="0 0 12.496 15.051">
      <g
        id="Icon_feather-map-pin"
        data-name="Icon feather-map-pin"
        transform="translate(0.5 0.5)">
        <path
          id="Path_3"
          data-name="Path 3"
          d="M16,7.248c0,4.471-5.748,8.3-5.748,8.3S4.5,11.719,4.5,7.248a5.748,5.748,0,0,1,11.5,0Z"
          transform="translate(-4.5 -1.5)"
          fill="none"
          stroke="#e44a4a"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M17.332,12.416A1.916,1.916,0,1,1,15.416,10.5,1.916,1.916,0,0,1,17.332,12.416Z"
          transform="translate(-9.668 -6.668)"
          fill="none"
          stroke="#e44a4a"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </svg>
  )
}

export const SmallUser = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.32"
      height="15.024"
      viewBox="0 0 26.981 30.836">
      <path
        id="user-graduate"
        d="M19.236,19.308,13.491,24.09,7.745,19.308A8.11,8.11,0,0,0,0,27.945a2.892,2.892,0,0,0,2.891,2.891h21.2a2.892,2.892,0,0,0,2.891-2.891A8.106,8.106,0,0,0,19.236,19.308Zm-6.709,9.6H2.891a.966.966,0,0,1-.964-.964c0-6,5.065-6.619,5.2-6.643l5.4,4.493Zm12.527-.964a.966.966,0,0,1-.964.964H14.454V25.795l5.4-4.493C19.989,21.326,25.054,21.946,25.054,27.945ZM.795,6.023l.41.12V8.408a1.391,1.391,0,0,0-.054,2.409l-.94,3.758c-.1.416.126.843.458.843H3.186c.331,0,.56-.428.458-.843L2.7,10.823A1.391,1.391,0,0,0,2.65,8.414V6.577l2.813.831A8.412,8.412,0,0,0,4.818,10.6a8.673,8.673,0,0,0,17.345,0,8.412,8.412,0,0,0-.644-3.192l4.667-1.385a1.31,1.31,0,0,0,0-2.409L14.508.151a3.515,3.515,0,0,0-2.036,0L.795,3.614A1.31,1.31,0,0,0,.795,6.023Zm12.7,11.322A6.75,6.75,0,0,1,6.745,10.6a6.544,6.544,0,0,1,.578-2.638l5.149,1.53a3.542,3.542,0,0,0,2.036,0l5.149-1.53a6.566,6.566,0,0,1,.578,2.638,6.75,6.75,0,0,1-6.745,6.745Zm-.47-15.352a1.673,1.673,0,0,1,.94,0l9.516,2.825L13.96,7.643a1.673,1.673,0,0,1-.94,0L3.505,4.818l9.516-2.825Z"
        fill="#e44a4a"
      />
    </svg>
  )
}

export const SmallTruck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.32"
      height="10.024"
      viewBox="0 0 14.32 10.024">
      <path
        id="truck-moving"
        d="M13.9,35.878l-1.309-1.309a1.433,1.433,0,0,0-1.014-.418h-1.2V32.716A.715.715,0,0,0,9.666,32H.716A.715.715,0,0,0,0,32.716v7.518A1.786,1.786,0,0,0,3.222,41.3a1.786,1.786,0,0,0,3.222-1.065,1.886,1.886,0,0,0-.036-.358H10.06a1.72,1.72,0,0,0-.036.358,1.79,1.79,0,0,0,3.58,0,1.886,1.886,0,0,0-.036-.358h.394a.359.359,0,0,0,.358-.358V36.889A1.427,1.427,0,0,0,13.9,35.878ZM1.79,41.308a1.074,1.074,0,1,1,1.074-1.074A1.074,1.074,0,0,1,1.79,41.308Zm2.864,0a1.074,1.074,0,1,1,1.074-1.074A1.074,1.074,0,0,1,4.654,41.308ZM9.666,39.16H6.077a1.772,1.772,0,0,0-2.846,0H3.215a1.791,1.791,0,0,0-1.425-.716,1.764,1.764,0,0,0-1.074.367v-6.1h8.95Zm.716-4.3h1.2a.705.705,0,0,1,.506.21L13.307,36.3H10.382Zm1.432,6.444a1.074,1.074,0,1,1,1.074-1.074A1.074,1.074,0,0,1,11.814,41.308ZM13.6,39.16h-.367a1.772,1.772,0,0,0-2.846,0h-.009V37.012H13.6Z"
        transform="translate(0 -32)"
        fill="#e44a4a"
      />
    </svg>
  )
}

export const SmallWatch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.44"
      height="12.587"
      viewBox="0 0 9.44 12.587">
      <path
        id="watch"
        d="M7.867,2.776V.59A.592.592,0,0,0,7.277,0H2.163a.592.592,0,0,0-.59.59V2.776a4.72,4.72,0,0,0,0,7.036V12a.592.592,0,0,0,.59.59H7.277a.592.592,0,0,0,.59-.59V9.812a4.72,4.72,0,0,0,0-7.036ZM2.36.787H7.08V2.205a4.724,4.724,0,0,0-4.72,0ZM7.08,11.8H2.36V10.382a4.724,4.724,0,0,0,4.72,0ZM4.72,10.227A3.933,3.933,0,1,1,8.654,6.294,3.932,3.932,0,0,1,4.72,10.227Zm1.2-2.267L4.447,6.886a.3.3,0,0,1-.12-.238V3.695a.3.3,0,0,1,.3-.3h.2a.3.3,0,0,1,.3.3V6.4l1.273.927a.3.3,0,0,1,.064.413l-.116.16a.294.294,0,0,1-.411.064Z"
        transform="translate(0 0)"
        fill="#e44a4a"
      />
    </svg>
  )
}
export const SmallCalender = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.788"
      height="15.758"
      viewBox="0 0 13.788 15.758">
      <path
        id="calendar-alt"
        d="M12.311,1.97H10.834V.369A.37.37,0,0,0,10.464,0h-.246a.37.37,0,0,0-.369.369v1.6H3.939V.369A.37.37,0,0,0,3.57,0H3.324a.37.37,0,0,0-.369.369v1.6H1.477A1.478,1.478,0,0,0,0,3.447V14.281a1.478,1.478,0,0,0,1.477,1.477H12.311a1.478,1.478,0,0,0,1.477-1.477V3.447A1.478,1.478,0,0,0,12.311,1.97ZM1.477,2.955H12.311a.494.494,0,0,1,.492.492V4.924H.985V3.447A.494.494,0,0,1,1.477,2.955ZM12.311,14.773H1.477a.494.494,0,0,1-.492-.492V5.909H12.8v8.371A.494.494,0,0,1,12.311,14.773ZM4.555,9.849H3.324a.37.37,0,0,1-.369-.369V8.248a.37.37,0,0,1,.369-.369H4.555a.37.37,0,0,1,.369.369V9.479A.37.37,0,0,1,4.555,9.849Zm2.955,0H6.279a.37.37,0,0,1-.369-.369V8.248a.37.37,0,0,1,.369-.369H7.51a.37.37,0,0,1,.369.369V9.479A.37.37,0,0,1,7.51,9.849Zm2.955,0H9.233a.37.37,0,0,1-.369-.369V8.248a.37.37,0,0,1,.369-.369h1.231a.37.37,0,0,1,.369.369V9.479A.37.37,0,0,1,10.464,9.849ZM7.51,12.8H6.279a.37.37,0,0,1-.369-.369V11.2a.37.37,0,0,1,.369-.369H7.51a.37.37,0,0,1,.369.369v1.231A.37.37,0,0,1,7.51,12.8Zm-2.955,0H3.324a.37.37,0,0,1-.369-.369V11.2a.37.37,0,0,1,.369-.369H4.555a.37.37,0,0,1,.369.369v1.231A.37.37,0,0,1,4.555,12.8Zm5.909,0H9.233a.37.37,0,0,1-.369-.369V11.2a.37.37,0,0,1,.369-.369h1.231a.37.37,0,0,1,.369.369v1.231A.37.37,0,0,1,10.464,12.8Z"
        fill="#e44a4a"
      />
    </svg>
  )
}
