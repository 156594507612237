import jsPDF from 'jspdf'
import 'jspdf-autotable'
// Date Fns is used to format the dates we receive
// from our API call
import { format } from 'date-fns'

// define a generatePDF function that accepts a tickets argument
const generatePDF = (StudentReport) => {
  // initialize jsPDF
  //   const doc = new jsPDF();
  var doc = new jsPDF('l', 'mm', 'a4')
  var d = format(new Date(), 'yyyy-MM-dd')
  // define the columns we want and their titles
  const tableColumn = [
    'Name',
    'Email',
    'Phone',
    'Gender',
    'DOB',
    'Address',
    'Join Date',
    'Learning Hrs',
  ]
  // define an empty array of rows
  const tableRows = []

  // for each report pass all its data into an array
  StudentReport.forEach((report) => {
    const studentData = [
      report.firstName + ' ' + report.lastName,
      report.email,
      report.phoneNumber,
      report.gender,
      report.dateOfBirth,
      report.address,
      report.createdDate,
      report.totalTrainingHrs,
      // called date-fns to format the date on the report
    ]

    tableRows.push(studentData)
  })

  // startY is basically margin-top
  doc.autoTable(tableColumn, tableRows, { startY: 20 })
  const date = Date().split(' ')
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4]
  // report title. and margin-top + margin-left
  doc.text('Student Details' + d, 14, 15)
  // we define the name of our PDF file.
  doc.save(`Student_Details_${d}.pdf`)
}

export default generatePDF
