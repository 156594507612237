import React, { useEffect, Fragment } from 'react'
import {
  FormGroup,
  Card,
  Form,
  Row,
  Label,
  Input,
  FormFeedback,
  Col,
  Button,
} from 'reactstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'
export default function ManualTimer(props) {
  const { GetTime, toogle } = props

  const CreateManualTimer = async (values) => {
    let diff = moment(values.endTime, 'HH:mm').diff(
      moment(values.startTime, 'HH:mm')
    )
    let d = moment.duration(diff)
    let hours = Math.floor(d.asHours())
    let minutes = moment.utc(diff).format('mm')
    let total = hours + ':' + minutes

    GetTime({ ...values, time: total })
  }

  const validationSchema = Yup.object().shape({
    startTime: Yup.string().required('Starting time  Required'),
    endTime: Yup.string()
      .required('Ending time Required')
      .test(
        'Check',
        'Ending  time must greater than  Start time',
        function (value) {
          const Indate = this.parent.startTime?.replace(':', '')
          const outDate = value?.replace(':', '')
          return Indate < outDate
        }
      ),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: { startTime: '', endTime: '', time: '' },
    validationSchema,
    onSubmit: (values) => CreateManualTimer(values),
  })
  const flag = !!Object.entries(values).filter((i) => i[1] != '').length

  useEffect(() => {
    if (!flag) {
      toogle(false)
    } else {
      toogle(true)
    }
  }, [flag])

  const reSet = () => {
    resetForm({})
  }
  console.log(errors)
  return (
    <Fragment>
      <Card body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="4" xl="6">
              <FormGroup>
                <Label for="">Start time</Label>
                <Input
                  type="time"
                  name="startTime"
                  id="startTime"
                  placeholder=""
                  value={values.startTime}
                  invalid={errors.startTime && touched.startTime ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormFeedback>
                  {errors.startTime ? errors.startTime : ''}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="4" xl="6">
              <FormGroup>
                <Label for="">End time</Label>
                <Input
                  type="time"
                  name="endTime"
                  id="endTime"
                  placeholder=""
                  value={values.endTime}
                  invalid={errors.endTime && touched.endTime ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormFeedback>
                  {errors.endTime ? errors.endTime : ''}
                </FormFeedback>
              </FormGroup>
            </Col>
            {/* <Col md="4" xl="3">
              <FormGroup>
                <Label for="">Seconds</Label>
                <Input
                  type="number"
                  name="seconds"
                  id="seconds"
                  placeholder=""
                  value={values.seconds}
                  invalid={errors.seconds && touched.seconds ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormFeedback>
                  {errors.seconds && touched.seconds ? errors.seconds : ''}
                </FormFeedback>
              </FormGroup>
            </Col> */}
          </Row>
          <div className="btn-center">
            <Row>
              <Col>
                <Button color="primary" className="pull-left">
                  Record
                </Button>
              </Col>
              <Col>
                {flag && (
                  <Button color="danger" onClick={reSet}>
                    Reset
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Form>
      </Card>
    </Fragment>
  )
}
