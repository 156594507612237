import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Table,
} from 'reactstrap'
import { CustomMessage, sorting } from '../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import Rollcallpdf from '../admin/rollover/Rollcallpdf'
import { CSVLink } from 'react-csv'
import { useHistory, useParams } from 'react-router'
import CustomPageLoading from '../../function/CustomPageLoading'
import {
  GET_ATTENDENCEREP,
  INSTRUCTOR_DASHBOARD,
  LIST_STATION,
  STUDENT_LIST,
  STUDENT_REPS,
} from '../../constants/Apipath'
import { authHeader, getUserId } from '../../function/Mainfunction'
import moment from 'moment'
import API from '../../constants/API'
import {
  INSTRUCTOR_HOME_ROUTE,
  INSTRUCTOR_ROLLCALL,
  ROLLCALL,
} from '../../constants/RoutePaths'
import Select, { components } from 'react-select'
import AttendenceButtonDropdown from '../admin/rollover/AttendenceButtonDropdown'
import Listmodal from '../admin/rollover/Listmodal'
const STU_ = 'stuId_'
const InstructorRolloverView = () => {
  const [dpsSwitch, setdpsSwitch] = useState(true)
  const [loading, setloading] = useState(false)
  const [orderListvalue, setorderListvalue] = useState('LA')
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const [rollcallTimeflag, setrollcallTimeflag] = useState(false)
  const [Attendence, setAttendence] = useState({})
  const [stationList, setStationList] = useState([])
  const SwitchHandle = () => setdpsSwitch((val) => !val)
  const [studentModal, setstudentModal] = useState(false)
  const studenttoggle = () => setstudentModal(!studentModal)
  const [downloadFlag, setdownloadFlag] = useState(false)
  const [studentList, setstudentList] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [station, setstation] = useState('')
  const history = useHistory()
  const { date } = useParams()

  const OrderList = (e) => {
    setorderListvalue(e.target.value)
  }
  const exportToggle = () => {
    setdropdownOpen(!dropdownOpen)
  }

  const Exportrollcallpdf = () => {
    if (rollcallTimeflag) {
      CustomMessage(
        'Submit the data before get  report',
        'error',
        enqueueSnackbar
      )

      return false
    }
    Rollcallpdf(Attendence, orderListvalue)
  }
  let csvReport = [
    [
      'Student name',
      'Road',
      // 'Roll call Time',
      // 'Station Instructor',
      // 'Drive Instructor',
      // 'Station',
      'AM',
      // 'AM Time stamp',
      'PM',
      // 'PM Time stamp',
      'Noon',
      // 'Noon Time stamp',
    ],
  ]
  Object.values(Attendence)
    .sort((a, b) => sorting(a, b, orderListvalue))
    .forEach((rec, index) => {
      const {
        firstName,
        lastName,
        pm,
        road,
        noon,
        rollCallTime,
        stationInstructor,
        driveInstructor,
        stationId,
        am,
        noonTimeStamp,
        pmTimeStamp,
        amTimeStamp,
      } = rec

      csvReport.push([
        firstName + ' ' + lastName || 'N/A',
        road || 'N/A',
        // rollCallTime || 'N/A',
        // stationInstructor?.label || 'N/A',
        // driveInstructor?.label || 'N/A',
        // stationId?.label || 'N/A',
        am || 'N/A',
        // amTimeStamp,
        pm || 'N/A',
        // pmTimeStamp,
        noon || 'N/A',
        // noonTimeStamp,
      ])
    })
  const getRollcall = async () => {
    const config = {
      method: 'GET',
      url: GET_ATTENDENCEREP,
      headers: authHeader(),
      params: {
        attendanceDate: moment(date).format('YYYY/MM/DD'),
        dpsAttendanceDate: moment(date).format('YYYY/MM/DD'),
      },
    }

    setloading(true)
    try {
      const response = await API(config)
      const { status, data, dbsData } = response.data
      console.log('resp', response.data)
      if (status === 'Success') {
        setdownloadFlag(true)
        let NewAttendence = {}
        data.forEach((at) => {
          // var Listing = stationList.find((item) => item.label == at.road)
          NewAttendence[STU_ + at.userId] = {
            firstName: at.firstName,
            lastName: at.lastName,
            attendanceId: at.attendanceId,
            userId: at.userId,
            road: at.road,
            // road: { label: 'April 11th', value: 41 },
            am: at.am,
            pm: at.pm,
            // noonTimeStamp: at.noonTimeStamp,
            // pmTimeStamp: at.pmTimeStamp,
            // amTimeStamp: at.amTimeStamp,
            // rollCallTime: at.rollCallTime || '',
            noon: at.noon,
            isDeleted: false,
          }
        })

        setAttendence(NewAttendence)
        let newdpstest = {}
        dbsData.forEach(
          (dps) =>
            (newdpstest[STU_ + dps.userId] = {
              attendanceId: dps.attendanceId,
              firstName: dps.firstName,
              lastName: dps.lastName,
              userId: dps.userId,
              status: dps.status,
              isDeleted: false,
            })
        )
        // setdpsTestAttendence(newdpstest)

        setTimeout(() => {
          setdownloadFlag(false)
        }, 2000)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    getRollcall()
  }, [])

  const fromSubmit = async () => {
    const config = {
      method: 'POST',
      url: STUDENT_REPS,
      headers: authHeader(), // data: reqData,
      data: {
        createdBy: getUserId(),
        attendanceDate: moment(date).format('YYYY/MM/DD'),
        repsRoleAttendanceDetails: Object.values(Attendence).map((stu) => ({
          attendanceId: stu.attendanceId,
          userId: stu.userId,
          road: stu.road,
          am: stu.am || '',
          pm: stu.pm || '',
          noon: stu.noon || '',
          isDeleted: false,
        })),
      },
    }
    {
      console.log(config)
    }
    setloading(true)
    try {
      const response = await API(config)
      const { data, status, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar)
        // getRollcall()
        setrollcallTimeflag(false)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar)
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }
  const goBack = () => {
    history.push(
      `${INSTRUCTOR_ROLLCALL}/${moment(new Date()).format('MM-DD-YYYY')}`
    )
  }
  const getStationList = async (values) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_STATION,
      params: {
        stationName: values,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setStationList(
          data.map((d) => ({
            value: d.stationId,
            label: d.stationName,
            isNew: false,
          }))
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }
  useEffect(() => {
    getStationList()
  }, [])
  const handleChangeStation = (e, userId) => {
    console.log(e, userId, Attendence)
    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userId]['road'] = e.label
    setAttendence(statusupdate)
    //  setSelectedStation((e.target.name = e?.label || '')
  }
  const stationNamehandle = (text) => {
    console.log(text)
    setstation(text)
  }
  const setabsent = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'absent'
    setAttendence(statusupdate)
  }

  const setpremesstion = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'excused'
    setAttendence(statusupdate)
  }
  const setout = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'unmarked'
    setAttendence(statusupdate)
  }
  const setpresent = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'present'
    setAttendence(statusupdate)
  }

  const attendecedata = {
    present: { status: 'Present', onclick: setabsent },
    absent: { status: 'Absent', onclick: setpremesstion },
    excused: { status: 'Excused', onclick: setout },
    unmarked: { status: 'Unmarked', onclick: setpresent },
  }
  const getStudent = async () => {
    const config = {
      method: 'GET',
      url: STUDENT_LIST,
      headers: authHeader(),
      params: {
        studentStatus: 'Active',
        count: 1000,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status === 'Success') {
        setstudentList(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }
  useEffect(() => {
    getStudent()
  }, [])
  const successfullcall = () => {
    getRollcall()
  }
  // select.find((e) => e.label == road)
  const formatOptionLabel = ({ children, ...props }) => {
    const { data } = props
    return (
      <>
        {data.isNew ? (
          ''
        ) : (
          <components.Option {...props}>{children}</components.Option>
        )}
      </>
    )
  }
  return (
    <div className="wrapper">
      {loading && <CustomPageLoading />}
      <div className="page-title">
        <h4> General Roll call </h4>
        <div className="ml-auto">
          <Button onClick={SwitchHandle} color="primary">
            General Roll call
          </Button>
        </div>
        <div className="btn-right">
          <Input value={orderListvalue} onChange={OrderList} type="select">
            <option value={'FA'}>First Name Ascending </option>
            <option value={'LA'}>Last Name Ascending </option>
            <option value={'FD'}>First Name Descending </option>
            <option value={'LD'}>Last Name Descending </option>
          </Input>
          <Dropdown isOpen={dropdownOpen} toggle={exportToggle}>
            <DropdownToggle color="danger" caret>
              Export
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={Exportrollcallpdf}>PDF</DropdownItem>
              <DropdownItem>
                <CSVLink
                  onClick={() => {
                    if (rollcallTimeflag) {
                      CustomMessage(
                        'Submit the data before get  report',
                        'error',
                        enqueueSnackbar
                      )

                      return false
                    }
                  }}
                  filename={`${date}_attendencerollcall.csv`}
                  data={csvReport}>
                  CSV
                </CSVLink>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {/* {currentActive !== 'rollcalladmin' && (
            <> */}
          <Button onClick={studenttoggle} color="danger">
            Edit
          </Button>
          <Button onClick={fromSubmit} color="danger">
            Submit
          </Button>
          <Button onClick={goBack} color="danger">
            Back
          </Button>
        </div>
      </div>
      <>
        <Container>
          <Card body>
            <Table className="rollcall_table" responsive>
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Road</th>
                  {/* <th>Station Instructor</th>
                    <th>Drive Instructor</th>
                    <th>Station</th>
                    <th>Time stamp</th> */}
                  <th>AM</th>
                  <th>Noon</th>
                  <th>PM</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(Attendence)
                  .sort((a, b) => sorting(a, b, orderListvalue, Attendence))
                  .map((rec, index) => {
                    const { road, am, pm, noon } = rec
                    let select = stationList
                    select.push({
                      value: road,
                      label: road,
                      isNew: true,
                    })
                    return (
                      <tr>
                        <td>{rec.firstName + ' ' + rec.lastName}</td>
                        {console.log('sssss', stationList)}
                        <td
                          style={{ width: '210px', paddingRight: '50px' }}
                          key={'key_' + stationList.length}>
                          <Select
                            // name={index}
                            options={select}
                            components={{ Option: formatOptionLabel }}
                            isClearable={false}
                            defaultValue={select.find((e) => e.label == road)}
                            onChange={(e) => handleChangeStation(e, rec.userId)}
                            onInputChange={stationNamehandle}
                          />
                        </td>
                        <td>
                          <AttendenceButtonDropdown
                            userid={rec.userId}
                            data={attendecedata}
                            time={'am'}
                            value={am || ''}
                          />
                        </td>
                        <td>
                          <AttendenceButtonDropdown
                            userid={rec.userId}
                            data={attendecedata}
                            time={'noon'}
                            value={noon || ''}
                          />
                        </td>{' '}
                        <td>
                          <AttendenceButtonDropdown
                            userid={rec.userId}
                            data={attendecedata}
                            time={'pm'}
                            value={pm || ''}
                          />
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </Card>
          <Listmodal
            date={date}
            data={studentList}
            successfullcall={successfullcall}
            toggle={studenttoggle}
            modal={studentModal}
            student={Attendence}
          />
        </Container>
      </>
    </div>
  )
}

export default InstructorRolloverView
