import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import 'react-datepicker/dist/react-datepicker.css'
import CloseIcon from '@material-ui/icons/Close'
import { Button } from '@material-ui/core'
import ConfigureStore from './redux/store/ConfigureStore'
import { Provider } from 'react-redux'
import './scss/font.css'
const notistackRef = React.createRef()
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key)
}
const store = ConfigureStore()
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider
        ref={notistackRef}
        action={(key) => (
          <Button onClick={onClickDismiss(key)}>
            {' '}
            <CloseIcon />
          </Button>
        )}
        maxSnack={1}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
