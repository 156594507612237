import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import './App.scss'
import PageNotFound from './components/PageNotFount'
import Signin from './components/Signin'

import AdminIndex from './components/admin/layout'
//
import {
  SIGNIN_ROUTE,
  PAGE_NOT_FOUND_ROUTE,
  INITIAL_ROUTE,
  INSTRUCTOR_HOME_ROUTE,
  ADMIN_ROUTE,
  ADMIN_DASHBOARD,
  INSTRCTOR_ROUT,
  FORGET_PASSWORD,
  ROLLCALL_VIEW,
  ROLLCALL_INITIAL,
  ROLLCALL,
} from './constants/RoutePaths'

import InstructorDashboard from './components/instructor/index'
import RollcallDashboard from './components/rollcall'
import { PublicRoute, PrivateRoute, authRole } from './function/Mainfunction'
import Forgetpassword from './components/Forgetpassword'
function App() {
  const RouteDash = {
    1: ADMIN_DASHBOARD,
    2: ADMIN_DASHBOARD,
    5: ROLLCALL,
  }
  return (
    <div className="show-sidebar">
      <Switch>
        <PublicRoute exact path={SIGNIN_ROUTE} component={Signin} />
        <PublicRoute exact path={FORGET_PASSWORD} component={Forgetpassword} />
        <PrivateRoute path={ADMIN_ROUTE} component={AdminIndex} />
        {/* instructor */}
        {console.log(authRole(), 'tttt', RouteDash[authRole()])}
        <Route
          exact
          path={INITIAL_ROUTE}
          render={() => (
            <Redirect to={RouteDash[authRole()] || INSTRUCTOR_HOME_ROUTE} />
          )}
        />
        <PrivateRoute path={INSTRCTOR_ROUT} component={InstructorDashboard} />
        <PrivateRoute path={ROLLCALL_INITIAL} component={RollcallDashboard} />
        <Route path={PAGE_NOT_FOUND_ROUTE} component={PageNotFound} />
      </Switch>
    </div>
  )
}

export default App
