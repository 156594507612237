import React, { Fragment, useEffect, useState } from 'react'
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import classnames from 'classnames'
import { StateValue } from '../../constants/Helper'
import { useLocation, useHistory } from 'react-router'
import { useSnackbar } from 'notistack'
import Pagination from 'react-js-pagination'
import { BATCH_HISTORY } from '../../constants/Apipath'
import { authHeader, getUserId } from '../../function/Mainfunction'
import { CustomMessage } from '../../function/Helperfunction'
import API from '../../constants/API'
import BatchModel from '../../function/BatchModel'
import { TableLoader } from '../../function/Loader'
import noData from '../../images/no-data.svg'

const BatchIntial = {
  activeTab: '1',
  offset: '0',
}
export default function Batch() {
  const location = useLocation()
  const [loading, setloading] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const { enqueueSnackbar } = useSnackbar()
  const [todayCurrentpage, settodayCurrentpage] = useState(1)
  const [tomarrowCurrentpage, settomarrowCurrentpage] = useState(1)
  const [historyCurrentpage, sethistoryCurrentpage] = useState(1)
  const [TodayBatch, setTodayBatch] = useState([])
  const [TodayCount, setTodayCount] = useState('')
  const [tomarrowBatch, settomarrowBatch] = useState([])
  const [TomarrowCount, setTomarrowCount] = useState('')
  const [historyCount, sethistoryCount] = useState('')
  const [historyBatch, sethistoryBatch] = useState([])
  const GetBatch = async () => {
    const config = {
      method: 'GET',
      url: BATCH_HISTORY,
      headers: authHeader(),
      params: {
        searchDate: 'today',
        instructorId: getUserId(),
        offset: todayCurrentpage - 1,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        setTodayBatch(data)
        setTodayCount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const handleTodayPageChange = (page) => {
    settodayCurrentpage(page)
  }
  const handleTomarrowPageChange = (page) => {
    settomarrowCurrentpage(page)
  }
  const handleHistoryPageChange = (page) => {
    sethistoryCurrentpage(page)
  }

  const GetTomarrowBatch = async () => {
    const config = {
      method: 'GET',
      url: BATCH_HISTORY,
      headers: authHeader(),
      params: {
        searchDate: 'tomorrow',
        instructorId: getUserId(),
        offset: tomarrowCurrentpage - 1,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        settomarrowBatch(data)
        setTomarrowCount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const GetHistoryBatch = async () => {
    const config = {
      method: 'GET',
      url: BATCH_HISTORY,
      headers: authHeader(),
      params: {
        searchDate: 'history',
        instructorId: getUserId(),
        offset: historyCurrentpage - 1,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        sethistoryBatch(data)
        sethistoryCount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  useEffect(() => {
    GetHistoryBatch()
  }, [historyCurrentpage])
  useEffect(() => {
    GetTomarrowBatch()
  }, [tomarrowCurrentpage])
  useEffect(() => {
    GetBatch()
  }, [todayCurrentpage])

  return (
    <Fragment>
      <div className="page-title">
        <h4>My batch</h4>
      </div>

      <Container fluid={true}>
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1')
                }}>
                Today ({TodayCount})
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2')
                }}>
                Tomorrow ({TomarrowCount})
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  toggle('3')
                }}>
                History ({historyCount})
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {loading && <TableLoader />}
              {!loading && !TodayBatch.length && (
                <div className="nodata-sec">
                  <img src={noData} />
                </div>
              )}
              {!loading && (
                <BatchModel
                  batchDetails={TodayBatch}
                  hideActionButtons={true}
                />
              )}

              <div className="d-flex flex-row py-4 justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={todayCurrentpage}
                  itemsCountPerPage={10}
                  totalItemsCount={TodayCount}
                  pageRangeDisplayed={5}
                  onChange={handleTodayPageChange}
                />
              </div>
            </TabPane>
            <TabPane tabId="2">
              {loading && <TableLoader />}
              {!loading && !tomarrowBatch.length && (
                <div className="nodata-sec">
                  <img src={noData} />
                </div>
              )}

              {!loading && (
                <BatchModel
                  batchDetails={tomarrowBatch}
                  hideActionButtons={true}
                />
              )}

              <div className="d-flex flex-row py-4 justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={tomarrowCurrentpage}
                  itemsCountPerPage={10}
                  totalItemsCount={TomarrowCount}
                  pageRangeDisplayed={5}
                  onChange={handleTomarrowPageChange}
                />
              </div>
            </TabPane>
            <TabPane tabId="3">
              {loading && <TableLoader />}
              {!loading && !historyBatch.length && (
                <div className="nodata-sec">
                  <img src={noData} />
                </div>
              )}

              {!loading && (
                <BatchModel
                  history={false}
                  batchDetails={historyBatch}
                  hideActionButtons={true}
                />
              )}

              <div className="d-flex flex-row py-4 justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={historyCurrentpage}
                  itemsCountPerPage={10}
                  totalItemsCount={historyCount}
                  pageRangeDisplayed={5}
                  onChange={handleHistoryPageChange}
                />
              </div>
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </Fragment>
  )
}
