import React, { Fragment, useState, useRef, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  Container,
  Button,
  Card,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  CustomInput,
  Form,
  FormFeedback,
} from 'reactstrap'
import API from '../../../constants/API'
import { useSnackbar } from 'notistack'
import {
  CustomMessage,
  CustomPageLoading,
} from '../../../function/Helperfunction'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { NUMBER_REGEX, PHONE_NUMBER_REGEX } from '../../../constants/Regex'
import moment from 'moment'
import {
  REFEREDBY_FILTER_URL,
  UPDATE_STUDENT_DETAILS,
  STUDENT_DETAILS,
} from '../../../constants/Apipath'
import { authHeader } from '../../../function/Mainfunction'
import Select from 'react-select'
import { STUDENT_ROUTE } from '../../../constants/RoutePaths'
import { debounce } from 'underscore'

export default function CreateStudent() {
  const history = useHistory()
  const { studentId } = useParams()
  const [loading, setloading] = useState(false)
  const [referOptions, setReferOptions] = useState([])
  const selectInputRefer = useRef()
  const [selectRefer, setselectRefer] = useState('')
  const [userId, setUserId] = useState('')
  const [formData, setformData] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const Goback = () => {
    history.goBack()
  }

  useEffect(() => {
    getStudentDetails()
  }, [])

  const getStudentDetails = async () => {
    const config = {
      method: 'GET',
      url: STUDENT_DETAILS,
      headers: authHeader(),
      params: {
        studentId: studentId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      console.info(response, 'response')
      const { data } = response.data
      setformData({ ...data, studentId: data.userId })
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.warn(data)
      }
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const getreferId = async () => {
    const config = {
      headers: authHeader(),
      method: 'GET',
      url: REFEREDBY_FILTER_URL,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      setReferOptions(
        data.map((d) => ({ value: d.userId, label: d.firstName }))
      )
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.warn(data)
      }
    } finally {
      setloading(false)
    }
  }

  const OptionHandleRefer = (e) => {
    if (e !== null) {
      const { value } = e
      setUserId(value)
      setFieldValue('referedBy', value)
    } else {
      setselectRefer('')
      setUserId('')
    }
  }
  const OptionInputHandleRefer = (text) => {
    setselectRefer(text)
  }

  const ReferTypeSearch = useRef(
    debounce((selectRefer, userId) => getreferId(selectRefer, userId))
  ).current

  useEffect(() => {
    //callList();
    ReferTypeSearch(selectRefer, userId)
  }, [selectRefer, userId])

  const UpdateStudent = async (values) => {
    delete values.driveDetails
    delete values.incabDetails
    delete values.maneuverDetails
    delete values.preTripDetails
    delete values.preTripDetails
    delete values.repsRoleAttendanceDetails

    const config = {
      method: 'PUT',
      url: UPDATE_STUDENT_DETAILS,
      headers: authHeader(),
      data: values,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status } = response.data
      if (status === 'Success') {
        CustomMessage(
          'Student Updated   Successfully',
          'success',
          enqueueSnackbar
        )
        history.push(STUDENT_ROUTE)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar)
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is Required'),
    lastName: Yup.string().required('Last name is Required'),
    email: Yup.string().email('invalid email').required('Email Required'),
    phoneNumber: Yup.string()
      .matches(PHONE_NUMBER_REGEX, 'Phone number is not valid')
      .max(10, 'Phone number is not valid')
      .required('Phone number Required'),
    // state: Yup.string().required('State is Required'),
    gender: Yup.string().required('Gender is Required'),
    dateOfBirth: Yup.string()
      .max(new Date(), 'Invalid date')
      .required('Date of birth is Required'),
    // entrollmentDate: Yup.string().required('Entrollment date is Required'),
    // entrollContract: Yup.string().required('Entroll contract is Required'),
    // permitIssueDate: Yup.string().required('PermitIssue date is Required'),
    // startDate: Yup.string().required('Start date is Required'),
    // medicalCardExpDate: Yup.string().required(
    //   'Medical expiry date is Required'
    // ),
    // address: Yup.string().required('Address is Required'),
    // zip: Yup.string()
    //   .max(6, 'Invalid Zip code')
    //   .matches(NUMBER_REGEX, 'Invalid Zip code')
    //   .required('Zip Required'),
    downPayment: Yup.number().test(
      'Is positive?',
      'Invalid',
      (value) => value >= 0
    ),
    // alternatePhone: Yup.string()
    //   .matches(PHONE_NUMBER_REGEX, 'Alternate phone is not valid')
    //   .max(10, 'Alternate phone is not valid')
    //   .required('Alternate Phone is Required'),
    // driversLicense: Yup.string().required('Drivers License is Required'),
    // fundingSource: Yup.string().required('Funding Source is Required'),
    // referedBy: Yup.string()
    //   .required('Refered By Source is Required')
    //   .nullable(),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => UpdateStudent(values),
  })

  const RadioHandle = (e) => {
    const { name, value } = e.target
    console.log(value)
    setValues({ ...values, [name]: value == 'true' ? true : false })
  }

  return (
    <Fragment>
      <div className="wrapper">
        {loading && <CustomPageLoading />}
        <div className="page-title">
          <h4>Update Student</h4>
          <div style={{ flexDirection: 'row' }} className="ml-auto">
            <FormGroup>
              <Input
                type="select"
                name="studentStatus"
                id="studentStatus"
                value={values.studentStatus}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="Active">Active</option>
                <option value="Graduated">Graduated</option>
                <option value="Dropped">Dropped</option>
                <option value="Hold">Hold</option>
                <option value="No permit">No permit</option>
              </Input>
            </FormGroup>
          </div>
          <div className="btn-right">
            <Button onClick={Goback} color="primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.543"
                height="8.695"
                viewBox="0 0 5.543 9.695">
                <path
                  id="Icon_ionic-ios-arrow-back"
                  data-name="Icon ionic-ios-arrow-back"
                  d="M12.921,11.04,16.59,7.374a.693.693,0,1,0-.981-.978l-4.156,4.153a.691.691,0,0,0-.02.955l4.173,4.182a.693.693,0,1,0,.981-.978Z"
                  transform="translate(-11.251 -6.194)"
                  fill="#fff"
                />
              </svg>
              Back
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <Card body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="firstName">First name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="Enter first name"
                      value={values.firstName}
                      invalid={
                        errors.firstName && touched.firstName ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.firstName && touched.firstName
                        ? errors.firstName
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Last name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Enter last name"
                      value={values.lastName}
                      invalid={
                        errors.lastName && touched.lastName ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.lastName && touched.lastName
                        ? errors.lastName
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Email address</Label>
                    <Input
                      type="email"
                      name="email"
                      disabled={true}
                      id=""
                      placeholder="Enter email"
                      value={values.email}
                      invalid={errors.email && touched.email ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.email && touched.email ? errors.email : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Gender</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio"
                        label="Male"
                        name="gender"
                        value={'male'}
                        onChange={handleChange}
                        checked={values.gender === 'male'}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio2"
                        label="Female"
                        name="gender"
                        value={'female'}
                        onChange={handleChange}
                        checked={values.gender === 'female'}
                      />
                    </Row>
                    <FormFeedback style={{ display: errors.gender && 'block' }}>
                      {touched.gender && errors.gender}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">DOB</Label>
                    <Input
                      type="date"
                      name="dateOfBirth"
                      id="dateOfBirth"
                      value={moment(values.dateOfBirth).format('YYYY-MM-DD')}
                      onBlur={handleBlur}
                      invalid={
                        touched.dateOfBirth && errors.dateOfBirth ? true : false
                      }
                      onChange={handleChange}
                      placeholder="Enter DOB"
                    />
                    <FormFeedback>
                      {touched.dateOfBirth && errors.dateOfBirth}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Phone number</Label>
                    <Input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="Enter phone number"
                      value={values.phoneNumber}
                      invalid={
                        errors.phoneNumber && touched.phoneNumber ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.phoneNumber && touched.phoneNumber
                        ? errors.phoneNumber
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Address</Label>
                    <Input
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Enter addres"
                      value={values.address}
                      invalid={errors.address && touched.address ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.address && touched.address ? errors.address : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">City</Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="Enter city"
                      value={values.city}
                      invalid={errors.city && touched.city ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.city && touched.city ? errors.city : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">State</Label>
                    <Input
                      type="text"
                      name="state"
                      id="state"
                      placeholder="Enter state"
                      value={values.state}
                      invalid={errors.state && touched.state ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.state && touched.state ? errors.state : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Zip</Label>
                    <Input
                      type="text"
                      name="zip"
                      id="zip"
                      placeholder="Enter zip"
                      value={values.zip}
                      invalid={errors.zip && touched.zip ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.zip && touched.zip ? errors.zip : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Alternate phone</Label>
                    <Input
                      type="text"
                      name="alternatePhone"
                      id="alternatePhone"
                      placeholder="Enter alternate phone"
                      value={values.alternatePhone}
                      invalid={
                        errors.alternatePhone && touched.alternatePhone
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.alternatePhone && touched.alternatePhone
                        ? errors.alternatePhone
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Drivers license</Label>
                    <Input
                      type="text"
                      name="driversLicense"
                      id="driversLicense"
                      placeholder="Enter driver license"
                      value={values.driversLicense}
                      invalid={
                        errors.driversLicense && touched.driversLicense
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.driversLicense && touched.driversLicense
                        ? errors.driversLicense
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Down payment</Label>
                    <Input
                      type="number"
                      name="downPayment"
                      id="downPayment"
                      placeholder=""
                      value={values.downPayment}
                      invalid={
                        errors.downPayment && touched.downPayment ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.downPayment && touched.downPayment
                        ? errors.downPayment
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Funding source</Label>
                    <Input
                      type="text"
                      name="fundingSource"
                      id="fundingSource"
                      placeholder="Enter Funding source"
                      value={values.fundingSource}
                      invalid={
                        errors.fundingSource && touched.fundingSource
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.fundingSource && touched.fundingSource
                        ? errors.fundingSource
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Recruiter</Label>
                    <Input
                      type="text"
                      name="recruiter"
                      id="recruiter"
                      placeholder="Enter recruiter"
                      value={values.recruiter}
                      invalid={
                        errors.recruiter && touched.recruiter ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.recruiter && touched.recruiter
                        ? errors.recruiter
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">ReferedBy</Label>
                    <Input
                      type="text"
                      name="referedBy"
                      id="Referred By"
                      placeholder="Enter Referred By"
                      value={values.referedBy}
                      invalid={
                        errors.referedBy && touched.referedBy ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.referedBy && touched.referedBy
                        ? errors.referedBy
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup id="Custom">
                    <Label for="">Student type</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio33"
                        label="Weekend"
                        name="isWeekendStudent"
                        value={true}
                        onChange={RadioHandle}
                        checked={values.isWeekendStudent == true}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio44"
                        name="isWeekendStudent"
                        label="Weekdays"
                        value={false}
                        onChange={RadioHandle}
                        checked={values.isWeekendStudent == false}
                      />
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Copy of DL</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio3"
                        label="Yes"
                        name="copyOfDL"
                        value={true}
                        onChange={RadioHandle}
                        checked={values.copyOfDL == true}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio4"
                        label={'No'}
                        name="copyOfDL"
                        value={false}
                        onChange={RadioHandle}
                        checked={values.copyOfDL === false}
                      />
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="isPayed"
                        checked={values.isPayed}
                        onChange={handleChange}
                        type="checkbox"
                      />{' '}
                      Is Payed
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="mvrBackground"
                        checked={values.mvrBackground}
                        onChange={handleChange}
                        type="checkbox"
                      />{' '}
                      mvr Background
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="uaResults"
                        checked={values.uaResults}
                        onChange={handleChange}
                        type="checkbox"
                      />{' '}
                      Ua Results
                    </Label>
                  </FormGroup>
                </Col>{' '}
                <Col md="4" xl="3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="proofOfRes"
                        checked={values.proofOfRes}
                        onChange={handleChange}
                        type="checkbox"
                      />{' '}
                      Proof Of Res
                    </Label>
                  </FormGroup>
                </Col>{' '}
                <Col md="4" xl="3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="agencyDoc"
                        checked={values.agencyDoc}
                        onChange={handleChange}
                        type="checkbox"
                      />{' '}
                      Agency Doc
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="tribeDesc"
                        checked={values.tribeDesc}
                        onChange={handleChange}
                        type="checkbox"
                      />{' '}
                      Tribe Desc
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="qaSchoolApp"
                        checked={values.qaSchoolApp}
                        onChange={handleChange}
                        type="checkbox"
                      />{' '}
                      Qa School App
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Copy of SS</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio5"
                        label="Yes"
                        name="copyOfSS"
                        value={true}
                        onChange={RadioHandle}
                        checked={values.copyOfSS === true}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio6"
                        label={'No'}
                        name="copyOfSS"
                        value={false}
                        onChange={RadioHandle}
                        checked={values.copyOfSS === false}
                      />
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Copy of permit</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio7"
                        label="Yes"
                        name="copyOfPermit"
                        value={true}
                        onChange={RadioHandle}
                        checked={values.copyOfPermit === true}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio8"
                        label={'No'}
                        name="copyOfPermit"
                        value={false}
                        onChange={RadioHandle}
                        checked={values.copyOfPermit === false}
                      />
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Permit issue date</Label>
                    <Input
                      type="date"
                      name="permitIssueDate"
                      id="permitIssueDate"
                      placeholder=""
                      value={moment(values.permitIssueDate).format(
                        'YYYY-MM-DD'
                      )}
                      onBlur={handleBlur}
                      invalid={
                        touched.permitIssueDate && errors.permitIssueDate
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      placeholder="Enter permit issue date"
                    />
                    <FormFeedback>
                      {touched.permitIssueDate && errors.permitIssueDate}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Medical card</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio9"
                        label="Yes"
                        name="medicalCard"
                        value={true}
                        onChange={RadioHandle}
                        checked={values.medicalCard === true}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio10"
                        label={'No'}
                        name="medicalCard"
                        value={false}
                        onChange={RadioHandle}
                        checked={values.medicalCard === false}
                      />
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Medical card exp date</Label>
                    <Input
                      type="date"
                      name="medicalCardExpDate"
                      id="medicalCardExpDate"
                      placeholder=""
                      value={moment(values.medicalCardExpDate).format(
                        'YYYY-MM-DD'
                      )}
                      onBlur={handleBlur}
                      invalid={
                        touched.medicalCardExpDate && errors.medicalCardExpDate
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      placeholder=""
                    />
                    <FormFeedback>
                      {touched.medicalCardExpDate && errors.medicalCardExpDate}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Tribal docs</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio11"
                        label="Yes"
                        name="tribalDocs"
                        value={true}
                        onChange={RadioHandle}
                        checked={values.tribalDocs === true}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio12"
                        label={'No'}
                        name="tribalDocs"
                        value={false}
                        onChange={RadioHandle}
                        checked={values.tribalDocs === false}
                      />
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Counselor</Label>
                    <Input
                      type="text"
                      name="counselor"
                      id="counselor"
                      placeholder="Enter Counselor"
                      value={values.counselor}
                      invalid={
                        errors.counselor && touched.counselor ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.counselor && touched.counselor
                        ? errors.counselor
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Enrollment date</Label>
                    <Input
                      type="date"
                      name="entrollmentDate"
                      id="entrollmentDate"
                      placeholder=""
                      value={moment(values.entrollmentDate).format(
                        'YYYY-MM-DD'
                      )}
                      onBlur={handleBlur}
                      invalid={
                        touched.entrollmentDate && errors.entrollmentDate
                          ? true
                          : false
                      }
                      onChange={handleChange}
                    />
                    <FormFeedback>
                      {touched.entrollmentDate && errors.entrollmentDate}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Enroll contract</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio15"
                        label="Yes"
                        name="entrollContract"
                        value={true}
                        onChange={RadioHandle}
                        checked={values.entrollContract === true}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio16"
                        label={'No'}
                        name="enrollContract"
                        value={false}
                        onChange={RadioHandle}
                        checked={values.entrollContract === false}
                      />
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">DPS permit policy</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio17"
                        label="Yes"
                        name="dpsPemitPolicy"
                        value={true}
                        onChange={RadioHandle}
                        checked={values.dpsPemitPolicy === true}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio18"
                        label={'No'}
                        name="dpsPemitPolicy"
                        value={false}
                        onChange={RadioHandle}
                        checked={values.dpsPemitPolicy === false}
                      />
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Birth certificate</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio19"
                        label="Yes"
                        name="birthCertificate"
                        value={true}
                        onChange={RadioHandle}
                        checked={values.birthCertificate === true}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio20"
                        label={'No'}
                        name="birthCertificate"
                        value={false}
                        onChange={RadioHandle}
                        checked={values.birthCertificate === false}
                      />
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Start date</Label>
                    <Input
                      type="date"
                      name="startDate"
                      id="startDate"
                      placeholder=""
                      value={moment(values.startDate).format('YYYY-MM-DD')}
                      onBlur={handleBlur}
                      invalid={
                        touched.startDate && errors.startDate ? true : false
                      }
                      onChange={handleChange}
                      placeholder="Enter medical card expiry date"
                    />
                    <FormFeedback>
                      {touched.startDate && errors.startDate}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                {/* <Col md="4" xl="3">
                <FormGroup>
                  <Label for="">End Date</Label>
                  <Input type="date" name="entrollmentDate" id="entrollmentDate" placeholder=""
                   value={moment(values.entrollmentDate).format("YYYY-MM-DD")}
                   onBlur={handleBlur}
                   invalid={
                     touched.entrollmentDate && errors.entrollmentDate ? true : false
                   }
                   onChange={handleChange}
                   placeholder="Enter entrollment date"
                 />
                 <FormFeedback>
                   {touched.entrollmentDate && errors.entrollmentDate}
                 </FormFeedback>
                </FormGroup>
              </Col> */}
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Notes</Label>
                    <Input
                      type="text"
                      name="notes"
                      id="notes"
                      placeholder="Enter notes"
                      value={values.notes}
                      invalid={errors.notes && touched.notes ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.notes && touched.notes ? errors.notes : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <div className="btn-center">
                <Button color="primary" className="pull-left">
                  Update
                </Button>
              </div>
            </Form>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}
