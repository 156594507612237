import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router'
import {
  Container,
  Button,
  Card,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  CustomInput,
  FormFeedback,
  Form,
} from 'reactstrap'
import * as Yup from 'yup'
import {
  CustomMessage,
  CustomPageLoading,
} from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import API from '../../../constants/API'
import { useFormik } from 'formik'
import moment from 'moment'
import { PHONE_NUMBER_REGEX, NUMBER_REGEX } from '../../../constants/Regex'
import { CREATE_USER_URL, CREATE_INSTRUCTOR } from '../../../constants/Apipath'
import {
  userName,
  getRoleId,
  getUserId,
  authHeader,
} from '../../../function/Mainfunction'
import { USER_ROUTE } from '../../../constants/RoutePaths'
import { isFirstDayOfMonth } from 'date-fns/esm/fp'

export default function CreateUser() {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const user_name = userName()
  const user_id = getUserId()
  const currentDate = new Date()

  const Goback = () => {
    history.goBack()
  }
  const [loading, setloading] = useState(false)
  const CreateUser = async (values) => {
    let diff = moment(values.dayOutTime, 'HH:mm').diff(
      moment(values.dayInTime, 'HH:mm')
    )
    let d = moment.duration(diff)
    let hours = Math.floor(d.asHours())
    let minutes = moment.utc(diff).format('mm')
    let total = hours + ':' + minutes
    const config = {
      method: 'POST',
      headers: authHeader(),
      url: values.roleId === '3' ? CREATE_INSTRUCTOR : CREATE_USER_URL,
      data: { ...values, totalWorkingHours: total },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
        resetForm({})
        history.push(USER_ROUTE)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data?.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name Required'),
    lastName: Yup.string().required('Last name Required'),
    email: Yup.string().email('Invalid Email').required('Email Required'),
    dateOfBirth: Yup.date()
      .max(new Date(), 'Invalid Date')
      .required('Date Required'),
    phoneNumber: Yup.string()
      .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
      .min(10, 'Invalid phone number')
      .max(10, 'Invalid phone number')
      .required('Phone number Required'),
    city: Yup.string().required('City Required'),
    zip: Yup.string()
      .max(6, 'Invalid Zip code')
      .matches(NUMBER_REGEX, 'Invalid Zip code')
      .required('Zip Required'),
    state: Yup.string().required('State Required'),
    address: Yup.string().required('Address Required'),
    roleId: Yup.string().required('Role Name Required'),
    dayInTime: Yup.string().test('test', 'Required', function (value) {
      if (this.parent.roleId === '3') {
        const boo = !!value
        return boo
      } else return true
    }),
    dayOutTime: Yup.string()
      .test('test', 'Required', function (value) {
        if (this.parent.roleId === '3') {
          const boo = !!value
          return boo
        } else return true
      })
      .test(
        'Check',
        'Day out time must greater than Day in time',
        function (value) {
          if (this.parent.roleId === '3') {
            const Indate = this.parent.dayInTime?.replace(':', '')
            const outDate = value?.replace(':', '')
            return Indate < outDate
          } else return true
        }
      ),
  })

  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      gender: '',
      dateOfBirth: '',
      profileImage: '',
      phoneNumber: '',
      state: '',
      city: '',
      address: '',
      zip: '',
      createdBy: user_id && user_id,
      roleId: '',
      hyperLink: '',
      dayInTime: '',
      dayOutTime: '',
      totalWorkingHours: '',
    },
    validationSchema,
    onSubmit: (values) => CreateUser(values),
  })

  return (
    <Fragment>
      <div className="wrapper">
        {loading && <CustomPageLoading />}
        <div className="page-title">
          {values.roleId !== '3' && <h4>Create New User</h4>}
          {values.roleId === '3' && <h4>Create New Instructor</h4>}
          <div className="btn-right">
            <Button onClick={Goback} color="primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.543"
                height="8.695"
                viewBox="0 0 5.543 9.695">
                <path
                  id="Icon_ionic-ios-arrow-back"
                  data-name="Icon ionic-ios-arrow-back"
                  d="M12.921,11.04,16.59,7.374a.693.693,0,1,0-.981-.978l-4.156,4.153a.691.691,0,0,0-.02.955l4.173,4.182a.693.693,0,1,0,.981-.978Z"
                  transform="translate(-11.251 -6.194)"
                  fill="#fff"
                />
              </svg>
              Back
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <Card body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">First name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="Enter first name"
                      value={values.firstName}
                      invalid={
                        errors.firstName && touched.firstName ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.firstName && touched.firstName
                        ? errors.firstName
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Last name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Enter last name"
                      value={values.lastName}
                      invalid={
                        errors.lastName && touched.lastName ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.lastName && touched.lastName
                        ? errors.lastName
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Email address</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter email"
                      value={values.email}
                      invalid={errors.email && touched.email ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.email && touched.email ? errors.email : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Gendar</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio"
                        name="customRadio"
                        value={'male'}
                        name="gender"
                        label="Male"
                        onChange={handleChange}
                        checked={values.gender === 'male'}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio2"
                        name="customRadio"
                        value={'female'}
                        name="gender"
                        label="Female"
                        onChange={handleChange}
                        checked={values.gender === 'female'}
                      />
                    </Row>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">DOB</Label>
                    <Input
                      type="date"
                      name="dateOfBirth"
                      id="dateOfBirth"
                      placeholder=""
                      value={moment(values.dateOfBirth).format('YYYY-MM-DD')}
                      onBlur={handleBlur}
                      invalid={
                        touched.dateOfBirth && errors.dateOfBirth ? true : false
                      }
                      onChange={handleChange}
                      placeholder="Enter DOB"
                    />
                    <FormFeedback>
                      {touched.dateOfBirth && errors.dateOfBirth}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Phone number</Label>
                    <Input
                      type="tel"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="Enter phone number"
                      value={values.phoneNumber}
                      invalid={
                        errors.phoneNumber && touched.phoneNumber ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.phoneNumber && touched.phoneNumber
                        ? errors.phoneNumber
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Address</Label>
                    <Input
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Enter address"
                      value={values.address}
                      invalid={errors.address && touched.address ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.address && touched.address ? errors.address : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">City</Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="Enter City"
                      value={values.city}
                      invalid={errors.city && touched.city ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.city && touched.city ? errors.city : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">State</Label>
                    <Input
                      type="text"
                      name="state"
                      id="state"
                      placeholder="Enter State"
                      value={values.state}
                      invalid={errors.state && touched.state ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.state && touched.state ? errors.state : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Zip</Label>
                    <Input
                      type="text"
                      name="zip"
                      id="zip"
                      placeholder="Enter Zip"
                      value={values.zip}
                      invalid={errors.zip && touched.zip ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormFeedback>
                      {errors.zip && touched.zip ? errors.zip : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Role</Label>
                    <Input
                      type="select"
                      name="roleId"
                      id="roleId"
                      placeholder=""
                      value={values.roleId}
                      invalid={errors.roleId && touched.roleId ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}>
                      <option value="">select role</option>
                      {getRoleId() == 1 && (
                        <option value="1">Super Admin</option>
                      )}
                      <option value="2">Admin</option>
                      <option value="3">Instructor</option>
                      <option value="5">Roll call user</option>
                    </Input>
                    <FormFeedback>
                      {errors.roleId && touched.roleId ? errors.roleId : ''}
                    </FormFeedback>

                    {/* <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <Input
                          addon
                          type="radio"
                          aria-label="Checkbox for following text input"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" value="Super Admin" />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="4" xl="3">
                <FormGroup>
                  <Label for=""></Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <Input
                          addon
                          type="radio"
                          aria-label="Checkbox for following text input"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" value="Admin" />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="4" xl="3">
                <FormGroup>
                  <Label for=""></Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <Input
                          addon
                          type="radio"
                          aria-label="Checkbox for following text input"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" value="Instructor" />
                  </InputGroup> */}
                  </FormGroup>
                </Col>

                {values.roleId === '3' && (
                  <>
                    <Col md="4" xl="3">
                      <FormGroup>
                        <Label for="">Day In Time</Label>
                        <Input
                          type="time"
                          name="dayInTime"
                          id="dayInTime"
                          placeholder="Enter Day In Time"
                          value={values.dayInTime}
                          invalid={
                            errors.dayInTime && touched.dayInTime ? true : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <FormFeedback>
                          {errors.dayInTime && touched.dayInTime
                            ? errors.dayInTime
                            : ''}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    {values.roleId === '3' && (
                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">Day Out Time</Label>
                          <Input
                            type="time"
                            name="dayOutTime"
                            id="dayOutTime"
                            placeholder="Enter Day Out Time"
                            value={values.dayOutTime}
                            invalid={
                              errors.dayOutTime && touched.dayOutTime
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormFeedback>
                            {errors.dayOutTime && touched.dayOutTime
                              ? errors.dayOutTime
                              : ''}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}

                    {/* {values.roleId === '3' && (
                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">Total Working Hours</Label>
                          <Input
                            type="time"
                            name="totalWorkingHours"
                            id="totalWorkingHours"
                            placeholder="Enter Total Working Hours"
                            value={values.totalWorkingHours}
                            invalid={
                              errors.totalWorkingHours &&
                              touched.totalWorkingHours
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormFeedback>
                            {errors.totalWorkingHours &&
                            touched.totalWorkingHours
                              ? errors.totalWorkingHours
                              : ''}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )} */}
                  </>
                )}
              </Row>
              <div className="btn-center">
                <Button color="primary" className="pull-left">
                  Create
                </Button>
              </div>
            </Form>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}
