import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Form,
  CustomInput,
  Label,
  FormFeedback,
  Spinner,
} from 'reactstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { authHeader, getUserId } from '../../function/Mainfunction'
import { SAVE_ATTEDANCCE } from '../../constants/Apipath'
import { CustomMessage } from '../../function/Helperfunction'
import API from '../../constants/API'

export default function AttedenceManual(props) {
  const {
    attedenceModal,
    BatDetails = {},
    close,
    batchStuId,
    successFull,
    flag,
    attedenceDate,
  } = props

  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const AttendanceCheckin = async (values) => {
    const config = {
      method: 'POST',
      headers: authHeader(),
      url: SAVE_ATTEDANCCE,
      data: {
        createdBy: getUserId(),
        ...values,
        checkIn:
          moment(attedenceDate).format('YYYY/MM/DD') + ` ` + values.checkIn,
        checkOut:
          moment(attedenceDate).format('YYYY/MM/DD') + ` ` + values.checkOut,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      //const response = {}
      const { status } = response.data
      if (status === 'Success') {
        CustomMessage('Present', 'success', enqueueSnackbar) //call toster
        close()
        successFull()
        resetForm({})
      }
    } catch (error) {
      console.error(error)
      let { data } = error.response
      CustomMessage(data?.message, 'error', enqueueSnackbar) //call toster
    } finally {
      setloading(false)
    }
  }
  const validationSchema = Yup.object().shape({
    checkIn: Yup.string().required(' Required'),
    checkOut: Yup.string()
      .required('Required')
      .test(
        'Check',
        'Check out time must greater than check in time',
        function (value) {
          const Indate = this.parent.checkIn?.replace(':', '')
          const outDate = value?.replace(':', '')
          return Indate < outDate
        }
      ),
  })

  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: flag
      ? {
          ...batchStuId,
          attendanceId: 0,
          createdBy: getUserId(),
          checkIn: '',
          checkOut: '',
        }
      : {
          ...BatDetails[`Ba_${batchStuId.batchId}`]?.[
            `Stu_${batchStuId.userId}`
          ],
          ...batchStuId,
        },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => AttendanceCheckin(values),
  })
  const DateHandle = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <div>
      <Modal isOpen={attedenceModal}>
        <ModalHeader>Attedence </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>IN</Label>

              <Input
                name="checkIn"
                onBlur={handleBlur}
                onChange={DateHandle}
                value={values.checkIn}
                invalid={errors.checkIn && touched.checkIn}
                type="time"
              />
              <FormFeedback>
                {errors.checkIn && touched.checkIn ? errors.checkIn : ''}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>Out</Label>
              <Input
                name="checkOut"
                onBlur={handleBlur}
                invalid={errors.checkOut && touched.checkOut}
                onChange={handleChange}
                value={values.checkOut}
                type="time"
              />
              <FormFeedback>
                {errors.checkOut && touched.checkOut ? errors.checkOut : ''}
              </FormFeedback>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {' '}
              {loading ? <Spinner size="sm" /> : 'submit'}
            </Button>

            <Button
              color="secondary"
              onClick={() => {
                close()
                resetForm({})
              }}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  )
}
