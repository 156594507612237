import React, { useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Card,
  CardTitle,
} from 'reactstrap'
import { CheckBoxViewComponet } from './CheckBoxViewComponet'

function IncabPreview(props) {
  const { IncabDetails } = props
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const fieldValues = {
    seatbelts: 'Seat belts',
    emergencyTriangles3: 'Emergency Triangles3',
    fireExtinguisher: 'Fire Extinguisher',
    mirrorsDrivePassHood: 'Mirrors DrivePassHood',
    windshieldWipers: 'Windshield Wipers',
    circuitBreaker: 'Circuit Breaker',
    horns: 'Horns',
    steeringWheel: 'SteeringWheel',
    clutch: 'Clutch',
    startTruck: 'StartTruck',
    ABS: 'ABS',
    lightsLTRT4wayH: 'LightsLTRT4wayH',
    coolantTemperatureGauge: 'CoolantTemperatureGauge',
    oilPressureGauge: 'OilPressureGauge',
    def: 'Def',
    primarySecondaryAirGuages: 'PrimarySecondaryAirGuages',
    voltage: 'Voltage',
    heaterDefroster: 'Heater Defroster',
    washerfluid: 'Washerfluid',
    shutTruckOff: 'Shut TruckOff',
    leaveTruckinGear: 'Leave TruckinGear',
    turnKeyBackOn: 'TurnKey BackOn',
    electricOn: 'ElectricOn',
    pushInYellowValveFromA: 'PushInYellowValveFromA',
    verifyRedValve: 'Verify RedValve',
    pushonBrakePedalHold: 'Pushon BrakePedalHold',
    checkforAirLoss: 'Check for AirLoss',
    startPumpingBrakes: 'Start PumpingBrakes',
    watchforLowAirPressure: 'Watch for LowAirPressure',
    lightBuzzerPSI: 'Light BuzzerPSI',
    continuetoPumpBrakes: 'Continueto PumpBrakes',
    watchAirGaugeanBothValves: 'Watch Air Gaugean Both Valves',
    valvesShouldPopout: 'Valves Should Popout',
    pumpBrakesUntil: 'Pump Brakes Until',
    govTest: 'GovTest',
    pushInRedValve: 'Push InRedValve',
    leaveYellowValveOut: 'Leave YellowValveOut',
    putInLowGearandPull: 'PutIn LowGearandPull',
    pushInYellowvalveFormB: 'PushIn YellowvalveFormB',
    leaveRedValveOut: 'Leave RedValveOut',
    putInLowGearandPullAgainstBrakes: 'PutIn LowGearandPullAgainstBrakes',
    placeVehicleinLowGear: 'Place VehicleinLowGear',
    releaseAllBrakes: 'Release AllBrakes',
    applyBrakesFirmly: 'Apply BrakesFirmly',
  }
  const Field = (props) => {
    const { name } = props
    return (
      <CheckBoxViewComponet
        value={IncabDetails[name]}
        label={fieldValues[name]}
      />
    )
  }
  return (
    <div>
      <AiFillEye onClick={toggle} />

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Incab Preview</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="trip-preview">
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">IN cab checks with truck off</CardTitle>
                    <Field name="seatbelts" />
                    <Field name="fireExtinguisher" />
                    <Field name="windshieldWipers" />
                    <Field name="horns" />
                    <Field name="clutch" />
                  </Card>
                </div>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">With truck running</CardTitle>
                    <Field name="coolantTemperatureGauge" />
                    <Field name="def" />
                    <Field name="voltage" />
                    <Field name="washerfluid" />
                  </Card>
                </div>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Emergency warning test</CardTitle>
                    <Field name="startPumpingBrakes" />
                    <Field name="lightBuzzerPSI" />
                  </Card>
                </div>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Tractor parking brake test</CardTitle>

                    <Field name="govTest" />
                    <Field name="leaveYellowValveOut" />
                  </Card>
                </div>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Service brake test</CardTitle>

                    <Field name="placeVehicleinLowGear" />
                    <Field name="applyBrakesFirmly" />
                    <Field name="emergencyTriangles3" />
                    <Field name="mirrorsDrivePassHood" />
                    <Field name="circuitBreaker" />
                    <Field name="steeringWheel" />
                    <Field name="startTruck" />
                    <Field name="oilPressureGauge" />
                    <Field name="primarySecondaryAirGuages" />
                    <Field name="heaterDefroster" />
                    <Field name="watchforLowAirPressure" />
                    <Field name="pushInRedValve" />
                    <Field name="putInLowGearandPull" />
                    <Field name="releaseAllBrakes" />
                  </Card>
                </div>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Dasglights</CardTitle>
                    <Field name="ABS" />
                  </Card>
                </div>

                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">
                      Air Brakes test/ait loss test
                    </CardTitle>
                    <Field name="shutTruckOff" />
                    <Field name="turnKeyBackOn" />
                    <Field name="pushInYellowValveFromA" />
                    <Field name="pushonBrakePedalHold" />
                  </Card>
                </div>

                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Emergency spring brake test</CardTitle>
                    <Field name="continuetoPumpBrakes" />
                    <Field name="valvesShouldPopout" />
                  </Card>
                </div>

                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Trailer parking brake test</CardTitle>

                    <Field name="pushInYellowvalveFormB" />
                    <Field name="putInLowGearandPullAgainstBrakes" />
                    <Field name="lightsLTRT4wayH" />
                    <Field name="leaveTruckinGear" />
                    <Field name="electricOn" />
                    <Field name="verifyRedValve" />
                    <Field name="checkforAirLoss" />
                    <Field name="watchAirGaugeanBothValves" />
                    <Field name="pumpBrakesUntil" />
                    <Field name="leaveRedValveOut" />
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default IncabPreview
