import jsPDF from 'jspdf'
import 'jspdf-autotable'
// Date Fns is used to format the dates we receive
// from our API call
import { format } from 'date-fns'

// define a generatePDF function that accepts a tickets argument
const generatePDF = (name, PretripReport) => {
  // initialize jsPDF
  //   const doc = new jsPDF();
  var doc = new jsPDF('l', 'mm', 'a4')
  var d = format(new Date(), 'yyyy-MM-dd')
  // define the columns we want and their titles
  const tableColumn = [
    'Pre-Trip ID',
    'Trip Date',
    'Pre-Trip Time',
    'Comments',
    // 'Leans Leaks Debris',
    // 'Lights Clearance',
    // 'Alternator Belt',
    // 'Coolant Reservoir',
    // 'Water Pump Belt',
    // 'Power Steering Pump',
    // 'Air CompressorGear',
    // 'Power Steering Reservior',
    // 'Engine OilDipStick',
    // 'Engine OilFillTube',
    // 'Steering GearBox',
    // 'PitmanArm',
    // 'DragLink',
    // 'SteeringKnucle',
    // 'Castle Nut CotterKey',
    // 'Leaf Spring MountFormA',
    // 'Leaf Springs',
    // 'Shock Absorbers FormA',
    // 'Brake AirHosesFormA',
    // 'Brake ChamberFormA',
    // 'Push RodFormA',
    // 'Slack AdjusterFormA',
    // 'Brake DrumFormA',
    // 'Brake LiningsFormA',
    // 'RimFormA',
    // 'TireABC',
    // 'Tire DepthFormA',
    // 'ValveStem',
    // 'LugNutsFormA',
    // 'HubOilSealFormA',
    // 'LightLT',
    // 'MirrorBracket',
    // 'EntrySteps',
    // 'Def',
    // 'Door HingesSeal',
    // 'FuelTank',
    // 'TruckFrame',
    // 'DriveShaft',
    // 'Tire PressureFormA',
    // 'Exhaust',
    // 'CatwalkSteps',
    // 'TorqueBarFormC',
    // 'Leaf SpringMountFormB',
    // 'Leaf SpringUJoints',
    // 'Brake AirHosesFormB',
    // 'Brake ChamberFormB',
    // 'Push RodFormB',
    // 'Slack AdjusterFormB',
    // 'Brake DrumFormB',
    // 'Brake LiningsFormB',
    // 'RimFormB',
    // 'TiresABC',
    // 'Tire DepthFormB',
    // 'Debris SpaceFormB',
    // 'Valve StemCapFormB',
    // 'Tire PressureFormB',
    // 'Lug NutsFormB',
    // 'Axel Seal',
    // 'Shock AbsorbersFormB',
    // 'Airbags Mounts',
    // 'Mud FlapInches',
    // 'LightsLT',
    // 'Reflectors FormB',
    // 'Glad Hands',
    // 'AirLinesto Tractor',
    // 'Apron',
    // 'King Pin',
    // 'Locking Jaws',
    // 'Skid Plate',
    // 'Platform',
    // 'Mounting Bolts',
    // 'Release ArmFormC',
    // 'Safety Latch',
    // 'Sliding Assembly',
    // 'Clearancefor Turns',
    // 'Header Board',
    // 'Dot TapeFormB',
    // 'Trailer Frame',
    // 'Landing Gear',
    // 'Light Marker',
    // 'Reflectors FormC',
    // 'Frame',
    // 'Release ArmFormB',
    // 'Locking Pins',
    // 'Torque BarFormB',
    // 'Leaf SpringMounts',
    // 'Leaf SpringsUBolts',
    // 'Brake AirLines',
    // 'Brake ChamberFormC',
    // 'Push RodFormC',
    // 'Slack AdjusterFormC',
    // 'Brake LiningsFormC',
    // 'Brake DrumFormC',
    // 'Rim FormC',
    // 'Tire ABCs',
    // 'Debris SpaceFormC',
    // 'Valve StemCapFormC',
    // 'Tire PressureFormC',
    // 'Lug NutsFormC',
    // 'Hub OilSealFormC',
    // 'Ans light',
    // 'Corner MarkerLight',
    // 'Mud Flap',
    // 'Doors HingesLatches',
    // 'Weather Stripping',
    // 'Clearance Lights',
    // 'LlghtsLT',
    // 'DOT tapeFormC',
    // 'Dote Tape100FormC',
    // 'Front',
    // 'Rear',
  ]
  // define an empty array of rows
  const tableRows = []

  // for each report pass all its data into an array
  PretripReport.forEach((p) => {
    const pretripData = [
      p.preTripId,
      p.tripDate,
      p.preTripTime,
      p.comments,
      // p.leansLeaksDebris,
      // p.lightsClearance,
      // p.alternatorBelt,
      // p.coolantReservoir,
      // p.waterPumpBelt,
      // p.powerSteeringPump,
      // p.airCompressorGear,
      // p.powerSteeringReservior,
      // p.engineOilDipStick,
      // p.engineOilFillTube,
      // p.steeringGearBox,
      // p.pitmanArm,
      // p.dragLink,
      // p.steeringKnucle,
      // p.castleNutCotterKey,
      // p.leafSpringMountFormA,
      // p.leafSprings,
      // p.shockAbsorbersFormA,
      // p.brakeAirHosesFormA,
      // p.brakeChamberFormA,
      // p.pushRodFormA,
      // p.slackAdjusterFormA,
      // p.brakeDrumFormA,
      // p.brakeLiningsFormA,
      // p.rimFormA,
      // p.tireABC,
      // p.tireDepthFormA,
      // p.valveStem,
      // p.lugNutsFormA,
      // p.hubOilSealFormA,
      // p.lightLT,
      // p.mirrorBracket,
      // p.entrySteps,
      // p.def,
      // p.doorHingesSeal,
      // p.fuelTank,
      // p.truckFrame,
      // p.driveShaft,
      // p.tirePressureFormA,
      // p.exhaust,
      // p.catwalkSteps,
      // p.torqueBarFormC,
      // p.leafSpringMountFormB,
      // p.leafSpringUJoints,
      // p.brakeAirHosesFormB,
      // p.brakeChamberFormB,
      // p.pushRodFormB,
      // p.slackAdjusterFormB,
      // p.brakeDrumFormB,
      // p.brakeLiningsFormB,
      // p.rimFormB,
      // p.tireABCsFormB,
      // p.tireDepthFormB,
      // p.debrisSpaceFormB,
      // p.valveStemCapFormB,
      // p.tirePressureFormB,
      // p.lugNutsFormB,
      // p.axelSeal,
      // p.shockAbsorbersFormB,
      // p.airbagsMounts,
      // p.mudFlapInches,
      // p.LightsLT,
      // p.reflectorsFormB,
      // p.gladHands,
      // p.airLinestoTractor,
      // p.apron,
      // p.kingPin,
      // p.lockingJaws,
      // p.skidPlate,
      // p.platform,
      // p.mountingBolts,
      // p.releaseArmFormC,
      // p.safetyLatch,
      // p.slidingAssembly,
      // p.clearanceforTurns,
      // p.headerBoard,
      // p.dotTapeFormB,
      // p.trailerFrame,
      // p.landingGear,
      // p.lightMarker,
      // p.reflectorsFormC,
      // p.frame,
      // p.releaseArmFormB,
      // p.lockingPins,
      // p.torqueBarFormB,
      // p.leafSpringMounts,
      // p.leafSpringsUBolts,
      // p.brakeAirLines,
      // p.brakeChamberFormC,
      // p.pushRodFormC,
      // p.slackAdjusterFormC,
      // p.brakeLiningsFormC,
      // p.brakeDrumFormC,
      // p.rimFormC,
      // p.tireABCsFormA,
      // p.debrisSpaceFormC,
      // p.valveStemCapFormC,
      // p.tirePressureFormC,
      // p.lugNutsFormC,
      // p.hubOilSealFormC,
      // p.anslight,
      // p.cornerMarkerLight,
      // p.mudFlap,
      // p.doorsHingesLatches,
      // p.weatherStripping,
      // p.clearanceLights,
      // p.llghtsLT,
      // p.dOTtapeFormC,
      // p.doteTape100FormC,
      // p.front,
      // p.rear,
      // called date-fns to format the date on the report
    ]

    tableRows.push(pretripData)
  })

  // startY is basically margin-top
  doc.autoTable(tableColumn, tableRows, { startY: 20 })
  const date = Date().split(' ')
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4]
  // report title. and margin-top + margin-left
  doc.text(`${name}_Pretrip Details` + d, 14, 15)
  // we define the name of our PDF file.
  doc.save(`${name}_Pretrip_Details_${d}.pdf`)
}

export default generatePDF
