import React, { useEffect, useRef, useState } from 'react'
import {
  Badge,
  Button,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  Dropdown,
  CardTitle,
} from 'reactstrap'
import { Delete, Pencil } from '../../../function/Icons'
import { MyLoader } from '../../../function/Loader'
import Avatar from 'react-avatar'
import Pagination from 'react-js-pagination'
import { DELETE_INSTRUCTOR, LIST_INSTRUCTOR } from '../../../constants/Apipath'
import { CustomMessage, locationParams } from '../../../function/Helperfunction'
import API from '../../../constants/API'
import { useSnackbar } from 'notistack'
import { authHeader } from '../../../function/Mainfunction'
import { AssDess, UrlReplace } from '../../../constants/Helper'
import { useHistory, useLocation } from 'react-router'
import { debounce } from 'underscore'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { UPDATE_INSTRUCTOR_ROUTE } from '../../../constants/RoutePaths'
import { CSVLink } from 'react-csv'
import generatePDF from './InstructorPDFGenerator'
import NoRecordFound from '../../../function/EmptyRecord'

export default function Instructor(props) {
  const { setfilterOpen, Filterstate, setFilterstate, ordervalue } = props
  const location = useLocation()
  const history = useHistory()
  const [totalcount, setTotalcount] = useState(0)
  const [instructorlist, setInstructorlist] = useState([])
  const [loading, setloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const exportToggle = () => {
    setdropdownOpen(!dropdownOpen)
  }
  const headers = [
    { label: 'Name', key: 'firstName' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phoneNumber' },
    { label: 'Gender', key: 'gender' },
    { label: 'DOB', key: 'dateOfBirth' },
    { label: 'Address', key: 'address' },
    { label: 'Day In', key: 'dayInTime' },
    { label: 'Day Out', key: 'dayOutTime' },
    { label: 'Total Hours', key: 'totalWorkingHours' },
  ]

  const Export = () => {
    generatePDF(instructorlist)
  }
  const GetInstructor = async (Filterstate, value) => {
    const config = {
      method: 'GET',
      url: LIST_INSTRUCTOR,
      headers: authHeader(),
      params: {
        count: 10,
        ...Filterstate,
        ...AssDess[value],
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        setInstructorlist(data)
        setTotalcount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  const handlePageChange = (page) => {
    setFilterstate({ ...Filterstate, offset: page - 1 })
  }
  const DeleteAlert = (e) => {
    const Id = e.currentTarget.dataset.instructordetailid
    Swal.fire({
      title: 'Are you want to delete this Instructor?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteInstructor(Id)
      }
    })
  }

  const DeleteInstructor = async (Id) => {
    const config = {
      method: 'DELETE',
      url: DELETE_INSTRUCTOR,
      headers: authHeader(),
      params: { userId: Id },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
        GetInstructor()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  const delaySearch = useRef(
    debounce((Filterstate, ordervalue) =>
      GetInstructor(Filterstate, ordervalue)
    )
  ).current
  useEffect(() => {
    delaySearch(Filterstate, ordervalue)
  }, [Filterstate, ordervalue])

  useEffect(() => {
    let pagePath = location.pathname
    let locParams = locationParams(location.search)
    UrlReplace(pagePath, locParams, Filterstate, setfilterOpen, history)
  }, [Filterstate])
  return (
    <>
      <Card body>
        <CardTitle tag="h5">
          Instructor
          <div className="btn-sec">
            <Dropdown isOpen={dropdownOpen} toggle={exportToggle}>
              <DropdownToggle color="danger" caret>
                Export
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={Export}>PDF</DropdownItem>

                <DropdownItem>
                  <CSVLink
                    filename={'Instructor_Details.csv'}
                    data={instructorlist}
                    headers={headers}>
                    CSV
                  </CSVLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </CardTitle>
        {loading && (
          <tr>
            <td colSpan={10}>
              <MyLoader rowCount={5} columncount={8} />
            </td>
          </tr>
        )}
        {!loading && instructorlist && instructorlist.length > 0 && (
          <Table hover responsive>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Address</th>
                <th>Day In</th>
                <th>Day Out</th>
                <th>Total Hours</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                instructorlist.map((ins) => (
                  <tr>
                    <th>
                      <Avatar
                        name={`${ins.firstName} ${ins.lastName}`}
                        size={40}
                        round="50px"
                      />
                    </th>
                    <td>
                      {ins.firstName} {``} {ins.lastName}{' '}
                    </td>
                    <td>{ins.email}</td>
                    <td>{ins.phoneNumber}</td>
                    <td>{ins.gender}</td>
                    <td>{ins.dateOfBirth}</td>
                    <td>{ins.address}</td>
                    <td>{ins.dayInTime}</td>
                    <td>{ins.dayOutTime}</td>
                    <td>
                      <Badge color="danger">
                        {ins.totalWorkingHours || '00:00'}
                      </Badge>
                    </td>
                    <td className="btn-column" colSpan={2}>
                      <Link to={`${UPDATE_INSTRUCTOR_ROUTE}/${ins.userId}`}>
                        <Button color="link">
                          <Pencil />
                        </Button>
                      </Link>
                      <Button
                        data-instructordetailid={ins.userId}
                        onClick={DeleteAlert}
                        color="link">
                        <Delete />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
        {!loading && !instructorlist.length && <NoRecordFound />}
      </Card>
      <div className="d-flex flex-row py-4 justify-content-end">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={parseInt(Filterstate.offset) + 1}
          itemsCountPerPage={10}
          totalItemsCount={totalcount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      </div>
    </>
  )
}
