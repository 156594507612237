import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Form,
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import CheckBoxcomponent from './inputcomponets/CheckBoxcomponent'
export default function Incab(props) {
  const { GetValues, time, toggle, modal } = props
  const validationSchema = Yup.object().shape({
    incabDate: Yup.date().required('Incab Date Required'),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      incabDate: '',
      seatbelts: 'No',
      emergencyTriangles3: 'No',
      fireExtinguisher: 'No',
      mirrorsDrivePassHood: 'No',
      windshieldWipers: 'No',
      circuitBreaker: 'No',
      horns: 'No',
      steeringWheel: 'No',
      clutch: 'No',
      startTruck: 'No',
      ABS: 'No',
      lightsLTRT4wayH: 'No',
      coolantTemperatureGauge: 'No',
      oilPressureGauge: 'No',
      def: 'No',
      primarySecondaryAirGuages: 'No',
      voltage: 'No',
      heaterDefroster: 'No',
      washerfluid: 'No',
      shutTruckOff: 'No',
      leaveTruckinGear: 'No',
      turnKeyBackOn: 'No',
      electricOn: 'No',
      pushInYellowValveFromA: 'No',
      verifyRedValve: 'No',
      pushonBrakePedalHold: 'No',
      checkforAirLoss: 'No',
      startPumpingBrakes: 'No',
      watchforLowAirPressure: 'No',
      lightBuzzerPSI: 'No',
      continuetoPumpBrakes: 'No',
      watchAirGaugeanBothValves: 'No',
      valvesShouldPopout: 'No',
      pumpBrakesUntil: 'No',
      govTest: 'No',
      pushInRedValve: 'No',
      leaveYellowValveOut: 'No',
      putInLowGearandPull: 'No',
      pushInYellowvalveFormB: 'No',
      leaveRedValveOut: 'No',
      putInLowGearandPullAgainstBrakes: 'No',
      placeVehicleinLowGear: 'No',
      releaseAllBrakes: 'No',
      applyBrakesFirmly: 'No',
      comments: '',
    },
    validationSchema,
    onSubmit: (values) => GetValues(values),
  })

  const fieldValues = {
    seatbelts: 'Seat belts',
    emergencyTriangles3: 'Emergency Triangles3',
    fireExtinguisher: 'Fire Extinguisher',
    mirrorsDrivePassHood: 'Mirrors DrivePassHood',
    windshieldWipers: 'Windshield Wipers',
    circuitBreaker: 'Circuit Breaker',
    horns: 'Horns',
    steeringWheel: 'SteeringWheel',
    clutch: 'Clutch',
    startTruck: 'StartTruck',
    ABS: 'ABS',
    lightsLTRT4wayH: 'LightsLTRT4wayH',
    coolantTemperatureGauge: 'CoolantTemperatureGauge',
    oilPressureGauge: 'OilPressureGauge',
    def: 'Def',
    primarySecondaryAirGuages: 'PrimarySecondaryAirGuages',
    voltage: 'Voltage',
    heaterDefroster: 'Heater Defroster',
    washerfluid: 'Washerfluid',
    shutTruckOff: 'Shut TruckOff',
    leaveTruckinGear: 'Leave TruckinGear',
    turnKeyBackOn: 'TurnKey BackOn',
    electricOn: 'ElectricOn',
    pushInYellowValveFromA: 'PushInYellowValveFromA',
    verifyRedValve: 'Verify RedValve',
    pushonBrakePedalHold: 'Pushon BrakePedalHold',
    checkforAirLoss: 'Check for AirLoss',
    startPumpingBrakes: 'Start PumpingBrakes',
    watchforLowAirPressure: 'Watch for LowAirPressure',
    lightBuzzerPSI: 'Light BuzzerPSI',
    continuetoPumpBrakes: 'Continueto PumpBrakes',
    watchAirGaugeanBothValves: 'Watch Air Gaugean Both Valves',
    valvesShouldPopout: 'Valves Should Popout',
    pumpBrakesUntil: 'Pump Brakes Until',
    govTest: 'GovTest',
    pushInRedValve: 'Push InRedValve',
    leaveYellowValveOut: 'Leave YellowValveOut',
    putInLowGearandPull: 'PutIn LowGearandPull',
    pushInYellowvalveFormB: 'PushIn YellowvalveFormB',
    leaveRedValveOut: 'Leave RedValveOut',
    putInLowGearandPullAgainstBrakes: 'PutIn LowGearandPullAgainstBrakes',
    placeVehicleinLowGear: 'Place VehicleinLowGear',
    releaseAllBrakes: 'Release AllBrakes',
    applyBrakesFirmly: 'Apply BrakesFirmly',
  }

  const formHandle = (e) => {
    const { name, value } = e.target
    setValues((va) => {
      return { ...va, [name]: value === 'No' ? 'Yes' : 'No' }
    })
  }
  const Field = (props) => {
    const { name } = props
    return (
      <CheckBoxcomponent
        value={values[name]}
        handleChange={formHandle}
        name={name}
        label={fieldValues[name]}
      />
    )
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row xs="1">
          <Col xs="12" sm="12" md="12" lg="12">
            <Row>
              <Col xs="12" sm="12" md="6" lg="3">
                <FormGroup>
                  <Label for="">Incab Date</Label>
                  <Input
                    type="date"
                    name="incabDate"
                    placeholder=""
                    onBlur={handleBlur}
                    value={values.incabDate}
                    invalid={
                      errors.incabDate && touched.incabDate ? true : false
                    }
                    onChange={handleChange}
                  />

                  <FormFeedback>
                    {errors.incabDate && touched.incabDate
                      ? errors.incabDate
                      : ''}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col xs="12" sm="12" md="6" lg="3">
                <FormGroup>
                  <Label for="">Comments</Label>
                  <Input
                    type="text"
                    name="comments"
                    placeholder="Enter your comments"
                    onBlur={handleBlur}
                    value={values.comments}
                    invalid={errors.comments && touched.comments ? true : false}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row xs="12" sm="12" md="12" lg="12" className="pretriprow">
              <Col xs="12" sm="12" md="12" lg="6">
                <h6 className="pretripfontrow">IN cab checks with truck off</h6>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <Field name="seatbelts" />
                  <Field name="emergencyTriangles3" />
                  <Field name="fireExtinguisher" />
                  <Field name="mirrorsDrivePassHood" />
                  <Field name="windshieldWipers" />
                  <Field name="circuitBreaker" />
                  <Field name="horns" />
                  <Field name="steeringWheel" />
                  <Field name="clutch" />
                  <Field name="startTruck" />
                </Row>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <h6 className="pretripfontrow">Dasglights</h6>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <Field name="ABS" />
                  <Field name="lightsLTRT4wayH" />
                </Row>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <h6 className="pretripfontrow">With truck running</h6>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <Field name="coolantTemperatureGauge" />
                  <Field name="oilPressureGauge" />
                  <Field name="def" />
                  <Field name="primarySecondaryAirGuages" />
                  <Field name="voltage" />
                  <Field name="heaterDefroster" />
                  <Field name="washerfluid" />
                </Row>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <h6 className="pretripfontrow">
                  Air Brakes test/ait loss test
                </h6>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <Field name="shutTruckOff" />
                  <Field name="leaveTruckinGear" />
                  <Field name="turnKeyBackOn" />
                  <Field name="electricOn" />
                  <Field name="pushInYellowValveFromA" />
                  <Field name="verifyRedValve" />
                  <Field name="pushonBrakePedalHold" />
                  <Field name="checkforAirLoss" />
                </Row>{' '}
              </Col>

              <Col xs="12" sm="12" md="12" lg="6">
                <h6 className="pretripfontrow">Emergency warning test</h6>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <Field name="startPumpingBrakes" />
                  <Field name="watchforLowAirPressure" />
                  <Field name="lightBuzzerPSI" />
                </Row>{' '}
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <h6 className="pretripfontrow">Emergency spring brake test</h6>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <Field name="continuetoPumpBrakes" />
                  <Field name="watchAirGaugeanBothValves" />
                  <Field name="valvesShouldPopout" />
                  <Field name="pumpBrakesUntil" />
                </Row>{' '}
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <h6 className="pretripfontrow">Tractor parking brake test</h6>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <Field name="govTest" />
                  <Field name="pushInRedValve" />
                  <Field name="leaveYellowValveOut" />
                  <Field name="putInLowGearandPull" />
                </Row>{' '}
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <h6 className="pretripfontrow">Trailer parking brake test</h6>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <Field name="pushInYellowvalveFormB" />
                  <Field name="leaveRedValveOut" />
                  <Field name="putInLowGearandPullAgainstBrakes" />
                </Row>{' '}
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <h6 className="pretripfontrow"  >Service brake test</h6>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <Field name="placeVehicleinLowGear" />
                  <Field name="releaseAllBrakes" />
                  <Field name="applyBrakesFirmly" />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="btn-center">
          <Button color="primary" type="submit" className="pull-left">
            Submit
          </Button>
        </div>
      </Form>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Instruction</ModalHeader>
        <ModalBody>
          1.START AIR TEST (TURN KEY ON)
          <li>Build air pressure to maximum (120-130 psi)</li>
          <li>
            Place the transmission in low gear, shut off the engine, let off the
            clutch and push in the parking brake (yellow knob), and the trailer
            air supply valve (red knob), this releases both brakes
          </li>
          <li>
            Watch for air pressure to stabilize (the needles in the air pressure
            gauges are steady)
          </li>
          2. APPLIED AIR TEST (ELECTRIC ON){' '}
          <li>
            {' '}
            Apply firm, steady pressure to the foot (service) brake. Watch for
            air loss. No more than 4 psi per minute for combination vehicles.
          </li>
          3. LOW AIR WARNING TEST <li> Pump (fan) brakes. </li>
          <li>
            {' '}
            The low air warning light and or buzzer should come on at or before
            60 psi.
          </li>
          4. EMERGENCY BRAKE TEST{' '}
          <li>
            {' '}
            Continue to bleed air pressure down by fanning brakes until trailer
            air supply valve (red knob) pops out. Be sure the parking brake
            (yellow knob) pops out as well. This should occur between 20 to 45
            psi.
          </li>
          5. GOVERNOR TEST{' '}
          <li> Restart engine, build air pressure to maximum. (1400 rpms) </li>
          <li> Note where the low-pressure warning goes off.</li>
          <li>
            {' '}
            Check governor, truck hisses. This should occur between 120 to 130
            psi.{' '}
          </li>
          6. PARKING BRAKE TEST
          <li>
            a) Put the transmission in low gear, release the trailer brakes
            (RED) and tug gently against the tractor brakes.{' '}
          </li>
          <li>
            {' '}
            Set the trailer brakes, release the tractor brakes (YELLOW) and tug
            gently against the trailer. The truck shouldn't move.
          </li>
          7. SERVICE BRAKE TEST{' '}
          <li>
            a) Place the vehicle in low gear, release all the brakes and move
            forward about five (5) MPH. Apply the brake firmly. The truck should
            stop immediately and not pull to the left or right.
          </li>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
