import React, { Fragment, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  ADMIN_DASHBOARD,
  USER_ROUTE,
  STUDENT_ROUTE,
  FOUL_ROUTE,
  TRUCK_ROUTE,
  ADMIN_ROLLOVER,
  DPS_TEST_ROUTE,
  ADMIN_REPORT,
  STATION,
  ADMIN_SETTINGS,
  ADMIN_BATCH,
  CREATE_BATCH,
} from '../../../constants/RoutePaths'
export default function Sidebar(props) {
  const [activeMenu, setActiveItem] = useState('/')
  const { menu, toggle } = props
  let location = useLocation()
  useEffect(() => {
    let currentActive = window.location.pathname.split('/')[2]
    setActiveItem(currentActive)
    toggle(false)
    console.log('loc')
  }, [location])

  //  :first-child {
  //     transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
  //   }

  //   :nth-child(2) {
  //     opacity: ${({ open }) => open ? '0' : '1'};
  //     transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
  //   }

  //   :nth-child(3) {
  //     transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
  //   }
  return (
    <Fragment>
      <div
        style={{ transform: menu ? 'translateX(0)' : 'translateX(-100%)' }}
        className="sidebar">
        <ul>
          <li className={activeMenu === 'dashboard' ? 'active' : ''}>
            <Link to={ADMIN_DASHBOARD}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.174"
                height="17.654"
                viewBox="0 0 20.174 17.654">
                <path
                  id="home"
                  d="M52.11,39.782l-2.4-1.964v-3.05a.236.236,0,0,0-.236-.236h-.788a.236.236,0,0,0-.236.236v2.023l-5.51-4.5a1.267,1.267,0,0,0-1.591,0L32.17,39.782a.315.315,0,0,0-.046.443l.4.489a.315.315,0,0,0,.441.047l1.61-1.315v9.576a.631.631,0,0,0,.631.631h5.044a.631.631,0,0,0,.631-.631V43.977l2.522.012v5.047a.631.631,0,0,0,.631.631l5.044-.013a.631.631,0,0,0,.631-.631V39.445l1.61,1.315a.315.315,0,0,0,.443-.046l.4-.489a.315.315,0,0,0-.048-.444ZM48.441,48.4h0l-3.783.012V43.357a.633.633,0,0,0-.629-.631l-3.783-.011a.631.631,0,0,0-.632.631V48.4H35.835v-9.98l6.305-5.146,6.305,5.146Z"
                  transform="translate(-32.054 -32.013)"
                />
              </svg>

              <span>Home</span>
            </Link>
          </li>
          <li
            className={
              ['users', 'user', 'instructor'].includes(activeMenu)
                ? 'active'
                : ''
            }>
            <Link to={{ pathname: USER_ROUTE }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.5"
                height="14.35"
                viewBox="0 0 20.5 14.35">
                <path
                  id="user-friends"
                  d="M15.375,39.175A3.075,3.075,0,1,0,12.3,36.1,3.076,3.076,0,0,0,15.375,39.175Zm0-5.125a2.05,2.05,0,1,1-2.05,2.05A2.052,2.052,0,0,1,15.375,34.05ZM6.15,39.175a3.587,3.587,0,1,0-3.587-3.587A3.586,3.586,0,0,0,6.15,39.175Zm0-6.15a2.562,2.562,0,1,1-2.562,2.562A2.566,2.566,0,0,1,6.15,33.025Zm2.566,6.791c-1.07,0-1.336.384-2.566.384s-1.5-.384-2.566-.384a3.53,3.53,0,0,0-2.956,1.5A3.671,3.671,0,0,0,0,43.377v1.435A1.538,1.538,0,0,0,1.537,46.35h9.225A1.538,1.538,0,0,0,12.3,44.812V43.377a3.671,3.671,0,0,0-.628-2.06,3.53,3.53,0,0,0-2.956-1.5Zm2.559,5a.514.514,0,0,1-.512.513H1.537a.514.514,0,0,1-.512-.513V43.377a2.661,2.661,0,0,1,.452-1.486,2.507,2.507,0,0,1,2.1-1.051c.878,0,1.192.384,2.569.384s1.691-.384,2.566-.384a2.507,2.507,0,0,1,2.1,1.051,2.661,2.661,0,0,1,.452,1.486v1.435Zm8.7-3.68a2.944,2.944,0,0,0-2.463-1.252c-.89,0-1.115.32-2.14.32s-1.249-.32-2.14-.32a3.124,3.124,0,0,0-1.22.259,3.089,3.089,0,0,1,.647.852,2.1,2.1,0,0,1,.573-.083,21.353,21.353,0,0,0,2.14.32,21.353,21.353,0,0,0,2.14-.32,1.919,1.919,0,0,1,1.614.8,2.038,2.038,0,0,1,.349,1.144v1.192a.257.257,0,0,1-.256.256h-5.9a4.6,4.6,0,0,1-.051,1.025h5.945A1.281,1.281,0,0,0,20.5,44.044V42.849A3.06,3.06,0,0,0,19.978,41.132Z"
                  transform="translate(0 -32)"
                />
              </svg>

              <span>Users</span>
            </Link>
          </li>
          <li
            className={
              ['student', 'student-detail', 'student-details'].includes(
                activeMenu
              )
                ? 'active'
                : ''
            }>
            <Link
              to={{
                pathname: STUDENT_ROUTE,
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.813"
                height="13.501"
                viewBox="0 0 11.813 13.501">
                <path
                  id="user-graduate"
                  d="M8.422,8.454,5.906,10.547,3.391,8.454A3.551,3.551,0,0,0,0,12.235,1.266,1.266,0,0,0,1.266,13.5h9.282a1.266,1.266,0,0,0,1.266-1.266A3.549,3.549,0,0,0,8.422,8.454Zm-2.937,4.2H1.266a.423.423,0,0,1-.422-.422A2.7,2.7,0,0,1,3.122,9.326l2.363,1.967Zm5.485-.422a.423.423,0,0,1-.422.422H6.328V11.294L8.691,9.326A2.7,2.7,0,0,1,10.969,12.235ZM.348,2.637l.179.053v.991A.609.609,0,0,0,.5,4.736L.092,6.381c-.045.182.055.369.2.369h1.1c.145,0,.245-.187.2-.369L1.184,4.738A.609.609,0,0,0,1.16,3.684v-.8l1.231.364a3.683,3.683,0,0,0-.282,1.4,3.8,3.8,0,0,0,7.594,0,3.683,3.683,0,0,0-.282-1.4l2.044-.606a.574.574,0,0,0,0-1.055L6.352.066a1.539,1.539,0,0,0-.891,0L.348,1.582A.574.574,0,0,0,.348,2.637ZM5.906,7.594A2.955,2.955,0,0,1,2.953,4.641a2.865,2.865,0,0,1,.253-1.155l2.254.67a1.551,1.551,0,0,0,.891,0l2.254-.67A2.875,2.875,0,0,1,8.86,4.641,2.955,2.955,0,0,1,5.906,7.594ZM5.7.873a.732.732,0,0,1,.411,0l4.166,1.237L6.112,3.346a.732.732,0,0,1-.411,0L1.535,2.109,5.7.873Z"
                />
              </svg>

              <span>Students</span>
            </Link>
          </li>
          <li
            className={
              ['trucks', 'truck'].includes(activeMenu) ? 'active' : ''
            }>
            <Link to={TRUCK_ROUTE}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.32"
                height="10.024"
                viewBox="0 0 14.32 10.024">
                <path
                  id="truck-moving"
                  d="M13.9,35.878l-1.309-1.309a1.433,1.433,0,0,0-1.014-.418h-1.2V32.716A.715.715,0,0,0,9.666,32H.716A.715.715,0,0,0,0,32.716v7.518A1.786,1.786,0,0,0,3.222,41.3a1.786,1.786,0,0,0,3.222-1.065,1.886,1.886,0,0,0-.036-.358H10.06a1.72,1.72,0,0,0-.036.358,1.79,1.79,0,0,0,3.58,0,1.886,1.886,0,0,0-.036-.358h.394a.359.359,0,0,0,.358-.358V36.889A1.427,1.427,0,0,0,13.9,35.878ZM1.79,41.308a1.074,1.074,0,1,1,1.074-1.074A1.074,1.074,0,0,1,1.79,41.308Zm2.864,0a1.074,1.074,0,1,1,1.074-1.074A1.074,1.074,0,0,1,4.654,41.308ZM9.666,39.16H6.077a1.772,1.772,0,0,0-2.846,0H3.215a1.791,1.791,0,0,0-1.425-.716,1.764,1.764,0,0,0-1.074.367v-6.1h8.95Zm.716-4.3h1.2a.705.705,0,0,1,.506.21L13.307,36.3H10.382Zm1.432,6.444a1.074,1.074,0,1,1,1.074-1.074A1.074,1.074,0,0,1,11.814,41.308ZM13.6,39.16h-.367a1.772,1.772,0,0,0-2.846,0h-.009V37.012H13.6Z"
                  transform="translate(0 -32)"
                />
              </svg>

              <span>Truck</span>
            </Link>
          </li>
          <li
            className={
              ['stations', 'station'].includes(activeMenu) ? 'active' : ''
            }>
            <Link to={STATION}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.466"
                height="13.466"
                viewBox="0 0 13.466 13.466">
                <path
                  id="location"
                  d="M13.256,6.312H11.762A5.046,5.046,0,0,0,7.154,1.7V.21A.21.21,0,0,0,6.944,0H6.523a.21.21,0,0,0-.21.21V1.7A5.046,5.046,0,0,0,1.7,6.312H.21a.21.21,0,0,0-.21.21v.421a.21.21,0,0,0,.21.21H1.7a5.046,5.046,0,0,0,4.608,4.608v1.494a.21.21,0,0,0,.21.21h.421a.21.21,0,0,0,.21-.21V11.762a5.046,5.046,0,0,0,4.608-4.608h1.494a.21.21,0,0,0,.21-.21V6.523A.21.21,0,0,0,13.256,6.312ZM6.733,10.941a4.208,4.208,0,1,1,4.208-4.208A4.213,4.213,0,0,1,6.733,10.941Zm0-6.733A2.525,2.525,0,1,0,9.258,6.733,2.525,2.525,0,0,0,6.733,4.208Zm0,4.208A1.683,1.683,0,1,1,8.417,6.733,1.685,1.685,0,0,1,6.733,8.417Z"
                />
              </svg>

              <span>Station</span>
            </Link>
          </li>
          <li
            className={
              ([ADMIN_BATCH], CREATE_BATCH.includes(activeMenu) ? 'active' : '')
            }>
            <Link to={ADMIN_BATCH}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.223"
                height="11.379"
                viewBox="0 0 14.223 11.379">
                <path
                  id="users-class"
                  d="M12.09,4.978a1.778,1.778,0,1,0,1.778,1.778A1.777,1.777,0,0,0,12.09,4.978Zm0,2.845a1.067,1.067,0,1,1,1.067-1.067A1.067,1.067,0,0,1,12.09,7.823ZM5.334,6.756A1.778,1.778,0,1,0,7.112,4.978,1.777,1.777,0,0,0,5.334,6.756Zm2.845,0A1.067,1.067,0,1,1,7.112,5.689,1.067,1.067,0,0,1,8.178,6.756ZM2.133,8.534A1.778,1.778,0,1,0,.356,6.756,1.777,1.777,0,0,0,2.133,8.534Zm0-2.845A1.067,1.067,0,1,1,1.067,6.756,1.067,1.067,0,0,1,2.133,5.689Zm10.4,3.556h-.889a1.737,1.737,0,0,0-1.689,1.778.356.356,0,1,0,.711,0,1.027,1.027,0,0,1,.978-1.067h.889a1.027,1.027,0,0,1,.978,1.067.356.356,0,0,0,.711,0A1.737,1.737,0,0,0,12.534,9.245Zm-9.956,0H1.689A1.737,1.737,0,0,0,0,11.023a.356.356,0,1,0,.711,0,1.027,1.027,0,0,1,.978-1.067h.889a1.027,1.027,0,0,1,.978,1.067.356.356,0,1,0,.711,0A1.737,1.737,0,0,0,2.578,9.245Zm4.978,0H6.667a1.737,1.737,0,0,0-1.689,1.778.356.356,0,1,0,.711,0,1.027,1.027,0,0,1,.978-1.067h.889a1.027,1.027,0,0,1,.978,1.067.356.356,0,1,0,.711,0A1.737,1.737,0,0,0,7.556,9.245ZM1.422,1.067A.356.356,0,0,1,1.778.711H12.445a.356.356,0,0,1,.356.356V4.383a2.471,2.471,0,0,1,.711.336V1.067A1.068,1.068,0,0,0,12.445,0H1.778A1.068,1.068,0,0,0,.711,1.067V4.719a2.477,2.477,0,0,1,.711-.336Z"
                />
              </svg>

              <span>Batch</span>
            </Link>
          </li>
          <li
            className={
              ['dps-tests', 'dps-test'].includes(activeMenu) ? 'active' : ''
            }>
            <Link to={DPS_TEST_ROUTE}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.146"
                height="14.862"
                viewBox="0 0 11.146 14.862">
                <path
                  id="clipboard"
                  d="M9.753,1.858H7.181A1.535,1.535,0,0,0,7.2,1.626a1.626,1.626,0,1,0-3.251,0,1.535,1.535,0,0,0,.017.232H1.393A1.394,1.394,0,0,0,0,3.251V13.469a1.394,1.394,0,0,0,1.393,1.393h8.36a1.394,1.394,0,0,0,1.393-1.393V3.251A1.394,1.394,0,0,0,9.753,1.858ZM5.573.929a.7.7,0,1,1-.7.7A.695.695,0,0,1,5.573.929Zm4.644,12.54a.466.466,0,0,1-.464.464H1.393a.466.466,0,0,1-.464-.464V3.251a.466.466,0,0,1,.464-.464H2.787v.581a.349.349,0,0,0,.348.348H8.012a.349.349,0,0,0,.348-.348V2.787H9.753a.466.466,0,0,1,.464.464Z"
                />
              </svg>

              <span>DPS Test</span>
            </Link>
          </li>
          <li
            className={
              ['foul', 'create-foul'].includes(activeMenu) ? 'active' : ''
            }>
            <Link to={FOUL_ROUTE}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.251"
                height="13.251"
                viewBox="0 0 13.251 13.251">
                <path
                  id="square"
                  d="M11.831,32H1.42A1.42,1.42,0,0,0,0,33.42V43.831a1.42,1.42,0,0,0,1.42,1.42H11.831a1.42,1.42,0,0,0,1.42-1.42V33.42A1.42,1.42,0,0,0,11.831,32ZM12.3,43.831a.475.475,0,0,1-.473.473H1.42a.475.475,0,0,1-.473-.473V33.42a.475.475,0,0,1,.473-.473H11.831a.475.475,0,0,1,.473.473Z"
                  transform="translate(0 -32)"
                />
              </svg>

              <span>Foul</span>
            </Link>
          </li>
          <li className={['report'].includes(activeMenu) ? 'active' : ''}>
            <Link to={ADMIN_REPORT}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.77"
                height="13.026"
                viewBox="0 0 9.77 13.026">
                <path
                  id="file-chart-line"
                  d="M3.46,8.141H3.053a.2.2,0,0,0-.2.2v2.442a.2.2,0,0,0,.2.2H3.46a.2.2,0,0,0,.2-.2V8.345A.2.2,0,0,0,3.46,8.141ZM5.088,5.7H4.681a.2.2,0,0,0-.2.2v4.885a.2.2,0,0,0,.2.2h.407a.2.2,0,0,0,.2-.2V5.9A.2.2,0,0,0,5.088,5.7ZM6.106,7.531v3.257a.2.2,0,0,0,.2.2h.407a.2.2,0,0,0,.2-.2V7.531a.2.2,0,0,0-.2-.2H6.31A.2.2,0,0,0,6.106,7.531Zm3.3-5.038L7.277.359A1.221,1.221,0,0,0,6.415,0H1.221A1.225,1.225,0,0,0,0,1.224V11.805a1.221,1.221,0,0,0,1.221,1.221H8.549A1.221,1.221,0,0,0,9.77,11.805V3.358a1.227,1.227,0,0,0-.359-.865ZM6.514.829A.4.4,0,0,1,6.7.936L8.836,3.07a.4.4,0,0,1,.107.188H6.514ZM8.956,11.805a.408.408,0,0,1-.407.407H1.221a.408.408,0,0,1-.407-.407V1.224A.41.41,0,0,1,1.221.814H5.7V3.462a.608.608,0,0,0,.611.609H8.956Z"
                />
              </svg>

              <span>Report</span>
            </Link>
          </li>
          <li
            className={
              ['rollcall', 'rollcall-overview'].includes(activeMenu)
                ? 'active'
                : ''
            }>
            <Link to={ADMIN_ROLLOVER}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M336 64h-88.58a57.06 57.06 0 0 0 .58-8 56 56 0 0 0-112 0 57.06 57.06 0 0 0 .58 8H48a48 48 0 0 0-48 48v352a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V112a48 48 0 0 0-48-48zM192 32a24 24 0 1 1-24 24 24 24 0 0 1 24-24zm160 432a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V112a16 16 0 0 1 16-16h48v20a12 12 0 0 0 12 12h168a12 12 0 0 0 12-12V96h48a16 16 0 0 1 16 16zM236.81 336c-21.5 2.38-22.6 8-44.81 8s-23.31-5.58-44.81-8C101.31 336 64 369 64 409.59 64 435 83 448 102.41 448h179.18C301 448 320 435 320 409.59 320 369 282.69 336 236.81 336zm44.78 80H102.41c-6.82 0-6.41-3.19-6.41-6.41 0-22.93 23-41.59 51.19-41.59 4.66 0 18.43 8 44.81 8s40.15-8 44.81-8C265 368 288 386.66 288 409.59c0 3.22.41 6.41-6.41 6.41zM192 320a80 80 0 1 0-80-80 80.09 80.09 0 0 0 80 80zm0-128a48 48 0 1 1-48 48 48.05 48.05 0 0 1 48-48z" />
              </svg>
              <span>Roll Call</span>
            </Link>
          </li>
          <li className={['settings'].includes(activeMenu) ? 'active' : ''}>
            <Link to={ADMIN_SETTINGS}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.418"
                height="12.616"
                viewBox="0 0 14.418 12.616">
                <path
                  id="sliders-h"
                  d="M14.193,41.912H5.407V40.786a.674.674,0,0,0-.676-.676H3.379a.674.674,0,0,0-.676.676v1.126H.225A.226.226,0,0,0,0,42.138v.451a.226.226,0,0,0,.225.225H2.7V43.94a.674.674,0,0,0,.676.676H4.731a.674.674,0,0,0,.676-.676V42.813h8.786a.226.226,0,0,0,.225-.225v-.451A.226.226,0,0,0,14.193,41.912Zm-9.687,1.8H3.6v-2.7h.9ZM14.193,33.8H7.209V32.676A.674.674,0,0,0,6.533,32H5.181a.674.674,0,0,0-.676.676V33.8H.225A.226.226,0,0,0,0,34.028v.451a.226.226,0,0,0,.225.225h4.28V35.83a.674.674,0,0,0,.676.676H6.533a.674.674,0,0,0,.676-.676V34.7h6.984a.226.226,0,0,0,.225-.225v-.451A.226.226,0,0,0,14.193,33.8ZM6.308,35.6h-.9V32.9h.9Zm7.885,2.253H11.714V36.731a.674.674,0,0,0-.676-.676H9.687a.674.674,0,0,0-.676.676v1.126H.225A.226.226,0,0,0,0,38.083v.451a.226.226,0,0,0,.225.225H9.011v1.126a.674.674,0,0,0,.676.676h1.352a.674.674,0,0,0,.676-.676V38.758h2.478a.226.226,0,0,0,.225-.225v-.451A.226.226,0,0,0,14.193,37.857Zm-3.379,1.8h-.9v-2.7h.9Z"
                  transform="translate(0 -32)"
                />
              </svg>

              <span>Settings</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="sidebar-backdrop"></div>
    </Fragment>
  )
}
