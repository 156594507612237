import React, { Fragment } from 'react'
import ContentLoader from 'react-content-loader'

export const MyLoader = (props) => {
  const { rowCount = 3, columncount = 5, speed = 2, barHeight = 10 } =
    props || {}
  const width = columncount * 133
  const height = rowCount * 70
  const loader = Array(rowCount)
    .fill()
    .map((e, i) => (
      <Fragment>
        <circle cx="21" cy={20 + i * 50} r="20" />
        {Array(columncount)
          .fill()
          .map((e, j) => (
            <>
              <rect
                x={48 + j * 140}
                y={10 + i * 50}
                rx="3"
                ry="3"
                width={Math.random() * 100 + 68}
                height={barHeight}
              />
              <rect
                x={48 + j * 140}
                y={32 + i * 50}
                rx="3"
                ry="3"
                width="52"
                height={barHeight}
              />
            </>
          ))}
      </Fragment>
    ))

  return (
    <ContentLoader
      speed={speed}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}>
      {loader}
    </ContentLoader>
  )
}

export const Truckloader = (props) => (
  <ContentLoader
    speed={2}
    width={340}
    height={84}
    viewBox="0 0 340 84"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
    <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
    <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
    <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
    <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
    <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
    <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
    <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
  </ContentLoader>
)

export const TableLoader = (props) => (
  <ContentLoader
    speed={2}
    width={340}
    height={9}
    viewBox="0 0 340 9"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
    <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
  </ContentLoader>
)
export const SmallLoader = (props) => (
  <ContentLoader
    speed={2}
    width={30}
    height={9}
    viewBox="0 0 30 9"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
    <circle cx="275" cy="-31" r="10" />
  </ContentLoader>
)
