import React from 'react'
import { Card, CardTitle, Table } from 'reactstrap'
import noData from '../../../images/no-data.svg'


export default function Dpstest(props) {
  const { DpsTest } = props
  return (
    <>
      <Card body className="drive-data-card">
        <CardTitle tag="h5">Dps Test</CardTitle>
        {!!DpsTest.length && (
          <Table responsive hover>
            <thead>
              <tr>
                <th>Test Date</th>
                <th>Test Hours</th>
                <th>Comments</th>
                <th>Test Result</th>
              </tr>
            </thead>
            <tbody>
              {DpsTest.length &&
                DpsTest.map((dp) => (
                  <tr>
                    <td>{dp.testDate}</td>
                    <td>{dp.testHours}</td>
                    <td>{dp.comments}</td>
                    <td>{dp.testResult}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
        {!DpsTest.length && <div className="nodata-sec"><img src={noData} /></div>}
      </Card>
    </>
  )
}
