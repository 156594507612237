import React, { useState, useEffect } from 'react'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
  Container,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'
import { useHistory } from 'react-router'
import classnames from 'classnames'
import { useSnackbar } from 'notistack'
import ChangePassword from './ChangePassword'
import API from '../../../constants/API'
import {
  CustomMessage,
  CustomPageLoading,
} from '../../../function/Helperfunction'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { NUMBER_REGEX, PHONE_NUMBER_REGEX } from '../../../constants/Regex'
import { authHeader, getUserId } from '../../../function/Mainfunction'
import { USER_DETAILS, UPDATE_USER_DETAILS } from '../../../constants/Apipath'

function Settings() {
  const [loading, setloading] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const [formData, setformData] = useState({})

  const Goback = () => {
    history.goBack()
  }

  useEffect(() => {
    UserDeatils()
  }, [])

  const UserDeatils = async (values) => {
    const config = {
      method: 'GET',
      url: USER_DETAILS,
      headers: authHeader(),
      params: {
        userId: getUserId(),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { data } = response.data
      setformData(data)
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const UpdateUser = async (values) => {
    const config = {
      method: 'PUT',
      url: UPDATE_USER_DETAILS,
      headers: authHeader(),
      data: values,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status } = response.data
      if (status === 'Success') {
        CustomMessage(
          'User Details Update Successful',
          'success',
          enqueueSnackbar
        ) //call toster
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name Required'),
    lastName: Yup.string().required('Last name Required'),
    email: Yup.string().email('Invalid Email').required('Email Required'),
    dateOfBirth: Yup.string()
      .max(new Date(), 'Invalid date')
      .required('Date Required'),
    phoneNumber: Yup.string()
      .matches(PHONE_NUMBER_REGEX, 'invalid phone number')
      .max(10, 'invalid phone number')
      .required('Phone number Required'),
    city: Yup.string().required('City Required'),
    zip: Yup.string()
      .max(6, 'Invalid Zip code')
      .matches(NUMBER_REGEX, 'Invalid Zip code')
      .required('Zip Required'),
    state: Yup.string().required('State Required'),
    address: Yup.string().required('Address Required'),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => UpdateUser(values),
  })

  return (
    <div>
      <div className="wrapper">
        {loading && <CustomPageLoading />}
        <div className="page-title">
          <div className="settingstitle col-lg-12 col-md-12">
            <h4>Settings</h4>
            <div className="btn-right">
              <Button onClick={Goback} color="primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.543"
                  height="8.695"
                  viewBox="0 0 5.543 9.695">
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M12.921,11.04,16.59,7.374a.693.693,0,1,0-.981-.978l-4.156,4.153a.691.691,0,0,0-.02.955l4.173,4.182a.693.693,0,1,0,.981-.978Z"
                    transform="translate(-11.251 -6.194)"
                    fill="#fff"
                  />
                </svg>
                Back
              </Button>
            </div>
          </div>
        </div>

        <Container fluid>
          <Card body className="mt-4">
            {/* Nav Items */}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1')
                  }}>
                  My Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2')
                  }}>
                  Security
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Form form="true" onSubmit={handleSubmit}>
                  <div className="page">
                    <Row>
                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">First name</Label>
                          <Input
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="Enter first name"
                            value={values.firstName}
                            invalid={
                              errors.firstName && touched.firstName
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormFeedback>
                            {errors.firstName && touched.firstName
                              ? errors.firstName
                              : ''}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">Last name</Label>
                          <Input
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Enter last name"
                            value={values.lastName}
                            invalid={
                              errors.lastName && touched.lastName ? true : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormFeedback>
                            {errors.lastName && touched.lastName
                              ? errors.lastName
                              : ''}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">Email address</Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter email"
                            value={values.email}
                            disabled={true}
                            invalid={
                              errors.email && touched.email ? true : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormFeedback>
                            {errors.email && touched.email ? errors.email : ''}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">DOB</Label>
                          <Input
                            type="date"
                            name="dateOfBirth"
                            id="dateOfBirth"
                            max={moment(new Date()).format('YYYY-MM-DD')}
                            value={moment(values.dateOfBirth).format(
                              'YYYY-MM-DD'
                            )}
                            onBlur={handleBlur}
                            invalid={
                              touched.dateOfBirth && errors.dateOfBirth
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            placeholder="Enter DOB"
                          />
                          <FormFeedback>
                            {touched.dateOfBirth && errors.dateOfBirth}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">Phone number</Label>
                          <Input
                            type="tel"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="Enter phone number"
                            value={values.phoneNumber}
                            invalid={
                              errors.phoneNumber && touched.phoneNumber
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormFeedback>
                            {errors.phoneNumber && touched.phoneNumber
                              ? errors.phoneNumber
                              : ''}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">Address</Label>
                          <Input
                            type="text"
                            name="address"
                            id="address"
                            placeholder="Enter address"
                            value={values.address}
                            invalid={
                              errors.address && touched.address ? true : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormFeedback>
                            {errors.address && touched.address
                              ? errors.address
                              : ''}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">City</Label>
                          <Input
                            type="text"
                            name="city"
                            id="city"
                            placeholder="Enter City"
                            value={values.city}
                            invalid={errors.city && touched.city ? true : false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormFeedback>
                            {errors.city && touched.city ? errors.city : ''}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">State</Label>
                          <Input
                            type="text"
                            name="state"
                            id="state"
                            placeholder="Enter State"
                            value={values.state}
                            invalid={
                              errors.state && touched.state ? true : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormFeedback>
                            {errors.state && touched.state ? errors.state : ''}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md="4" xl="3">
                        <FormGroup>
                          <Label for="">Zip</Label>
                          <Input
                            type="text"
                            name="zip"
                            id="zip"
                            placeholder="Enter Zip"
                            value={values.zip}
                            invalid={errors.zip && touched.zip ? true : false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormFeedback>
                            {errors.zip && touched.zip ? errors.zip : ''}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="btn-center">
                      <Button color="primary" className="pull-left">
                        Update
                      </Button>
                    </div>
                  </div>
                </Form>
              </TabPane>
              <TabPane tabId="2">
                <ChangePassword />
              </TabPane>
            </TabContent>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default Settings
