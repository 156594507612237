import * as types from '../action/ActionType'
import { batid_, stuid_ } from '../helper'
export default function AttendanceRepsReducer(state = {}, action) {
  const { type, payload, batchId, studentid, value, attid, name } = action
  switch (type) {
    case types.ATTENDANCE_ONCHANGE_HANDLE:
      let changevalue = { ...state }
      changevalue[batid_ + batchId + stuid_ + studentid] = {
        attendanceId: attid,
        userId: studentid,
        batchId: batchId,
        isDeleted: false,
        driverLicense: false,
        permit: false,
        medicalCard: false,
        ...changevalue[batid_ + batchId + stuid_ + studentid],
        [name]: value,
      }

      return changevalue
    case types.GET_ATTENANCE_PREVIEW:
      let updatevalue = { ...state }
      updatevalue[batid_ + batchId + stuid_ + studentid] = {
        userId: studentid,
        batchId: batchId,
        isDeleted: false,
        ...payload,
      }

      return updatevalue

    case types.REFRESH_DATA:
      return (state = {})
    default:
      return state
  }
}
