import React from 'react'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import Avatar, { ConfigProvider } from 'react-avatar'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  closeIcon: {
    color: '#fff',
    float: 'right',
    margin: '-8px',
  },
}))

//Custom Page Loading
export const CustomPageLoading = () => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export const CustomMessage = (message, type, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: ['success', 'info'].includes(type) ? false : true,
    autoHideDuration: ['success', 'info'].includes(type) && 3000,
  })
}

export const locationParams = (search) => {
  var searchParams = new URLSearchParams(search)
  let ob = {}
  searchParams.forEach(function (value, key) {
    ob[key] = value
  })
  return ob
}
export const JSONParser = (str, def) => {
  try {
    return JSON.parse(str) || def
  } catch (e) {
    console.warn(e)
    return def
  }
}

export const ProfileImage = (props) => {
  const { BatchStudents } = props
  const student = JSONParser(BatchStudents, [])
  const ca = student.slice(0, 6)
  const alrtCall = () => {
    window.alert('I am alert')
  }
  return (
    <div className="marginLeftSmall">
      {ca.map((rec, index) => {
        return (
          <ConfigProvider colors={['red', 'green', 'blue']}>
            <Avatar
              name={rec.studentName}
              size={20}
              round="50px"
              fgColor="#FFF"
              className="avatars"
            />
          </ConfigProvider>
        )
      })}
      {student.length > 6 ? (
        <Avatar
          name={`${'+'} ${student.length - ca.length}`}
          size={21}
          round="50px"
          data-len={student.length - ca.length}
          onClick={alrtCall}
          className="avatars"
          color="grey"
        />
      ) : (
        ''
      )}
    </div>
  )
}

export function sorting(a, b, value) {
  const AssDess = {
    LA: { columnName: 'lastName', orderBy: 'asc' },
    FA: { columnName: 'firstName', orderBy: 'asc' },
    LD: { columnName: 'lastName', orderBy: 'desc' },
    FD: { columnName: 'firstName', orderBy: 'desc' },
  }
  var nameA = a[AssDess[value].columnName]?.toLowerCase() // ignore upper and lowercase
  var nameB = b[AssDess[value].columnName]?.toLowerCase() // ignore upper and lowercase
  if (AssDess[value].orderBy == 'asc') {
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  } else {
    if (nameA < nameB) {
      return 1
    }
    if (nameA > nameB) {
      return -1
    }

    // names must be equal
    return 0
  }
}
export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1)
}
