export const INITIAL_ROUTE = '/'
export const ADMIN_ROUTE = '/admin'
export const INSTRCTOR_ROUT = '/instructor'
export const ROLLCALL_INITIAL = '/rollcalladmin'
export const ADMIN_DASHBOARD = '/admin/dashboard'
export const SIGNIN_ROUTE = '/signin'
export const FORGET_PASSWORD = '/forgetpassword'
export const USER_ROUTE = '/admin/users'
export const CREATE_USER_ROUTE = USER_ROUTE + '/create-user'
export const UPDATE_USER_ROUTE = '/admin/user'
export const STUDENT_ROUTE = '/admin/student'
export const CREATE_STUDENT_ROUTE = STUDENT_ROUTE + '/create-student'
export const STUDENT_DETAILS_ROUTE = '/admin/student-details'
export const UPDATE_STUDENT_ROUTE = '/admin/student-detail'
export const TRUCK_ROUTE = '/admin/trucks'
export const UPDATE_TRUCK_ROUTE = '/admin/truck'

export const CREATE_TRUCK_ROUTE = TRUCK_ROUTE + '/create-truck'

export const DPS_TEST_ROUTE = '/admin/dps-tests'
export const CREATE_DPS_TEST_ROUTE = DPS_TEST_ROUTE + '/create-dps-test'
export const UPDATE_DPS_TEST_ROUTE = '/admin/dps-test'
export const FOUL_ROUTE = '/admin/foul'
export const STATION = '/admin/stations'
export const BATCH = '/admin/batch'
export const ADMIN_REPORT = '/admin/report'
export const CREATE_STATION = STATION + '/create-station'
export const CREATE_BATCH = BATCH + '/create-batch'
export const UPDATE_BATCH_ROUTE = BATCH + '/update-batch'
export const UPDATE_STATION_ROUTE = '/admin/station'
export const ADMIN_SETTINGS = '/admin/settings'
export const ADMIN_BATCH = '/admin/batch'
export const CREATE_FOUL_ROUTE = '/admin/create-foul'
export const UPDATE_FOUL_ROUTE = '/admin/foul'
export const ADMIN_BATCHDETAILS = '/admin/batchdetails'
export const ADMIN_ROLLOVER = '/admin/rollcall'
//
export const INSTRUCTOR_HOME_ROUTE = '/instructor/home'
export const UPDATE_INSTRUCTOR_ROUTE = '/admin/instructor'
export const BATCH_ROUTE = '/instructor/batch'
export const BATCH_DETAILS_ROUTE = '/instructor/batch-details'
export const CREATE_BTW_ROUTE = '/instructor/add_drive'
export const INSTRUCTOR_SETTINGS = '/instructor/settings'
export const INSTRUCTOR_ATTENDANCE = '/instructor/attendance'
export const INSTRUCTOR_ROLLCALL = '/instructor/rollcall'
export const INSTRUCTOR_STUDENT_DETAILS = '/instructor/student_details'
export const PAGE_NOT_FOUND_ROUTE = '*'
export const INSTRUCTOR_ROLLOVER_VIEW = '/instructor/instructorRolloverView'

export const ADMIN_ROLLOVER_VIEW = '/admin/rollcall-overview'

export const ROLLCALL = ROLLCALL_INITIAL + '/rollcall'
export const ROLLCALL_VIEW = ROLLCALL_INITIAL + '/rollcall-overview'
export const CLASSROOM_ROLLCALL_VIEW = '/admin/classroom-rollcall-overview'
export const ROLLCALL_CLASS_ROOM =
  ROLLCALL_INITIAL + '/classroom-rollcall-overview'
