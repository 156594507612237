import React, { useState } from 'react'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

function Dpstestattendence(props) {
  const { value, userid, dpsstatus } = props

  const { status, onclick } = value[dpsstatus] || {}
  const [isOpen, setisOpen] = useState(false)
  const toggle = () => setisOpen((val) => !val)
  let statuscolor = {
    Present: 'success',
    Unmarked: 'primary',
    Absent: 'danger',
  }

  return (
    <>
      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
        <Button
          id="caret"
          onClick={() => onclick(userid)}
          color={statuscolor[status]}>
          {status}
        </Button>
        <DropdownToggle split color={statuscolor[status]} />
        <DropdownMenu>
          <DropdownItem>
            {' '}
            <div onClick={() => value['unmarked'].onclick(userid)}>Present</div>
          </DropdownItem>
          <DropdownItem>
            {' '}
            <div onClick={() => value['present'].onclick(userid)}>Absent</div>
          </DropdownItem>
          <DropdownItem>
            {' '}
            <div onClick={() => value['absent'].onclick(userid)}>Unmark</div>
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  )
}

export default Dpstestattendence
