import React, { Fragment, useRef, useEffect, useState, useMemo } from 'react'
import {
  CustomPageLoading,
  CustomMessage,
  JSONParser,
} from '../../../function/Helperfunction'
import Swal from 'sweetalert2'
import moment from 'moment'
import TimePicker from 'react-time-picker'
import Avatar from 'react-avatar'
import {
  Button,
  Container,
  Card,
  Row,
  CustomInput,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  CardTitle,
  Badge,
} from 'reactstrap'
import { useHistory, useParams } from 'react-router'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import Select, { components } from 'react-select'
import API from '../../../constants/API'
import {
  CREATE_BATCH_URL,
  STUDENT_LIST,
  LIST_INSTRUCTOR,
  LIST_STATION,
  CREATE_BATCH_STUDENTS_URL,
  LIST_TRUCK,
  GET_BATCH,
  UPDATE_BATCH,
  DELETE_BATCH,
  STUDENT_REPS,
  CLASSROOM_ROLLCALL_REPS,
} from '../../../constants/Apipath'

import {
  ADMIN_ROLLOVER_VIEW,
  BATCH,
  CLASSROOM_ROLLCALL_VIEW,
  CREATE_BATCH,
} from '../../../constants/RoutePaths'
import { authHeader, authRole, getUserId } from '../../../function/Mainfunction'
import { BackArrow } from '../../../function/Icons'
import { debounce } from 'underscore'

// import ClassRollOverListModal from '../rollover/ClassRollOverListModal'
import StudentSelectionPopup from '../rollover/RoadSideRollCall'
let stuId_ = 'stuId_'

export default function CommonBatchForm() {
  const History = useHistory()
  const { batchId } = useParams()
  const [loading, setloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [studentList, setStudentList] = useState([])
  const [stationList, setStationList] = useState([])
  const [truckList, setTruckList] = useState([])
  const [instructorList, setInstructorList] = useState([
    // {
    //   label: 'Select all',
    //   value: props.selectedValue === 'all',
    // },
  ])

  const [selectedInstructor, setSelectedInstructor] = useState('')
  const [truckName, settruckName] = useState('')
  const [station, setstation] = useState('')
  const [date, setDate] = useState('')
  const [selectedStudents, setSelectedStudents] = useState([])
  const [searchStudent, setSearchStudent] = useState('')
  const [formData, setFormData] = useState({})
  const [addStudentmodal, setaddStudentmodal] = useState(false)
  const [addClassStudentmodal, setaddClassStudentmodal] = useState(false)
  const [roadSideCall, setRoadsidecall] = useState([])
  console.log(selectedStudents)

  useEffect(() => {
    if (batchId) {
      GetBatch()
    }
  }, [])

  //get batch
  const GetBatch = async () => {
    const config = {
      method: 'GET',
      url: GET_BATCH,
      headers: authHeader(),
      params: { batchId: batchId },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setFormData({
          ...data,
          truckId: { value: data.truckId, label: data.truckName },
          stationId: { value: data.stationId, label: data.stationName },
        })

        // setSelectedInstructor(data?.instructorDetails)
        setSelectedInstructor(
          JSONParser(data?.instructorDetails, []).map((s) => ({
            ...s,
            instructorId: s.instructorId,
          }))
        )
        console.log(selectedInstructor)
        // let detail = JSONParser(data?.instructorDetails)
        // if (detail.length == 1) {
        //   console.log(detail[0]?.instructorId)
        //   setSelectedInstructor(detail[0]?.instructorId)
        // } else {
        //   setSelectedInstructor('all')
        // }
        // if (data?.instructorId) {
        //   setSelectedInstructor(data?.instructorId)
        // } else if (data?.instructorDetails) {
        //   setSelectedInstructor('all')
        // }
        setSelectedStudents(
          JSONParser(data.studentDetails, []).map((s) => ({
            ...s,
            value: s.userId,
            label: s.studentName,
            isWeekendStudent: s.isWeekendStudent,
            isDeleted: false,
          }))
        )
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
      }
    } finally {
      setloading(false)
    }
  }
  const BatchUpdate = async (values) => {
    console.log(
      11111111,
      date,
      values,
      moment(date).format('YYYY/MM/DD'),
      values.batchStartDate
    )

    let reqData = {
      ...values,
      stationId: values?.stationId?.value,
      truckId: values?.truckId?.value,
      instructorId: selectedInstructor[0].instructorId.toString() || '',
      createdBy: getUserId(),
      instructorDetails: '',
    }
    console.log('istruct', selectedInstructor)
    // if (selectedInstructor === 'all') {
    //   reqData.instructorDetails = instructorList.map((e) => e.value).join(',')
    //   delete reqData.instructorId
    // } else {
    //   reqData.instructorId = selectedInstructor.toString()
    //   delete reqData.instructorDetails
    // }
    console.log('22222', values)
    const config = {
      method: batchId ? 'PUT' : 'POST',
      headers: authHeader(),
      url: batchId ? UPDATE_BATCH : CREATE_BATCH_URL,
      data: reqData,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, batchId: newBatchId } = response.data
      if (status === 'Success') {
        let studentAPIConfig = {
          method: 'POST',
          headers: authHeader(),
          url: CREATE_BATCH_STUDENTS_URL,
          data: {
            batchId: batchId || newBatchId,
            batchStudentId: selectedStudents.map((s) => s.userId).join(','),
          },
        }
        const secondResponse = await API(studentAPIConfig)
        const { status: secondResStatus } = secondResponse.data

        if (secondResStatus === 'Success') {
          let roadSideAPIResponse = {}
          if (values.isRoadsideRollcall) {
            console.log('12345', values.isRoadsideRollcall)
            let roadSideAPIConfig = {
              method: 'POST',
              url: STUDENT_REPS,
              headers: authHeader(),
              data: {
                createdBy: getUserId(),
                attendanceDate: values.batchStartDate,
                repsRoleAttendanceDetails: Object.values(selectedStudents).map(
                  (stu) => ({
                    classRoomAttendanceId: '',
                    userId: stu.userId,
                    am: stu.am,
                    pm: stu.pm,
                    noon: stu.noon,
                    isDeleted: stu.isDeleted,
                    attendanceId: stu.attendanceId,
                    road: stu.road,
                    stationInstructor: stu.stationInstructor,
                    driveInstructor: stu.driveInstructor,
                    stationId: stu.stationId,
                    rollCallTime: stu.rollCallTime,
                  })
                ),
              },
            }
            roadSideAPIResponse = await API(roadSideAPIConfig)
            console.log(roadSideAPIResponse)
          }

          let classRoomAPIResponse = {}
          if (values.isClassroomRollcall) {
            console.log('54321', values.isClassroomRollcall)
            let classRommAPIConfig = {
              method: 'POST',
              url: CLASSROOM_ROLLCALL_REPS,
              headers: authHeader(),
              data: {
                createdBy: getUserId(),
                attendanceDate: values.batchStartDate,
                classRoomAttendanceDetails: Object.values(selectedStudents).map(
                  (stu) => ({
                    classRoomAttendanceId: stu.classRoomAttendanceId,
                    userId: stu.userId,
                    am: stu.am,
                    pm: stu.pm,
                    noon: stu.noon,
                    isDeleted: stu.isDeleted,
                  })
                ),
              },
            }
            classRoomAPIResponse = await API(classRommAPIConfig)
          }
          const { status: classRoomAPIStatus } = classRoomAPIResponse.data || {}
          const { status: roadSideAPIResStatus } =
            roadSideAPIResponse.data || {}

          if (
            roadSideAPIResStatus === 'Success' ||
            classRoomAPIStatus === 'Success'
          ) {
            CustomMessage(
              `Batch ${batchId ? 'Updated' : 'Created'} Successfull`,
              'success',
              enqueueSnackbar
            )
            History.push(BATCH)
            resetForm()
          }
        }
      }
    } catch (error) {
      console.error(error)
      if (error.response) {
        let { data } = error.response
        CustomMessage(data?.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    batchName: Yup.string().required('Batch Name Required'),
    batchStartTime: Yup.string()
      .required('Batch Start Time Required')
      .nullable(),
    batchEndTime: Yup.string().required('Batch End Time Required').nullable(),
    batchStartDate: Yup.date()
      // .min(new Date(), 'Invalid Date')
      .required('Batch Start Date Required'),
    batchEndDate: Yup.date()
      // .min(new Date(), 'Invalid Date')
      .required('Batch End Date Required'),
    //  instructorId: Yup.string().required('Instructor Name  Required'),
    trainingTypeId: Yup.string().required('Training Type   Required'),
    batchCategory: Yup.string().required('Batch Category Required'),
    stationId: Yup.object().test(
      'passwords-match',
      'Station  is required',
      function (value) {
        console.log(value)
        if (value?.value) {
          return true
        } else {
          return false
        }
      }
    ),
    truckId: Yup.object().test(
      'passwords-match',
      'Truck is required',
      function (value) {
        console.log(value)
        if (value?.value) {
          return true
        } else {
          return false
        }
      }
    ),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    setValues,
    handleBlur,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: batchId
      ? formData
      : {
          batchName: '',
          batchStartDate: '',
          batchEndDate: '',
          batchStartTime: '',
          batchEndTime: '',
          notes: '',
          instructorId: '',
          trainingTypeId: '',
          batchCategory: '',
          stationId: '',
          truckId: '',
          isRoadsideRollcall: false,
          isClassroomRollcall: false,
        },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => BatchUpdate(values),
  })

  const getStudentList = async () => {
    const config = {
      method: 'GET',
      url: STUDENT_LIST,
      headers: authHeader(),
      params: {
        studentStatus: 'Active',
        count: 1000,
      },
    }
    // setloading(true)
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status === 'Success') {
        setStudentList(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
    // const config = {
    //   method: 'GET',
    //   headers: authHeader(),
    //   url: STUDENT_LIST,
    //   params: {
    //     firstName: searchStudent,
    //     studentStatus: 'Active',
    //   },
    // }
    // //  setloading(true)
    // try {
    //   const response = await API(config)
    //   const { status, data } = response.data
    //   if (status === 'Success') {
    //     console.log(data)

    //     let isWeekendStudent = []
    //     let isWeeDaysStudent = []
    //     let newData = [
    //       {
    //         label: 'Weekend student',
    //         options: isWeekendStudent,
    //       },
    //       {
    //         label: 'weekdays student',
    //         options: isWeeDaysStudent,
    //       },
    //     ]

    //     data.forEach((element) => {
    //       if (element.isWeekendStudent) {
    //         isWeekendStudent.push({
    //           value: element.userId,
    //           label: element.firstName + ' ' + element.lastName,
    //           isWeekendStudent: element.isWeekendStudent,
    //         })
    //       } else {
    //         isWeeDaysStudent.push({
    //           value: element.userId,
    //           label: element.firstName + ' ' + element.lastName,
    //           isWeekendStudent: element.isWeekendStudent,
    //         })
    //       }
    //     })

    //     setStudentsList(newData)
    //   }
    // } catch (error) {
    //   console.error(error)
    // } finally {
    //   // setloading(false)
    // }
  }

  const getInstructorList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_INSTRUCTOR,
      params: {
        count: 50000,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setInstructorList(
          data.map((d) => ({ value: d.userId, label: d.firstName }))
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const getTruckList = async (val) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_TRUCK,
      params: {
        truckName: val || '',
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setTruckList(
          data.map((d) => ({ value: d.truckId, label: d.truckName }))
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const getStationList = async (values) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_STATION,
      params: {
        stationName: values,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setStationList(
          data.map((d) => ({ value: d.stationId, label: d.stationName }))
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  // const handleStudentChange = (e) => {
  //   console.log(selectedStudents)
  //   let id = (e?.target?.id || '').replace('selectStudent_', '')
  //   id = parseInt(id)
  //   setSelectedStudents((list) => {
  //     console.log(
  //       list.includes(id) ? list.filter((f) => f != id) : [...list, id]
  //     )
  //     return list.includes(id) ? list.filter((f) => f != id) : [...list, id]
  //   })
  //   console.log(e)
  //   console.log(e?.target?.id)
  //   console.log(e?.target?.value)
  //   console.log(e?.target?.checked)
  // }
  const successfullcall = (repsRoleAttendanceDetails) => {
    setRoadsidecall(repsRoleAttendanceDetails)
    console.log(8888888888, repsRoleAttendanceDetails)
    setSelectedStudents(repsRoleAttendanceDetails)
    // History.push(CREATE_BATCH)
  }
  // const classrommRollsuccess = () => {
  //   History.push(`${CLASSROOM_ROLLCALL_VIEW}/${date}`)
  // }
  const addStudenttoggle = () => {
    setaddStudentmodal(true)
    // setaddStudentmodal((val) => !val)
  }
  const closeStudenttoggle = () => {
    setaddStudentmodal(false)
    // setaddStudentmodal((val) => !val)
  }
  // const addClassStudenttoggle = () => {
  //   setaddClassStudentmodal(true)
  // }
  // const closeClassStudenttoggle = () => {
  //   setaddClassStudentmodal(false)
  // }

  const dateChange = (e) => {
    setDate(e.target.value)
    handleChange(e)
  }
  const handleDelete = async () => {
    Swal.fire({
      title: 'Are you want to Delete this batch?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteAPI(batchId)
      }
    })
  }

  const handleDeleteAPI = async (batchId) => {
    const config = {
      method: 'DELETE',
      headers: authHeader(),
      url: DELETE_BATCH,
      params: {
        batchId: batchId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar)
        History.push(BATCH)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const delaySearch = useRef(
    debounce((searchStudent) => getStudentList(searchStudent))
  ).current

  // const nameHandle = (e, a) => {
  //   const onchangeselectedStudent = e[0]
  //   if (onchangeselectedStudent) {
  //     let newselect = selectedStudents.find(
  //       (v) => v.value == onchangeselectedStudent.value
  //     )

  //     if (newselect) {
  //       setSelectedStudents((va) =>
  //         va.filter((v) => v.value != onchangeselectedStudent.value)
  //       )
  //     } else {
  //       setSelectedStudents((students) => {
  //         let flag = students.find(
  //           (stu) => stu.value === onchangeselectedStudent.value
  //         )
  //         console.log(flag, students, students, onchangeselectedStudent)
  //         return flag ? students : [...students, onchangeselectedStudent]
  //       })
  //     }
  //   }
  // }

  const RemoveStudent = (id) => {
    console.log(selectedStudents)
    let selectedStudentList = selectedStudents.filter((s) => {
      console.log(id, s.userId != id, s.userId, id)
      return s.userId != id
    })
    console.log(selectedStudentList)
    setSelectedStudents(selectedStudentList)
  }

  const stationNamehandle = (e) => {
    setstation(e)
  }
  const truckNamehandle = (e) => {
    settruckName(e)
  }
  // const InputHandle = (e) => {
  //   setSearchStudent(e)
  // }

  const optionHandle = (e) => {
    setValues((val) => ({ ...val, truckId: e }))
  }

  const stationHandle = (e) => {
    setValues((val) => ({ ...val, stationId: e }))
  }
  const searchTruck = useRef(
    debounce((truckName) => getTruckList(truckName), 500)
  ).current

  useEffect(() => {
    searchTruck(truckName)
  }, [truckName])

  useEffect(() => {
    delaySearch(searchStudent)
  }, [searchStudent])

  const searchStation = useRef(
    debounce((truckName) => getStationList(truckName), 500)
  ).current
  useEffect(() => {
    searchStation(station)
  }, [station])
  useEffect(() => {
    getInstructorList()
  }, [])

  const Back = () => {
    History.goBack()
  }

  useMemo(() => {
    if (values.batchCategory == 2) {
      setSelectedInstructor('')
    }
  }, [values.batchCategory])
  return (
    <Fragment>
      <div className="wrapper">
        {loading && <CustomPageLoading />}
        <div className="page-title">
          {batchId ? <h4>Update Batch</h4> : <h4>Create Batch</h4>}
          <div className="btn-right">
            {batchId && (
              <Button
                onClick={handleDelete}
                color="danger"
                className="pull-left">
                Delete
              </Button>
            )}
            <Button
              onClick={handleSubmit}
              color="primary"
              className="pull-left">
              {batchId ? 'Update' : 'Create'}
            </Button>
            <Button onClick={Back} color="primary">
              <BackArrow />
              Back
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <Row>
            <Col lg="5" xl="6" className="mt-2">
              <Card body>
                <CardTitle tag="h5">Fill Details</CardTitle>
                <FormGroup>
                  <Label for="">Batch name</Label>
                  <Input
                    type="text"
                    name="batchName"
                    id="batchName"
                    placeholder="Enter batch name"
                    value={values.batchName}
                    invalid={
                      errors.batchName && touched.batchName ? true : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormFeedback>
                    {errors.batchName && touched.batchName
                      ? errors.batchName
                      : ''}
                  </FormFeedback>
                </FormGroup>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="">Start Date</Label>
                      <Input
                        type="date"
                        name="batchStartDate"
                        id="batchStartDate"
                        placeholder=""
                        value={moment(values.batchStartDate).format(
                          'YYYY-MM-DD'
                        )}
                        onBlur={handleBlur}
                        invalid={
                          errors.batchStartDate && touched.batchStartDate
                            ? true
                            : false
                        }
                        // onChange={handleChange}
                        onChange={dateChange}
                      />
                      <FormFeedback>
                        {errors.batchStartDate && touched.batchStartDate
                          ? errors.batchStartDate
                          : ''}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="">End Date</Label>
                      <Input
                        type="date"
                        name="batchEndDate"
                        id="batchEndDate"
                        placeholder=""
                        min={moment(values.batchStartDate).format('YYYY-MM-DD')}
                        value={moment(values.batchEndDate).format('YYYY-MM-DD')}
                        onBlur={handleBlur}
                        invalid={
                          errors.batchEndDate && touched.batchEndDate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                      />
                      <FormFeedback>
                        {errors.batchEndDate && touched.batchEndDate
                          ? errors.batchEndDate
                          : ''}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="">Start Time</Label>
                      <br />
                      <Input
                        type="time"
                        name="batchStartTime"
                        id="batchStartTime"
                        placeholder="Enter Day In Time"
                        value={values.batchStartTime}
                        invalid={
                          errors.batchStartTime && touched.batchStartTime
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <FormFeedback
                        style={{
                          display:
                            errors.batchStartTime && touched.batchStartTime
                              ? 'block'
                              : 'none',
                        }}>
                        {errors.batchStartTime && touched.batchStartTime
                          ? errors.batchStartTime
                          : ''}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="">End time</Label>
                      <br />
                      <Input
                        type="time"
                        name="batchEndTime"
                        id="batchEndTime"
                        placeholder="Enter Day End Time"
                        value={values.batchEndTime}
                        invalid={
                          errors.batchEndTime && touched.batchEndTime
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <FormFeedback
                        style={{
                          display:
                            errors.batchEndTime && touched.batchEndTime
                              ? 'block'
                              : 'none',
                        }}>
                        {errors.batchEndTime && touched.batchEndTime
                          ? errors.batchEndTime
                          : ''}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="">Notes</Label>
                  <Input
                    type="textarea"
                    name="notes"
                    id="notes"
                    placeholder="Enter notes"
                    value={values.notes}
                    invalid={errors.notes && touched.notes ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormFeedback>
                    {errors.notes && touched.notes ? errors.notes : ''}
                  </FormFeedback>
                </FormGroup>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="">Training Type</Label>
                      <Input
                        type="select"
                        name="trainingTypeId"
                        id="trainingTypeId"
                        placeholder="Select Training Type"
                        value={values.trainingTypeId}
                        invalid={
                          errors.trainingTypeId && touched.trainingTypeId
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}>
                        <option value="">Please select</option>
                        <option value="1">Pretrip</option>
                        <option value="2">Incab</option>
                        <option value="3">Maneuver</option>
                        <option value="4">Drive</option>
                      </Input>
                      <FormFeedback
                        style={{
                          display:
                            errors.trainingTypeId && touched.trainingTypeId
                              ? 'block'
                              : 'none',
                        }}>
                        {errors.trainingTypeId && touched.trainingTypeId
                          ? errors.trainingTypeId
                          : ''}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="">Batch Category</Label>
                      <Input
                        type="select"
                        name="batchCategory"
                        id="batchCategory"
                        placeholder="Select Batch Category"
                        value={values.batchCategory}
                        invalid={errors.batchCategory && touched.batchCategory}
                        onChange={handleChange}
                        onBlur={handleBlur}>
                        <option value="">Please select</option>
                        <option value="1">Normal</option>
                        <option value="2">Floaters</option>
                      </Input>

                      <FormFeedback>
                        {errors.batchCategory && touched.batchCategory
                          ? errors.batchCategory
                          : ''}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="">Truck</Label>

                  <Select
                    options={truckList}
                    value={values.truckId}
                    // ref={selectInputRefStudent}
                    // placeholder="Select Student"
                    onChange={optionHandle}
                    onInputChange={truckNamehandle}
                  />

                  <FormFeedback
                    style={{
                      display:
                        errors.truckId && touched.truckId ? 'block' : 'none',
                    }}>
                    {errors.truckId && touched.truckId ? errors.truckId : ''}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="">Station</Label>

                  <Select
                    options={stationList}
                    value={values.stationId}
                    // ref={selectInputRefStudent}
                    // placeholder="Select Student"
                    onChange={stationHandle}
                    onInputChange={stationNamehandle}
                  />
                  {/* <Input
                    type="select"
                    name="stationId"
                    id="stationId"
                    placeholder="Select Station"
                    value={values.stationId}
                    invalid={errors.stationId && touched.stationId}
                    onChange={handleChange}
                    onBlur={handleBlur}>
                    <option value="">Please select</option>
                    {stationList.map((e) => (
                      <option value={e.stationId}>{e.stationName}</option>
                    ))}
                  </Input> */}
                  <FormFeedback
                    style={{
                      display:
                        errors.stationId && touched.stationId
                          ? 'block'
                          : 'none',
                    }}>
                    {errors.stationId && touched.stationId
                      ? errors.stationId
                      : ''}
                  </FormFeedback>
                </FormGroup>
              </Card>
            </Col>
            <Col lg="7" xl="6">
              <Row>
                <Col md="6" className="mt-2">
                  <Card body>
                    <CardTitle tag="h5">Select Instructor</CardTitle>
                    {console.log('testtttt', selectedInstructor)}
                    <FormGroup>
                      <Select
                        options={instructorList}
                        isClearable={true}
                        placeholder="Search"
                        isDisabled={values.batchCategory == 2}
                        name="instructorId"
                        value={instructorList.find(
                          (i) => i.value === selectedInstructor[0]?.instructorId
                        )}
                        onChange={(e) =>
                          setSelectedInstructor(
                            [{ instructorId: e?.value }] || ''
                          )
                        }
                        // onInputChange={() => {}}
                      />
                      {/* <DropDown
                        value={
                          selectedInstructor == 'all'
                            ? [
                                {
                                  label: 'Select all',
                                  value: 'all',
                                },
                              ]
                            : instructorList.filter(
                                (i) => i.value == selectedInstructor
                              )
                        }
                        options={instructorList}
                        handleChange={(e) =>
                          // console.log(e.value)
                          setSelectedInstructor(e?.value || '')
                        }
                        multi={true}
                        selectedValue={selectedInstructor}
                      /> */}
                      {console.log('sssss', selectedInstructor)}
                      <FormFeedback
                        style={{
                          display:
                            errors.instructorId && touched.instructorId
                              ? 'block'
                              : 'none',
                        }}>
                        {errors.instructorId && touched.instructorId
                          ? errors.instructorId
                          : ''}
                      </FormFeedback>
                    </FormGroup>
                    {/* {values.batchCategory == 2 &&
                      'Note: Instructor not available for Floater'} */}
                    <Col>
                      <FormGroup
                        check
                        style={{
                          marginTop: '10px',
                        }}>
                        <Label check>
                          <Input
                            name="isRoadsideRollcall"
                            checked={values.isRoadsideRollcall === true}
                            onChange={handleChange}
                            type="checkbox"
                          />{' '}
                          Road side Roll call
                        </Label>

                        <Label check style={{}}>
                          <Input
                            name="isClassroomRollcall"
                            checked={values.isClassroomRollcall === true}
                            onChange={handleChange}
                            type="checkbox"
                          />{' '}
                          Class room Roll call
                        </Label>
                      </FormGroup>
                    </Col>
                  </Card>
                </Col>

                <Col
                  md="6"
                  className="mt-2"
                  style={{
                    maxHeight: '650px',
                    overflowY: 'scroll',
                    marginBottom: '20px',
                  }}>
                  <Card body>
                    <CardTitle tag="h5">Select Student</CardTitle>

                    <FormGroup style={{ margin: 0 }}>
                      {/* <Multiselect
                        displayValue="label"
                        groupBy="groupby"
                        // onKeyPressFn={function noRefCheck() {}}
                        // onRemove={function noRefCheck() {}}
                        // onSearch={function noRefCheck() {}}
                        selectedValues={[]}
                        onSelect={nameHandle}
                        options={studentsList}
                        showCheckbox
                      /> */}

                      <Input
                        onClick={addStudenttoggle}
                        placeholder="Select"
                        // onClick={addClassStudenttoggle}
                      />
                      {/* <Select
                        styles={{ display: 'none' }}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        value={null}
                        onChange={nameHandle}
                        onInputChange={InputHandle}
                        showCheckbox
                        components={{ Option: Option }}
                        innerProps={{
                          id: 'react-select-5-option-0-0',
                          name: JSON.stringify(selectedStudents),
                        }}
                        options={studentsList}
                      /> */}
                    </FormGroup>
                    <p className="mt-2">Selected: {selectedStudents.length}</p>
                    <FormGroup>
                      {selectedStudents.map((e) => {
                        return (
                          <CustomInput
                            className="col-auto"
                            key={e.userId}
                            type="checkbox"
                            onClick={() => RemoveStudent(e.userId)}
                            checked={true}
                            id={`selectStudent ${e.userId}`}
                            name="selectStudent"
                            value={e.label}
                            label={
                              <p>
                                {/* <Avatar
                                  name={e.userId}
                                  size={25}
                                  className="mr-2"
                                  round="50px"
                                /> */}
                                {e.label || `${e.firstName} ${e.lastName}`}
                                <Badge
                                  color={
                                    e.isWeekendStudent ? 'danger' : 'primary'
                                  }
                                  pill>
                                  {e.isWeekendStudent ? 'We' : 'Wd'}
                                </Badge>
                              </p>
                            }
                          />
                        )
                      })}
                    </FormGroup>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <StudentSelectionPopup
        data={studentList}
        date={date}
        successfullcall={successfullcall}
        student={selectedStudents.reduce((a, obj) => {
          a[stuId_ + obj.userId] = obj
          return a
        }, {})}
        modal={addStudentmodal}
        toggle={closeStudenttoggle}
      />
      {/* <ClassRollOverListModal
        data={studentList}
        date={date}
        successfullcall={classrommRollsuccess}
        student={{}}
        modal={addClassStudentmodal}
        toggle={closeClassStudenttoggle}
      /> */}
    </Fragment>
  )
}

const Option = (props) => {
  let selectedStudentNew = JSONParser(
    props.selectProps.innerProps.name,
    []
  ).map((c) => c.value)

  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={selectedStudentNew.includes(props.value)}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

// const DropDown = (props) => {
//   const options = [
//     {
//       label: 'Select all',
//       value: props.selectedValue === 'all' ? 'none' : 'all',
//     },
//     ...props.options,
//   ]
//   return (
//     <div className={`react-select-wrapper ${props.multi ? 'multi' : ''}`}>
//       <Select
//         name="example"
//         options={options}
//         styles={{
//           option: (styles) => ({
//             ...styles,
//             background: props.selectedValue === 'all' ? '#2684FF' : '',
//             zIndex: 1,
//           }),
//         }}
//         // key={props.selectedValue}
//         value={props.value ? props.value : props.selectedValue}
//         onChange={props.handleChange}
//         // onChange={(selected) => {
//         //   props.multi &&
//         //   selected.length &&
//         //   selected.find((option) => option.value === 'all')
//         //     ? props.handleChange(options.slice(1))
//         //     : !props.multi
//         //     ? props.handleChange((selected && selected.value) || null)
//         //     : props.handleChange(selected)
//         // }}
//       />
//     </div>
//   )
// }
