import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Spinner,
} from 'reactstrap'
import API from '../../../constants/API'
import { DPS_TEST_SCHEDULE } from '../../../constants/Apipath'
import { sorting } from '../../../function/Helperfunction'
import { authHeader } from '../../../function/Mainfunction'
let stuId_ = 'stuId_'
function Dpstestudentadd(props) {
  const { modal, toggle, studentList, successfullcall, date, student } = props
  const [allstudent, setallstudent] = useState([])
  const [loading, setloading] = useState(false)
  const [markedstudent, setmarkedStudent] = useState({})
  const [orderList, setorderList] = useState('LA')
  const [filterValue, setfilterValue] = useState([])

  const searchstudent = (e) => {
    let { value } = e.target

    const filteredList = studentList.filter((job) => {
      if (
        job.firstName.toLowerCase().startsWith(value.toLowerCase()) ||
        job.lastName.toLowerCase().startsWith(value.toLowerCase())
      ) {
        return job
      }
    })

    setallstudent(filteredList)
  }
  const Markstudent = (e) => {
    const { checked, id } = e.target
    let student = { ...markedstudent }

    student[stuId_ + id] = {
      attendanceId: 0,
      status: 'unmarked',
      ...(student[stuId_ + id] || ''),
      userId: id,
      isDeleted: !checked,
    }

    setmarkedStudent(student)
  }
  const CreateDpstest = async () => {
    const config = {
      method: 'POST',
      url: DPS_TEST_SCHEDULE,
      headers: authHeader(),
      data: {
        attendanceDate: moment(date).format('YYYY/MM/DD'),
        DPSTestScheduleDetails: Object.values(markedstudent),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status === 'Success') {
        toggle()
        successfullcall()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  const selectAllstudent = () => {
    let newstudent = { ...markedstudent }

    allstudent.forEach((mark) => {
      newstudent[stuId_ + mark.userId] = {
        attendanceId: 0,
        status: 'unmarked',
        ...(student[stuId_ + mark.userId] || ''),
        userId: mark.userId,
        isDeleted: false,
      }
    })
    setmarkedStudent(newstudent)
  }

  const unselectAllstudent = () => {
    let student = { ...markedstudent }
    Object.values(student).forEach(
      (val) =>
        (student[stuId_ + val.userId] = {
          attendanceId: 0,
          status: 'unmarked',
          ...(student[stuId_ + val.userId] || ''),
          userId: val.userId,
          isDeleted: true,
        })
    )
    setmarkedStudent(student)
  }
  const setDefaultvalues = () => {
    setmarkedStudent(student)
    setallstudent(studentList)
    setorderList('LA')
  }
  const OrderList = (e) => {
    setorderList(e.target.value)
  }

  useEffect(() => {
    let isChecked = []
    let isUnChecked = []

    allstudent.forEach((d) => {
      const { isDeleted } = markedstudent[stuId_ + d.userId] || {}
      if (isDeleted == false) {
        isUnChecked.push(d)
      } else {
        isChecked.push(d)
      }
    })
    setfilterValue((val) => [
      ...isUnChecked.sort((a, b) => sorting(a, b, orderList)),
      ...isChecked.sort((a, b) => sorting(a, b, orderList)),
    ])
  }, [orderList])

  useEffect(() => {
    let isChecked = []
    let isUnChecked = []
    setmarkedStudent(student)

    allstudent.forEach((d) => {
      const { isDeleted } = student[stuId_ + d.userId] || {}
      if (isDeleted == false) {
        isUnChecked.push(d)
      } else {
        isChecked.push(d)
      }
    })
    setfilterValue((val) => [...isUnChecked, ...isChecked])
  }, [student, allstudent])

  useEffect(() => {
    setallstudent(studentList)
  }, [studentList])
  return (
    <div>
      {' '}
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          toggle()
          setDefaultvalues()
        }}>
        <ModalHeader toggle={toggle}>
          <Row className="mb-4">
            <Col>
              <Input
                type="search"
                placeholder="Search students"
                onChange={searchstudent}
              />{' '}
            </Col>
            <Col>
              {' '}
              <Input value={orderList} onChange={OrderList} type="select">
                <option value={'FA'}>First Name (Ascending) </option>
                <option value={'LA'}>Last Name (Ascending) </option>
                <option value={'FD'}>First Name (Descending) </option>
                <option value={'LD'}>Last Name (Descending) </option>
              </Input>
            </Col>
            <Col>
              <Button color="primary" onClick={selectAllstudent}>
                Check All
              </Button>
            </Col>
            <Col>
              <Button color="danger" onClick={unselectAllstudent}>
                Uncheck All
              </Button>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody>
          <Row xs="3">
            {filterValue.map((d) => {
              const { isDeleted } = markedstudent[stuId_ + d.userId] || {}
              return (
                <Col>
                  {' '}
                  <FormGroup check>
                    <Label check>
                      <Input
                        onChange={Markstudent}
                        id={d.userId}
                        checked={isDeleted == false ? true : false}
                        type="checkbox"
                      />
                      {d.firstName + ' ' + d.lastName}
                    </Label>
                  </FormGroup>
                </Col>
              )
            })}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={CreateDpstest}>
            {loading && <Spinner size="sm" />} Add
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Dpstestudentadd
