import React, { Fragment, useState, useRef, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  Container,
  Button,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Card,
  Row,
  Col,
  FormFeedback,
  Form,
} from 'reactstrap'
import {
  CustomMessage,
  CustomPageLoading,
} from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import API from '../../../constants/API'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'
import {
  STUDENT_LIST,
  TEST_TYPE_LIST,
  TEST_TYPE_DETAILS,
  UPDATE_TEST_TYPE_DETAILS,
  STUDENT_DETAILS,
} from '../../../constants/Apipath'
import { DPS_TEST_ROUTE } from '../../../constants/RoutePaths'
import Select from 'react-select'
import { debounce } from 'underscore'
import { authHeader } from '../../../function/Mainfunction'

export default function CreateDPSTest() {
  const history = useHistory()
  const { testId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const [studentOptions, setStudentOptions] = useState([])
  const selectInputRefStudent = useRef()
  const [selectStudent, setSelectStudent] = useState('')
  const [userId, setUserId] = useState('')
  const [typeOptions, settypeOptions] = useState([])
  const selectInputRefType = useRef()
  const [selectType, setSelectType] = useState('')
  const [testTypeId, setTestTypeId] = useState('')
  const [formData, setformData] = useState({})

  const Goback = () => {
    history.goBack()
  }

  useEffect(() => {
    getUpdateDetails()
  }, [])

  const getUpdateDetails = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: TEST_TYPE_DETAILS,
      params: {
        testId: testId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setformData(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.warn(data)
      }
    } finally {
      setloading(false)
    }
  }

  const getStudentList = async (event, ad) => {
    console.log(event, ad)
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: STUDENT_DETAILS,
      params: {
        firstName: 'sa',
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setStudentOptions(
          data.map((d) => ({ value: d.userId, label: d.firstName }))
        )
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.warn(data)
      }
    } finally {
      setloading(false)
    }
  }

  const OptionHandleStudent = (e) => {
    if (e !== null) {
      const { value } = e
      setUserId(value)
      setFieldValue('studentId', value)
    } else {
      setSelectStudent('')
      setUserId('')
    }
  }
  const OptionInputHandleStudent = (text) => {
    setSelectStudent(text)
    setFieldValue('StudentName', text)
  }

  // const StudentSearch = useRef(
  //   debounce((selectStudent, userId) => getStudentList(selectStudent, userId))
  // ).current

  // useEffect(() => {
  //   //callList();
  //   StudentSearch(selectStudent, userId)
  // }, [selectStudent, userId])

  //Get Type List
  const getTypeList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: TEST_TYPE_LIST,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        settypeOptions(
          data.map((d) => ({ value: d.testTypeId, label: d.name }))
        )
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.warn(data)
      }
    } finally {
      setloading(false)
    }
  }

  const OptionHandleType = (e) => {
    if (e !== null) {
      const { value } = e.target
      // setTestTypeId(value)
      setFieldValue('testType', value)
    } else {
      setSelectType('')
      // setTestTypeId('')
    }
  }
  const OptionInputHandleType = (text) => {
    setSelectType(text)
  }

  const TestTypeSearch = useRef(
    debounce((selectType, testTypeId) => getTypeList(selectType, testTypeId))
  ).current

  useEffect(() => {
    //callList();
    TestTypeSearch(selectType, testTypeId)
  }, [selectType, testTypeId])

  const DPSTestUpdate = async (values) => {
    const config = {
      method: 'PUT',
      url: UPDATE_TEST_TYPE_DETAILS,
      headers: authHeader(),
      data: values,
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status } = response.data
      if (status === 'Success') {
        CustomMessage('Update DPS Successful', 'success', enqueueSnackbar) //call toster
        resetForm({})
        history.push(DPS_TEST_ROUTE)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    testDate: Yup.string().required('Test Date Required'),
    comments: Yup.string().test('oneOfRequired', 'Required', function (item) {
      console.log(this.parent.testResult == 'pass')
      if (this.parent.testResult == 'pass') {
        return true
      } else {
        return false
      }
    }),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => DPSTestUpdate(values),
  })
  console.log(values)

  return (
    <Fragment>
      <div className="wrapper">
        {loading && <CustomPageLoading />}
        <div className="page-title">
          <h4>Update DPS Test</h4>
          <div className="btn-right">
            <Button onClick={Goback} color="primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.543"
                height="8.695"
                viewBox="0 0 5.543 9.695">
                <path
                  id="Icon_ionic-ios-arrow-back"
                  data-name="Icon ionic-ios-arrow-back"
                  d="M12.921,11.04,16.59,7.374a.693.693,0,1,0-.981-.978l-4.156,4.153a.691.691,0,0,0-.02.955l4.173,4.182a.693.693,0,1,0,.981-.978Z"
                  transform="translate(-11.251 -6.194)"
                  fill="#fff"
                />
              </svg>
              Back
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <Card body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <span for="">Student</span>
                    <Select
                      // options={studentOptions}
                      isClearable={true}
                      isDisabled={true}
                      // value={studentOptions.find(
                      //   (i) => i.value == values.studentId
                      // )}
                      value={{
                        label: formData.firstName + ' ' + formData.lastName,
                        value: formData.studentId,
                      }}
                      ref={selectInputRefStudent}
                      placeholder="Select Student"
                      // onChange={OptionHandleStudent}
                      // onInputChange={OptionInputHandleStudent}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Date</Label>
                    <Input
                      type="date"
                      name="testDate"
                      id="testDate"
                      placeholder=""
                      value={moment(values.testDate).format('YYYY-MM-DD')}
                      onBlur={handleBlur}
                      invalid={
                        errors.testDate && touched.testDate ? true : false
                      }
                      onChange={handleChange}
                    />
                    <FormFeedback>
                      {errors.testDate && touched.testDate
                        ? errors.testDate
                        : ''}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <span>Test Type</span>
                    <Input
                      value={values.testType}
                      placeholder="Select Type"
                      onChange={OptionHandleType}
                      name="testType"
                      id="testType"
                    />
                    {/* <Select
                      options={typeOptions}
                      isClearable={true}
                      value={typeOptions.filter(
                        (i) => i.value == values.testTypeId
                      )}
                      ref={selectInputRefType}
                      placeholder="Select Type"
                      onChange={OptionHandleType}
                      onInputChange={OptionInputHandleType}
                    /> */}
                  </FormGroup>
                </Col>

                {/* <Col md="4" xl="3">
                <FormGroup>
                  <Label for="">Test hours</Label>
                  <Input type="time" name="" id="" placeholder="" />
                </FormGroup>
              </Col> */}

                <Col md="4" xl="3">
                  <FormGroup>
                    <Label for="">Result</Label>
                    <Row className="mt-2 ml-1">
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio"
                        name="customRadio"
                        value={'pass'}
                        name="testResult"
                        label="Pass"
                        onChange={handleChange}
                        checked={values.testResult === 'pass'}
                      />
                      <CustomInput
                        className="col-auto"
                        type="radio"
                        id="exampleCustomRadio2"
                        name="customRadio"
                        value={'fail'}
                        name="testResult"
                        label="Fail"
                        onChange={handleChange}
                        checked={values.testResult === 'fail'}
                      />
                    </Row>
                  </FormGroup>
                </Col>
                {values.testResult === 'fail' && (
                  <Col md="4" xl="3">
                    <FormGroup>
                      <Label for="">Comments</Label>
                      <Input
                        type="text"
                        name="comments"
                        id="comments"
                        placeholder="Enter comments"
                        value={values.comments}
                        invalid={
                          errors.comments && touched.comments ? true : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormFeedback>
                        {errors.comments && touched.comments
                          ? errors.comments
                          : ''}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <div className="btn-center">
                <Button color="primary" className="pull-left">
                  Update
                </Button>
              </div>
            </Form>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}
