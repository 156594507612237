import React, { useState } from 'react'
import { Fragment } from 'react'
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import Iconawesomeeye from '../../../images/Iconawesomeeye.svg'
import Iconionicmdeyeoff from '../../../images/Iconionicmdeyeoff.svg'
import {
  CustomMessage,
  CustomPageLoading,
} from '../../../function/Helperfunction'
import API from '../../../constants/API'
import { CHANGE_PASSWORD } from '../../../constants/Apipath'
import { authHeader, getEmail } from '../../../function/Mainfunction'
import { USER_ROUTE } from '../../../constants/RoutePaths'

export default function ChangePassword() {
  const [oldpasswordShown, SetoldpasswordShown] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmpasswordShown, setconfirmPasswordShown] = useState(false)

  const oldTogglePassword = () => {
    SetoldpasswordShown(oldpasswordShown ? false : true)
  }

  const togglePassword = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const confirmTogglePassword = () => {
    setconfirmPasswordShown(confirmpasswordShown ? false : true)
  }

  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const validationSchema = Yup.object({
    oldpassword: Yup.string()
      .trim()
      .min(6, 'Password must be six characters')
      .required('Password is required'),
    newpassword: Yup.string()
      .trim()
      .min(6, 'Password mst be six characters')
      .required('New Password Required'),
    confirmPassword: Yup.string()
      .min(6, 'Password must be six characters')
      .required('Confirm password is required')
      .test('passwords-match', 'Password mismatch', function (value) {
        return this.parent.newpassword === value
      }),
  })
  const ChangePassword = async (values) => {
    setLoading(true)
    const config = {
      method: 'PUT',
      url: CHANGE_PASSWORD,
      headers: authHeader(),
      data: {
        email: getEmail(),
        oldpassword: values.oldpassword,
        newpassword: values.newpassword,
      },
    }

    try {
      const response = await API(config)
      const { status, data } = response.data

      if (status === 'Success') {
        resetForm({})
        CustomMessage(
          'Password Changed Successfull!',
          'success',
          enqueueSnackbar
        )
      }
    } catch (error) {
      let { data } = error.response

      CustomMessage(data.message, 'error', enqueueSnackbar)
    } finally {
      setLoading(false)
    }
  }
  //Formik
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      email: getEmail(),
      oldpassword: '',
      newpassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => ChangePassword(values),
  })
  return (
    <Fragment>
      <div className="page">
        {loading && <CustomPageLoading />}
        <Form form="true" onSubmit={handleSubmit}>
          <Row>
            <Col sm="12">
              <h5>Change Password</h5>
            </Col>
            <Col md="5">
              <FormGroup>
                <Label for="oldpassword">Old Password</Label>
                <InputGroup>
                  <Input
                    type={oldpasswordShown ? 'text' : 'password'}
                    name="oldpassword"
                    id="oldpassword"
                    placeholder="Enter Your Password"
                    value={values.oldpassword}
                    placeholder="Enter Old Password"
                    invalid={
                      errors.oldpassword && touched.oldpassword ? true : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter Old Password"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={oldTogglePassword}
                      style={{ cursor: 'pointer' }}>
                      {oldpasswordShown ? (
                        <img src={Iconawesomeeye} />
                      ) : (
                        <img src={Iconionicmdeyeoff} />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback>
                    {errors.oldpassword && touched.oldpassword
                      ? errors.oldpassword
                      : ''}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="newpassword">New Password</Label>
                <InputGroup>
                  <Input
                    type={passwordShown ? 'text' : 'password'}
                    name="newpassword"
                    id="newpassword"
                    placeholder="Enter New Password"
                    value={values.newpassword}
                    invalid={
                      errors.newpassword && touched.newpassword ? true : false
                    }
                    placeholder="Enter New Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter New Password"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={togglePassword}
                      style={{ cursor: 'pointer' }}>
                      {passwordShown ? (
                        <img src={Iconawesomeeye} />
                      ) : (
                        <img src={Iconionicmdeyeoff} />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback>
                    {errors.newpassword && touched.newpassword
                      ? errors.newpassword
                      : ''}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword">Confirm Password</Label>
                <InputGroup>
                  <Input
                    type={confirmpasswordShown ? 'text' : 'password'}
                    name="confirmPassword"
                    placeholder="Confirm Your Password"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    placeholder="Enter Confirm Password"
                    invalid={
                      errors.confirmPassword && touched.confirmPassword
                        ? true
                        : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter Confirm Password"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={confirmTogglePassword}
                      style={{ cursor: 'pointer' }}>
                      {confirmpasswordShown ? (
                        <img src={Iconawesomeeye} />
                      ) : (
                        <img src={Iconionicmdeyeoff} />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback>
                    {errors.confirmPassword && touched.confirmPassword
                      ? errors.confirmPassword
                      : ''}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              {/* button */}
              <div className="top-btn">
                <Button type="submit" color="primary">
                  Save Changes
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Fragment>
  )
}
