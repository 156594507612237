import React, { useState, Fragment } from 'react'
import { Route, Switch, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Navbar,
  Button,
  NavbarBrand,
  Nav,
  NavbarText,
  Tooltip,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap'

//
import {
  ROLLCALL_VIEW,
  ROLLCALL,
  CLASSROOM_ROLLCALL_VIEW,
  ROLLCALL_CLASS_ROOM,
} from '../../constants/RoutePaths'
import Avatar from 'react-avatar'
import Swal from 'sweetalert2'

import Logo from '../../images/logo.png'
import RolloverView from '../admin/rollover/RolloverView'
import Rollover from '../admin/rollover/Rollover'
import { LAST_NAME, USER_EMAIL, USER_NAME } from '../../constants/Localvaribale'
import ClassRoomRollOverView from '../admin/rollover/ClassRoomRollOverView'

export default function Layout() {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const history = useHistory()
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const firstName = localStorage.getItem(USER_NAME)
  const lastName = localStorage.getItem(LAST_NAME)
  const email = localStorage.getItem(USER_EMAIL)
  const [menuOpen, setmenuOpen] = useState(true)
  const menuToggle = () => setmenuOpen((val) => !val)
  const LogOut = () => {
    Swal.fire({
      title: 'Are you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear()
        history.push('/signin')
      }
    })
  }
  return (
    <Fragment>
      <Navbar color="white" light expand="md" sticky={'top'}>
        <NavbarBrand>
          <img src={Logo} />
        </NavbarBrand>
        <svg
          onClick={menuToggle}
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 1024 1024"
          height="2em"
          width="2em"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
        </svg>

        <Nav className="mr-auto" navbar></Nav>
        <NavbarText className="user-profile">
          <UncontrolledDropdown direction="down" setActiveFromChild>
            <DropdownToggle className="nav-link" caret>
              <Avatar
                name={`${firstName} ${lastName}`}
                size={30}
                round="50px"
              />{' '}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem className="nav-drop-profile">
                <div className="info">
                  <Avatar
                    name={`${firstName} ${lastName}`}
                    size={60}
                    round="50px"
                  />{' '}
                  <h4>{`${firstName} ${lastName}`} </h4>
                  <p>{email}</p>
                  <Button color="danger" onClick={LogOut}>
                    <svg
                      className="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="15.52"
                      viewBox="0 0 16.693 12.52">
                      <path
                        id="sign-out-alt"
                        d="M5.217,68.992a.523.523,0,0,1,.522-.522h4.7V65.409a.261.261,0,0,1,.443-.186l4.617,4.666a.521.521,0,0,1,0,.74l-4.617,4.666a.26.26,0,0,1-.443-.186V72.047h-4.7a.523.523,0,0,1-.522-.522V68.992m-1.043,0v2.533A1.565,1.565,0,0,0,5.738,73.09H9.39v2.018a1.3,1.3,0,0,0,2.224.923l4.62-4.662a1.566,1.566,0,0,0,0-2.217l-4.62-4.666a1.3,1.3,0,0,0-2.224.923v2.018H5.738A1.568,1.568,0,0,0,4.173,68.992ZM0,65.565v9.39A1.565,1.565,0,0,0,1.565,76.52h4.3a.392.392,0,0,0,.391-.391v-.261a.392.392,0,0,0-.391-.391h-4.3a.523.523,0,0,1-.522-.522v-9.39a.523.523,0,0,1,.522-.522h4.3a.392.392,0,0,0,.391-.391v-.261A.392.392,0,0,0,5.869,64h-4.3A1.565,1.565,0,0,0,0,65.565Z"
                        transform="translate(0 -64)"
                        fill="#fff"
                      />
                    </svg>
                    Logout
                  </Button>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </NavbarText>
        {/* <IoIosLogOut
          color="#727CF5"
          onClick={LogOut}
          id="TooltipExample"
          size="24px"
        />
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          target="TooltipExample"
          toggle={toggle}>
          Log out!
        </Tooltip> */}
      </Navbar>
      {/* <Sidebar menu={menuOpen} toggle={setmenuOpen} /> */}
      <Switch>
        <Route path={`${ROLLCALL_VIEW}/:${'date'}`} component={RolloverView} />
        <Route
          exact
          path={`${ROLLCALL_CLASS_ROOM}/:${'date'}`}
          component={ClassRoomRollOverView}
        />{' '}
        <Route path={ROLLCALL} component={Rollover} />
      </Switch>
    </Fragment>
  )
}
