import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  Container,
  Input,
  Table,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Card,
} from 'reactstrap'
import Autocomplete from 'react-autocomplete'
import Creatable from 'react-select/creatable'
import { authHeader, getUserId } from '../../../function/Mainfunction'
import CustomPageLoading from '../../../function/CustomPageLoading'
import API from '../../../constants/API'
import Listmodal from './Listmodal'
import {
  DPS_TEST_SCHEDULE,
  GET_ATTENDENCEREP,
  LIST_INSTRUCTOR,
  LIST_STATION,
  STUDENT_LIST,
  STUDENT_REPS,
} from '../../../constants/Apipath'
import AttendenceButtonDropdown from './AttendenceButtonDropdown'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import { useHistory, useParams } from 'react-router'
import DpstestAttendance from './DpstestAttendance'
import Dpstestudentadd from './Dpstestudentadd'
import { CustomMessage, sorting } from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import Rollcallpdf from './Rollcallpdf'
import Select, { components } from 'react-select'
import Dpstestpdf from './Dpstestpdf'
import { debounce } from 'underscore'
import { ROLLCALL, ADMIN_ROLLOVER } from '../../../constants/RoutePaths'
import { DataUsageOutlined } from '@material-ui/icons'

const STU_ = 'stuId_'
const TimeStampInitial = ['7am - 9am', '9am - 12pm', '12pm - 2pm', '2pm - 4pm']

function RolloverView() {
  const [items, setitems] = useState([])
  let currentActive = window.location.pathname.split('/')[1]
  const history = useHistory()
  const { date } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const ref = useRef()
  const [station, setstation] = useState('')
  const [studentList, setstudentList] = useState([])
  const [Attendence, setAttendence] = useState({})
  const [studentModal, setstudentModal] = useState(false)
  const studenttoggle = () => setstudentModal(!studentModal)
  const [rollcallTimeflag, setrollcallTimeflag] = useState(false)
  const selectInputRefStation = useRef()
  const [dpsSwitch, setdpsSwitch] = useState(true)
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const [dpsTestAttendence, setdpsTestAttendence] = useState({})
  const [orderListvalue, setorderListvalue] = useState('LA')
  const [downloadFlag, setdownloadFlag] = useState(false)
  const exportToggle = () => {
    setdropdownOpen(!dropdownOpen)
  }
  const SwitchHandle = () => setdpsSwitch((val) => !val)
  const [dpsTestmodal, setdpsTestmodal] = useState(false)
  const dpsTesttoggle = () => setdpsTestmodal((val) => !val)
  const [loading, setloading] = useState(false)
  const [stationName, setstationName] = useState('')
  const [instructorName, setinstructorName] = useState('')
  const [stationList, setStationList] = useState([])
  const [instructionList, setinstructionList] = useState([])

  const AutocompleteHandleChange = (e, id) => {
    const { value } = e.target
    let item = [...items]
    let data = { ...Attendence }
    data[STU_ + id]['road'] = value
    if (item.find((val) => val.id == id)?.id == id) {
      items.find((val) => val.id == id)['label'] = value
      setitems(item)
    } else {
      setitems((val) => [...val, { label: value, id: id }])
    }

    setAttendence(data)
  }

  const AutocompleteValueSelect = (e, id) => {
    let data = { ...Attendence }
    data[STU_ + id]['road'] = e
    setAttendence(data)
  }
  const getStudent = async () => {
    const config = {
      method: 'GET',
      url: STUDENT_LIST,
      headers: authHeader(),
      params: {
        studentStatus: 'Active',
        count: 1000,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status === 'Success') {
        setstudentList(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  const getRollcall = async () => {
    const config = {
      method: 'GET',
      url: GET_ATTENDENCEREP,
      headers: authHeader(),
      params: {
        attendanceDate: moment(date).format('YYYY/MM/DD'),
        dpsAttendanceDate: moment(date).format('YYYY/MM/DD'),
      },
    }

    setloading(true)
    try {
      const response = await API(config)
      const { status, data, dbsData } = response.data
      if (status === 'Success') {
        setdownloadFlag(true)

        let NewAttendence = {}
        data.forEach((at) => {
          // var Listing = stationList.find((item) => item.label == at.road)
          NewAttendence[STU_ + at.userId] = {
            firstName: at.firstName,
            lastName: at.lastName,
            attendanceId: at.attendanceId,
            userId: at.userId,
            road: at.road,
            // road: { label: 'April 11th', value: 41 },
            am: at.am,
            pm: at.pm,
            // noonTimeStamp: at.noonTimeStamp,
            // pmTimeStamp: at.pmTimeStamp,
            // amTimeStamp: at.amTimeStamp,
            // rollCallTime: at.rollCallTime || '',
            noon: at.noon,
            isDeleted: false,
          }
        })

        setAttendence(NewAttendence)
        let newdpstest = {}
        dbsData.forEach(
          (dps) =>
            (newdpstest[STU_ + dps.userId] = {
              attendanceId: dps.attendanceId,
              firstName: dps.firstName,
              lastName: dps.lastName,
              userId: dps.userId,
              status: dps.status,
              isDeleted: false,
            })
        )
        setdpsTestAttendence(newdpstest)

        setTimeout(() => {
          setdownloadFlag(false)
        }, 2000)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  const successfullcall = () => {
    getRollcall()
  }
  const setabsent = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'absent'
    setAttendence(statusupdate)
  }

  const setpremesstion = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'excused'
    setAttendence(statusupdate)
  }
  const setout = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'unmarked'
    setAttendence(statusupdate)
  }
  const setpresent = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'present'
    setAttendence(statusupdate)
  }
  const attendecedata = {
    present: { status: 'Present', onclick: setabsent },
    absent: { status: 'Absent', onclick: setpremesstion },
    excused: { status: 'Excused', onclick: setout },
    unmarked: { status: 'Unmarked', onclick: setpresent },
  }

  const dpstestsetabsent = (userid) => {
    let dpsstatusupdate = { ...dpsTestAttendence }
    dpsstatusupdate[STU_ + userid]['status'] = 'absent'
    setAttendence(dpsstatusupdate)
  }

  const dpstestsetunmark = (userid) => {
    let dpsstatusupdate = { ...dpsTestAttendence }
    dpsstatusupdate[STU_ + userid]['status'] = 'unmarked'
    setAttendence(dpsstatusupdate)
  }
  const dpstestsetpresent = (userid) => {
    let dpsstatusupdate = { ...dpsTestAttendence }

    dpsstatusupdate[STU_ + userid]['status'] = 'present'
    setAttendence(dpsstatusupdate)
  }
  const dpsattendecedata = {
    present: { status: 'Present', onclick: dpstestsetabsent },
    absent: { status: 'Absent', onclick: dpstestsetunmark },
    unmarked: { status: 'Unmarked', onclick: dpstestsetpresent },
  }

  const fromSubmit = async () => {
    const config = {
      method: 'POST',
      url: STUDENT_REPS,
      headers: authHeader(), // data: reqData,
      data: {
        createdBy: getUserId(),
        attendanceDate: moment(date).format('YYYY/MM/DD'),
        repsRoleAttendanceDetails: Object.values(Attendence).map((stu) => ({
          attendanceId: stu.attendanceId,
          userId: stu.userId,
          road: stu.road,
          am: stu.am || '',
          pm: stu.pm || '',
          noon: stu.noon || '',
          isDeleted: false,
        })),
      },
    }
    {
      console.log(config)
    }
    setloading(true)
    try {
      const response = await API(config)
      const { data, status, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar)
        getRollcall()
        setrollcallTimeflag(false)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar)
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  const dpsTestsubmit = async () => {
    const config = {
      method: 'POST',
      url: DPS_TEST_SCHEDULE,
      headers: authHeader(),
      data: {
        attendanceDate: moment(date).format('YYYY/MM/DD'),
        DPSTestScheduleDetails: Object.values(dpsTestAttendence).map((stu) => ({
          attendanceId: stu.attendanceId,
          userId: stu.userId,
          status: stu.status,
          isDeleted: false,
        })),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { data, status, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar)
        getRollcall()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar)
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  let csvReport = [
    [
      'Student name',
      'Road',
      // 'Roll call Time',
      // 'Station Instructor',
      // 'Drive Instructor',
      // 'Station',
      'AM',
      // 'AM Time stamp',
      'PM',
      // 'PM Time stamp',
      'Noon',
      // 'Noon Time stamp',
    ],
  ]

  Object.values(Attendence)
    .sort((a, b) => sorting(a, b, orderListvalue))
    .forEach((rec, index) => {
      const {
        firstName,
        lastName,
        pm,
        road,
        noon,
        rollCallTime,
        stationInstructor,
        driveInstructor,
        stationId,
        am,
        noonTimeStamp,
        pmTimeStamp,
        amTimeStamp,
      } = rec

      csvReport.push([
        firstName + ' ' + lastName || 'N/A',
        road || 'N/A',
        // rollCallTime || 'N/A',
        // stationInstructor?.label || 'N/A',
        // driveInstructor?.label || 'N/A',
        // stationId?.label || 'N/A',
        am || 'N/A',
        // amTimeStamp,
        pm || 'N/A',
        // pmTimeStamp,
        noon || 'N/A',
        // noonTimeStamp,
      ])
    })

  let dpsTestreport = [['Student name', 'date', 'status']]

  Object.values(dpsTestAttendence)
    .sort((a, b) => sorting(a, b, orderListvalue))
    .forEach((rec, index) => {
      const { firstName, lastName, status, attendanceDate } = rec
      dpsTestreport.push([
        firstName + ' ' + lastName || 'N/A',
        status || 'N/A',
        moment(attendanceDate).format('YYYY/MM/DD'),
      ])
    })

  const Exportrollcallpdf = () => {
    // if (downloadFlag) {
    //   CustomMessage('Please wait data is loading...', 'error', enqueueSnackbar)

    //   return false
    // }
    if (rollcallTimeflag) {
      CustomMessage(
        'Submit the data before get  report',
        'error',
        enqueueSnackbar
      )

      return false
    }
    Rollcallpdf(Attendence, orderListvalue)
  }

  const ExportDpstest = () => {
    Dpstestpdf(dpsTestAttendence, orderListvalue)
  }

  useEffect(() => {
    getRollcall()
    getStudent()
  }, [])
  //

  const OrderList = (e) => {
    setorderListvalue(e.target.value)
  }

  // function slide(direction) {
  //   // ref.current.scrollIntoView(false)
  //   // ref.current.scrollIntoView({
  //   //   behavior: 'smooth',
  //   //   // block: 'end',
  //   //   inline: 'nearest',
  //   // })
  //   // ref.current.scrollLeft += 20

  //   console.log(ref.current)

  //   console.log(document.getElementById('container'))
  //   ref.current.scrollLeft += 20
  //   document.getElementById('container').scrollLeft += 20

  //   // ref.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest' })
  // }

  const getStationList = async (values) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_STATION,
      params: {
        stationName: values,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      console.log(data)
      if (status === 'Success') {
        setStationList(
          data.map((d) => ({
            value: d.stationId,
            label: d.stationName,
            isNew: false,
          }))
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const getInstructorList = async (name) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_INSTRUCTOR,
      params: {
        firstName: name,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setinstructionList(
          data.map((d) => ({ value: d.userId, label: d.firstName }))
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const InstructorHandle = (e) => {
    setinstructorName(e)
  }

  const instructorSearch = useRef(
    debounce((instructorName) => getInstructorList(instructorName), 500)
  ).current

  const stationInstructorOptionHandle = (e, id, name) => {
    let statusupdate = { ...Attendence }
    statusupdate[STU_ + id][name] = e
    setAttendence(statusupdate)
  }

  const stationNameHandle = (e) => {
    setstationName(e)
  }
  const handleChangeStation = (e, userId) => {
    console.log(e, userId, Attendence)
    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userId]['road'] = e.label
    setAttendence(statusupdate)
    //  setSelectedStation((e.target.name = e?.label || '')
  }
  useEffect(() => {
    //callList();
    instructorSearch(instructorName)
  }, [instructorName])

  const stationNamehandle = (text) => {
    console.log(text)
    setstation(text)
  }

  console.log('tttt', station)

  const stationSearch = useRef(
    debounce((stationName) => getStationList(stationName), 500)
  ).current

  useEffect(() => {
    stationSearch(stationName)
  }, [stationName])

  const goBack = () => {
    history.push(ADMIN_ROLLOVER)
  }
  const formatOptionLabel = ({ children, ...props }) => {
    const { data } = props
    return (
      <>
        {data.isNew ? (
          ''
        ) : (
          <components.Option {...props}>{children}</components.Option>
        )}
      </>
    )
  }
  return (
    <div className="wrapper">
      {loading && <CustomPageLoading />}
      <div className="page-title">
        <h4>{dpsSwitch ? 'General Roll call' : 'DPS test Roll call'}</h4>
        <div className="ml-auto">
          {!dpsSwitch ? (
            <Button onClick={SwitchHandle} color="primary">
              General Roll call
            </Button>
          ) : (
            <Button onClick={SwitchHandle} color="primary">
              DPS test Roll call
            </Button>
          )}
        </div>
        <div className="btn-right">
          <Input value={orderListvalue} onChange={OrderList} type="select">
            <option value={'FA'}>First Name Ascending </option>
            <option value={'LA'}>Last Name Ascending </option>
            <option value={'FD'}>First Name Descending </option>
            <option value={'LD'}>Last Name Descending </option>
          </Input>

          <Dropdown isOpen={dropdownOpen} toggle={exportToggle}>
            <DropdownToggle color="danger" caret>
              Export
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={dpsSwitch ? Exportrollcallpdf : ExportDpstest}>
                PDF
              </DropdownItem>
              <DropdownItem>
                <CSVLink
                  onClick={() => {
                    if (rollcallTimeflag) {
                      CustomMessage(
                        'Submit the data before get  report',
                        'error',
                        enqueueSnackbar
                      )

                      return false
                    }
                  }}
                  filename={
                    dpsSwitch
                      ? `${date}_attendencerollcall.csv`
                      : `${date}_attendencdpstest.csv`
                  }
                  data={dpsSwitch ? csvReport : dpsTestreport}>
                  CSV
                </CSVLink>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {/* {currentActive !== 'rollcalladmin' && (
            <> */}
          <Button
            onClick={dpsSwitch ? studenttoggle : dpsTesttoggle}
            color="danger">
            Edit
          </Button>
          <Button
            onClick={dpsSwitch ? fromSubmit : dpsTestsubmit}
            color="danger">
            Submit
          </Button>
          {/* </>
          )} */}

          <Button onClick={goBack} color="danger">
            Back
          </Button>
        </div>
      </div>
      <>
        <Container>
          {dpsSwitch ? (
            <Card body>
              <Table className="rollcall_table" responsive>
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>Road</th>
                    {/* <th>Station Instructor</th>
                    <th>Drive Instructor</th>
                    <th>Station</th>
                    <th>Time stamp</th> */}
                    <th>AM</th>
                    <th>Noon</th>
                    <th>PM</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(Attendence)
                    .sort((a, b) => sorting(a, b, orderListvalue, Attendence))
                    .map((rec, index) => {
                      const { road, am, pm, noon } = rec
                      let select = stationList
                      select.push({
                        value: road,
                        label: road,
                        isNew: true,
                      })
                      return (
                        <tr>
                          <td>{rec.firstName + ' ' + rec.lastName}</td>
                          {console.log(stationList)}
                          <td
                            style={{ width: '210px', paddingRight: '50px' }}
                            key={'key_' + stationList.length}>
                            <Select
                              // name={index}
                              options={select}
                              isClearable={false}
                              defaultValue={select.find((e) => e.label == road)}
                              components={{ Option: formatOptionLabel }}
                              onChange={(e) =>
                                handleChangeStation(e, rec.userId)
                              }
                              onInputChange={stationNamehandle}
                            />
                          </td>
                          {/* <td>
                            <Autocomplete
                              wrapperStyle={{
                                // position: 'relative',
                                'z-index': '1000000000',
                              }}
                              menuStyle={{
                                // position: 'absolute',
                                cursor: 'pointer',

                                top: '35px',
                                left: 0,
                              }}
                              getItemValue={(item) => item.label}
                              items={items}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  style={{
                                    background: isHighlighted
                                      ? 'lightgray'
                                      : 'white',
                                  }}>
                                  {item.label}
                                </div>
                              )}
                              value={road || ''}
                              onSelect={(e) =>
                                AutocompleteValueSelect(e, rec.userId)
                              }
                              onChange={(e) =>
                                AutocompleteHandleChange(e, rec.userId)
                              }
                            />
                          </td>{' '} */}
                          {/* <td>
                            <Select
                              styles={{ display: 'none' }}
                              hideSelectedOptions={true}
                              value={[rec.stationInstructor]}
                              onChange={(e) =>
                                stationInstructorOptionHandle(
                                  e,
                                  rec.userId,
                                  'stationInstructor'
                                )
                              }
                              onInputChange={InstructorHandle}
                              options={instructionList}
                            />
                          </td>
                          <td>
                            <Select
                              styles={{ display: 'none' }}
                              hideSelectedOptions={true}
                              value={[rec.driveInstructor]}
                              onChange={(e) =>
                                stationInstructorOptionHandle(
                                  e,
                                  rec.userId,
                                  'driveInstructor'
                                )
                              }
                              onInputChange={InstructorHandle}
                              options={instructionList}
                            />
                          </td>{' '}
                          <td>
                            <Select
                              styles={{ display: 'none' }}
                              value={[rec.stationId]}
                              onChange={(e) =>
                                stationInstructorOptionHandle(
                                  e,
                                  rec.userId,
                                  'stationId'
                                )
                              }
                              onInputChange={stationNameHandle}
                              hideSelectedOptions={true}
                              options={stationList}
                            />
                          </td>
                          <td>
                            <Input
                              name="rollCallTime"
                              id={rec.userId}
                              value={rec.rollCallTime}
                              onChange={(e) =>
                                stationInstructorOptionHandle(
                                  e.target.value,
                                  rec.userId,
                                  'rollCallTime'
                                )
                              }
                              type="select">
                              <option value="">Select Time stamp</option>

                              {TimeStampInitial.map((t) => (
                                <option value={t}>{t}</option>
                              ))}
                            </Input>
                          </td> */}
                          <td>
                            <AttendenceButtonDropdown
                              userid={rec.userId}
                              data={attendecedata}
                              time={'am'}
                              value={am || ''}
                            />
                          </td>
                          <td>
                            <AttendenceButtonDropdown
                              userid={rec.userId}
                              data={attendecedata}
                              time={'noon'}
                              value={noon || ''}
                            />
                          </td>{' '}
                          <td>
                            <AttendenceButtonDropdown
                              userid={rec.userId}
                              data={attendecedata}
                              time={'pm'}
                              value={pm || ''}
                            />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </Card>
          ) : (
            <DpstestAttendance
              value={orderListvalue}
              dpsTestAttendence={dpsTestAttendence}
              attendecedata={dpsattendecedata}
            />
          )}
          <Listmodal
            date={date}
            data={studentList}
            successfullcall={successfullcall}
            toggle={studenttoggle}
            modal={studentModal}
            student={Attendence}
          />
          <Dpstestudentadd
            date={date}
            student={dpsTestAttendence}
            successfullcall={successfullcall}
            studentList={studentList}
            modal={dpsTestmodal}
            toggle={dpsTesttoggle}
          />
        </Container>
      </>
    </div>
  )
}

export default RolloverView
