import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Form,
  Button,
  FormGroup,
} from 'reactstrap'

export default function Drive(props) {
  const { GetValues } = props

  const validationSchema = Yup.object().shape({
    driveDate: Yup.date().required('Drive Date Required'),
    tractor: Yup.string().required('Tractor Required'),
    upshifting: Yup.string().required('Up shifting Required'),
    timeIn: Yup.string().required('Time In Required'),
    timeOut: Yup.string().required('Time Out Required'),
    downshifting: Yup.string().required('Down shifting Required'),
    turnCoasting: Yup.string().required('Turn Coasting Required'),
    turns: Yup.string().required('Turns Required'),
    turntrafficCheck: Yup.string().required('Turn Traffic Check Required'),
    signal: Yup.string().required('Signal Check Required'),
    lane: Yup.string().required('Lane Required'),
    hitCurb: Yup.string().required('Hit Curb Required'),
    turnmirrors: Yup.string().required('Turn mirrors Required'),
    shifting: Yup.string().required('Shifting Required'),
    interCoasting: Yup.string().required('Inter Coasting Required'),
    intersectionMirrors: Yup.string().required('Inter sectionMirrors Required'),
    intersectiontrafficCheck: Yup.string().required(
      'Inter SectiontrafficCheck Required'
    ),
    stop: Yup.string().required('Stop Required'),
    brake: Yup.string().required('Brake Required'),
    prep: Yup.string().required('Prep Required'),
    reaction: Yup.string().required('Reaction Required'),
    rpms: Yup.string().required('Rpms Required'),
    timing: Yup.string().required('Timing Required'),
    gearPattern: Yup.string().required('Gear Pattern Required'),
    bears: Yup.string().required('Bears Required'),
    weaves: Yup.string().required('Weaves Required'),
    followDistance: Yup.string().required('Follow Distance Required'),
    mergingTrafficCheck: Yup.string().required(
      'Merging Traffic Check Required'
    ),
    mergingSmooth: Yup.string().required('Merging Smooth Required'),
    railroadTrafficCheck: Yup.string().required(
      'Rail Road Traffic Check Required'
    ),
    railroadSmooth: Yup.string().required('Rail Road Smooth Required'),
    hoverH: Yup.string().required('HoverH Required'),
    // example: Yup.string().required('Example Required'),
    observation: Yup.string().required('Observation Required'),
    // observationTraffic: Yup.boolean().oneOf(
    //   [true],
    //   'ObservationTraffic Required'
    // ),
    // identifyHazards: Yup.boolean().oneOf([true], 'IdentifyHazards Required'),
    // signs: Yup.boolean().oneOf([true], 'Signs Required'),
    // comments: Yup.string().required('Comments Required'),
  })
  const { handleSubmit, handleChange, values, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        driveDate: '',
        tractor: '',
        timeIn: '',
        timeOut: '',
        upshifting: '',
        turnCoasting: '',
        downshifting: '',
        turns: '',
        turntrafficCheck: '',
        signal: '',
        lane: '',
        hitCurb: '',
        turnmirrors: '',
        shifting: '',
        interCoasting: '',
        intersectionMirrors: '',
        intersectiontrafficCheck: '',
        stop: '',
        brake: '',
        prep: '',
        reaction: '',
        rpms: '',
        timing: '',
        gearPattern: '',
        bears: '',
        weaves: '',
        followDistance: '',
        mergingTrafficCheck: '',
        mergingSmooth: '',
        railroadTrafficCheck: '',
        railroadSmooth: '',
        hoverH: '',
        // example: '',
        observation: '',
        observationTraffic: false,
        identifyHazards: false,
        signs: false,
        comments: '',
      },
      validationSchema,
      onSubmit: (values) => GetValues(values),
    })

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Drive Date</Label>
              <Input
                type="date"
                name="driveDate"
                placeholder=""
                onBlur={handleBlur}
                value={values.driveDate}
                invalid={errors.driveDate && touched.driveDate ? true : false}
                onChange={handleChange}
              />
              <FormFeedback>
                {errors.driveDate && touched.driveDate ? errors.driveDate : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Tractor</Label>
              <Input
                type="text"
                name="tractor"
                placeholder="Enter Tractor"
                onBlur={handleBlur}
                value={values.tractor}
                invalid={errors.tractor && touched.tractor ? true : false}
                onChange={handleChange}
              />
              {/* <Input
                type="select"
                name="tractor"
                placeholder=""
                onBlur={handleBlur}
                value={values.tractor}
                invalid={errors.tractor && touched.tractor ? true : false}
                onChange={handleChange}>
                <option value="">Select tractor</option>
                {[
                  '2012.0',
                  '2014.0',
                  '2015.0',
                  '2016.0',
                  '2017.0',
                  '2018.0',
                  '2020.0',
                  'Auto',
                ].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input> */}
              <FormFeedback>
                {errors.tractor && touched.tractor ? errors.tractor : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">timeIn</Label>
              <Input
                type="time"
                name="timeIn"
                placeholder=""
                onBlur={handleBlur}
                value={values.timeIn}
                invalid={errors.timeIn && touched.timeIn ? true : false}
                onChange={handleChange}
              />
              <FormFeedback>
                {errors.timeIn && touched.timeIn ? errors.timeIn : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Time Out</Label>
              <Input
                type="time"
                name="timeOut"
                placeholder=""
                onBlur={handleBlur}
                value={values.timeOut}
                invalid={errors.timeOut && touched.timeOut ? true : false}
                onChange={handleChange}
              />
              <FormFeedback>
                {errors.timeOut && touched.timeOut ? errors.timeOut : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Up shifting</Label>
              <Input
                type="select"
                name="upshifting"
                placeholder=""
                onBlur={handleBlur}
                value={values.upshifting}
                invalid={errors.upshifting && touched.upshifting ? true : false}
                onChange={handleChange}>
                <option value="">Select upshifting</option>
                {['Good', 'Fair', 'Poor'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.upshifting && touched.upshifting
                  ? errors.upshifting
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Down shifting</Label>
              <Input
                type="select"
                name="downshifting"
                placeholder=""
                onBlur={handleBlur}
                value={values.downshifting}
                invalid={
                  errors.downshifting && touched.downshifting ? true : false
                }
                onChange={handleChange}>
                <option value="">Select downshifting</option>
                {['Good', 'Fair', 'Poor'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.downshifting && touched.downshifting
                  ? errors.downshifting
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Turn Coasting</Label>
              <Input
                type="select"
                name="turnCoasting"
                placeholder=""
                onBlur={handleBlur}
                value={values.turnCoasting}
                invalid={
                  errors.turnCoasting && touched.turnCoasting ? true : false
                }
                onChange={handleChange}>
                <option value="">Select Turn Coasting</option>
                {['Yes', 'No', 'Sometimes'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.turnCoasting && touched.turnCoasting
                  ? errors.turnCoasting
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Turn</Label>
              <Input
                type="select"
                name="turns"
                placeholder=""
                onBlur={handleBlur}
                value={values.turns}
                invalid={errors.turns && touched.turns ? true : false}
                onChange={handleChange}>
                <option value="">Select Turn</option>
                {['Fast', 'Wide', 'Short', 'Normal'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.turns && touched.turns ? errors.turns : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Turn Traffic Check</Label>
              <Input
                type="select"
                name="turntrafficCheck"
                placeholder=""
                onBlur={handleBlur}
                value={values.turntrafficCheck}
                invalid={
                  errors.turntrafficCheck && touched.turntrafficCheck
                    ? true
                    : false
                }
                onChange={handleChange}>
                <option value="">Select Turn Traffic </option>
                {['Yes', 'No', 'Sometimes'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.turntrafficCheck && touched.turntrafficCheck
                  ? errors.turntrafficCheck
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Signal</Label>
              <Input
                type="select"
                name="signal"
                placeholder=""
                onBlur={handleBlur}
                value={values.signal}
                invalid={errors.signal && touched.signal ? true : false}
                onChange={handleChange}>
                <option value="">Select Signal</option>
                {['Early', 'Late', 'Never', 'Ok'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.signal && touched.signal ? errors.signal : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Lane</Label>
              <Input
                type="select"
                name="lane"
                placeholder=""
                onBlur={handleBlur}
                value={values.lane}
                invalid={errors.lane && touched.lane ? true : false}
                onChange={handleChange}>
                <option value="">Select Lane</option>
                {['Yes', 'No'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.lane && touched.lane ? errors.lane : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Hit Curb</Label>
              <Input
                type="select"
                name="hitCurb"
                placeholder=""
                onBlur={handleBlur}
                value={values.hitCurb}
                invalid={errors.hitCurb && touched.hitCurb ? true : false}
                onChange={handleChange}>
                <option value="">Select Hit Curb</option>
                {['Yes', 'No'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.hitCurb && touched.hitCurb ? errors.hitCurb : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Turn mirrors</Label>
              <Input
                type="select"
                name="turnmirrors"
                placeholder=""
                onBlur={handleBlur}
                value={values.turnmirrors}
                invalid={
                  errors.turnmirrors && touched.turnmirrors ? true : false
                }
                onChange={handleChange}>
                <option value="">Select Turn mirrors</option>
                {['Yes', 'No'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.turnmirrors && touched.turnmirrors
                  ? errors.turnmirrors
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Shifting</Label>
              <Input
                type="select"
                name="shifting"
                placeholder=""
                onBlur={handleBlur}
                value={values.shifting}
                invalid={errors.shifting && touched.shifting ? true : false}
                onChange={handleChange}>
                <option value="">Select Shifting</option>
                {['Yes', 'No'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.shifting && touched.shifting ? errors.shifting : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">InterCoasting</Label>
              <Input
                type="select"
                name="interCoasting"
                placeholder=""
                onBlur={handleBlur}
                value={values.interCoasting}
                invalid={
                  errors.interCoasting && touched.interCoasting ? true : false
                }
                onChange={handleChange}>
                <option value="">Select InterCoasting</option>
                {['Yes', 'No', 'Sometimes'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.interCoasting && touched.interCoasting
                  ? errors.interCoasting
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Inter sectionMirrors</Label>
              <Input
                type="select"
                name="intersectionMirrors"
                placeholder=""
                onBlur={handleBlur}
                value={values.intersectionMirrors}
                invalid={
                  errors.intersectionMirrors && touched.intersectionMirrors
                    ? true
                    : false
                }
                onChange={handleChange}>
                <option value="">Select Inter Section Mirrors</option>
                {['Yes', 'No'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.intersectionMirrors && touched.intersectionMirrors
                  ? errors.intersectionMirrors
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Inter Section TrafficCheck</Label>
              <Input
                type="select"
                name="intersectiontrafficCheck"
                placeholder=""
                onBlur={handleBlur}
                value={values.intersectiontrafficCheck}
                invalid={
                  errors.intersectiontrafficCheck &&
                  touched.intersectiontrafficCheck
                    ? true
                    : false
                }
                onChange={handleChange}>
                <option value="">Select Inter Section TrafficCheck</option>
                {['Yes', 'No', 'Sometimes'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.intersectiontrafficCheck &&
                touched.intersectiontrafficCheck
                  ? errors.intersectiontrafficCheck
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Stop</Label>
              <Input
                type="select"
                name="stop"
                placeholder=""
                onBlur={handleBlur}
                value={values.stop}
                invalid={errors.stop && touched.stop ? true : false}
                onChange={handleChange}>
                <option value="">Select Stop</option>
                {['Bar', 'Full', 'Roll'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.stop && touched.stop ? errors.stop : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Brake</Label>
              <Input
                type="select"
                name="brake"
                placeholder=""
                onBlur={handleBlur}
                value={values.brake}
                invalid={errors.brake && touched.brake ? true : false}
                onChange={handleChange}>
                <option value="">Select Brake</option>
                {['Yes', 'No'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.brake && touched.brake ? errors.brake : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Prep</Label>
              <Input
                type="select"
                name="prep"
                placeholder=""
                onBlur={handleBlur}
                value={values.prep}
                invalid={errors.prep && touched.prep ? true : false}
                onChange={handleChange}>
                <option value="">Select Prep</option>
                {['Slow', 'Too Soon'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.prep && touched.prep ? errors.prep : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Reaction</Label>
              <Input
                type="text"
                name="reaction"
                placeholder="Enter Reaction"
                onBlur={handleBlur}
                value={values.reaction}
                invalid={errors.reaction && touched.reaction ? true : false}
                onChange={handleChange}
              />

              <FormFeedback>
                {errors.reaction && touched.reaction ? errors.reaction : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Rpms</Label>
              <Input
                type="select"
                name="rpms"
                placeholder=""
                onBlur={handleBlur}
                value={values.rpms}
                invalid={errors.rpms && touched.rpms ? true : false}
                onChange={handleChange}>
                <option value="">Select Rpms</option>
                {['Low', 'High', 'Normal'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.rpms && touched.rpms ? errors.rpms : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Timing</Label>
              <Input
                type="select"
                name="timing"
                placeholder=""
                onBlur={handleBlur}
                value={values.timing}
                invalid={errors.timing && touched.timing ? true : false}
                onChange={handleChange}>
                <option value="">Select Timing</option>
                {['Early', 'Late', 'Ok'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.timing && touched.timing ? errors.timing : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Gear Pattern</Label>
              <Input
                type="select"
                name="gearPattern"
                placeholder=""
                onBlur={handleBlur}
                value={values.gearPattern}
                invalid={
                  errors.gearPattern && touched.gearPattern ? true : false
                }
                onChange={handleChange}>
                <option value="">Select Gear Pattern</option>
                {['Good', 'Fair', 'Poor'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.gearPattern && touched.gearPattern
                  ? errors.gearPattern
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Bears</Label>
              <Input
                type="select"
                name="bears"
                placeholder=""
                onBlur={handleBlur}
                value={values.bears}
                invalid={errors.bears && touched.bears ? true : false}
                onChange={handleChange}>
                <option value="">Select Bears</option>
                {['Left', 'Right'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.bears && touched.bears ? errors.bears : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Weaves</Label>
              <Input
                type="select"
                name="weaves"
                placeholder=""
                onBlur={handleBlur}
                value={values.weaves}
                invalid={errors.weaves && touched.weaves ? true : false}
                onChange={handleChange}>
                <option value="">Select Weaves</option>
                {['Yes', 'No'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.weaves && touched.weaves ? errors.weaves : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Follow Distance</Label>
              <Input
                type="select"
                name="followDistance"
                placeholder=""
                onBlur={handleBlur}
                value={values.followDistance}
                invalid={
                  errors.followDistance && touched.followDistance ? true : false
                }
                onChange={handleChange}>
                <option value="">Select Follow Distance</option>
                {['Fair', 'Close', 'Ok'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.followDistance && touched.followDistance
                  ? errors.followDistance
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Merging TrafficCheck</Label>
              <Input
                type="select"
                name="mergingTrafficCheck"
                placeholder=""
                onBlur={handleBlur}
                value={values.mergingTrafficCheck}
                invalid={
                  errors.mergingTrafficCheck && touched.mergingTrafficCheck
                    ? true
                    : false
                }
                onChange={handleChange}>
                <option value="">Select Merging TrafficCheck</option>
                {['Yes', 'No'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.mergingTrafficCheck && touched.mergingTrafficCheck
                  ? errors.mergingTrafficCheck
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Merging Smooth</Label>
              <Input
                type="select"
                name="mergingSmooth"
                placeholder=""
                onBlur={handleBlur}
                value={values.mergingSmooth}
                invalid={
                  errors.mergingSmooth && touched.mergingSmooth ? true : false
                }
                onChange={handleChange}>
                <option value="">Select Merging Smooth</option>
                {['Yes', 'No'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.mergingSmooth && touched.mergingSmooth
                  ? errors.mergingSmooth
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Rail road Traffic Check</Label>
              <Input
                type="select"
                name="railroadTrafficCheck"
                placeholder=""
                onBlur={handleBlur}
                value={values.railroadTrafficCheck}
                invalid={
                  errors.railroadTrafficCheck && touched.railroadTrafficCheck
                    ? true
                    : false
                }
                onChange={handleChange}>
                <option value="">Select Railroad TrafficCheck</option>
                {['Yes', 'No', 'N/A'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.railroadTrafficCheck && touched.railroadTrafficCheck
                  ? errors.railroadTrafficCheck
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Rail road Smooth</Label>
              <Input
                type="select"
                name="railroadSmooth"
                placeholder=""
                onBlur={handleBlur}
                value={values.railroadSmooth}
                invalid={
                  errors.railroadSmooth && touched.railroadSmooth ? true : false
                }
                onChange={handleChange}>
                <option value="">Select Railroad Smooth</option>
                {['Yes', 'No', 'N/A'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.railroadSmooth && touched.railroadSmooth
                  ? errors.railroadSmooth
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">HoverH</Label>
              <Input
                type="select"
                name="hoverH"
                placeholder=""
                onBlur={handleBlur}
                value={values.hoverH}
                invalid={errors.hoverH && touched.hoverH ? true : false}
                onChange={handleChange}>
                <option value="">Select HoverH</option>
                {['Yes', 'No'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.hoverH && touched.hoverH ? errors.hoverH : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          {/* <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Example</Label>
              <Input
                type="text"
                name="example"
                placeholder="Enter Example"
                onBlur={handleBlur}
                value={values.example}
                invalid={errors.example && touched.example ? true : false}
                onChange={handleChange}
              />

              <FormFeedback>
                {errors.example && touched.example ? errors.example : ''}
              </FormFeedback>
            </FormGroup>
          </Col> */}
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Observation</Label>
              <Input
                type="select"
                name="observation"
                placeholder=""
                onBlur={handleBlur}
                value={values.observation}
                invalid={
                  errors.observation && touched.observation ? true : false
                }
                onChange={handleChange}>
                <option value="">Select Observation</option>
                {['Good', 'Fair', 'Poor'].map((t) => (
                  <option value={t}>{t}</option>
                ))}
              </Input>
              <FormFeedback>
                {errors.observation && touched.observation
                  ? errors.observation
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup check>
              <Label check for="">
                <Input
                  type="checkbox"
                  name="observationTraffic"
                  onBlur={handleBlur}
                  value={values.observationTraffic}
                  invalid={
                    errors.observationTraffic && touched.observationTraffic
                      ? true
                      : false
                  }
                  onChange={handleChange}
                />
                Observation Traffic
              </Label>

              <FormFeedback
                style={{
                  display:
                    errors.observationTraffic && touched.observationTraffic
                      ? 'block'
                      : 'none',
                }}>
                {errors.observationTraffic && touched.observationTraffic
                  ? errors.observationTraffic
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup check>
              <Label check for="">
                <Input
                  type="checkbox"
                  name="identifyHazards"
                  onBlur={handleBlur}
                  value={values.identifyHazards}
                  invalid={
                    errors.identifyHazards && touched.identifyHazards
                      ? true
                      : false
                  }
                  onChange={handleChange}
                />
                Identify Hazards
              </Label>

              <FormFeedback
                style={{
                  display:
                    errors.identifyHazards && touched.identifyHazards
                      ? 'block'
                      : 'none',
                }}>
                {errors.identifyHazards && touched.identifyHazards
                  ? errors.identifyHazards
                  : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup check>
              <Label check for="">
                <Input
                  type="checkbox"
                  name="signs"
                  onBlur={handleBlur}
                  value={values.signs}
                  invalid={errors.signs && touched.signs ? true : false}
                  onChange={handleChange}
                />
                Signs
              </Label>
              <FormFeedback
                style={{
                  display: errors.signs && touched.signs ? 'block' : 'none',
                }}>
                {errors.signs && touched.signs ? errors.signs : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md="4" xl="3">
            <FormGroup>
              <Label for="">Comments</Label>
              <Input
                type="text"
                name="comments"
                placeholder="Enter Comments"
                onBlur={handleBlur}
                value={values.comments}
                invalid={errors.comments && touched.comments ? true : false}
                onChange={handleChange}
              />

              <FormFeedback>
                {errors.comments && touched.comments ? errors.comments : ''}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <div className="btn-center">
          <Button color="primary" type="submit" className="pull-left">
            Submit
          </Button>
        </div>{' '}
      </Form>
    </div>
  )
}
