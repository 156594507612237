import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  Container,
  Button,
  Card,
  Table,
  Row,
  Col,
  Collapse,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'reactstrap'
import { useHistory, useLocation } from 'react-router'
import { Add, Delete, Filter, Pencil } from '../../../function/Icons'
import { CustomMessage, locationParams } from '../../../function/Helperfunction'
import { authHeader } from '../../../function/Mainfunction'
import { DELETE_FOUL, LIST_FOUL } from '../../../constants/Apipath'
import Pagination from 'react-js-pagination'
import { useSnackbar } from 'notistack'
import API from '../../../constants/API'
import { MyLoader } from '../../../function/Loader'
import { StateValue, UrlReplace } from '../../../constants/Helper'
import { debounce } from 'underscore'
import Swal from 'sweetalert2'
import {
  CREATE_FOUL_ROUTE,
  UPDATE_FOUL_ROUTE,
} from '../../../constants/RoutePaths'
import { Link } from 'react-router-dom'
import generatePDF from './FoulPDFGenerator'
import { CSVLink } from 'react-csv'
import NoRecordFound from '../../../function/EmptyRecord'
import noData from '../../../images/no-data.svg'

const Initialvalue = {
  firstName: '',
  email: '',
  instuctorname: '',
  batchname: '',
  offset: '0',
}
export default function Foul() {
  const history = useHistory()
  const location = useLocation()
  const [loading, setloading] = useState(false)
  const [foulList, setFoulList] = useState([])
  const [totalcount, setTotalcount] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [filterOpen, setfilterOpen] = useState(false)
  const filtertoggle = () => setfilterOpen(!filterOpen)
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const toggle = () => {
    setdropdownOpen(!dropdownOpen)
  }

  const headers = [
    { label: 'Name', key: 'firstName' },
    { label: 'Batch Name', key: 'batchName' },
    { label: 'Card Type', key: 'cardType' },
    { label: 'Email', key: 'email' },
    { label: 'Instructor Name', key: 'instructorName' },
    { label: 'BatchStart Date', key: 'batchStartDate' },
  ]
  const [filterValue, setfilterValue] = useState(
    StateValue(location.search, Initialvalue)
  )

  const Export = () => {
    generatePDF(foulList)
  }
  const GetFoul = async (filterValue) => {
    const config = {
      method: 'GET',
      url: LIST_FOUL,
      headers: authHeader(),
      params: {
        count: 10,
        ...filterValue,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        setFoulList(data)
        setTotalcount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }
  const DeleteAlert = (e) => {
    const Id = e.currentTarget.dataset.studentcarddetailid
    Swal.fire({
      title: 'Are you want to Delete this Foul?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteFoul(Id)
      }
    })
  }

  const DeleteFoul = async (Id) => {
    const config = {
      method: 'DELETE',
      url: DELETE_FOUL,
      headers: authHeader(),
      params: { studentCardDetailId: Id },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
        GetFoul()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const filterHandle = (e) => {
    const { name, value } = e.target
    setfilterValue({ ...filterValue, [name]: value, offset: '0' })
  }

  const handlePageChange = (page) => {
    setfilterValue({ ...filterValue, offset: page - 1 })
  }
  const Clean = () => {
    setfilterValue(Initialvalue)
  }

  const delaySearch = useRef(debounce((filterValue) => GetFoul(filterValue)))
    .current
  useEffect(() => {
    let pagePath = location.pathname
    let locParams = locationParams(location.search)
    UrlReplace(pagePath, locParams, filterValue, setfilterOpen, history)
    delaySearch(filterValue)
  }, [filterValue])

  const CreateNewFoul = () => {
    history.push(CREATE_FOUL_ROUTE)
  }

  return (
    <Fragment>
      <div className="wrapper">
        <div className="page-title">
          <h4>Foul</h4>
          <div className="btn-right">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle color="danger" caret>
                Export
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={Export}>PDF</DropdownItem>

                <DropdownItem>
                  <CSVLink
                    filename={'FoulReport.csv'}
                    data={foulList}
                    headers={headers}>
                    CSV
                  </CSVLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Button onClick={filtertoggle} color="danger">
              <Filter />
              Filter
            </Button>
            <Button color="primary" onClick={CreateNewFoul}>
              <Add />
              New Foul
            </Button>
          </div>
        </div>
        <Container fluid={true} className="mb-4">
          <Collapse isOpen={filterOpen}>
            <Card body>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="firstName"
                      value={filterValue.firstName}
                      onChange={filterHandle}
                      placeholder="Enter  First Name"
                    />
                  </FormGroup>
                </Col>{' '}
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="email"
                      value={filterValue.email}
                      onChange={filterHandle}
                      placeholder="Enter Email"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="instuctorname"
                      value={filterValue.instuctorname}
                      onChange={filterHandle}
                      placeholder="Enter Instuctorname"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="batchname"
                      value={filterValue.batchname}
                      onChange={filterHandle}
                      placeholder="Enter Batchname"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <Button className="mt-1" color="danger" onClick={Clean}>
                    Clean
                  </Button>
                </Col>
              </Row>
            </Card>
          </Collapse>
        </Container>
        <Container fluid={true}>
          <div>
            <Card body>
              {loading && (
                <tr>
                  <td colSpan={11}>
                    <MyLoader rowCount={5} columncount={8} />
                  </td>
                </tr>
              )}

              {!loading && !foulList.length && <NoRecordFound />}

              {foulList && foulList.length > 0 && (
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Batch name</th>
                      <th>Card Type</th>
                      <th>Email</th>
                      <th>Instuctor Name</th>
                      <th>Batch Start Date</th>
                      <th>Comments</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      foulList.map((f) => (
                        <tr>
                          <td>
                            {f.firstName}
                            {f.lastName}
                          </td>
                          <td>{f.batchName}</td>
                          <td>
                            {f.cardType === 'red' ? (
                              <span className="table-foulcard red"></span>
                            ) : (
                              <span className="table-foulcard yellow"></span>
                            )}
                          </td>
                          <td>{f.email}</td>
                          <td>{f.insructorName}</td>
                          <td>{f.batchStartDate}</td>
                          <td>{f.comments}</td>
                          <td className="btn-column">
                            <Link
                              to={`${UPDATE_FOUL_ROUTE}/${f.studentCardDetailId}`}>
                              <Button color="link">
                                <Pencil />
                              </Button>
                            </Link>
                            <Button
                              onClick={DeleteAlert}
                              data-studentcarddetailid={f.studentCardDetailId}
                              color="link">
                              <Delete />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}

              <div className="d-flex flex-row justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={parseInt(filterValue.offset) + 1}
                  itemsCountPerPage={10}
                  totalItemsCount={totalcount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              </div>
            </Card>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
