import React from 'react'
import { Card, CardTitle, Table } from 'reactstrap'
import { capitalizeFirstLetter } from '../../../function/Helperfunction'
import noData from '../../../images/no-data.svg'

export default function RepsRollCall({ RepsRoll }) {
  return (
    <>
      <Card body className="drive-data-card">
        <CardTitle tag="h5">Roll Call</CardTitle>
        {RepsRoll.length > 0 && (
          <Table responsive hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>AM</th>
                <th>PM</th>
                <th>NOON</th>
              </tr>
            </thead>
            {RepsRoll.length > 0 &&
              RepsRoll.map(({ attendanceDate, am, pm, noon }, i) => (
                <tbody>
                  <tr key={i}>
                    <td>{attendanceDate}</td>
                    <td>{capitalizeFirstLetter(am)}</td>
                    <td>{capitalizeFirstLetter(pm)}</td>
                    <td>{capitalizeFirstLetter(noon)}</td>
                  </tr>
                </tbody>
              ))}
          </Table>
        )}
        {!RepsRoll.length && (
          <div className="nodata-sec">
            <img src={noData} />
          </div>
        )}
      </Card>
    </>
  )
}
