import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  Button,
  NavLink,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Container,
  UncontrolledDropdown,
  Collapse,
} from 'reactstrap'
import Swal from 'sweetalert2'
import Avatar from 'react-avatar'
import { LAST_NAME, USER_NAME, USER_EMAIL } from '../../constants/Localvaribale'
import {
  BATCH_ROUTE,
  INSTRUCTOR_HOME_ROUTE,
  INSTRUCTOR_SETTINGS,
  INSTRUCTOR_ATTENDANCE,
  INSTRUCTOR_ROLLCALL,
} from '../../constants/RoutePaths'

import Logo from '../../images/logo.png'

export default function Header() {
  const history = useHistory()
  const firstName = localStorage.getItem(USER_NAME)
  const lastName = localStorage.getItem(LAST_NAME)
  const email = localStorage.getItem(USER_EMAIL)
  const date = moment(new Date()).format('MM-DD-YYYY')

  const LogOut = () => {
    Swal.fire({
      title: 'Are you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Log out',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear()
        history.push('/signin')
      }
    })
  }
  const [isOpen, setIsOpen] = React.useState(false)

  const [activeMenu, setActiveItem] = useState('/')
  let location = useLocation()
  useEffect(() => {
    let currentActive = window.location.pathname.split('/')[2]
    setActiveItem(currentActive)
  }, [location])

  const attendanceDate = moment(new Date()).format('MM-DD-YYYY')
  return (
    <div>
      <Navbar
        className="instructor-nav"
        color="white"
        light
        sticky="top"
        expand="md">
        <Container fluid className="titlecollapse">
          <NavbarBrand
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(INSTRUCTOR_HOME_ROUTE)}
            className="ml-0">
            <img src={Logo} />
          </NavbarBrand>
          <NavbarToggler
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto mr-4 navbaruser" navbar>
              <NavItem>
                <Link to={INSTRUCTOR_HOME_ROUTE}>
                  <NavLink className={activeMenu == 'home' ? 'active' : ''}>
                    Home
                  </NavLink>
                </Link>
              </NavItem>

              <NavItem>
                <Link to={BATCH_ROUTE}>
                  <NavLink className={activeMenu == 'batch' ? 'active' : ''}>
                    My batch
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to={INSTRUCTOR_SETTINGS}>
                  <NavLink className={activeMenu == 'settings' ? 'active' : ''}>
                    Settings
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to={`${INSTRUCTOR_ATTENDANCE}/${attendanceDate}`}>
                  <NavLink
                    className={activeMenu == 'attendance' ? 'active' : ''}>
                    Attendance
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to={`${INSTRUCTOR_ROLLCALL}/${date}`}>
                  <NavLink className={activeMenu == 'rollcall' ? 'active' : ''}>
                    Roll Call
                  </NavLink>{' '}
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
          <NavbarText className="user-profile mr-2">
            <UncontrolledDropdown direction="bottom" setActiveFromChild>
              <DropdownToggle className="nav-link" caret>
                <Avatar
                  name={`${firstName} ${lastName}`}
                  size={30}
                  round="50px"
                />{' '}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="nav-drop-profile">
                  <div className="info">
                    <Avatar
                      name={`${firstName} ${lastName}`}
                      size={60}
                      round="50px"
                    />{' '}
                    <h4>{`${firstName} ${lastName}`} </h4>
                    <p>{email}</p>
                    <Button color="danger" onClick={LogOut}>
                      <svg
                        className="mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="15.52"
                        viewBox="0 0 16.693 12.52">
                        <path
                          id="sign-out-alt"
                          d="M5.217,68.992a.523.523,0,0,1,.522-.522h4.7V65.409a.261.261,0,0,1,.443-.186l4.617,4.666a.521.521,0,0,1,0,.74l-4.617,4.666a.26.26,0,0,1-.443-.186V72.047h-4.7a.523.523,0,0,1-.522-.522V68.992m-1.043,0v2.533A1.565,1.565,0,0,0,5.738,73.09H9.39v2.018a1.3,1.3,0,0,0,2.224.923l4.62-4.662a1.566,1.566,0,0,0,0-2.217l-4.62-4.666a1.3,1.3,0,0,0-2.224.923v2.018H5.738A1.568,1.568,0,0,0,4.173,68.992ZM0,65.565v9.39A1.565,1.565,0,0,0,1.565,76.52h4.3a.392.392,0,0,0,.391-.391v-.261a.392.392,0,0,0-.391-.391h-4.3a.523.523,0,0,1-.522-.522v-9.39a.523.523,0,0,1,.522-.522h4.3a.392.392,0,0,0,.391-.391v-.261A.392.392,0,0,0,5.869,64h-4.3A1.565,1.565,0,0,0,0,65.565Z"
                          transform="translate(0 -64)"
                          fill="#fff"
                        />
                      </svg>
                      Logout
                    </Button>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavbarText>
        </Container>
      </Navbar>
    </div>
  )
}
