export const PHONE_NUMBER_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const NUMER_REGEX = /[1-4]/g
export const HOURS_REGEX = /^$|^(([0-9])|(1[0-9])|(2[0-3]))$/
export const MINUTES_REGEX = /^$|^(([0-9])|[0-5][0-9])$/
export const FIVE_DIGIT_NUMBER_REGEX = /^\d{5}$/
export const US_PHONE_NUMBER_REGEX = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
export const MINIMUM_EIGHT_MIXED_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
export const MAXIMUM_FIVE_DIGITS_REGEX = /^[0-9]{3,5}$/
export const NINE_DIGIT_REGEX = /^\d{9}$/
export const SIX_DIGIT_OTP_CODE_REGEX = /^\d{6}$/
export const MINIMUM_SIX_CHARACTERS = /^.{6,}$/
export const SIX_CHARACTERS_REGEX = /^[a-zA-Z0-9]{6,}$/
export const NUMBER_REGEX = /^\d+$/
export const FLOAT_VALUE_REGEX = /^[+-]?([0-9]*[.])?[0-9]+$/
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const SOME = /^[A-Za-z0-9_-]*$/
export const PHONE = /^[0-9-()]+$/
