import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  DropdownItem,
  Container,
  Input,
  Table,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Card,
} from 'reactstrap'

import { authHeader, getUserId } from '../../../function/Mainfunction'
import CustomPageLoading from '../../../function/CustomPageLoading'
import API from '../../../constants/API'
import {
  GET_CLASS_ROOM_ATTENDENCEREP,
  LIST_INSTRUCTOR,
  STUDENT_LIST,
  CLASSROOM_ROLLCALL_REPS,
} from '../../../constants/Apipath'
import AttendenceButtonDropdown from './AttendenceButtonDropdown'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import { useHistory, useParams } from 'react-router'
import DpstestAttendance from './DpstestAttendance'
import Dpstestudentadd from './Dpstestudentadd'
import { CustomMessage, sorting } from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'

import Dpstestpdf from './Dpstestpdf'
import { debounce } from 'underscore'
import { ADMIN_ROLLOVER } from '../../../constants/RoutePaths'
import ClassRollOverListModal from './ClassRollOverListModal'
import Classroompdf from './Classroompdf'
const STU_ = 'stuId_'

function ClassRoomRollOverView() {
  let currentActive = window.location.pathname.split('/')[1]
  const history = useHistory()
  const { date } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [addClassRoomStudentmodal, setaddClassRoomStudentmodal] =
    useState(false)
  const addClassRoomStudentToggle = () => setaddClassRoomStudentmodal((v) => !v)
  const [studentList, setstudentList] = useState([])
  const [Attendence, setAttendence] = useState({})
  const [studentModal, setstudentModal] = useState(false)
  const studenttoggle = () => setstudentModal(!studentModal)
  const [rollcallTimeflag, setrollcallTimeflag] = useState(false)

  const [dpsSwitch, setdpsSwitch] = useState(true)
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const [dpsTestAttendence, setdpsTestAttendence] = useState({})
  const [orderListvalue, setorderListvalue] = useState('LA')
  const [downloadFlag, setdownloadFlag] = useState(false)
  const exportToggle = () => {
    setdropdownOpen(!dropdownOpen)
  }
  const SwitchHandle = () => setdpsSwitch((val) => !val)
  const [dpsTestmodal, setdpsTestmodal] = useState(false)
  const dpsTesttoggle = () => setdpsTestmodal((val) => !val)
  const [loading, setloading] = useState(false)
  const [stationName, setstationName] = useState('')
  const [instructorName, setinstructorName] = useState('')
  const [stationList, setstationList] = useState([])
  const [instructionList, setinstructionList] = useState([])

  const getStudent = async () => {
    const config = {
      method: 'GET',
      url: STUDENT_LIST,
      headers: authHeader(),
      params: {
        studentStatus: 'Active',
        count: 1000,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status === 'Success') {
        setstudentList(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  const getRollcall = async () => {
    const config = {
      method: 'GET',
      url: GET_CLASS_ROOM_ATTENDENCEREP,
      headers: authHeader(),
      params: {
        attendanceDate: moment(date).format('YYYY/MM/DD'),
      },
    }

    setloading(true)
    try {
      const response = await API(config)
      const { status, absentPresentCount, data, dbsData } = response.data
      console.log('Data:', data, absentPresentCount)
      if (status === 'Success') {
        setdownloadFlag(true)
        let NewAttendence = {}
        data.forEach(
          (at) =>
            (NewAttendence[STU_ + at.userId] = {
              firstName: at.firstName,
              lastName: at.lastName,

              classRoomAttendanceId: at.classRoomAttendanceId,
              userId: at.userId,
              road: at.road,
              am: at.am,
              pm: at.pm,
              noon: at.noon,
              isDeleted: false,
            })
        )

        setAttendence(NewAttendence)
        setTimeout(() => {
          setdownloadFlag(false)
        }, 2000)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  const successfullcall = () => {
    getRollcall()
  }
  const setabsent = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'absent'
    setAttendence(statusupdate)
  }

  const setpremesstion = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'excused'
    setAttendence(statusupdate)
  }
  const setout = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'unmarked'
    setAttendence(statusupdate)
  }
  const setpresent = (userid, time) => {
    setrollcallTimeflag(true)

    let statusupdate = { ...Attendence }
    statusupdate[STU_ + userid][time] = 'present'
    setAttendence(statusupdate)
  }
  const attendecedata = {
    present: { status: 'Present', onclick: setabsent },
    absent: { status: 'Absent', onclick: setpremesstion },
    excused: { status: 'Excused', onclick: setout },
    unmarked: { status: 'Unmarked', onclick: setpresent },
  }

  const dpstestsetabsent = (userid) => {
    let dpsstatusupdate = { ...dpsTestAttendence }
    dpsstatusupdate[STU_ + userid]['status'] = 'absent'
    setAttendence(dpsstatusupdate)
  }

  const dpstestsetunmark = (userid) => {
    let dpsstatusupdate = { ...dpsTestAttendence }
    dpsstatusupdate[STU_ + userid]['status'] = 'unmarked'
    setAttendence(dpsstatusupdate)
  }
  const dpstestsetpresent = (userid) => {
    let dpsstatusupdate = { ...dpsTestAttendence }

    dpsstatusupdate[STU_ + userid]['status'] = 'present'
    setAttendence(dpsstatusupdate)
  }
  const dpsattendecedata = {
    present: { status: 'Present', onclick: dpstestsetabsent },
    absent: { status: 'Absent', onclick: dpstestsetunmark },
    unmarked: { status: 'Unmarked', onclick: dpstestsetpresent },
  }

  const fromSubmit = async () => {
    const config = {
      method: 'POST',
      url: CLASSROOM_ROLLCALL_REPS,
      headers: authHeader(),
      data: {
        createdBy: getUserId(),
        attendanceDate: moment(date).format('YYYY/MM/DD'),
        classRoomAttendanceDetails: Object.values(Attendence).map((stu) => ({
          classRoomAttendanceId: stu.classRoomAttendanceId,
          userId: stu.userId,
          road: stu.road || '',
          am: stu.am || '',
          pm: stu.pm || '',
          noon: stu.noon || '',
          isDeleted: false,
        })),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { data, status, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar)
        getRollcall()
        setrollcallTimeflag(false)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar)
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  let csvReport = [['Student name', 'AM', 'PM', 'Noon']]

  Object.values(Attendence)
    .sort((a, b) => sorting(a, b, orderListvalue))
    .forEach((rec, index) => {
      const { firstName, lastName, pm, noon, am } = rec

      csvReport.push([
        firstName + ' ' + lastName || 'N/A',
        am || 'N/A',
        pm || 'N/A',
        noon || 'N/A',
      ])
    })

  let dpsTestreport = [['Student name', 'date', 'status']]

  Object.values(dpsTestAttendence)
    .sort((a, b) => sorting(a, b, orderListvalue))
    .forEach((rec, index) => {
      const { firstName, lastName, status, attendanceDate } = rec

      dpsTestreport.push([
        firstName + ' ' + lastName || 'N/A',
        status || 'N/A',
        moment(attendanceDate).format('YYYY/MM/DD'),
      ])
    })

  const Exportrollcallpdf = () => {
    if (rollcallTimeflag) {
      CustomMessage(
        'Submit the data before get  report',
        'error',
        enqueueSnackbar
      )

      return false
    }
    Classroompdf(Attendence, orderListvalue)
  }

  const ExportDpstest = () => {
    Dpstestpdf(dpsTestAttendence, orderListvalue)
  }

  useEffect(() => {
    getRollcall()
    getStudent()
  }, [])

  const OrderList = (e) => {
    setorderListvalue(e.target.value)
  }

  const getInstructorList = async (name) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: LIST_INSTRUCTOR,
      params: {
        firstName: name,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setinstructionList(
          data.map((d) => ({ value: d.userId, label: d.firstName }))
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const instructorSearch = useRef(
    debounce((instructorName) => getInstructorList(instructorName), 500)
  ).current

  useEffect(() => {
    instructorSearch(instructorName)
  }, [instructorName])

  const classrommRollsuccess = () => {
    getRollcall()
  }

  const goBack = () => {
    history.push(ADMIN_ROLLOVER)
  }
  console.log(Attendence)
  return (
    <div className="wrapper">
      {loading && <CustomPageLoading />}
      <div className="page-title">
        <h4>Class Room Roll call</h4>
        <div className="ml-auto"></div>
        <div className="btn-right">
          <Input value={orderListvalue} onChange={OrderList} type="select">
            <option value={'FA'}>First Name Ascending </option>
            <option value={'LA'}>Last Name Ascending </option>
            <option value={'FD'}>First Name Descending </option>
            <option value={'LD'}>Last Name Descending </option>
          </Input>

          <Dropdown isOpen={dropdownOpen} toggle={exportToggle}>
            <DropdownToggle color="danger" caret>
              Export
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={dpsSwitch ? Exportrollcallpdf : ExportDpstest}>
                PDF
              </DropdownItem>
              <DropdownItem>
                <CSVLink
                  onClick={() => {
                    if (rollcallTimeflag) {
                      CustomMessage(
                        'Submit the data before get  report',
                        'error',
                        enqueueSnackbar
                      )

                      return false
                    }
                  }}
                  filename={`${date}_classroomrollcall.csv`}
                  data={csvReport}>
                  CSV
                </CSVLink>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Button onClick={addClassRoomStudentToggle} color="danger">
            Edit
          </Button>
          <Button onClick={fromSubmit} color="danger">
            Submit
          </Button>

          {/* {currentActive !== 'rollcalladmin' && ( */}
          <Button onClick={goBack} color="danger">
            Back
          </Button>
          {/* )} */}
        </div>
      </div>
      <>
        <Container>
          {dpsSwitch ? (
            <Card body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>AM</th>
                    <th>Noon</th>
                    <th>PM</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(Attendence)
                    .sort((a, b) => sorting(a, b, orderListvalue))
                    .map((rec, index) => {
                      const { road, am, pm, noon } = rec
                      return (
                        <tr>
                          <td>{rec.firstName + ' ' + rec.lastName}</td>
                          <td>
                            <AttendenceButtonDropdown
                              userid={rec.userId}
                              data={attendecedata}
                              time={'am'}
                              value={am || ''}
                            />
                          </td>
                          <td>
                            <AttendenceButtonDropdown
                              userid={rec.userId}
                              data={attendecedata}
                              time={'noon'}
                              value={noon || ''}
                            />
                          </td>{' '}
                          <td>
                            <AttendenceButtonDropdown
                              userid={rec.userId}
                              data={attendecedata}
                              time={'pm'}
                              value={pm || ''}
                            />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </Card>
          ) : (
            <DpstestAttendance
              value={orderListvalue}
              dpsTestAttendence={dpsTestAttendence}
              attendecedata={dpsattendecedata}
            />
          )}

          <ClassRollOverListModal
            data={studentList}
            date={date}
            successfullcall={classrommRollsuccess}
            student={Attendence}
            modal={addClassRoomStudentmodal}
            toggle={addClassRoomStudentToggle}
          />
          <Dpstestudentadd
            date={date}
            student={dpsTestAttendence}
            successfullcall={successfullcall}
            studentList={studentList}
            modal={dpsTestmodal}
            toggle={dpsTesttoggle}
          />
        </Container>
      </>
    </div>
  )
}

export default ClassRoomRollOverView
