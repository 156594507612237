import React from 'react'
import { Card, CardTitle, Table } from 'reactstrap'
import noData from '../../../images/no-data.svg'
import PretripPreview from './PretripPreview'

export default function Pretrip(props) {
  const { PreTripDetails } = props
  return (
    <>
      <Card body className="drive-data-card">
        <CardTitle tag="h5">Pre Trip</CardTitle>
        {!!PreTripDetails.length && (
          <Table responsive hover>
            <thead>
              <tr>
                <th>Pre-Trip ID</th>
                <th>Trip Date</th>
                <th>Pre-Trip Time</th>
                <th>Comments</th>

                <th>Action</th>
              </tr>
            </thead>
            {PreTripDetails.length &&
              PreTripDetails.map((pre, i) => (
                <tbody>
                  <tr key={i}>
                    <td>{pre.preTripId}</td>
                    <td>{pre.tripDate}</td>
                    <td>{pre.preTripTime}</td>
                    <td>{pre.comments || 'N/A'}</td>
                    <td>
                      <PretripPreview PreTripDetails={pre} />
                    </td>
                  </tr>
                </tbody>
              ))}
          </Table>
        )}
        {!PreTripDetails.length && (
          <div className="nodata-sec">
            <img src={noData} />
          </div>
        )}
      </Card>
    </>
  )
}
