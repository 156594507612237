import React, { useEffect, useRef, useState } from 'react'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import PauseIcon from '@material-ui/icons/Pause'
import RefreshIcon from '@material-ui/icons/Refresh'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { Row, Card, Col } from 'reactstrap'
import moment from 'moment'
const timeInitial = {
  startTime: '',
  endTime: '',
}
export default function Timer(props) {
  const { GetTime, toogle } = props
  const [timer, setTimer] = useState(0)
  const [startandendtime, setstartandendtime] = useState(timeInitial)
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [isSubmited, setisSubmited] = useState(true)
  const [flag, setflag] = useState(false)
  const countRef = useRef(null)
  const handlePause = () => {
    setflag(false)
    clearInterval(countRef.current)
    setIsPaused(false)
  }

  const handleResume = () => {
    setstartandendtime((val) => ({
      ...val,
      startTime: val.startTime
        ? val.startTime
        : moment(new Date()).format('HH:mm'),
    }))
    toogle(true)
    setIsPaused(true)
    setflag(true)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  const handleReset = () => {
    clearInterval(countRef.current)
    setIsActive(false)
    setisSubmited(true)
    setIsPaused(false)
    toogle(false)
    GetTime('')
    setstartandendtime(timeInitial)
    setTimer(0)
  }

  const handleStop = () => {
    clearInterval(countRef.current)
    setIsActive(false)
    setIsPaused(false)
  }
  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2)
    const minutes = `${Math.floor(timer / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

    return `${getHours}:${getMinutes}:${getSeconds}`
  }
  const submitFun = () => {
    setstartandendtime((val) => ({
      ...val,
      endTime: val.endTime ? val.endTime : moment(new Date()).format('HH:mm'),
      time: formatTime(),
    }))
  }

  useEffect(() => {
    if (startandendtime.endTime) {
      setisSubmited(false)
      handleStop()
      GetTime(startandendtime)
    }
  }, [startandendtime.endTime])

  return (
    <Card body >
      <div className="app">
        <div className="stopwatch-card">
          <p>Starting time:{startandendtime.startTime || '--/--'}</p>
          <p style={{ float: 'right' }}>
            Ending time:{startandendtime.endTime || '--/--'}
          </p>
          <p style={{ color: 'black', fontSize: '30px', textAlign: 'center' }}>
            {formatTime()}
          </p>

          <Row style={{ textAlign: 'center' }}>
            <Col md="4" xl="4">
              {!flag && (
                <button onClick={handleReset}>
                  <RefreshIcon />
                </button>
              )}
            </Col>
            <Col md="4" xl="4">
              {!isActive && !isPaused && isSubmited && (
                <button onClick={handleResume}>
                  <PlayCircleFilledIcon />{' '}
                </button>
              )}
            </Col>
            {!isActive && isPaused && (
              <Col md="4" xl="4">
                <button onClick={handlePause}>
                  <PauseIcon />{' '}
                </button>
              </Col>
            )}
            {!flag && (
              <Col md="4" xl="4">
                <button onClick={submitFun}>
                  <CheckBoxIcon />
                </button>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </Card>
  )
}
