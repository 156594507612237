import React from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap'

export default class CheckBoxcomponent extends React.PureComponent {
  constructor(props) {
    super()
  }

  render() {
    return (
      <Col>
        <FormGroup check>
          <Label check>
            <Input
              name={this.props.name}
              onChange={this.props.handleChange}
              type="checkbox"
              value={this.props.value}
              checked={this.props.value == 'Yes' ? true : false}
            />
            {this.props.label}
          </Label>
        </FormGroup>
      </Col>
    )
  }
}
