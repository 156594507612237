import React, { useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  CardTitle,
  Card,
} from 'reactstrap'
import { CheckBoxViewComponet } from './CheckBoxViewComponet'

function PretripPreview(props) {
  const { PreTripDetails } = props
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  let fieldValues = {
    leansLeaksDebris: 'Leans Leaks Debris',
    lightsClearance: 'Lights Clearance',
    alternatorBelt: 'Alternator Belt',
    coolantReservoir: 'Coolant Reservoir',
    waterPumpBelt: 'Water Pump Belt',
    powerSteeringPump: 'Power Steering Pump',
    airCompressorGear: 'Air CompressorGear',
    powerSteeringReservior: 'Power Steering Reservior',
    engineOilDipStick: 'Engine OilDipStick',
    engineOilFillTube: 'Engine OilFillTube',
    steeringGearBox: 'Steering GearBox',
    pitmanArm: 'PitmanArm',
    dragLink: 'DragLink',
    steeringKnucle: 'SteeringKnucle',
    castleNutCotterKey: 'Castle Nut CotterKey',
    leafSpringMountFormA: 'Leaf Spring MountFormA',
    leafSprings: 'Leaf Springs',
    shockAbsorbersFormA: 'Shock Absorbers FormA',
    brakeAirHosesFormA: 'Brake AirHosesFormA',
    brakeChamberFormA: 'Brake ChamberFormA',
    pushRodFormA: 'Push RodFormA',
    slackAdjusterFormA: 'Slack AdjusterFormA',
    brakeDrumFormA: 'Brake DrumFormA',
    brakeLiningsFormA: 'Brake LiningsFormA',
    rimFormA: 'RimFormA',
    tireABC: 'TireABC',
    tireDepthFormA: 'Tire DepthFormA',
    valveStem: 'ValveStem',
    lugNutsFormA: 'LugNutsFormA',
    hubOilSealFormA: 'HubOilSealFormA',
    lightLT: 'LightLT',
    mirrorBracket: 'MirrorBracket',
    entrySteps: 'EntrySteps',
    def: 'Def',
    doorHingesSeal: 'Door HingesSeal',
    fuelTank: 'FuelTank',
    truckFrame: 'TruckFrame',
    driveShaft: 'DriveShaft',
    tirePressureFormA: 'Tire PressureFormA',
    exhaust: 'Exhaust',
    catwalkSteps: 'CatwalkSteps',
    torqueBarFormC: 'TorqueBarFormC',
    leafSpringMountFormB: 'Leaf SpringMountFormB',
    leafSpringUJoints: 'Leaf SpringUJoints',
    brakeAirHosesFormB: 'Brake AirHosesFormB',
    brakeChamberFormB: 'Brake ChamberFormB',
    pushRodFormB: 'Push RodFormB',
    slackAdjusterFormB: 'Slack AdjusterFormB',
    brakeDrumFormB: 'Brake DrumFormB',
    brakeLiningsFormB: 'Brake LiningsFormB',
    rimFormB: 'RimFormB',
    tireABCsFormB: 'TiresABC',
    tireDepthFormB: 'Tire DepthFormB',
    debrisSpaceFormB: 'Debris SpaceFormB',
    valveStemCapFormB: 'Valve StemCapFormB',
    tirePressureFormB: 'Tire PressureFormB',
    lugNutsFormB: 'Lug NutsFormB',
    axelSeal: 'Axel Seal',
    shockAbsorbersFormB: 'Shock AbsorbersFormB',
    airbagsMounts: 'Airbags Mounts',
    mudFlapInches: 'Mud FlapInches',
    LightsLT: 'LightsLT',
    reflectorsFormB: 'Reflectors FormB',
    gladHands: 'Glad Hands',
    airLinestoTractor: 'AirLinesto Tractor',
    apron: 'Apron',
    kingPin: 'King Pin',
    lockingJaws: 'Locking Jaws',
    skidPlate: 'Skid Plate',
    platform: 'Platform',
    mountingBolts: 'Mounting Bolts',
    releaseArmFormC: 'Release ArmFormC',
    safetyLatch: 'Safety Latch',
    slidingAssembly: 'Sliding Assembly',
    clearanceforTurns: 'Clearancefor Turns',
    headerBoard: 'Header Board',
    dotTapeFormB: 'Dot TapeFormB',
    trailerFrame: 'Trailer Frame',
    landingGear: 'Landing Gear',
    lightMarker: 'Light Marker',
    reflectorsFormC: 'Reflectors FormC',
    frame: 'Frame',
    releaseArmFormB: 'Release ArmFormB',
    lockingPins: 'Locking Pins',
    torqueBarFormB: 'Torque BarFormB',
    leafSpringMounts: 'Leaf SpringMounts',
    leafSpringsUBolts: 'Leaf SpringsUBolts',
    brakeAirLines: 'Brake AirLines',
    brakeChamberFormC: 'Brake ChamberFormC',
    pushRodFormC: 'Push RodFormC',
    slackAdjusterFormC: 'Slack AdjusterFormC',
    brakeLiningsFormC: 'Brake LiningsFormC',
    brakeDrumFormC: 'Brake DrumFormC',
    rimFormC: 'Rim FormC',
    tireABCs: 'Tire ABCs',
    debrisSpaceFormC: 'Debris SpaceFormC',
    valveStemCapFormC: 'Valve StemCapFormC',
    tirePressureFormC: 'Tire PressureFormC',
    lugNutsFormC: 'Lug NutsFormC',
    hubOilSealFormC: 'Hub OilSealFormC',
    anslight: 'Ans light',
    cornerMarkerLight: 'Corner MarkerLight',
    mudFlap: 'Mud Flap',
    doorsHingesLatches: 'Doors HingesLatches',
    weatherStripping: 'Weather Stripping',
    clearanceLights: 'Clearance Lights',
    llghtsLT: 'LlghtsLT',
    dOTtapeFormC: 'DOT tapeFormC',
    doteTape100FormC: 'Dote Tape100FormC',
    front: 'Front',
    rear: 'Rear',
  }

  const Field = (props) => {
    const { name } = props

    return (
      <CheckBoxViewComponet
        value={PreTripDetails[name]}
        label={fieldValues[name]}
      />
    )
  }
  return (
    <div>
      <AiFillEye onClick={toggle} />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Pretrip Preview</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <div className="trip-preview">
                <h4>Form 1</h4>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Font of vehicle</CardTitle>
                    <Field name="lightsClearance" />
                    <Field name="alternatorBelt" />
                    <Field name="waterPumpBelt" />
                    <Field name="airCompressorGear" />
                    <Field name="engineOilDipStick" />
                  </Card>
                </div>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Steering Axle</CardTitle>
                    <Field name="pitmanArm" />
                    <Field name="steeringKnucle" />
                    <Field name="leafSpringMountFormA" />
                    <Field name="shockAbsorbersFormA" />
                    <Field name="brakeAirHosesFormA" />
                    <Field name="slackAdjusterFormA" />
                    <Field name="steeringGearBox" />
                    <Field name="brakeChamberFormA" />
                    <Field name="tireABCs" />
                    <Field name="valveStem" />
                    <Field name="lugNutsFormA" />
                    <Field name="leansLeaksDebris" />
                  </Card>
                </div>

                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Engine compartment</CardTitle>

                    <Field name="coolantReservoir" />
                    <Field name="powerSteeringPump" />
                    <Field name="powerSteeringReservior" />
                    <Field name="engineOilFillTube" />
                    <Field name="steeringGearBox" />
                    <Field name="dragLink" />
                    <Field name="castleNutCotterKey" />
                    <Field name="leafSprings" />
                    <Field name="brakeAirHosesFormA" />
                    <Field name="pushRodFormA" />
                    <Field name="brakeDrumFormA" />
                    <Field name="rimFormA" />
                    <Field name="tireDepthFormA" />
                    <Field name="tirePressureFormA" />
                    <Field name="hubOilSealFormA" />
                  </Card>
                </div>
              </div>
            </Col>
            <Col md="12">
              <div className="trip-preview">
                <h4>Form 2</h4>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Side cab</CardTitle>
                    <Field name="mirrorBracket" />
                    <Field name="def" />
                  </Card>
                </div>

                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Rear of tractor</CardTitle>
                    <Field name="truckFrame" />
                    <Field name="exhaust" />
                    <Field name="torqueBarFormB" />
                    <Field name="leafSpringUJoints" />
                    <Field name="brakeChamberFormB" />
                    <Field name="slackAdjusterFormB" />
                    <Field name="tireABCsFormB" />
                    <Field name="debrisSpaceFormB" />
                    <Field name="tirePressureFormB" />
                    <Field name="axelSeal" />
                    <Field name="airbagsMounts" />
                    <Field name="LightsLT" />
                  </Card>
                </div>

                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">5th Wheel coupling</CardTitle>

                    <Field name="airLinestoTractor" />
                    <Field name="kingPin" />
                    <Field name="skidPlate" />
                    <Field name="mountingBolts" />
                    <Field name="safetyLatch" />
                    <Field name="clearanceforTurns" />
                    <Field name="lightLT" />
                    <Field name="entrySteps" />
                    <Field name="doorHingesSeal" />
                    <Field name="fuelTank" />
                    <Field name="driveShaft" />
                    <Field name="catwalkSteps" />
                    <Field name="leafSpringMountFormB" />
                    <Field name="brakeAirHosesFormB" />
                    <Field name="pushRodFormB" />
                    <Field name="brakeDrumFormB" />
                    <Field name="rimFormB" />
                    <Field name="tireDepthFormB" />
                    <Field name="valveStemCapFormB" />
                    <Field name="lugNutsFormB" />
                    <Field name="shockAbsorbersFormB" />
                    <Field name="mudFlapInches" />
                    <Field name="reflectorsFormB" />
                    <Field name="gladHands" />
                    <Field name="apron" />
                    <Field name="lockingJaws" />
                    <Field name="platform" />
                    <Field name="releaseArmFormB" />
                    <Field name="slidingAssembly" />
                  </Card>
                </div>
              </div>
            </Col>
            <Col md="12">
              <div className="trip-preview">
                <h4>Form 3</h4>

                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Trailer body</CardTitle>
                    <Field name="trailerFrame" />
                    <Field name="lightMarker" />
                  </Card>
                </div>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Sliding tandem assembly</CardTitle>
                    <Field name="lockingPins" />
                  </Card>
                </div>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Trailer Axels</CardTitle>
                    <Field name="leafSpringsUBolts" />
                    <Field name="brakeChamberFormC" />
                    <Field name="slackAdjusterFormC" />
                    <Field name="brakeDrumFormC" />
                    <Field name="tireABC" />
                    <Field name="valveStemCapFormC" />
                    <Field name="lugNutsFormC" />
                    <Field name="anslight" />
                  </Card>
                </div>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Rear or trailer</CardTitle>

                    <Field name="doorsHingesLatches" />
                    <Field name="clearanceLights" />
                    <Field name="dOTtapeFormC" />
                    <Field name="doteTape100FormC" />
                    <Field name="rear" />
                    <Field name="headerBoard" />
                    <Field name="landingGear" />
                    <Field name="reflectorsFormC" />
                    <Field name="frame" />
                    <Field name="releaseArmFormC" />
                    <Field name="leafSpringMounts" />
                    <Field name="brakeAirLines" />
                    <Field name="pushRodFormC" />
                    <Field name="brakeLiningsFormC" />
                    <Field name="rimFormC" />
                    <Field name="debrisSpaceFormC" />
                    <Field name="tirePressureFormC" />
                    <Field name="hubOilSealFormC" />
                    <Field name="cornerMarkerLight" />
                    <Field name="mudFlap" />
                    <Field name="weatherStripping" />
                    <Field name="llghtsLT" />
                  </Card>
                </div>
                <div className="details">
                  <Card body>
                    <CardTitle tag="h5">Exterior light check</CardTitle>
                    <Field name="front" />
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default PretripPreview
