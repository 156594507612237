import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  Container,
  Button,
  Badge,
  Card,
  Table,
  Collapse,
  Input,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'reactstrap'
import {
  CREATE_DPS_TEST_ROUTE,
  UPDATE_DPS_TEST_ROUTE,
} from '../../../constants/RoutePaths'
import { Add, Delete, Filter, Pencil } from '../../../function/Icons'
import API from '../../../constants/API'
import { authHeader } from '../../../function/Mainfunction'
import {
  capitalizeFirstLetter,
  CustomMessage,
  locationParams,
} from '../../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import { LIST_TEST, DELETE_DPS_TEST } from '../../../constants/Apipath'
import Pagination from 'react-js-pagination'
import { Truckloader } from '../../../function/Loader'
import { debounce } from 'underscore'
import { useRef } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { StateValue, UrlReplace } from '../../../constants/Healper'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import generatePDF from './DpsTestPDFGenerator'
import NoRecordFound from '../../../function/EmptyRecord'

const filterInitial = {
  testId: '',
  studentId: '',
  testname: '',
  testDate: '',
  testresult: '',
  studentName: '',
  offset: '0',
  testType: '',
}
export default function DPSTest() {
  const history = useHistory()
  const location = useLocation()
  const [testdata, setTestdata] = useState([])
  const [totalcount, setTotalcount] = useState(0)
  const [loading, setloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [filterOpen, setfilterOpen] = useState(false)
  const filtertoggle = () => setfilterOpen(!filterOpen)
  const [filterValue, setfilterValue] = useState(
    StateValue(location.search, filterInitial)
  )
  const [orderList, setorderList] = useState('LA')

  const [dropdownOpen, setdropdownOpen] = useState(false)
  const toggle = () => {
    setdropdownOpen(!dropdownOpen)
  }

  const headers = [
    { label: 'First name', key: 'firstName' },
    { label: 'Last name', key: 'lastName' },

    { label: 'Student ID', key: 'studentId' },
    { label: 'Test Type', key: 'testName' },
    { label: 'Test ID', key: 'testId' },
    { label: 'Test Date', key: 'testDate' },
    { label: 'Test Hours', key: 'testHours' },
    { label: 'Result', key: 'testResult' },
    { label: 'Comments', key: 'comments' },
  ]

  const Export = () => {
    generatePDF(testdata)
  }

  const GetTest = async (filterValue, orderList) => {
    const AssDess = {
      LA: { columnName: 'lastName', orderBy: 'asc' },
      FA: { columnName: 'firstName', orderBy: 'asc' },
      LD: { columnName: 'lastName', orderBy: 'desc' },
      FD: { columnName: 'firstName', orderBy: 'desc' },
    }
    const config = {
      method: 'GET',
      url: LIST_TEST,
      headers: authHeader(),
      params: {
        count: 10,
        ...filterValue,
        ...AssDess[orderList],
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        setTestdata(data)
        setTotalcount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const DeleteAlert = (e) => {
    const Id = e.currentTarget.dataset.testid
    Swal.fire({
      title: 'Are you want to Delete this Test?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteTest(Id)
      }
    })
  }

  const DeleteTest = async (Id) => {
    const config = {
      method: 'DELETE',
      url: DELETE_DPS_TEST,
      headers: authHeader(),
      params: { testId: Id },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
        GetTest()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const filterHandle = (e) => {
    const { name, value } = e.target
    setfilterValue({ ...filterValue, [name]: value, offset: '0' })
  }

  const handlePageChange = (page) => {
    setfilterValue({ ...filterValue, offset: page - 1 })
  }

  const DateHandle = (date, name) => {
    const newDate = moment(date).format('L')
    setfilterValue({ ...filterValue, [name]: newDate })
  }

  const Clean = () => {
    setfilterValue(filterInitial)
  }

  const delaySearch = useRef(
    debounce((filterValue, orderList) => GetTest(filterValue, orderList))
  ).current
  useEffect(() => {
    let pagePath = location.pathname
    let locParams = locationParams(location.search)
    UrlReplace(pagePath, locParams, filterValue, setfilterOpen, history)
    delaySearch(filterValue, orderList)
  }, [filterValue, orderList])
  const CreateDps = () => {
    history.push(CREATE_DPS_TEST_ROUTE)
  }
  // program to convert first letter of a string to uppercase

  const OrderList = (e) => {
    setorderList(e.target.value)
  }
  return (
    <Fragment>
      <div className="wrapper">
        <div className="page-title">
          <h4>DPS Test</h4>
          <div className="new-input">
            <Input value={orderList} onChange={OrderList} type="select">
              <option value={'FA'}>First Name (Ascending) </option>
              <option value={'LA'}>Last Name (Ascending) </option>
              <option value={'FD'}>First Name (Descending) </option>
              <option value={'LD'}>Last Name (Descending) </option>
            </Input>
          </div>
          <div className="btn-right">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle color="danger" caret>
                Export
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={Export}>PDF</DropdownItem>

                <DropdownItem>
                  <CSVLink
                    filename={'DPSTest_Details.csv'}
                    data={testdata}
                    headers={headers}>
                    CSV
                  </CSVLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Button onClick={filtertoggle} color="danger">
              <Filter />
              Filter
            </Button>
            <Button onClick={CreateDps} color="primary">
              <Add />
              New Test
            </Button>
          </div>
        </div>
        <Container fluid={true} className="mb-4">
          <Collapse isOpen={filterOpen}>
            <Card body>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="number"
                      name="testId"
                      value={filterValue.testId}
                      onChange={filterHandle}
                      placeholder="Enter test id"
                    />
                  </FormGroup>
                </Col>{' '}
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="number"
                      name="studentId"
                      value={filterValue.studentId}
                      onChange={filterHandle}
                      placeholder="Enter student id"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="testType"
                      value={filterValue.testType}
                      onChange={filterHandle}
                      placeholder="Enter test type"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="studentName"
                      value={filterValue.studentName}
                      onChange={filterHandle}
                      placeholder="Enter student name"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="testresult"
                      value={filterValue.testresult}
                      onChange={filterHandle}
                      placeholder="Enter test result"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <DatePicker
                      name="testDate"
                      closeOnScroll={true}
                      placeholderText="Test date"
                      onChange={(date) => DateHandle(date, 'testDate')}
                      selected={
                        !filterValue.testDate
                          ? null
                          : new Date(filterValue.testDate)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <Button color="danger" className="mt-1" onClick={Clean}>
                    Clean
                  </Button>
                </Col>
              </Row>
            </Card>
          </Collapse>
        </Container>
        <Container fluid={true}>
          <div>
            <Card body>
              {loading && (
                <tr>
                  <td colSpan={7}>
                    <Truckloader />
                  </td>
                </tr>
              )}
              {!loading && testdata && testdata.length > 0 && (
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Student ID</th>
                      <th>Test Type</th>
                      <th>Test ID</th>
                      <th>Test Date</th>
                      <th>Test Hours</th>
                      <th>Result</th>
                      <th>Comments</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      testdata.length &&
                      testdata.map((tes) => (
                        <tr>
                          <td>{tes.firstName + ' ' + tes.lastName}</td>
                          <td>{tes.studentId}</td>
                          <td>{tes.testType}</td>
                          <td>{tes.testId}</td>
                          <td>{tes.testDate}</td>
                          <td>{tes.testHours}</td>
                          <td>
                            <Badge
                              color={
                                (tes?.testResult || '').toLowerCase() === 'pass'
                                  ? 'success'
                                  : 'danger'
                              }>
                              {capitalizeFirstLetter(tes.testResult)}
                            </Badge>
                          </td>
                          <td>{tes.comments ? tes.comments : 'N/A'}</td>
                          <td className="btn-column">
                            <Link to={`${UPDATE_DPS_TEST_ROUTE}/${tes.testId}`}>
                              <Button color="link">
                                <Pencil />
                              </Button>
                            </Link>
                            <Button
                              onClick={DeleteAlert}
                              data-testid={tes.testId}
                              color="link">
                              <Delete />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
              {!loading && !testdata.length && <NoRecordFound />}
              <div className="d-flex flex-row justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={parseInt(filterValue.offset) + 1}
                  itemsCountPerPage={10}
                  totalItemsCount={totalcount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              </div>
            </Card>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
