import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import API from '../../../constants/API'
import { STUDENT_REPS } from '../../../constants/Apipath'
import { sorting } from '../../../function/Helperfunction'
import { authHeader, getUserId } from '../../../function/Mainfunction'
let stuId_ = 'stuId_'
function Listmodal(props) {
  const { modal, toggle, data, date, successfullcall, student } = props
  const [refList, setrefList] = useState([])
  const [loading, setloading] = useState(false)
  const [filterValue, setfilterValue] = useState([])
  const [markedStudent, setmarkedStudent] = useState({})
  const [orderList, setorderList] = useState('LA')

  const Submit = async () => {
    const config = {
      method: 'POST',
      url: STUDENT_REPS,
      headers: authHeader(),
      data: {
        createdBy: getUserId(),
        attendanceDate: moment(date).format('YYYY/MM/DD'),
        repsRoleAttendanceDetails: Object.values(markedStudent).map((stu) => ({
          attendanceId: stu.attendanceId,
          userId: stu.userId,
          road: stu.road,
          am: stu.am,
          stationInstructor: stu.stationInstructor,
          driveInstructor: stu.driveInstructor,
          stationId: stu.stationId,
          rollCallTime: stu.rollCallTime,
          pm: stu.pm,
          noon: stu.noon,
          isDeleted: stu.isDeleted,
        })),
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status } = response.data
      if (status === 'Success') {
        toggle()
        successfullcall()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        console.error(data)
      }
    } finally {
      setloading(false)
    }
  }

  const searchstudent = (e) => {
    let { value } = e.target

    const filteredList = data.filter((job) => {
      if (
        job.firstName.toLowerCase().startsWith(value.toLowerCase()) ||
        job.lastName.toLowerCase().startsWith(value.toLowerCase())
      ) {
        return job
      }
    })

    setrefList(filteredList)
  }
  const Markstudent = (e) => {
    const { checked, id } = e.target
    let student = { ...markedStudent }

    student[stuId_ + id] = {
      am: 'unmarked',
      attendanceId: 0,
      noon: 'unmarked',
      pm: 'unmarked',
      stationInstructor: null,
      driveInstructor: null,
      stationId: null,
      rollCallTime: '',
      road: '',
      ...(student[stuId_ + id] || ''),
      userId: id,
      isDeleted: !checked,
    }

    setmarkedStudent(student)
  }
  useEffect(() => {
    setrefList(data)
  }, [data])

  // let allValues = [...isChecked, isUnChecked]
  useEffect(() => {
    let isChecked = []
    let isUnChecked = []

    refList.forEach((d) => {
      const { isDeleted } = student[stuId_ + d.userId] || {}
      if (isDeleted == false) {
        isUnChecked.push(d)
      } else {
        isChecked.push(d)
      }
    })
    setfilterValue((val) => [...isUnChecked, ...isChecked])
  }, [refList])
  useEffect(() => {
    setmarkedStudent(student)
  }, [student])
  const selectAllstudent = () => {
    let newstudent = { ...markedStudent }

    refList.forEach((mark) => {
      newstudent[stuId_ + mark.userId] = {
        am: 'unmarked',
        attendanceId: 0,
        noon: 'unmarked',
        pm: 'unmarked',
        road: '',
        ...(student[stuId_ + mark.userId] || ''),
        userId: mark.userId,
        isDeleted: false,
      }
    })
    setmarkedStudent(newstudent)
  }

  const unselectAllstudent = () => {
    let student = { ...markedStudent }
    Object.values(student).forEach(
      (val) =>
        (student[stuId_ + val.userId] = {
          am: 'unmarked',
          attendanceId: 0,
          noon: 'unmarked',
          pm: 'unmarked',
          road: '',
          ...(student[stuId_ + val.userId] || ''),
          userId: val.userId,
          isDeleted: true,
        })
    )
    setmarkedStudent(student)
  }
  const setDefaultvalue = () => {
    setrefList(data)
    setorderList('LA')
    setmarkedStudent(student)
  }

  const center = {
    display: 'flex',
    'flex-direction': 'column',
    flex: 1,
    overflow: 'hidden',
    'align-items': 'center',
    'justify-content': 'center',
  }
  const Design = {
    display: 'flex',
    'flex-direction': 'row',
    flex: 1,
    'justify-content': 'space-evenly',

    'align-items': 'center',
  }

  useEffect(() => {
    let isChecked = []
    let isUnChecked = []

    refList.forEach((d) => {
      const { isDeleted } = markedStudent[stuId_ + d.userId] || {}
      if (isDeleted == false) {
        isUnChecked.push(d)
      } else {
        isChecked.push(d)
      }
    })
    setfilterValue((val) => [
      ...isUnChecked.sort((a, b) => sorting(a, b, orderList)),
      ...isChecked.sort((a, b) => sorting(a, b, orderList)),
    ])
  }, [orderList])
  const OrderList = (e) => {
    setorderList(e.target.value)
  }
  return (
    <div>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          toggle()
          setDefaultvalue()
        }}>
        <ModalHeader toggle={toggle}>Add Roll Call</ModalHeader>
        <ModalBody>
          <Row className="mb-4">
            <Col

            // md="6" md={{ size: 6, offset: 3 }}
            >
              {' '}
              <Input
                type="search"
                placeholder="Search students"
                onChange={searchstudent}
              />{' '}
            </Col>
            <Col>
              {' '}
              <Input value={orderList} onChange={OrderList} type="select">
                <option value={'FA'}>First Name (Ascending) </option>
                <option value={'LA'}>Last Name (Ascending) </option>
                <option value={'FD'}>First Name (Descending) </option>
                <option value={'LD'}>Last Name (Descending) </option>
              </Input>
            </Col>
            <Col>
              <Button color="primary" onClick={selectAllstudent}>
                Check All
              </Button>
            </Col>
            <Col>
              <Button color="danger" onClick={unselectAllstudent}>
                Uncheck All
              </Button>
            </Col>
          </Row>
          <Row xs={3}>Weekdays students</Row>
          <br />
          <Row xs="3">
            {filterValue
              .filter((v) => v.isWeekendStudent == false)
              .map((d, index) => {
                const { isDeleted } = markedStudent[stuId_ + d.userId] || {}
                return (
                  <Col key={index}>
                    {' '}
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={Markstudent}
                          id={d.userId}
                          checked={isDeleted == false ? true : false}
                          type="checkbox"
                        />
                        {d.firstName + ' ' + d.lastName}
                      </Label>
                    </FormGroup>
                  </Col>
                )
              })}
          </Row>
          <br />
          <Row xs={3}>Weekend students</Row>
          <br />

          <Row xs="3">
            {filterValue
              .filter((v) => v.isWeekendStudent == true)
              .map((d, index) => {
                const { isDeleted } = markedStudent[stuId_ + d.userId] || {}
                return (
                  <Col key={index}>
                    {' '}
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={Markstudent}
                          id={d.userId}
                          checked={isDeleted == false ? true : false}
                          type="checkbox"
                        />
                        {d.firstName + ' ' + d.lastName}
                      </Label>
                    </FormGroup>
                  </Col>
                )
              })}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={Submit}>
            {loading && <Spinner size="sm" />}ADD
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Listmodal
