import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Form,
  Button,
  FormGroup,
} from 'reactstrap'
import CheckBoxcomponent from './inputcomponets/CheckBoxcomponent'
export default function Pretrip(props) {
  const { GetValues, time } = props
  const validationSchema = Yup.object().shape({
    tripDate: Yup.date().required('Pretrip Date  Required'),
  })
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      tripDate: '',
      preTripTime: '',
      startTime: '',
      endTime: '',
      leansLeaksDebris: 'No',
      lightsClearance: 'No',
      alternatorBelt: 'No',
      coolantReservoir: 'No',
      waterPumpBelt: 'No',
      powerSteeringPump: 'No',
      airCompressorGear: 'No',
      powerSteeringReservior: 'No',
      engineOilDipStick: 'No',
      engineOilFillTube: 'No',
      steeringGearBox: 'No',
      pitmanArm: 'No',
      dragLink: 'No',
      steeringKnucle: 'No',
      castleNutCotterKey: 'No',
      leafSpringMountFormA: 'No',
      leafSprings: 'No',
      tirePressureFormA: 'No',
      shockAbsorbersFormA: 'No',
      brakeAirHosesFormA: 'No',
      brakeChamberFormA: 'No',
      pushRodFormA: 'No',
      slackAdjusterFormA: 'No',
      brakeDrumFormA: 'No',
      brakeLiningsFormA: 'No',
      rimFormA: 'No',
      tireABC: 'No',
      tireDepthFormA: 'No',
      valveStem: 'No',
      lugNutsFormA: 'No',
      hubOilSealFormA: 'No',
      lightLT: 'No',
      mirrorBracket: 'No',
      entrySteps: 'No',
      def: 'No',
      doorHingesSeal: 'No',
      fuelTank: 'No',
      truckFrame: 'No',
      driveShaft: 'No',
      exhaust: 'No',
      catwalkSteps: 'No',
      torqueBarFormC: 'No',
      leafSpringMountFormB: 'No',
      leafSpringUJoints: 'No',
      brakeAirHosesFormB: 'No',
      brakeChamberFormB: 'No',
      pushRodFormB: 'No',
      slackAdjusterFormB: 'No',
      brakeDrumFormB: 'No',
      brakeLiningsFormB: 'No',
      rimFormB: 'No',
      tireABCsFormB: 'No',
      tireDepthFormB: 'No',
      debrisSpaceFormB: 'No',
      valveStemCapFormB: 'No',
      tirePressureFormB: 'No',
      lugNutsFormB: 'No',
      axelSeal: 'No',
      shockAbsorbersFormB: 'No',
      airbagsMounts: 'No',
      mudFlapInches: 'No',
      LightsLT: 'No',
      reflectorsFormB: 'No',
      gladHands: 'No',
      airLinestoTractor: 'No',
      apron: 'No',
      kingPin: 'No',
      lockingJaws: 'No',
      skidPlate: 'No',
      platform: 'No',
      mountingBolts: 'No',
      releaseArmFormC: 'No',
      safetyLatch: 'No',
      slidingAssembly: 'No',
      clearanceforTurns: 'No',
      headerBoard: 'No',
      dotTapeFormB: 'No',
      trailerFrame: 'No',
      landingGear: 'No',
      lightMarker: 'No',
      reflectorsForm: 'No',
      frame: 'No',
      releaseArmFormB: 'No',
      lockingPins: 'No',
      torqueBarFormB: 'No',
      leafSpringMounts: 'No',
      leafSpringsUBolts: 'No',
      brakeAirLines: 'No',
      brakeChamberFormC: 'No',
      pushRodFormC: 'No',
      slackAdjusterFormC: 'No',
      brakeLiningsFormC: 'No',
      brakeDrumFormC: 'No',
      rimFormC: 'No',
      tireABCsFormA: 'No',
      debrisSpaceFormC: 'No',
      valveStemCapFormC: 'No',
      tirePressureFormC: 'No',
      lugNutsFormC: 'No',
      hubOilSealFormC: 'No',
      anslight: 'No',
      cornerMarkerLight: 'No',
      mudFlap: 'No',
      doorsHingesLatches: 'No',
      weatherStripping: 'No',
      clearanceLights: 'No',
      llghtsLT: 'No',
      dOTtapeFormC: 'No',
      doteTape100FormC: 'No',
      front: 'No',
      rear: 'No',
      comments: '',
      reflectorsFormC: 'NO',
    },
    validationSchema,
    onSubmit: (values) => GetValues(values),
  })

  let fieldLabels = {
    leansLeaksDebris: 'Leans Leaks Debris',
    lightsClearance: 'Lights Clearance',
    alternatorBelt: 'Alternator Belt',
    coolantReservoir: 'Coolant Reservoir',
    waterPumpBelt: 'Water Pump Belt',
    powerSteeringPump: 'Power Steering Pump',
    airCompressorGear: 'Air CompressorGear',
    powerSteeringReservior: 'Power Steering Reservior',
    engineOilDipStick: 'Engine OilDipStick',
    engineOilFillTube: 'Engine OilFillTube',
    steeringGearBox: 'Steering GearBox',
    pitmanArm: 'PitmanArm',
    dragLink: 'DragLink',
    steeringKnucle: 'SteeringKnucle',
    castleNutCotterKey: 'Castle Nut CotterKey',
    leafSpringMountFormA: 'Leaf Spring MountFormA',
    leafSprings: 'Leaf Springs',
    shockAbsorbersFormA: 'Shock Absorbers FormA',
    brakeAirHosesFormA: 'Brake AirHosesFormA',
    brakeChamberFormA: 'Brake ChamberFormA',
    pushRodFormA: 'Push RodFormA',
    slackAdjusterFormA: 'Slack AdjusterFormA',
    brakeDrumFormA: 'Brake DrumFormA',
    brakeLiningsFormA: 'Brake LiningsFormA',
    rimFormA: 'RimFormA',
    tireABC: 'TireABC',
    tireDepthFormA: 'Tire DepthFormA',
    valveStem: 'ValveStem',
    lugNutsFormA: 'LugNutsFormA',
    hubOilSealFormA: 'HubOilSealFormA',
    lightLT: 'LightLT',
    mirrorBracket: 'MirrorBracket',
    entrySteps: 'EntrySteps',
    def: 'Def',
    doorHingesSeal: 'Door HingesSeal',
    fuelTank: 'FuelTank',
    truckFrame: 'TruckFrame',
    driveShaft: 'DriveShaft',
    tirePressureFormA: 'Tire PressureFormA',
    exhaust: 'Exhaust',
    catwalkSteps: 'CatwalkSteps',
    torqueBarFormC: 'TorqueBarFormC',
    leafSpringMountFormB: 'Leaf SpringMountFormB',
    leafSpringUJoints: 'Leaf SpringUJoints',
    brakeAirHosesFormB: 'Brake AirHosesFormB',
    brakeChamberFormB: 'Brake ChamberFormB',
    pushRodFormB: 'Push RodFormB',
    slackAdjusterFormB: 'Slack AdjusterFormB',
    brakeDrumFormB: 'Brake DrumFormB',
    brakeLiningsFormB: 'Brake LiningsFormB',
    rimFormB: 'RimFormB',
    tireABCsFormB: 'TiresABC',
    tireDepthFormB: 'Tire DepthFormB',
    debrisSpaceFormB: 'Debris SpaceFormB',
    valveStemCapFormB: 'Valve StemCapFormB',
    tirePressureFormB: 'Tire PressureFormB',
    lugNutsFormB: 'Lug NutsFormB',
    axelSeal: 'Axel Seal',
    shockAbsorbersFormB: 'Shock AbsorbersFormB',
    airbagsMounts: 'Airbags Mounts',
    mudFlapInches: 'Mud FlapInches',
    LightsLT: 'LightsLT',
    reflectorsFormB: 'Reflectors FormB',
    gladHands: 'Glad Hands',
    airLinestoTractor: 'AirLinesto Tractor',
    apron: 'Apron',
    kingPin: 'King Pin',
    lockingJaws: 'Locking Jaws',
    skidPlate: 'Skid Plate',
    platform: 'Platform',
    mountingBolts: 'Mounting Bolts',
    releaseArmFormC: 'Release ArmFormC',
    safetyLatch: 'Safety Latch',
    slidingAssembly: 'Sliding Assembly',
    clearanceforTurns: 'Clearancefor Turns',
    headerBoard: 'Header Board',
    dotTapeFormB: 'Dot TapeFormB',
    trailerFrame: 'Trailer Frame',
    landingGear: 'Landing Gear',
    lightMarker: 'Light Marker',
    reflectorsFormC: 'Reflectors FormC',
    frame: 'Frame',
    releaseArmFormB: 'Release ArmFormB',
    lockingPins: 'Locking Pins',
    torqueBarFormB: 'Torque BarFormB',
    leafSpringMounts: 'Leaf SpringMounts',
    leafSpringsUBolts: 'Leaf SpringsUBolts',
    brakeAirLines: 'Brake AirLines',
    brakeChamberFormC: 'Brake ChamberFormC',
    pushRodFormC: 'Push RodFormC',
    slackAdjusterFormC: 'Slack AdjusterFormC',
    brakeLiningsFormC: 'Brake LiningsFormC',
    brakeDrumFormC: 'Brake DrumFormC',
    rimFormC: 'Rim FormC',
    tireABCsFormA: 'Tire ABCs ',
    debrisSpaceFormC: 'Debris SpaceFormC',
    valveStemCapFormC: 'Valve StemCapFormC',
    tirePressureFormC: 'Tire PressureFormC',
    lugNutsFormC: 'Lug NutsFormC',
    hubOilSealFormC: 'Hub OilSealFormC',
    anslight: 'Ans light',
    cornerMarkerLight: 'Corner MarkerLight',
    mudFlap: 'Mud Flap',
    doorsHingesLatches: 'Doors HingesLatches',
    weatherStripping: 'Weather Stripping',
    clearanceLights: 'Clearance Lights',
    llghtsLT: 'LlghtsLT',
    dOTtapeFormC: 'DOT tapeFormC',
    doteTape100FormC: 'Dote Tape100FormC',
    front: 'Front',
    rear: 'Rear',
  }

  const formHandle = (e) => {
    const { name, value } = e.target
    setValues((va) => {
      return { ...va, [name]: value === 'No' ? 'Yes' : 'No' }
    })
  }

  const Field = (props) => {
    const { name } = props
    return (
      <CheckBoxcomponent
        value={values[name]}
        handleChange={formHandle}
        name={name}
        label={fieldLabels[name]}
      />
    )
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row xs="1">
          <Col xs="12" sm="12" md="12" lg="12">
            <Row>
              <Col xs="12" sm="12" md="6" lg="3">
                <FormGroup>
                  <Label for="">Pretrip Date</Label>
                  <Input
                    type="date"
                    name="tripDate"
                    placeholder=""
                    onBlur={handleBlur}
                    value={values.tripDate}
                    invalid={errors.tripDate && touched.tripDate ? true : false}
                    onChange={handleChange}
                  />

                  <FormFeedback>
                    {errors.tripDate && touched.tripDate ? errors.tripDate : ''}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12" sm="12" md="6" lg="3">
                <FormGroup>
                  <Label for="">Comments</Label>
                  <Input
                    type="text"
                    name="comments"
                    placeholder="Enter your comments"
                    onBlur={handleBlur}
                    value={values.comments}
                    invalid={errors.comments && touched.comments ? true : false}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row xs="12" sm="12" md="12" lg="12" className="pretriprow">
              <Col xs="12" sm="12" md="12" lg="6">
                <h4 className="pretriptitlerow">Form 1</h4>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <h6 className="pretripfontrow">Font of vehicle</h6>
                  <Field name="leansLeaksDebris" />
                  <Field name="lightsClearance" />

                  <h6 className="pretripfontrow">Engine compartment </h6>

                  <Field name="alternatorBelt" />
                  <Field name="coolantReservoir" />
                  <Field name="waterPumpBelt" />
                  <Field name="powerSteeringPump" />
                  <Field name="airCompressorGear" />
                  <Field name="powerSteeringReservior" />
                  <Field name="engineOilDipStick" />
                  <Field name="engineOilFillTube" />
                  <h6 className="pretripfontrow">Steering Axle </h6>

                  <Field name="steeringGearBox" />
                  <Field name="pitmanArm" />
                  <Field name="dragLink" />
                  <Field name="steeringKnucle" />
                  <Field name="castleNutCotterKey" />
                  <Field name="leafSpringMountFormA" />
                  <Field name="leafSprings" />
                  <Field name="shockAbsorbersFormA" />
                  <Field name="brakeAirHosesFormA" />
                  <Field name="brakeChamberFormA" />
                  <Field name="pushRodFormA" />
                  <Field name="slackAdjusterFormA" />
                  <Field name="brakeDrumFormA" />
                  <Field name="brakeLiningsFormA" />
                  <Field name="rimFormA" />
                  <Field name="tireABCsFormA" />
                  <Field name="tireDepthFormA" />
                  <Field name="valveStem" />
                  <Field name="tirePressureFormA" />
                  <Field name="lugNutsFormA" />
                  <Field name="hubOilSealFormA" />
                </Row>
              </Col>

              <Col xs="12" sm="12" md="12" lg="6">
                <h4 className="pretriptitlerow">Form 2</h4>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <h6 className="pretripfontrow">Side cab</h6>
                  <Field name="lightLT" />
                  <Field name="mirrorBracket" />
                  <Field name="entrySteps" />
                  <Field name="def" />
                  <Field name="doorHingesSeal" />
                  <h6 className="pretripfontrow">Rear of tractor</h6>

                  <Field name="fuelTank" />
                  <Field name="truckFrame" />
                  <Field name="driveShaft" />
                  <Field name="exhaust" />
                  <Field name="catwalkSteps" />
                  <Field name="torqueBarFormB" />

                  <Field name="leafSpringMountFormB" />
                  <Field name="leafSpringUJoints" />
                  <Field name="brakeAirHosesFormB" />
                  <Field name="brakeChamberFormB" />
                  <Field name="pushRodFormB" />
                  <Field name="slackAdjusterFormB" />
                  <Field name="brakeDrumFormB" />
                  <Field name="brakeLiningsFormB" />
                  <Field name="rimFormB" />
                  <Field name="tireABCsFormB" />
                  <Field name="tireDepthFormB" />
                  <Field name="debrisSpaceFormB" />
                  <Field name="valveStemCapFormB" />
                  <Field name="tirePressureFormB" />
                  <Field name="lugNutsFormB" />
                  <Field name="axelSeal" />
                  <Field name="shockAbsorbersFormB" />
                  <Field name="airbagsMounts" />
                  <Field name="mudFlapInches" />
                  <Field name="LightsLT" />
                  <Field name="reflectorsFormB" />
                  <h6 className="pretripfontrow">5th Wheel coupling</h6>

                  <Field name="gladHands" />
                  <Field name="airLinestoTractor" />
                  <Field name="apron" />
                  <Field name="kingPin" />
                  <Field name="lockingJaws" />
                  <Field name="skidPlate" />
                  <Field name="platform" />
                  <Field name="mountingBolts" />
                  <Field name="releaseArmFormB" />
                  <Field name="safetyLatch" />
                  <Field name="slidingAssembly" />
                  <Field name="clearanceforTurns" />
                </Row>
              </Col>
            </Row>

            <Row xs="12" sm="12" md="12" lg="12" className="pretriprow">
              <Col xs="12" sm="12" md="12" lg="6">
                <h4 className="pretriptitlerow">Form 1</h4>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <h6 className="pretripfontrow">Font of vehicle</h6>
                  <Field name="leansLeaksDebris" />
                  <Field name="lightsClearance" />
                  <h6 className="pretripfontrow">Engine compartment </h6>

                  <Field name="alternatorBelt" />
                  <Field name="coolantReservoir" />
                  <Field name="waterPumpBelt" />
                  <Field name="powerSteeringPump" />
                  <Field name="airCompressorGear" />
                  <Field name="powerSteeringReservior" />
                  <Field name="engineOilDipStick" />
                  <Field name="engineOilFillTube" />
                  <h6 className="pretripfontrow">Steering Axle </h6>

                  <Field name="steeringGearBox" />
                  <Field name="pitmanArm" />
                  <Field name="dragLink" />
                  <Field name="steeringKnucle" />
                  <Field name="castleNutCotterKey" />
                  <Field name="leafSpringMountFormA" />
                  <Field name="leafSprings" />
                  <Field name="shockAbsorbersFormA" />
                  <Field name="brakeAirHosesFormA" />
                  <Field name="brakeChamberFormA" />
                  <Field name="pushRodFormA" />
                  <Field name="slackAdjusterFormA" />
                  <Field name="brakeDrumFormA" />
                  <Field name="brakeLiningsFormA" />
                  <Field name="rimFormA" />
                  <Field name="tireABCsFormA" />
                  <Field name="tireDepthFormA" />
                  <Field name="valveStem" />
                  <Field name="tirePressureFormA" />
                  <Field name="lugNutsFormA" />
                  <Field name="hubOilSealFormA" />
                </Row>
              </Col>

              <Col xs="12" sm="12" md="12" lg="6">
                <h4 className="pretriptitlerow">Form 2</h4>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <h6 className="pretripfontrow">Side cab</h6>
                  <Field name="lightLT" />
                  <Field name="mirrorBracket" />
                  <Field name="entrySteps" />
                  <Field name="def" />
                  <Field name="doorHingesSeal" />
                  <h6 className="pretripfontrow">Rear of tractor</h6>

                  <Field name="fuelTank" />
                  <Field name="truckFrame" />
                  <Field name="driveShaft" />
                  <Field name="exhaust" />
                  <Field name="catwalkSteps" />
                  <Field name="torqueBarFormB" />

                  <Field name="leafSpringMountFormB" />
                  <Field name="leafSpringUJoints" />
                  <Field name="brakeAirHosesFormB" />
                  <Field name="brakeChamberFormB" />
                  <Field name="pushRodFormB" />
                  <Field name="slackAdjusterFormB" />
                  <Field name="brakeDrumFormB" />
                  <Field name="brakeLiningsFormB" />
                  <Field name="rimFormB" />
                  <Field name="tireABCsFormB" />
                  <Field name="tireDepthFormB" />
                  <Field name="debrisSpaceFormB" />
                  <Field name="valveStemCapFormB" />
                  <Field name="tirePressureFormB" />
                  <Field name="lugNutsFormB" />
                  <Field name="axelSeal" />
                  <Field name="shockAbsorbersFormB" />
                  <Field name="airbagsMounts" />
                  <Field name="mudFlapInches" />
                  <Field name="LightsLT" />
                  <Field name="reflectorsFormB" />
                  <h6 className="pretripfontrow">5th Wheel coupling</h6>

                  <Field name="gladHands" />
                  <Field name="airLinestoTractor" />
                  <Field name="apron" />
                  <Field name="kingPin" />
                  <Field name="lockingJaws" />
                  <Field name="skidPlate" />
                  <Field name="platform" />
                  <Field name="mountingBolts" />
                  <Field name="releaseArmFormB" />
                  <Field name="safetyLatch" />
                  <Field name="slidingAssembly" />
                  <Field name="clearanceforTurns" />
                </Row>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <h4 className="pretriptitlerow">Form 3</h4>
                <Row xs="1" sm="2" md="2" lg="2" className="pretriprow">
                  <h6 className="pretripfontrow">Trailer body</h6>

                  <Field name="headerBoard" />
                  <Field name="trailerFrame" />
                  <Field name="landingGear" />
                  <Field name="lightMarker" />
                  <Field name="reflectorsFormC" />
                  <h6 className="pretripfontrow">Sliding tandem assembly</h6>
                  <Field name="frame" />
                  <Field name="lockingPins" />
                  <Field name="releaseArmFormC" />
                  <h6 className="pretripfontrow">Trailer Axels</h6>
                  <Field name="leafSpringMounts" />
                  <Field name="leafSpringsUBolts" />
                  <Field name="brakeAirLines" />
                  <Field name="brakeChamberFormC" />

                  <Field name="pushRodFormC" />
                  <Field name="slackAdjusterFormC" />
                  <Field name="brakeLiningsFormC" />
                  <Field name="brakeDrumFormC" />
                  <Field name="rimFormC" />

                  <Field name="tireABC" />

                  <Field name="debrisSpaceFormC" />
                  <Field name="valveStemCapFormC" />
                  <Field name="tirePressureFormC" />
                  <Field name="lugNutsFormC" />
                  <Field name="hubOilSealFormC" />
                  <Field name="anslight" />
                  <Field name="cornerMarkerLight" />
                  <h6 className="pretripfontrow">Rear or trailer</h6>

                  <Field name="mudFlap" />
                  <Field name="doorsHingesLatches" />
                  <Field name="weatherStripping" />
                  <Field name="clearanceLights" />
                  <Field name="llghtsLT" />
                  <Field name="dOTtapeFormC" />
                  <h6 className="pretripfontrow">Exterior light check</h6>
                  <Field name="doteTape100FormC" />
                  <Field name="front" />
                  <Field name="rear" />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="btn-center">
          <Button color="primary" type="submit" className="pull-left">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  )
}

// class CheckBoxcomponent extends React.PureComponent {
//   constructor(props) {
//     super()
//   }
//   render() {
//     return (
//       <Col>
//         <FormGroup check>
//           <Label check>
//             <Input
//               name={this.props.name}
//               onChange={this.props.handleChange}
//               type="checkbox"
//               value={this.props.value}
//               checked={this.props.value == 'Yes' ? true : false}
//             />
//             {this.props.label}
//           </Label>
//         </FormGroup>
//       </Col>
//     )
//   }
// }
