import React, { Fragment, useEffect, useState } from 'react'
import { Container, Button, Card } from 'reactstrap'
import API from '../../constants/API'
import { CustomMessage, CustomPageLoading } from '../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import Timer from '../instructor/TimerComponent'
import ManualTimer from './ManualTimer'
import { useHistory, useParams } from 'react-router-dom'
import {
  CREATE_MANEUVER,
  CREATE_PRE_TRIP,
  CREATE_SAVE_DRIVE,
  CREATE_INCAB,
  BATCH_STUDENT,
} from '../../constants/Apipath'
import { BackArrow } from '../../function/Icons'
import { authHeader, getUserId } from '../../function/Mainfunction'
import Maneuver from './type/Maneuver'
import Drive from './type/Drive'
import Pretrip from './type/Pretrip'
import Incab from './type/Incab'
import Swal from 'sweetalert2'
let clockInitial = { startTime: '', endTime: '', time: '' }
export default function CommonInstructonDriveForm() {
  const { batchtype, batchId, userId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const [clocktime, setClockTime] = useState(clockInitial)
  const [batchDetails, setbatchDetails] = useState({})
  const history = useHistory()
  const [timerSwap, setTimerSwap] = useState(true)
  const [buttonDisable, setbuttonDisable] = useState(false)
  const [instmodal, setinstmodal] = useState(false)
  const Instopen = () => setinstmodal((val) => !val)

  const typeUrl = {
    Pretrip: CREATE_PRE_TRIP,
    Maneuver: CREATE_MANEUVER,
    Drive: CREATE_SAVE_DRIVE,
    Incab: CREATE_INCAB,
  }

  const timeDate = () => {
    let data = clocktime
    return data
  }

  const CreateForm = async (values) => {
    let diff = moment(values.timeOut, 'HH:mm').diff(
      moment(values.timeIn, 'HH:mm')
    )
    let d = moment.duration(diff)
    let hours = Math.floor(d.asHours())
    let minutes = moment.utc(diff).format('mm')
    let total = hours + ':' + minutes
    let { startTime, endTime, time } = timeDate()
    var Type = {
      Incab: { incabTime: time, startTime: startTime, endTime: endTime },
      Pretrip: { preTripTime: time, startTime: startTime, endTime: endTime },
      Maneuver: {
        instructorId: getUserId(),
        maneuverTime: time,
      },
      Drive: {
        instructorId: getUserId(),
        totalTime: total,
      },
    }

    const config = {
      method: 'POST',
      url: typeUrl[batchtype],
      headers: authHeader(),
      data: {
        batchId: batchId,
        studentId: userId,
        ...values,
        ...Type[batchtype],
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status } = response.data
      if (status === 'Success') {
        CustomMessage(
          batchtype + ' Created Successful',
          'success',
          enqueueSnackbar
        ) //call toster
        history.push(`/instructor/batch-details/${batchId}`)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const GetBatchDetails = async () => {
    const config = {
      method: 'GET',
      url: BATCH_STUDENT,
      headers: authHeader(),
      params: {
        BatchId: batchId,
        studentId: userId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setbatchDetails(data[0] || {})
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const CreateInstructorForm = (values) => {
    if (!(batchtype === 'Drive') && !clocktime.endTime) {
      CustomMessage('Time is not recorded', 'error', enqueueSnackbar) //call toster
      return null
    }
    Swal.fire({
      title: 'Are you want to Submit?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        CreateForm(values)
      }
    })
  }

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    GetBatchDetails()
  }, [])

  return (
    <Fragment>
      <div style={{ marginTop: '20px' }} className="page-title">
        <h6>{batchDetails.batchName} -</h6>
        <h6>
          {batchDetails.firstName} {batchDetails.lastName}{' '}
        </h6>
        {batchtype != 'Drive' && (
          <div className="btn-right">
            {batchtype == 'Incab' && (
              <Button color="danger" onClick={Instopen}>
                Instruction
              </Button>
            )}
            <Button onClick={goBack} color="primary">
              {' '}
              <BackArrow />
              Back{' '}
            </Button>
            {!timerSwap ? (
              <Button
                disabled={buttonDisable}
                onClick={() => setTimerSwap(true)}
                color={'danger'}>
                Stopwatch
              </Button>
            ) : (
              <Button className='btwbutton'
                disabled={buttonDisable}
                onClick={() => setTimerSwap(false)}
                color={'danger'}>
                BTW-Manual timer
              </Button>
            )}
          </div>
        )}
      </div>
      {batchtype != 'Drive' && (
        <div className="timer">
          {timerSwap ? (
            <Timer toogle={setbuttonDisable} GetTime={setClockTime} />
          ) : (
            <ManualTimer toogle={setbuttonDisable} GetTime={setClockTime} />
          )}
        </div>
      )}

      <Container fluid={true}>
        {loading && <CustomPageLoading />}
        <Card body>
          <>
            {batchtype == 'Incab' && (
              <Incab
                time={clocktime}
                modal={instmodal}
                toggle={Instopen}
                GetValues={CreateInstructorForm}
              />
            )}
            {batchtype == 'Pretrip' && (
              <Pretrip time={clocktime} GetValues={CreateInstructorForm} />
            )}
            {batchtype == 'Maneuver' && (
              <Maneuver time={clocktime} GetValues={CreateInstructorForm} />
            )}
            {batchtype == 'Drive' && <Drive GetValues={CreateInstructorForm} />}
          </>
        </Card>
      </Container>
    </Fragment>
  )
}
