import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import {
  Container,
  Button,
  Card,
  Table,
  Badge,
  Collapse,
  Row,
  Col,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'
import { DELETE_STUDENT, STUDENT_LIST } from '../../../constants/Apipath'
import {
  CREATE_STUDENT_ROUTE,
  STUDENT_DETAILS_ROUTE,
  UPDATE_STUDENT_ROUTE,
} from '../../../constants/RoutePaths'
import { MyLoader } from '../../../function/Loader'
import { useSnackbar } from 'notistack'
import Pagination from 'react-js-pagination'
import Avatar from 'react-avatar'
import { Add, Delete, Filter, Pencil } from '../../../function/Icons'
import { CustomMessage, locationParams } from '../../../function/Helperfunction'
import API from '../../../constants/API'
import { Link, useLocation } from 'react-router-dom'
import { authHeader } from '../../../function/Mainfunction'
import { debounce } from 'underscore'
import { StateValue, UrlReplace } from '../../../constants/Healper'
import Swal from 'sweetalert2'
import { CSVLink } from 'react-csv'
import generatePDF from './StudentPDFGenerator'
import NoRecordFound from '../../../function/EmptyRecord'
import { BsArrowBarDown } from 'react-icons/bs'

import { Dropdown as Newdropdown, Icon } from 'rsuite'
import { AssDess } from '../../../constants/Helper'

const InitialFilter = {
  studentStatus: '',
  firstName: '',
  phoneNumber: '',
  email: '',
  isWeekendStudent: false,
  offset: '0',
}
export default function Student() {
  const history = useHistory()
  const location = useLocation()
  const [totalcount, setTotalcount] = useState(0)
  const [studentlist, setStudentlist] = useState([])
  const [filterOpen, setfilterOpen] = useState(false)
  const [orderList, setorderList] = useState('LA')
  const filtertoggle = () => setfilterOpen(!filterOpen)
  const [Filterstate, setFilterstate] = useState(
    StateValue(location.search, InitialFilter)
  )
  console.log(Filterstate)
  const [loading, setloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const toggle = () => {
    setdropdownOpen(!dropdownOpen)
  }
  const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },

    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phoneNumber' },
    { label: 'Gender', key: 'gender' },
    { label: 'DOB', key: 'dateOfBirth' },
    { label: 'Address', key: 'address' },
    { label: 'Join Date', key: 'createdDate' },
    { label: 'Learning Hrs', key: 'totalTrainingHrs' },
  ]

  const Export = () => {
    generatePDF(studentlist)
  }

  const Getstudent = async (Filterstate, orderList) => {
    const config = {
      method: 'GET',
      url: STUDENT_LIST,
      headers: authHeader(),
      params: {
        count: 10,
        ...Filterstate,
        ...AssDess[orderList],
      },
    }
    console.log(config.params, 'val')
    setloading(true)
    try {
      const response = await API(config)
      const { status, data, totalCount } = response.data
      if (status === 'Success') {
        setStudentlist(data)
        setTotalcount(totalCount)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const DeleteAlert = (e) => {
    const Id = e.currentTarget.dataset.studentid
    Swal.fire({
      title: 'Are you want to Delete this Student?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteStudent(Id)
      }
    })
  }

  const DeleteStudent = async (Id) => {
    const config = {
      method: 'DELETE',
      url: DELETE_STUDENT,
      headers: authHeader(),
      params: { studentId: Id },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, message } = response.data
      if (status === 'Success') {
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
        Getstudent()
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const handlePageChange = (page) => {
    setFilterstate({ ...Filterstate, offset: page - 1 })
  }

  const CreatenewStudent = () => {
    history.push(CREATE_STUDENT_ROUTE)
  }

  const filterHandle = (e) => {
    const { name, value } = e.target
    setFilterstate({ ...Filterstate, [name]: value, offset: '0' })
  }

  const Clean = () => {
    setFilterstate(InitialFilter)
  }
  //Active/Graduate/Dropped -- student status
  let studentstatuscolor = {
    Active: 'success',
    Dropped: 'danger',
    Graduated: 'warning',
    Hold: 'info',
    'No permit': 'dark',
  }
  const delaySearch = useRef(
    debounce((Filterstate, orderList) => Getstudent(Filterstate, orderList))
  ).current
  useEffect(() => {
    let pagePath = location.pathname
    let locParams = locationParams(location.search)
    UrlReplace(pagePath, locParams, Filterstate, setfilterOpen, history)
    delaySearch(Filterstate, orderList)
  }, [Filterstate, orderList])

  const Drop = () => {
    return (
      <Newdropdown
        onSelect={(e) => OrderList(e)}
        title="First name"
        icon={<Icon icon="angle-down" />}>
        <Newdropdown.Item onClick={OrderList} icon={<Icon icon="angle-down" />}>
          First name
        </Newdropdown.Item>
        <Newdropdown.Item onSelect={OrderList} icon={<Icon icon="angle-up" />}>
          First name
        </Newdropdown.Item>
        <Newdropdown.Item icon={<Icon icon="angle-down" />}>
          Last name
        </Newdropdown.Item>
        <Newdropdown.Item icon={<Icon icon="angle-up" />}>
          Last name
        </Newdropdown.Item>
      </Newdropdown>
    )
  }
  const weekHandle = (value) => {
    setFilterstate((val) => ({
      ...val,
      isWeekendStudent: value,
    }))
  }

  const OrderList = (e) => {
    setorderList(e.target.value)
  }
  return (
    <Fragment>
      <div className="wrapper">
        <div className="page-title">
          <div className="top-title">
            <h4>Student</h4>
          </div>

          <div className="new-input">
            <Input value={orderList} onChange={OrderList} type="select">
              <option value={'FA'}>First Name (Ascending) </option>
              <option value={'LA'}>Last Name (Ascending) </option>
              <option value={'FD'}>First Name (Descending) </option>
              <option value={'LD'}>Last Name (Descending) </option>
            </Input>
          </div>
          <div className="btn-right">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle color="danger" caret>
                Export
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={Export}>PDF</DropdownItem>

                <DropdownItem>
                  <CSVLink
                    filename={'Student_Details.csv'}
                    data={studentlist}
                    headers={headers}>
                    CSV
                  </CSVLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Button onClick={filtertoggle} color="danger">
              <Filter />
              Filter
            </Button>

            <Button onClick={CreatenewStudent} color="primary">
              <Add />
              New Student
            </Button>
          </div>
        </div>
        <Container fluid={true}>
          <Collapse isOpen={filterOpen}>
            <Card body>
              <Row>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="firstName"
                      value={Filterstate.firstName}
                      placeholder="Enter Name"
                      onChange={filterHandle}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="phoneNumber"
                      value={Filterstate.phoneNumber}
                      placeholder="Enter Phone number"
                      onChange={filterHandle}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      placeholder="Enter Email"
                      type="text"
                      value={Filterstate.email}
                      name="email"
                      onChange={filterHandle}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Input
                      type="select"
                      name="studentStatus"
                      id="studentStatus"
                      placeholder="Enter first name"
                      value={Filterstate.studentStatus}
                      onChange={filterHandle}>
                      <option value="">Select status</option>
                      <option value="Active">Active</option>
                      <option value="Graduated">Graduated</option>
                      <option value="Dropped">Dropped</option>
                      <option value="Hold">Hold</option>
                      <option value="No permit">No permit</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4" xl="3">
                  <FormGroup>
                    <Button color="danger" onClick={Clean}>
                      Clean
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Card>
          </Collapse>
        </Container>

        <Container fluid={true}>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      Filterstate.isWeekendStudent === false &&
                      Filterstate.isWeekendStudent !== ''
                        ? true
                        : false,
                  })}
                  onClick={() => weekHandle(false)}>
                  Weekdays
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      Filterstate.isWeekendStudent === true &&
                      Filterstate.isWeekendStudent !== ''
                        ? true
                        : false,
                  })}
                  onClick={() => weekHandle(true)}>
                  Weekend
                </NavLink>
              </NavItem>{' '}
              <NavItem>
                <NavLink
                  className={classnames({
                    active: Filterstate.isWeekendStudent === '' ? true : false,
                  })}
                  onClick={() => weekHandle('')}>
                  All
                </NavLink>
              </NavItem>
            </Nav>
            <Card body>
              {loading && (
                <tr>
                  <td colSpan={9}>
                    <MyLoader rowCount={5} columncount={8} />
                  </td>
                </tr>
              )}
              {!loading && !!studentlist.length && (
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Gender</th>
                      <th>DOB</th>
                      <th>Address</th>
                      <th>Status</th>
                      <th>Join Date</th>
                      <th>Learning Hrs</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      studentlist.map((stu, index) => (
                        <tr className="new-class" key={index}>
                          <th>
                            <Avatar
                              name={`${stu.firstName} ${stu.lastName}`}
                              size={40}
                              round="50px"
                            />
                          </th>
                          <td>
                            <Link to={`${STUDENT_DETAILS_ROUTE}/${stu.userId}`}>
                              {stu.firstName} {``} {stu.lastName}{' '}
                            </Link>
                          </td>
                          <td>{stu.email}</td>
                          <td>{stu.phoneNumber}</td>
                          <td>{stu.gender}</td>
                          <td>{stu.dateOfBirth}</td>
                          <td>{stu.address || 'N/A'}</td>
                          <td>
                            <Badge
                              color={studentstatuscolor[stu.studentStatus]}>
                              {stu.studentStatus}
                            </Badge>
                          </td>
                          <td>{stu.createdDate}</td>
                          <td>
                            <Badge color="danger">
                              {stu.totalTrainingHrs || '00:00'}
                            </Badge>
                          </td>
                          <td className="btn-column" colSpan={2}>
                            <Link to={`${UPDATE_STUDENT_ROUTE}/${stu.userId}`}>
                              <Button color="link">
                                <Pencil />
                              </Button>
                            </Link>
                            <Button
                              data-studentid={stu.userId}
                              onClick={DeleteAlert}
                              color="link">
                              <Delete />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
              {!loading && !studentlist.length && <NoRecordFound />}
              <div className="d-flex flex-row justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={parseInt(Filterstate.offset) + 1}
                  itemsCountPerPage={10}
                  totalItemsCount={totalcount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              </div>
            </Card>
          </div>
        </Container>
      </div>{' '}
    </Fragment>
  )
}
