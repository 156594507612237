import React, { Fragment, useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Button,
  Badge,
  UncontrolledCollapse,
  NavItem,
  NavLink,
  Card,
  Row,
  Col,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
  ModalHeader,
  Modal,
  ModalBody,
  Input,
  Label,
} from 'reactstrap'
import classnames from 'classnames'
import { authHeader } from '../../../function/Mainfunction'
import API from '../../../constants/API'
import { useSnackbar } from 'notistack'
import { STUDENT_DETAILS } from '../../../constants/Apipath'
import {
  capitalizeFirstLetter,
  CustomMessage,
  JSONParser,
} from '../../../function/Helperfunction'
import { useHistory, useParams } from 'react-router'
import moment from 'moment'
import { BackArrow } from '../../../function/Icons'
import Pretrip from './Pretrip'
import Incab from './Incab'
import Maneuver from './Maneuver'
import Drive from './Drive'
import Dpstest from './ Dpstest'
import { TableLoader } from '../../../function/Loader'
import { CSVLink } from 'react-csv'
import AllStudentPDFGenerator from './StudentDetailsPDFGenerators/AllStudentPDFGenerator'
import PretripPDFGenerator from './StudentDetailsPDFGenerators/PretripPDFGenerator'
import IncabPDFGenerator from './StudentDetailsPDFGenerators/IncabPDFGenerator'
import ManeuverPDFGenrator from './StudentDetailsPDFGenerators/ManeuverPDFGenrator'
import DrivePDFGenerator from './StudentDetailsPDFGenerators/DrivePDFGenerator'
import DPSTesrPDFGenerator from './StudentDetailsPDFGenerators/DPSTesrPDFGenerator'
import RollCallPDFGenerator from './StudentDetailsPDFGenerators/RollCallPDFGenerator'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import RepsRollCall from './RepsRollCall'
import atl_log from '../../../images/atl_log.png'
import allRepot from './StudentDetailsPDFGenerators/Check'

export default function StudentDetails() {
  const history = useHistory()
  const { studentId } = useParams()
  const [activeTab, setActiveTab] = useState('1')
  const [studentDetails, setStudentDetails] = useState({})
  const [loading, setloading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const {
    dpsTest,
    driveDetails,
    incabDetails,
    maneuverDetails,
    preTripDetails,
    repsRoleAttendanceDetails,
  } = studentDetails
  const DpsTest = JSONParser(dpsTest, [])
  const DriveDetails = JSONParser(driveDetails, [])

  const IncabDetails = JSONParser(incabDetails, [])
  const ManeuverDetails = JSONParser(maneuverDetails, [])
  const PreTripDetails = JSONParser(preTripDetails, [])
  const RepsRoleAttendanceDetails = JSONParser(repsRoleAttendanceDetails, [])
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const [pdfDropdownOpen, setpdfDropdownOpen] = useState(false)
  const [csvDropdownOpen, setcsvDropdownOpen] = useState(false)
  const [previewModal, setpreviewModal] = useState(false)
  const previewToogle = () => setpreviewModal((val) => !val)

  const pdfToggle = () => {
    setpdfDropdownOpen(!pdfDropdownOpen)
  }

  const csvToggle = () => {
    setcsvDropdownOpen(!csvDropdownOpen)
  }

  const pretripHeaders = [
    { label: 'Pre-Trip ID', key: 'preTripId' },
    { label: 'Trip Date', key: 'tripDate' },
    { label: 'Pre-Trip Time', key: 'preTripTime' },
    { label: 'Comments', key: 'comments' },
    { key: 'leansLeaksDebris', label: 'Leans Leaks Debris' },
    { key: 'lightsClearance', label: 'Lights Clearance' },
    { key: 'alternatorBelt', label: 'Alternator Belt' },
    { key: 'coolantReservoir', label: 'Coolant Reservoir' },
    { key: 'waterPumpBelt', label: 'Water Pump Belt' },
    { key: 'powerSteeringPump', label: 'Power Steering Pump' },
    { key: 'airCompressorGear', label: 'Air CompressorGear' },
    { key: 'powerSteeringReservior', label: 'Power Steering Reservior' },
    { key: 'engineOilDipStick', label: 'Engine OilDipStick' },
    { key: 'engineOilFillTube', label: 'Engine OilFillTube' },
    { key: 'steeringGearBox', label: 'Steering GearBox' },
    { key: 'pitmanArm', label: 'PitmanArm' },
    { key: 'dragLink', label: 'DragLink' },
    { key: 'steeringKnucle', label: 'SteeringKnucle' },
    { key: 'castleNutCotterKey', label: 'Castle Nut CotterKey' },
    { key: 'leafSpringMountFormA', label: 'Leaf Spring MountFormA' },
    { key: 'leafSprings', label: 'Leaf Springs' },
    { key: 'shockAbsorbersFormA', label: 'Shock Absorbers FormA' },
    { key: 'brakeAirHosesFormA', label: 'Brake AirHosesFormA' },
    { key: 'brakeChamberFormA', label: 'Brake ChamberFormA' },
    { key: 'pushRodFormA', label: 'Push RodFormA' },
    { key: 'slackAdjusterFormA', label: 'Slack AdjusterFormA' },
    { key: 'brakeDrumFormA', label: 'Brake DrumFormA' },
    { key: 'brakeLiningsFormA', label: 'Brake LiningsFormA' },
    { key: 'rimFormA', label: 'RimFormA' },
    { key: 'tireABC', label: 'TireABC' },
    { key: 'tireDepthFormA', label: 'Tire DepthFormA' },
    { key: 'valveStem', label: 'ValveStem' },
    { key: 'lugNutsFormA', label: 'LugNutsFormA' },
    { key: 'hubOilSealFormA', label: 'HubOilSealFormA' },
    { key: 'lightLT', label: 'LightLT' },
    { key: 'mirrorBracket', label: 'MirrorBracket' },
    { key: 'entrySteps', label: 'EntrySteps' },
    { key: 'def', label: 'Def' },
    { key: 'doorHingesSeal', label: 'Door HingesSeal' },
    { key: 'fuelTank', label: 'FuelTank' },
    { key: 'truckFrame', label: 'TruckFrame' },
    { key: 'driveShaft', label: 'DriveShaft' },
    { key: 'tirePressureFormA', label: 'Tire PressureFormA' },
    { key: 'exhaust', label: 'Exhaust' },
    { key: 'catwalkSteps', label: 'CatwalkSteps' },
    { key: 'torqueBarFormC', label: 'TorqueBarFormC' },
    { key: 'leafSpringMountFormB', label: 'Leaf SpringMountFormB' },
    { key: 'leafSpringUJoints', label: 'Leaf SpringUJoints' },
    { key: 'brakeAirHosesFormB', label: 'Brake AirHosesFormB' },
    { key: 'brakeChamberFormB', label: 'Brake ChamberFormB' },
    { key: 'pushRodFormB', label: 'Push RodFormB' },
    { key: 'slackAdjusterFormB', label: 'Slack AdjusterFormB' },
    { key: 'brakeDrumFormB', label: 'Brake DrumFormB' },
    { key: 'brakeLiningsFormB', label: 'Brake LiningsFormB' },
    { key: 'rimFormB', label: 'RimFormB' },
    { key: 'tireABCsFormB', label: 'TiresABC' },
    { key: 'tireDepthFormB', label: 'Tire DepthFormB' },
    { key: 'debrisSpaceFormB', label: 'Debris SpaceFormB' },
    { key: 'valveStemCapFormB', label: 'Valve StemCapFormB' },
    { key: 'tirePressureFormB', label: 'Tire PressureFormB' },
    { key: 'lugNutsFormB', label: 'Lug NutsFormB' },
    { key: 'axelSeal', label: 'Axel Seal' },
    { key: 'shockAbsorbersFormB', label: 'Shock AbsorbersFormB' },
    { key: 'airbagsMounts', label: 'Airbags Mounts' },
    { key: 'mudFlapInches', label: 'Mud FlapInches' },
    { key: 'LightsLT', label: 'LightsLT' },
    { key: 'reflectorsFormB', label: 'Reflectors FormB' },
    { key: 'gladHands', label: 'Glad Hands' },
    { key: 'airLinestoTractor', label: 'AirLinesto Tractor' },
    { key: 'apron', label: 'Apron' },
    { key: 'kingPin', label: 'King Pin' },
    { key: 'lockingJaws', label: 'Locking Jaws' },
    { key: 'skidPlate', label: 'Skid Plate' },
    { key: 'platform', label: 'Platform' },
    { key: 'mountingBolts', label: 'Mounting Bolts' },
    { key: 'releaseArmFormC', label: 'Release ArmFormC' },
    { key: 'safetyLatch', label: 'Safety Latch' },
    { key: 'slidingAssembly', label: 'Sliding Assembly' },
    { key: 'clearanceforTurns', label: 'Clearancefor Turns' },
    { key: 'headerBoard', label: 'Header Board' },
    { key: 'dotTapeFormB', label: 'Dot TapeFormB' },
    { key: 'trailerFrame', label: 'Trailer Frame' },
    { key: 'landingGear', label: 'Landing Gear' },
    { key: 'lightMarker', label: 'Light Marker' },
    { key: 'reflectorsFormC', label: 'Reflectors FormC' },
    { key: 'frame', label: 'Frame' },
    { key: 'releaseArmFormB', label: 'Release ArmFormB' },
    { key: 'lockingPins', label: 'Locking Pins' },
    { key: 'torqueBarFormB', label: 'Torque BarFormB' },
    { key: 'leafSpringMounts', label: 'Leaf SpringMounts' },
    { key: 'leafSpringsUBolts', label: 'Leaf SpringsUBolts' },
    { key: 'brakeAirLines', label: 'Brake AirLines' },
    { key: 'brakeChamberFormC', label: 'Brake ChamberFormC' },
    { key: 'pushRodFormC', label: 'Push RodFormC' },
    { key: 'slackAdjusterFormC', label: 'Slack AdjusterFormC' },
    { key: 'brakeLiningsFormC', label: 'Brake LiningsFormC' },
    { key: 'brakeDrumFormC', label: 'Brake DrumFormC' },
    { key: 'rimFormC', label: 'Rim FormC' },
    { key: 'tireABCsFormA', label: 'Tire ABCs' },
    { key: 'debrisSpaceFormC', label: 'Debris SpaceFormC' },
    { key: 'valveStemCapFormC', label: 'Valve StemCapFormC' },
    { key: 'tirePressureFormC', label: 'Tire PressureFormC' },
    { key: 'lugNutsFormC', label: 'Lug NutsFormC' },
    { key: 'hubOilSealFormC', label: 'Hub OilSealFormC' },
    { key: 'anslight', label: 'Ans light' },
    { key: 'cornerMarkerLight', label: 'Corner MarkerLight' },
    { key: 'mudFlap', label: 'Mud Flap' },
    { key: 'doorsHingesLatches', label: 'Doors HingesLatches' },
    { key: 'weatherStripping', label: 'Weather Stripping' },
    { key: 'clearanceLights', label: 'Clearance Lights' },
    { key: 'llghtsLT', label: 'LlghtsLT' },
    { key: 'dOTtapeFormC', label: 'DOT tapeFormC' },
    { key: 'doteTape100FormC', label: 'Dote Tape100FormC' },
    { key: 'front', label: 'Front' },
    { key: 'rear', label: 'Rear' },
  ]

  const incabHaeders = [
    { label: 'Incab ID', key: 'incabId' },
    { label: 'Incab Date', key: 'incabDate' },
    { label: 'Comments', key: 'comments' },
    { key: 'seatbelts', label: 'Seat belts' },
    { key: 'emergencyTriangles3', label: 'Emergency Triangles3' },
    { key: 'fireExtinguisher', label: 'Fire Extinguisher' },
    { key: 'mirrorsDrivePassHood', label: 'Mirrors DrivePassHood' },
    { key: 'windshieldWipers', label: 'Windshield Wipers' },
    { key: 'circuitBreaker', label: 'Circuit Breaker' },
    { key: 'horns', label: 'Horns' },
    { key: 'steeringWheel', label: 'SteeringWheel' },
    { key: 'clutch', label: 'Clutch' },
    { key: 'startTruck', label: 'StartTruck' },
    { key: 'ABS', label: 'ABS' },
    { key: 'lightsLTRT4wayH', label: 'LightsLTRT4wayH' },
    { key: 'coolantTemperatureGauge', label: 'CoolantTemperatureGauge' },
    { key: 'oilPressureGauge', label: 'OilPressureGauge' },
    { key: 'def', label: 'Def' },
    { key: 'primarySecondaryAirGuages', label: 'PrimarySecondaryAirGuages' },
    { key: 'voltage', label: 'Voltage' },
    { key: 'heaterDefroster', label: 'Heater Defroster' },
    { key: 'washerfluid', label: 'Washerfluid' },
    { key: 'shutTruckOff', label: 'Shut TruckOff' },
    { key: 'leaveTruckinGear', label: 'Leave TruckinGear' },
    { key: 'turnKeyBackOn', label: 'TurnKey BackOn' },
    { key: 'electricOn', label: 'ElectricOn' },
    { key: 'pushInYellowValveFromA', label: 'PushInYellowValveFromA' },
    { key: 'verifyRedValve', label: 'Verify RedValve' },
    { key: 'pushonBrakePedalHold', label: 'Pushon BrakePedalHold' },
    { key: 'checkforAirLoss', label: 'Check for AirLoss' },
    { key: 'startPumpingBrakes', label: 'Start PumpingBrakes' },
    { key: 'watchforLowAirPressure', label: 'Watch for LowAirPressure' },
    { key: 'lightBuzzerPSI', label: 'Light BuzzerPSI' },
    { key: 'continuetoPumpBrakes', label: 'Continueto PumpBrakes' },
    {
      key: 'watchAirGaugeanBothValves',
      label: 'Watch Air Gaugean Both Valves',
    },
    { key: 'valvesShouldPopout', label: 'Valves Should Popout' },
    { key: 'pumpBrakesUntil', label: 'Pump Brakes Until' },
    { key: 'govTest', label: 'GovTest' },
    { key: 'pushInRedValve', label: 'Push InRedValve' },
    { key: 'leaveYellowValveOut', label: 'Leave YellowValveOut' },
    { key: 'putInLowGearandPull', label: 'PutIn LowGearandPull' },
    { key: 'pushInYellowvalveFormB', label: 'PushIn YellowvalveFormB' },
    { key: 'leaveRedValveOut', label: 'Leave RedValveOut' },
    {
      key: 'putInLowGearandPullAgainstBrakes',
      label: 'PutIn LowGearandPullAgainstBrakes',
    },
    { key: 'placeVehicleinLowGear', label: 'Place VehicleinLowGear' },
    { key: 'releaseAllBrakes', label: 'Release AllBrakes' },
    { key: 'applyBrakesFirmly', label: 'Apply BrakesFirmly' },
  ]

  const maneuverHeaders = [
    { label: 'Maneuver', key: 'maneuver' },
    { label: 'Mirrors', key: 'mirrors' },
    { label: 'Steering', key: 'steering' },
    { label: 'Encroaches', key: 'encroaches' },
    { label: 'Maneuver Date', key: 'maneuverDate' },
    { label: 'Maneuver Time', key: 'maneuverTime' },
    { label: 'Score', key: 'score' },
    { label: 'Comments', key: 'comments' },
  ]

  const driveHaeders = [
    { label: 'Bears', key: 'bears' },
    { label: 'Brake', key: 'brake' },
    { label: 'Downshifting', key: 'downshifting' },
    { label: 'FollowDistance', key: 'followDistance' },
    { label: 'Comments', key: 'comments' },
    { label: 'GearPattern', key: 'gearPattern' },
    { label: 'Brake', key: 'brake' },
    { label: 'HitCurb', key: 'hitCurb' },
    { label: 'HoverH', key: 'hoverH' },
    { label: 'Comments', key: 'lane' },
    { label: 'InterCoasting', key: 'interCoasting' },
    { label: 'IntersectionMirrors', key: 'intersectionMirrors' },
    { label: 'IntersectiontrafficCheck', key: 'intersectiontrafficCheck' },
    { label: 'MergingSmooth', key: 'mergingSmooth' },
    { label: 'MergingTrafficCheck', key: 'mergingTrafficCheck' },
    { label: 'TimeIn', key: 'timeIn' },
    { label: 'TimeOut', key: 'timeOut' },
    { label: 'Timing', key: 'timing' },
    { label: 'TotalTime', key: 'totalTime' },
    { label: 'Reaction', key: 'lane' },
  ]

  const dpsTestHeaders = [
    { label: 'Test Date', key: 'testDate' },
    { label: 'Test Hours', key: 'testHours' },
    { label: 'Comments', key: 'comments' },
    { label: 'Test Result', key: 'testResult' },
  ]

  const rollCallHeaders = [
    { label: 'Date', key: 'attendanceDate' },
    { label: 'AM', key: 'am' },
    { label: 'PM', key: 'pm' },
    { label: 'Noon', key: 'noon' },
  ]

  const AllStudentDetails = [
    ...DpsTest,
    ...DriveDetails,
    ...IncabDetails,
    ...ManeuverDetails,
    ...PreTripDetails,
    ...RepsRoleAttendanceDetails,
  ]

  //  const DpsTest = JSONParser(dpsTest, [])
  //  const DriveDetails = JSONParser(driveDetails, [])
  //  const IncabDetails = JSONParser(incabDetails, [])
  //  const ManeuverDetails = JSONParser(maneuverDetails, [])
  //  const PreTripDetails = JSONParser(preTripDetails, [])

  var csvDps = [[''], ['Dps Test'], dpsTestHeaders.map((i) => i.label)]
  DpsTest.forEach((s) =>
    csvDps.push([s.testDate, s.testHours, s.comments, s.testResult])
  )
  var csvDrive = [[''], ['Drive'], driveHaeders.map((i) => i.label)]

  DriveDetails.forEach((d) =>
    csvDrive.push([
      d.bears,
      d.brake,
      d.downshifting,
      d.followDistance,
      d.comments,
      d.gearPattern,
      d.hitCurb,
      d.hoverH,
      d.lane,
      d.interCoasting,
      d.intersectionMirrors,
      d.intersectiontrafficCheck,
      d.mergingSmooth,
      d.mergingTrafficCheck,
      d.timeIn,
      d.timeOut,
      d.timing,
      d.totalTime,
      d.lane,
      d.totalDrivingTime,
    ])
  )

  var csvIncab = [[''], ['In cab'], incabHaeders.map((i) => i.label)]
  IncabDetails.forEach((i) =>
    csvIncab.push([
      i.incabId,
      i.incabDate,
      i.comments,
      i.seatbelts,
      i.emergencyTriangles3,
      i.fireExtinguisher,
      i.mirrorsDrivePassHood,
      i.windshieldWipers,
      i.circuitBreaker,
      i.horns,
      i.steeringWheel,
      i.clutch,
      i.startTruck,
      i.ABS,
      i.lightsLTRT4wayH,
      i.coolantTemperatureGauge,
      i.oilPressureGauge,
      i.def,
      i.primarySecondaryAirGuages,
      i.voltage,
      i.heaterDefroster,
      i.washerfluid,
      i.shutTruckOff,
      i.leaveTruckinGear,
      i.turnKeyBackOn,
      i.electricOn,
      i.pushInYellowValveFromA,
      i.verifyRedValve,
      i.pushonBrakePedalHold,
      i.checkforAirLoss,
      i.startPumpingBrakes,
      i.watchforLowAirPressure,
      i.lightBuzzerPSI,
      i.continuetoPumpBrakes,
      i.watchAirGaugeanBothValves,
      i.valvesShouldPopout,
      i.pumpBrakesUntil,
      i.govTest,
      i.pushInRedValve,
      i.leaveYellowValveOut,
      i.putInLowGearandPull,
      i.pushInYellowvalveFormB,
      i.leaveRedValveOut,
      i.putInLowGearandPullAgainstBrakes,
      i.placeVehicleinLowGear,
      i.releaseAllBrakes,
      i.applyBrakesFirmly,
    ])
  )

  var csvMan = [[''], ['Maneuver'], maneuverHeaders.map((i) => i.label)]
  ManeuverDetails.forEach((m) =>
    csvMan.push([
      m.maneuver,
      m.mirrors,
      m.steering,
      m.encroaches,
      m.maneuverDate,
      m.maneuverTime,
      m.score,
      m.comments,
    ])
  )

  var csvPre = [[''], ['PreTrip'], pretripHeaders.map((i) => i.label)]
  PreTripDetails.forEach((p) =>
    csvPre.push([
      p.preTripId,
      p.tripDate,
      p.preTripTime,
      p.comments,
      p.leansLeaksDebris,
      p.lightsClearance,
      p.alternatorBelt,
      p.coolantReservoir,
      p.waterPumpBelt,
      p.powerSteeringPump,
      p.airCompressorGear,
      p.powerSteeringReservior,
      p.engineOilDipStick,
      p.engineOilFillTube,
      p.steeringGearBox,
      p.pitmanArm,
      p.dragLink,
      p.steeringKnucle,
      p.castleNutCotterKey,
      p.leafSpringMountFormA,
      p.leafSprings,
      p.shockAbsorbersFormA,
      p.brakeAirHosesFormA,
      p.brakeChamberFormA,
      p.pushRodFormA,
      p.slackAdjusterFormA,
      p.brakeDrumFormA,
      p.brakeLiningsFormA,
      p.rimFormA,
      p.tireABC,
      p.tireDepthFormA,
      p.valveStem,
      p.lugNutsFormA,
      p.hubOilSealFormA,
      p.lightLT,
      p.mirrorBracket,
      p.entrySteps,
      p.def,
      p.doorHingesSeal,
      p.fuelTank,
      p.truckFrame,
      p.driveShaft,
      p.tirePressureFormA,
      p.exhaust,
      p.catwalkSteps,
      p.torqueBarFormC,
      p.leafSpringMountFormB,
      p.leafSpringUJoints,
      p.brakeAirHosesFormB,
      p.brakeChamberFormB,
      p.pushRodFormB,
      p.slackAdjusterFormB,
      p.brakeDrumFormB,
      p.brakeLiningsFormB,
      p.rimFormB,
      p.tireABCsFormB,
      p.tireDepthFormB,
      p.debrisSpaceFormB,
      p.valveStemCapFormB,
      p.tirePressureFormB,
      p.lugNutsFormB,
      p.axelSeal,
      p.shockAbsorbersFormB,
      p.airbagsMounts,
      p.mudFlapInches,
      p.LightsLT,
      p.reflectorsFormB,
      p.gladHands,
      p.airLinestoTractor,
      p.apron,
      p.kingPin,
      p.lockingJaws,
      p.skidPlate,
      p.platform,
      p.mountingBolts,
      p.releaseArmFormC,
      p.safetyLatch,
      p.slidingAssembly,
      p.clearanceforTurns,
      p.headerBoard,
      p.dotTapeFormB,
      p.trailerFrame,
      p.landingGear,
      p.lightMarker,
      p.reflectorsFormC,
      p.frame,
      p.releaseArmFormB,
      p.lockingPins,
      p.torqueBarFormB,
      p.leafSpringMounts,
      p.leafSpringsUBolts,
      p.brakeAirLines,
      p.brakeChamberFormC,
      p.pushRodFormC,
      p.slackAdjusterFormC,
      p.brakeLiningsFormC,
      p.brakeDrumFormC,
      p.rimFormC,
      p.tireABCsFormA,
      p.debrisSpaceFormC,
      p.valveStemCapFormC,
      p.tirePressureFormC,
      p.lugNutsFormC,
      p.hubOilSealFormC,
      p.anslight,
      p.cornerMarkerLight,
      p.mudFlap,
      p.doorsHingesLatches,
      p.weatherStripping,
      p.clearanceLights,
      p.llghtsLT,
      p.dOTtapeFormC,
      p.doteTape100FormC,
      p.front,
      p.rear,
    ])
  )

  var rollCallPre = [[''], ['Roll call'], rollCallHeaders.map((i) => i.label)]

  RepsRoleAttendanceDetails.forEach((p) =>
    rollCallPre.push([p.attendanceDate, p.am, p.pm, p.noon])
  )

  const csvAll = [
    ...csvDps,
    ...csvDrive,
    ...csvIncab,
    ...csvMan,
    ...csvPre,
    ...rollCallPre,
  ]

  const AllExportPDF = () => {
    AllStudentPDFGenerator(studentDetails.firstName, AllStudentDetails)
    // allRepot()
  }
  const PretripExportPDF = () => {
    PretripPDFGenerator(studentDetails.firstName, PreTripDetails)
  }
  const IncabExportPDF = () => {
    IncabPDFGenerator(studentDetails.firstName, IncabDetails)
  }
  const ManeuverExportPDF = () => {
    ManeuverPDFGenrator(studentDetails.firstName, ManeuverDetails)
  }
  const DriveExportPDF = () => {
    DrivePDFGenerator(studentDetails.firstName, DriveDetails)
  }
  const DPSTestExportPDF = () => {
    DPSTesrPDFGenerator(studentDetails.firstName, DpsTest)
  }

  const RollCallExportPDF = () => {
    RollCallPDFGenerator(studentDetails.firstName, RepsRoleAttendanceDetails)
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const Getstudent = async () => {
    const config = {
      method: 'GET',
      url: STUDENT_DETAILS,
      headers: authHeader(),
      params: {
        studentId: studentId,
      },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data

      if (status === 'Success') {
        setStudentDetails(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const Back = () => {
    history.goBack()
  }

  useEffect(() => {
    Getstudent()
  }, [])

  const handleOpen = () => {
    setdropdownOpen(!dropdownOpen)
  }

  const getImageblob = async () => {
    let canvas = await html2canvas(document.getElementById('container'), {
      useCORS: true,
    })

    var imgData = canvas.toDataURL('image/jpeg', 1.0)
    var doc = new jsPDF('p', 'mm', 'a4')
    var width = doc.internal.pageSize.getWidth()
    var height = doc.internal.pageSize.getHeight()
    doc.addImage(imgData, 'JPEG', 10, 20, width - 20, height - 120)

    doc.save(
      `${studentDetails.firstName + ' ' + studentDetails.lastName}_Details.pdf`
    )
  }
  const colSty = { 'margin-top': '13px' }
  const CheckBoxsty = {
    display: 'flex',
    'margin-left': '30px',
  }
  const headingsty = { 'font-weight': '600' }
  return (
    <Fragment>
      <div className="wrapper">
        <div className="page-title">
          <h4>Student Details123</h4>
          <div className="btn-right">
            <Button onClick={handleOpen} color="danger">
              Export
            </Button>

            {dropdownOpen && (
              <>
                <Dropdown isOpen={pdfDropdownOpen} toggle={pdfToggle}>
                  <DropdownToggle color="danger" caret>
                    PDF
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={AllExportPDF}>ALL</DropdownItem>
                    <DropdownItem onClick={PretripExportPDF}>
                      Pretrip
                    </DropdownItem>
                    <DropdownItem onClick={IncabExportPDF}>Incab</DropdownItem>
                    <DropdownItem onClick={ManeuverExportPDF}>
                      Maneuver
                    </DropdownItem>
                    <DropdownItem onClick={DriveExportPDF}>Driver</DropdownItem>
                    <DropdownItem onClick={DPSTestExportPDF}>
                      DPS Test
                    </DropdownItem>
                    <DropdownItem onClick={RollCallExportPDF}>
                      Roll Call
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown isOpen={csvDropdownOpen} toggle={csvToggle}>
                  <DropdownToggle color="danger" caret>
                    CSV
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_All_Details.csv`}
                        data={csvAll}>
                        <span className="csvLink">ALL</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_Pretrip_Details.csv`}
                        data={PreTripDetails}
                        headers={pretripHeaders}>
                        <span className="csvLink">Pretrip</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_Incab_Details.csv`}
                        data={IncabDetails}
                        headers={incabHaeders}>
                        <span className="csvLink">Incab</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_Maneuver_Details.csv`}
                        data={ManeuverDetails}
                        headers={maneuverHeaders}>
                        <span className="csvLink">Maneuver</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_Drive_Details.csv`}
                        data={DriveDetails}
                        headers={driveHaeders}>
                        <span className="csvLink">Drive</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_DPSTest_Details.csv`}
                        data={DpsTest}
                        headers={dpsTestHeaders}>
                        <span className="csvLink">DPS Test</span>
                      </CSVLink>
                    </DropdownItem>
                    <DropdownItem>
                      <CSVLink
                        filename={`${
                          studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName
                        }_RepsRoll_Details.csv`}
                        data={RepsRoleAttendanceDetails}
                        headers={rollCallHeaders}>
                        <span className="csvLink">Roll Call</span>
                      </CSVLink>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </>
            )}

            <Button onClick={Back} color="primary">
              <BackArrow />
              Back
            </Button>
          </div>
        </div>

        <Container fluid={true}>
          <div>
            <Card body>
              <div className="detail-card col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="top col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="name col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <h4>
                      {loading ? (
                        <TableLoader />
                      ) : (
                        studentDetails.firstName + ' ' + studentDetails.lastName
                      )}
                      <Badge
                        pill
                        color={
                          studentDetails.isWeekendStudent ? 'danger' : 'primary'
                        }>
                        {studentDetails.isWeekendStudent ? 'We' : 'wd'}
                      </Badge>
                    </h4>
                  </div>

                  <div className="right col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div style={{ display: 'flex', float: 'right' }}>
                      {DriveDetails.map((dr) => {
                        const { totalDrivingTime } = dr
                        const minutes = totalDrivingTime % 60
                        const hours = Math.floor(totalDrivingTime / 60)
                        function padTo2Digits(num) {
                          return num.toString().padStart(2, '0')
                        }
                        const total = `${padTo2Digits(hours)}:${padTo2Digits(
                          minutes
                        )}`
                        return (
                          <Badge color="danger" style={{ marginRight: '15px' }}>
                            Total time : {total || '00:00'}
                          </Badge>
                        )
                      })}
                      <Badge color="danger">
                        Learning Hours :{' '}
                        {studentDetails.totalTrainingHrs || '00:00'}
                      </Badge>
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.347"
                          height="14.83"
                          viewBox="0 0 13.347 14.83">
                          <path
                            id="Icon_material-date-range"
                            data-name="Icon material-date-range"
                            d="M8.949,9.673H7.466v1.483H8.949Zm2.966,0H10.432v1.483h1.483Zm2.966,0H13.4v1.483h1.483Zm1.483-5.19h-.741V3H14.139V4.483H8.207V3H6.724V4.483H5.983A1.476,1.476,0,0,0,4.507,5.966L4.5,16.347A1.483,1.483,0,0,0,5.983,17.83H16.364a1.487,1.487,0,0,0,1.483-1.483V5.966A1.487,1.487,0,0,0,16.364,4.483Zm0,11.864H5.983V8.19H16.364Z"
                            transform="translate(-4.5 -3)"
                            fill="#c2c2c2"
                          />
                        </svg>
                        Joined {moment(studentDetails.createdDate).format('LL')}
                      </p>
                    </div>
                  </div>
                </div>

                <Row className="bottom pl-3">
                  <Col sm="2">
                    <div className="info">
                      <span>First name</span>
                      <p>
                        {' '}
                        {loading ? <TableLoader /> : studentDetails.firstName}
                      </p>
                    </div>
                  </Col>

                  <Col sm="2">
                    <div className="info">
                      <span>Last name</span>
                      <p>{studentDetails.lastName}</p>
                    </div>
                  </Col>

                  <Col sm="2">
                    <div className="info">
                      <span>Email</span>
                      <p>{studentDetails.email}</p>
                    </div>
                  </Col>

                  <Col sm="2">
                    <div className="info">
                      <span>Gender</span>
                      <p>
                        {loading ? (
                          <TableLoader />
                        ) : (
                          studentDetails.gender || 'N/A'
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col sm="2">
                    <div className="info">
                      <span>DOB</span>
                      <p>{studentDetails.dateOfBirth}</p>
                    </div>
                  </Col>
                  <Col sm="2">
                    <Button
                      onClick={previewToogle}
                      color="primary"
                      size="sm"
                      className="mt-3">
                      View All
                    </Button>
                  </Col>

                  <Col sm="2">
                    <Button
                      color="primary"
                      size="sm"
                      className="mt-3"
                      id="toggler">
                      Show more
                    </Button>
                  </Col>
                </Row>

                <UncontrolledCollapse className="p-0" toggler="#toggler">
                  <Row className="bottom m-0 border-none">
                    <Col sm="2">
                      <div className="info">
                        <span>Phone</span>
                        <p>{studentDetails.phoneNumber}</p>
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="info">
                        <span>Address</span>
                        <p>{studentDetails.address}</p>
                      </div>
                    </Col>

                    <Col sm="2">
                      <div className="info">
                        <span>Refered By</span>
                        <p>{studentDetails.referedBy || 'N/A'}</p>
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="info">
                        <span>Recruiter</span>
                        <p>{studentDetails.recruiter || 'N/A'}</p>
                      </div>
                    </Col>
                  </Row>
                </UncontrolledCollapse>
              </div>
            </Card>
          </div>

          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1')
                  }}>
                  Pretrip {PreTripDetails.length}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2')
                  }}>
                  Incab {IncabDetails.length}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    toggle('3')
                  }}>
                  Maneuver {ManeuverDetails.length}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => {
                    toggle('4')
                  }}>
                  Drive {DriveDetails.length}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => {
                    toggle('5')
                  }}>
                  DPS Test {DpsTest.length}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '6' })}
                  onClick={() => {
                    toggle('6')
                  }}>
                  Roll Call {RepsRoleAttendanceDetails.length}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Pretrip PreTripDetails={PreTripDetails} />
              </TabPane>
              <TabPane tabId="2">
                <Incab IncabDetails={IncabDetails} />
              </TabPane>
              <TabPane tabId="3">
                <Maneuver ManeuverDetails={ManeuverDetails} />
              </TabPane>
              <TabPane tabId="4">
                <Drive DriveDetails={DriveDetails} />
              </TabPane>
              <TabPane tabId="5">
                <Dpstest DpsTest={DpsTest} />
              </TabPane>
              <TabPane tabId="6">
                <RepsRollCall RepsRoll={RepsRoleAttendanceDetails} />
              </TabPane>
            </TabContent>
          </div>
          <Modal size="lg" isOpen={previewModal} toggle={previewToogle}>
            <ModalHeader toggle={previewToogle}>
              <Button color="danger" onClick={getImageblob}>
                PRINT
              </Button>
            </ModalHeader>
            <ModalBody>
              <div id="container" className="container">
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '25px',
                    'margin-top': '30px',
                  }}>
                  <img src={atl_log}></img>
                  <p> ............................................</p>
                </div>
                <Row xs="2">
                  <Col>
                    <Row xs="4">
                      <Col style={headingsty} xs="4">
                        Name:
                      </Col>
                      <Col xs="8">
                        {studentDetails.firstName +
                          ' ' +
                          studentDetails.lastName}
                      </Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      {/* <Col xs="4">SSN:</Col>
                      <Col xs="8">{'reffernce'}</Col>
                           <Col style={colSty} xs="12">{` `}</Col> */}
                      <Col style={headingsty} xs="4">
                        DOB:
                      </Col>
                      <Col xs="8">{studentDetails.dateOfBirth}</Col>{' '}
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="4">
                        ADDRESS:
                      </Col>
                      <Col xs="8">{studentDetails.address || 'N/A'}</Col>{' '}
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="2">
                        Zip:
                      </Col>
                      <Col xs="4">{studentDetails.zip || 'N/A'}</Col>
                      <Col style={headingsty} xs="2">
                        STATE:
                      </Col>
                      <Col xs="4">{studentDetails.state || 'N/A'}</Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="5">
                        EMAIL ADDRESS:
                      </Col>
                      <Col style={{ right: '32px' }} xs="6">
                        {studentDetails.email || 'N/A'}
                      </Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="4">
                        DOWN PYMT:
                      </Col>
                      <Col xs="2">${studentDetails.downPayment || 'N/A'}</Col>
                      <Col
                        style={{
                          display: 'flex',

                          'justify-content': 'center',
                        }}
                        xs="6">
                        <Label style={headingsty} check>
                          <Input
                            type="checkbox"
                            checked={studentDetails.isPayed}
                          />{' '}
                          $200 PAID
                        </Label>
                      </Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="4">
                        ADMISSION REP:
                      </Col>
                      <Col xs="8">
                        {studentDetails.admissionRepName || 'N/A'}
                      </Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="4">
                        REFERRED BY:
                      </Col>
                      <Col xs="8">{studentDetails.referedBy || 'N/A'}</Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                    </Row>
                  </Col>

                  <Col>
                    <Row xs="2">
                      <Col style={headingsty} xs="6">
                        CELL:
                      </Col>
                      <Col xs="6">{studentDetails.phoneNumber} </Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="6">
                        ALT.PHONE:
                      </Col>
                      <Col xs="6">
                        {studentDetails.alternatePhone || 'N/A'}{' '}
                      </Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="6">
                        START DATE:
                      </Col>
                      <Col xs="6">{studentDetails.createdDate} </Col>
                      <Col style={colSty} xs="12">{` `}</Col>

                      <Col style={headingsty} xs="6">
                        GENDER:
                      </Col>
                      <Col xs="6">
                        {capitalizeFirstLetter(studentDetails.gender) || 'N/A'}{' '}
                      </Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="6">
                        {' '}
                        CITY:
                      </Col>
                      <Col xs="6">{studentDetails.city || 'N/A'} </Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="6">
                        DRIVERS LICENSE:
                      </Col>
                      <Col xs="6">
                        {studentDetails.driversLicense || 'N/A' || 'N/A'}{' '}
                      </Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="6">
                        FUNDING SOURCE:
                      </Col>
                      <Col xs="6">
                        {studentDetails.fundingSource || 'N/A' || 'N/A'}{' '}
                      </Col>
                      <Col style={colSty} xs="12">{` `}</Col>
                      <Col style={headingsty} xs="6">
                        ENROLLMENT DATE:
                      </Col>
                      <Col xs="6">
                        {studentDetails.entrollmentDate || 'N/A' || 'N/A'}{' '}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row xs="2">
                  <Col>
                    <Row xs="2">
                      <Col>
                        <Row xs="1">
                          <Col style={CheckBoxsty} xs="12">
                            <Label>
                              <Input
                                checked={studentDetails.copyOfDL}
                                type="checkbox"
                              />{' '}
                              COPY OF DL
                            </Label>
                          </Col>
                          <Col style={CheckBoxsty} xs="12">
                            <Label>
                              <Input
                                checked={studentDetails.copyOfSS}
                                type="checkbox"
                              />
                              COPY OF SS
                            </Label>
                          </Col>
                          <Col style={CheckBoxsty} xs="12">
                            <Label>
                              <Input
                                checked={studentDetails.copyOfPermit}
                                type="checkbox"
                              />
                              COPY OF PERMIT
                            </Label>
                          </Col>
                          <Col style={CheckBoxsty} xs="12">
                            <Label>
                              <Input
                                checked={studentDetails.medicalCard}
                                type="checkbox"
                              />{' '}
                              MEDICAL CARD
                            </Label>
                          </Col>
                          <Col style={CheckBoxsty} xs="12">
                            <Label>
                              <Input
                                checked={studentDetails.uaResults}
                                type="checkbox"
                              />{' '}
                              UA RESULTS
                            </Label>
                          </Col>{' '}
                          <Col style={CheckBoxsty} xs="12">
                            <Label>
                              <Input
                                checked={studentDetails.birthCertificate}
                                type="checkbox"
                              />{' '}
                              BIRTH CERTIFICATE
                            </Label>
                          </Col>{' '}
                          <Col style={CheckBoxsty} xs="12">
                            <Label>
                              <Input
                                checked={studentDetails.mvrBackground}
                                type="checkbox"
                              />{' '}
                              MVR/ BACKGROUND
                            </Label>
                          </Col>{' '}
                        </Row>
                      </Col>

                      <Col>
                        <Row style={{ 'margin-top': '45px' }} xs="2">
                          <Col xs="7">ISSUE DATE:</Col>
                          <Col xs="3">
                            {studentDetails.permitIssueDate || 'N/A'}
                          </Col>
                          <Col style={colSty} xs="12">
                            {' '}
                          </Col>
                          <Col xs="6">EXP.DATE:</Col>{' '}
                          <Col xs="6">
                            {studentDetails.medicalCardExpDate || 'N/A'}
                          </Col>
                          <Col style={colSty} xs="12">
                            {' '}
                          </Col>
                          {/* <Col xs="8"> DATE TESTED:</Col>
                          <Col xs="4"></Col>
                          <Col  style={colSty} xs="12">
                            {' '}
                            
                          </Col> */}
                          <Col style={colSty} xs="12">
                            {' '}
                          </Col>
                          {/* <Col xs="6">COUNSELOR</Col>
                          <Col xs="6">
                            {studentDetails.counselor ? 'YES' : 'NO'}
                          </Col> */}
                          <Col style={colSty} xs="12">
                            {' '}
                          </Col>{' '}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row xs="1">
                      <Col style={CheckBoxsty}>
                        <Label>
                          <Input
                            checked={studentDetails.entrollContract}
                            type="checkbox"
                          />
                          ENTROLL.CONTRACT
                        </Label>
                      </Col>

                      <Col style={CheckBoxsty}>
                        <Label>
                          <Input
                            checked={studentDetails.qaSchoolApp}
                            type="checkbox"
                          />
                          Q/A-SCHOOL APP
                        </Label>
                      </Col>

                      <Col style={CheckBoxsty}>
                        <Label>
                          <Input
                            checked={studentDetails.dpsPemitPolicy}
                            type="checkbox"
                          />
                          DPS PERMIT POLICY
                        </Label>
                      </Col>

                      <Col style={CheckBoxsty}>
                        <Label>
                          <Input
                            checked={studentDetails.proofOfRes}
                            type="checkbox"
                          />
                          PROOF OF RES.
                        </Label>
                      </Col>
                    </Row>
                  </Col>
                  <Row style={{ 'margin-left': '0px' }} xs="4">
                    <Col xs="4" style={CheckBoxsty}>
                      <Label>
                        <Input
                          checked={studentDetails.tribalDocs}
                          type="checkbox"
                        />{' '}
                        TRIBAL DOCS
                      </Label>
                    </Col>{' '}
                    <Col style={{ 'margin-left': '31px' }} xs="3">
                      TRIBE:
                    </Col>
                    <Col xs="3">{studentDetails.tribeDesc ? 'YES' : 'NO'}</Col>
                    <Col xs="5" style={CheckBoxsty}>
                      <Label>
                        <Input
                          checked={studentDetails.agencyDoc}
                          type="checkbox"
                        />{' '}
                        AGENCY DOCS
                      </Label>
                    </Col>{' '}
                    <Col xs="3">counselor</Col>
                    <Col xs="3">
                      {' '}
                      {studentDetails.counselor
                        ? studentDetails.counselor
                        : 'N/A'}
                    </Col>
                    <Col xs="4">NOTES</Col>{' '}
                    <Col xs="8">{studentDetails.notes || 'N/A'} </Col>{' '}
                  </Row>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={previewToogle} color="primary">
                Close
              </Button>{' '}
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </Fragment>
  )
}
