import React, { Fragment, useEffect, useState } from 'react'
import { Container, Col, Row, Button, Badge } from 'reactstrap'
import { CustomMessage, JSONParser } from '../../function/Helperfunction'
import { useSnackbar } from 'notistack'
import API from '../../constants/API'
import { authHeader } from '../../function/Mainfunction'
import { GET_BATCH } from '../../constants/Apipath'
import { useHistory, useParams } from 'react-router'
import {
  BackArrow,
  SmallCalender,
  SmallLocation,
  SmallTruck,
  SmallWatch,
} from '../../function/Icons'
import { TableLoader } from '../../function/Loader'
import StudentBox from './StudentBox'
import { UPDATE_BATCH_ROUTE } from '../../constants/RoutePaths'
export default function BatchDetails() {
  const [loading, setloading] = useState(false)
  const { batchId } = useParams()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [batch, setBatch] = useState({})
  const StudentList = JSONParser(batch.studentDetails, [])
  const GetBatch = async () => {
    const config = {
      method: 'GET',
      url: GET_BATCH,
      headers: authHeader(),
      params: { batchId: batchId },
    }
    setloading(true)
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status === 'Success') {
        setBatch(data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
      }
    } finally {
      setloading(false)
    }
  }

  const Back = () => {
    history.goBack()
  }

  const editBatch = () => {
    history.push(UPDATE_BATCH_ROUTE + '/' + batchId)
  }

  useEffect(() => {
    GetBatch()
  }, [])

  return (
    <Fragment>
      <div className="page-title">
        <h4>Batch details</h4>
        <div className="btn-right">
          <Button onClick={Back} color="primary">
            <BackArrow />
            Back
          </Button>{' '}
        </div>
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <div className="batch-details-card card card-body">
              <div className="top">
                <h4>{batch.batchName}</h4>
                <Badge color="info">{batch.type}</Badge>
                {`     `}
                {/* {batch.hoursLeft ? (
                  <>
                    Time Left:
                    <Badge color="danger">
                      {batch.hoursLeft} {` `}
                    </Badge>
                    {'  '}Hours
                  </>
                ) : (
                  <Badge color="success" className="ml-auto">
                    Active
                  </Badge>
                )} */}
              </div>
              <Row>
                <Col md="3">
                  <div className="column">
                    <SmallCalender />
                    <p>
                      {loading && <TableLoader />}
                      {!loading &&
                        `${batch.batchStartDate + '-' + batch.batchEndDate}`}
                    </p>
                  </div>
                </Col>
                <Col md="3">
                  <div className="column">
                    <SmallWatch />
                    <p>
                      {loading && <TableLoader />}
                      {!loading &&
                        `${batch.batchStartTime + '-' + batch.batchEndTime}`}
                    </p>
                  </div>
                </Col>
                <Col md="3">
                  <div className="column">
                    <SmallTruck />
                    {loading && <TableLoader />}

                    {!loading && <p>Truck name - {batch.truckName}</p>}
                  </div>
                </Col>
                <Col md="3">
                  <div className="column">
                    <SmallLocation />
                    {loading && <TableLoader />}

                    <p> {!loading && batch.stationName}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <StudentBox batchId={batchId} StudentList={StudentList} />
        </Row>
      </Container>
    </Fragment>
  )
}
